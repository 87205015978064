// marketAnalysisService.js
import { api } from './config';

class MarketAnalysisService {
  async getMarketAnalysisByProduct(productId, params = {}) {
    try {
      const response = await api.get(`/market/${productId}`, { params });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { data: null };
      }
      throw new Error(`Error fetching market analysis: ${error.message}`);
    }
  }

  async createAnalysis(productId) {
    try {
      const response = await api.post(`/market/generate/${productId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating analysis: ${error.message}`);
    }
  }

  formatChartData(marketData, selectedProduct) {
    if (!marketData) return null;

    // Format for the line chart
    const marketShareData = marketData.historicalData?.map(point => {
      // Use analysisDate or, if not available, date
      const rawDate = point.analysisDate || point.date;
      const dateObj = rawDate ? new Date(rawDate) : null;
      return {
        name: dateObj ? dateObj.toLocaleDateString() : 'N/A',
        marketShare: point.share,
        competitors: point.competitorShare,
        potential: point.marketPotential
      };
    });

    // Format for the pie chart
    let competitorData =
      marketData.competitors?.map(competitor => ({
        name: competitor.name,
        value: competitor.marketShare,
        strengths: competitor.strengths,
        weaknesses: competitor.weaknesses
      })) || [];

    // Add our product as an additional slice
    if (selectedProduct && marketData.marketShare !== undefined) {
      const productSlice = {
        name: selectedProduct.name,
        value: marketData.marketShare,
        // Optionally, include strengths/weaknesses if available
        strengths: marketData.strengths || [],
        weaknesses: marketData.weaknesses || []
      };
      competitorData = [productSlice, ...competitorData];
    }

    return {
      marketShare: {
        data: marketShareData || []
      },
      competitors: competitorData || []
    };
  }

  calculateMetrics(marketData) {
    if (!marketData) return null;

    return {
      marketFit: this._calculateMarketFit(marketData),
      yearOverYear: this._calculateYearOverYear(marketData),
      competitivePosition: this._calculateCompetitivePosition(marketData),
      marketPenetration: this._calculateMarketPenetration(marketData),
      growthPotential: this._calculateGrowthPotential(marketData)
    };
  }

  _calculateMarketFit(marketData) {
    const factors = [
      marketData.customerSatisfaction || 0,
      marketData.productMarketFit || 0,
      marketData.marketShare || 0
    ];
    return Math.round(factors.reduce((acc, val) => acc + val, 0) / factors.length);
  }

  _calculateYearOverYear(marketData) {
    if (!marketData.historicalData?.length) return { growth: 0 };

    // Use analysisDate or date to sort data
    const sortedData = [...marketData.historicalData].sort((a, b) => {
      const dateA = new Date(a.analysisDate || a.date);
      const dateB = new Date(b.analysisDate || b.date);
      return dateB - dateA;
    });

    const currentShare = sortedData[0]?.share || 0;
    const lastYearShare = sortedData[sortedData.length - 1]?.share || 0;

    return {
      growth: lastYearShare ? ((currentShare - lastYearShare) / lastYearShare) * 100 : 0
    };
  }

  _calculateCompetitivePosition(marketData) {
    const totalMarket = marketData.competitors?.reduce(
      (sum, comp) => sum + (comp.marketShare || 0),
      marketData.marketShare || 0
    );
    return totalMarket ? Math.round((marketData.marketShare / totalMarket) * 100) : 0;
  }

  _calculateMarketPenetration(marketData) {
    return Math.round(
      ((marketData.activeUsers || 0) / (marketData.totalAddressableMarket || 1)) * 100
    );
  }

  _calculateGrowthPotential(marketData) {
    const potential = Math.min(
      100,
      Math.round(
        ((marketData.marketPotential || 0) - (marketData.marketShare || 0)) *
          (marketData.growthRate || 1)
      )
    );

    let confidence = 'medium';
    if (marketData.marketConfidence > 0.7) confidence = 'high';
    if (marketData.marketConfidence < 0.3) confidence = 'low';

    return { potential, confidence };
  }

  async getAnalysesHistory(productId, params = {}) {
    try {
      const response = await api.get(`/market/${productId}/history`, { params });
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching analyses history: ${error.message}`);
    }
  }

  async compareAnalyses(productId, analysisIds) {
    try {
      const response = await api.post(`/market/${productId}/compare`, { analysisIds });
      return response.data;
    } catch (error) {
      throw new Error(`Error comparing analyses: ${error.message}`);
    }
  }

  async exportAnalysis(analysis) {
    try {
      const data = {
        analysis,
        exportDate: new Date().toISOString(),
        metadata: {
          version: '1.0',
          type: 'single_analysis'
        }
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `market-analysis-${analysis.analysisId}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      throw new Error(`Error exporting analysis: ${error.message}`);
    }
  }
}

export default new MarketAnalysisService();
