// galleryImages.js
export const galleryImages = [
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4068314.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4268528.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4480448.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4503747.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4505452.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4676397.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4676441.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-4820677.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-5054352.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-5379228.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-6153343.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-6344231.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-6892716.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-6969266.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-7219661.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-cottonbro-8956532.jpg",
    "https://agtupsproductsimages.s3.us-east-2.amazonaws.com/gallery/pexels-heyho-7031706.jpg",
  ];
  