import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Typography,
  Alert,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Paper,
  useTheme
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RefreshCw, HelpCircle as HelpIcon, TrendingUp, BarChart2 } from 'lucide-react';
import PricingStrategyService from '../services/AGTUps-product-services/pricingStrategyService';
import { ProductContext } from '../../../context/ProductContext';

// Definimos colores consistentes
const COLORS = {
  primary: '#0385FF',
  text: {
    light: '#0385FF',
    dark: '#0385FF'
  }
};

// Creamos un theme personalizado
const createCustomTheme = (mode) => createTheme({
  palette: { mode },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          backgroundColor: COLORS.primary,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: COLORS.primary,
          },
          '&:disabled': {
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
          },
        },
        text: {
          color: COLORS.text[mode],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
  typography: {
    h5: { fontWeight: 600 },
    button: { textTransform: 'none' },
  },
});

const ReviewPricesShortcut = ({ open, onClose, product }) => {
  const theme = useTheme();
  const customTheme = createCustomTheme(theme.palette.mode);
  const { products } = useContext(ProductContext);
  const [selectedProduct, setSelectedProduct] = useState(product || (products.length > 0 ? products[0] : null));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pricingData, setPricingData] = useState(null);

  useEffect(() => {
    if (product) {
      setSelectedProduct(product);
    }
  }, [product]);

  const loadPricingData = async () => {
    if (!selectedProduct?.id) return;
    setLoading(true);
    setError(null);
    try {
      const response = await PricingStrategyService.getPricingStrategiesByProduct(selectedProduct.id);
      if (response.success) {
        setPricingData(response.data);
      } else {
        throw new Error('Failed to load pricing strategy');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && selectedProduct?.id) {
      loadPricingData();
    }
  }, [open, selectedProduct]);

  const handleRecalculate = async () => {
    if (!selectedProduct?.id) return;
    setLoading(true);
    setError(null);
    try {
      const currentPrice = pricingData?.executiveSummary?.pricing?.current || 0;
      const params = { currentPrice, pricingModel: 'fixed', marginTarget: 40 };
      const response = await PricingStrategyService.calculateOptimalPrice(selectedProduct.id, params);
      if (response.success) {
        setPricingData((prev) => ({
          ...prev,
          aiAnalysis: {
            ...prev.aiAnalysis,
            currentAnalysis: {
              ...prev.aiAnalysis.currentAnalysis,
            }
          }
        }));
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const breakEvenUnits = pricingData?.executiveSummary?.breakEven?.units || 0;
  const totalSalesToday = pricingData?.executiveSummary?.salesMetrics?.totalSalesToday || 0;

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
          <CircularProgress size={40} sx={{ color: COLORS.primary }} />
        </Box>
      );
    }

    if (error) {
      return <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>;
    }

    if (!selectedProduct) {
      return <Alert severity="info">No product available.</Alert>;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              border: '1px solid',
              borderColor: 'divider',
              bgcolor: theme.palette.mode === 'dark' ? 'rgba(3, 133, 255, 0.05)' : 'rgba(3, 133, 255, 0.02)'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 3 }}>
              <TrendingUp size={24} color={COLORS.primary} />
              <Typography variant="h6">{selectedProduct.name}</Typography>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Break-even Units
                  </Typography>
                  <Typography variant="h4">
                    {breakEvenUnits}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Units Sold Today
                  </Typography>
                  <Typography variant="h4">
                    {totalSalesToday}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {breakEvenUnits > 0 && totalSalesToday > 0 && (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <BarChart2 size={20} />
              <Typography variant="body2" color="text.secondary">
                Progress: {((totalSalesToday / breakEvenUnits) * 100).toFixed(1)}% of break-even achieved
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            pb: 2
          }}
        >
          <Typography variant="h5">
            Review Prices
          </Typography>
          <Tooltip title="Review and analyze product pricing">
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent sx={{ pt: 2 }}>
          {!product && products.length > 0 && (
            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Select Product</InputLabel>
              <Select
                value={selectedProduct?.id || ''}
                label="Select Product"
                onChange={(e) => {
                  const prod = products.find(p => p.id === e.target.value);
                  setSelectedProduct(prod);
                }}
              >
                {products.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {renderContent()}
        </DialogContent>

        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={onClose}
            sx={{ color: COLORS.text[theme.palette.mode] }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={handleRecalculate}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <RefreshCw size={20} />}
          >
            Recalculate
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ReviewPricesShortcut;