import React, { useRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Fade,
  CircularProgress,
  IconButton,
  Zoom
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { Globe, Clock, ArrowRight, ArrowDown } from 'lucide-react';
import AGTFound from './AGTFound';

const ScrollableContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  width: '100%',
  position: 'relative', // allows positioning the button absolutely within
  padding: theme.spacing(2),
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.text.primary, 0.2),
    borderRadius: '4px'
  }
}));

const ResultContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '800px',
  margin: '0 auto'
}));

const ModuleRecommendation = styled(Paper)(
  ({ theme, priority, color }) => {
    const fallbackColor =
      priority === 'HIGH'
        ? theme.palette.success.main
        : priority === 'MEDIUM'
        ? theme.palette.warning.main
        : theme.palette.text.secondary;

    const baseColor = color || fallbackColor;
    const backgroundDefault = alpha(baseColor, 0.15);
    const backgroundHover = alpha(baseColor, 0.25);
    const borderColor = alpha(baseColor, 0.3);

    return {
      padding: theme.spacing(3),
      backgroundColor: backgroundDefault,
      borderRadius: theme.shape.borderRadius * 2,
      position: 'relative',
      overflow: 'hidden',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      border: `1px solid ${borderColor}`,
      '&:hover': {
        backgroundColor: backgroundHover,
        transform: 'translateY(-2px)'
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '4px',
        height: '100%',
        backgroundColor: baseColor
      }
    };
  }
);

const UrlPath = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
  marginBottom: theme.spacing(0.5)
}));

const PriorityBadge = styled(Box)(({ theme, priority }) => {
  const colorHigh = theme.palette.success.main;
  const colorMedium = theme.palette.warning.main;
  const colorLow = theme.palette.text.secondary;

  const backgroundHigh = alpha(colorHigh, 0.2);
  const backgroundMedium = alpha(colorMedium, 0.2);
  const backgroundLow = alpha(colorLow, 0.2);

  let colorText = colorLow;
  let bgColor = backgroundLow;

  if (priority === 'HIGH') {
    colorText = colorHigh;
    bgColor = backgroundHigh;
  } else if (priority === 'MEDIUM') {
    colorText = colorMedium;
    bgColor = backgroundMedium;
  }

  return {
    display: 'inline-flex',
    padding: theme.spacing(0.5, 1.5),
    borderRadius: theme.shape.borderRadius * 4,
    fontSize: '0.75rem',
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    backgroundColor: bgColor,
    color: colorText
  };
});

// New styled component for the explicit "Recommended" badge
const RecommendedBadge = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: theme.shape.borderRadius * 4,
  fontSize: '0.75rem',
  fontWeight: 500,
  marginLeft: theme.spacing(2),
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  color: theme.palette.primary.main
}));

const MetaData = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  marginTop: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: '0.875rem'
}));

const LoaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(8),
  gap: theme.spacing(2),
  color: theme.palette.text.secondary
}));

const moduleColors = {
  product: '#415B7E',
  cashflow: '#DC9844',
  council: '#4A4F81',
  supplier: '#25505A',
  growth: '#FFB692',
  profile: '#BC4C4A'
};

const AGTFoundResults = ({
  results = [],
  onResultClick = () => {},
  isLoading = false,
  searchQuery = '',
  onSearchChange = () => {},
  onSearchResults = () => {},
  setIsSearching = () => {}
}) => {
  // Reference to the scrollable container
  const scrollableRef = useRef(null);

  // State to control the visibility of the scroll down button
  const [showScrollDown, setShowScrollDown] = useState(false);

  // Function to scroll to the bottom of the container
  const scrollToBottom = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: scrollableRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  // Detect if we're close to the bottom of the container
  const handleScroll = () => {
    if (!scrollableRef.current) return;

    const { scrollTop, clientHeight, scrollHeight } = scrollableRef.current;
    const nearBottom = scrollTop + clientHeight >= scrollHeight - 20;

    setShowScrollDown(!nearBottom);
  };

  useEffect(() => {
    // Recheck scroll position when results change
    handleScroll();
  }, [results]);

  const showResults = results && results.length > 0;
  const showLoader = isLoading || (!showResults && searchQuery);

  return (
    <ScrollableContainer ref={scrollableRef} onScroll={handleScroll}>
      <ResultContainer>
        <Box sx={{ mb: 4 }}>
          <AGTFound
            value={searchQuery}
            onChange={onSearchChange}
            onResults={onSearchResults}
            setIsSearching={setIsSearching}
            placeholder="Perform a new search..."
          />
        </Box>

        <Fade in>
          <Box>
            {showLoader && (
              <LoaderContainer>
                <CircularProgress size={40} />
                <Typography variant="body1">Searching for results...</Typography>
              </LoaderContainer>
            )}

            {showResults && (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '0.9rem',
                    mb: 2
                  }}
                >
                  {results.length} result{results.length !== 1 ? 's' : ''} found
                </Typography>

                {results.map((result, index) => (
                  <ModuleRecommendation
                    key={index}
                    priority={result.priority}
                    color={moduleColors[result.moduleId.toLowerCase()]}
                    onClick={() => onResultClick({ id: result.moduleId })}
                    elevation={0}
                  >
                    <UrlPath>
                      <Globe size={16} />
                      <span>agtapp.com › ups › {result.moduleId.toLowerCase()}</span>
                    </UrlPath>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 500,
                          letterSpacing: '0.5px'
                        }}
                      >
                        AGT {result.moduleId.charAt(0).toUpperCase() + result.moduleId.slice(1)}
                      </Typography>
                      {/* Instead of displaying "HIGH", show an explicit "Recommended" badge */}
                      {result.priority === 'HIGH' ? (
                        <RecommendedBadge>Recommended</RecommendedBadge>
                      ) : (
                        <PriorityBadge priority={result.priority}>
                          {result.priority}
                        </PriorityBadge>
                      )}
                    </Box>

                    <Typography
                      sx={{
                        color: 'text.secondary',
                        fontSize: '0.9rem',
                        lineHeight: 1.5,
                        mb: 1
                      }}
                    >
                      {result.reason}
                    </Typography>

                    <Box
                      sx={{
                        mt: 2,
                        p: 1.5,
                        borderRadius: 1,
                        backgroundColor: (theme) =>
                          alpha(theme.palette.text.primary, 0.05)
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ArrowRight size={18} color="inherit" />
                        <Typography
                          sx={{
                            fontSize: '0.875rem',
                            color: 'text.primary',
                            fontWeight: 500
                          }}
                        >
                          Next step:
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          ml: 3.5,
                          mt: 0.5,
                          fontSize: '0.875rem',
                          color: 'text.secondary'
                        }}
                      >
                        {result.nextStep}
                      </Typography>
                    </Box>

                    <MetaData>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Clock size={16} />
                        <Typography variant="body2">
                          Recently updated
                        </Typography>
                      </Box>
                    </MetaData>
                  </ModuleRecommendation>
                ))}
              </Box>
            )}
          </Box>
        </Fade>
      </ResultContainer>

      {/* Circular button with a downward arrow */}
      <Zoom in={showScrollDown}>
        <IconButton
          onClick={scrollToBottom}
          size="large"
          sx={{
            position: 'fixed',
            bottom: 24,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'background.paper',
            width: 40,
            height: 40,
            boxShadow: 1,
            '&:hover': {
              backgroundColor: 'background.paper'
            }
          }}
        >
          <ArrowDown size={32} strokeWidth={3} />
        </IconButton>
      </Zoom>
    </ScrollableContainer>
  );
};

export default AGTFoundResults;
