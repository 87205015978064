import axios from 'axios';
import API_CONFIG from './config';

class ProfileService {
  constructor() {
    this.api = null;
    this.endpoints = API_CONFIG.ENDPOINTS;
    this.CACHE_KEY_PREFIX = 'agtups_profile_';
    this.CACHE_DURATION = 24 * 60 * 60 * 1000;
  }

  initialize({ uid }) {
    this.api = API_CONFIG.createAxiosInstance();
    this.uid = uid;
    this.CACHE_KEY = `${this.CACHE_KEY_PREFIX}${uid}`;
  }

  _clearCache() {
    if (this.CACHE_KEY) {
      localStorage.removeItem(this.CACHE_KEY);
    }
  }

  _updateCache(profileData) {
    if (this.CACHE_KEY && profileData) {
      const dataToStore = {
        profile: profileData,
        timestamp: Date.now()
      };
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(dataToStore));
    }
  }

  _getCachedProfile() {
    if (!this.CACHE_KEY) return null;

    try {
      const cachedData = localStorage.getItem(this.CACHE_KEY);
      if (!cachedData) return null;

      const { profile, timestamp } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > this.CACHE_DURATION;

      if (isExpired) {
        this._clearCache();
        return null;
      }

      return profile;
    } catch (error) {
      this._clearCache();
      return null;
    }
  }

  async getProfile() {
    try {
      const cachedProfile = this._getCachedProfile();
      if (cachedProfile) {
        // Se actualiza el caché en segundo plano sin bloquear la respuesta
        this.api
          .get(API_CONFIG.ENDPOINTS.PROFILE)
          .then(response => {
            if (response.data.success && response.data.data) {
              this._updateCache(response.data.data);
            }
          })
          .catch(() => {});
        return {
          success: true,
          data: cachedProfile,
          exists: true,
          fromCache: true
        };
      }

      const response = await this.api.get(API_CONFIG.ENDPOINTS.PROFILE);

      if (response.data.success && response.data.data) {
        this._updateCache(response.data.data);
        return {
          success: true,
          data: response.data.data,
          exists: true
        };
      }

      return {
        success: true,
        exists: false,
        isNewProfile: true,
        data: null
      };
    } catch (error) {
      if (
        error.response?.status === 404 &&
        error.response?.data?.error === 'PROFILE_NOT_FOUND'
      ) {
        this._clearCache();
        return {
          success: true,
          exists: false,
          isNewProfile: true,
          data: null
        };
      }

      return {
        success: false,
        exists: false,
        error: this.handleError(error)
      };
    }
  }

  async createProfile(profileData) {
    try {
      const validation = await this.validateProfileData(profileData);
      if (!validation.isValid) {
        throw new Error(`Datos inválidos: ${validation.errors.join(', ')}`);
      }

      const profile = {
        ...profileData,
        userId: this.uid,
        status: 'active',
        forceCreate: true,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        metadata: {
          setupComplete: true,
          setupCompletedAt: new Date().toISOString()
        }
      };

      const response = await this.api.post(API_CONFIG.ENDPOINTS.PROFILE, profile);

      if (response.data.success) {
        this._updateCache(response.data.data);
        return {
          success: true,
          data: response.data,
          exists: true
        };
      }

      throw new Error('Error en la respuesta del servidor al crear perfil');
    } catch (error) {
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async updateProfile(profileId, profileData) {
    try {
      if (!profileId) {
        throw new Error('Profile ID es requerido para actualizar');
      }

      const validation = await this.validateProfileData(profileData);
      if (!validation.isValid) {
        throw new Error(`Datos inválidos: ${validation.errors.join(', ')}`);
      }

      const response = await this.api.put(
        `${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}`,
        {
          ...profileData,
          updatedAt: new Date().toISOString()
        }
      );

      if (response.data.success) {
        this._updateCache(response.data.data);
        return {
          success: true,
          data: response.data,
          exists: true
        };
      }

      throw new Error('Error en la respuesta del servidor al actualizar perfil');
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('El producto a actualizar no existe');
      }
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async deleteProfile(profileId) {
    try {
      if (!profileId) {
        throw new Error('Profile ID es requerido para eliminar');
      }

      const response = await this.api.delete(`${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}`);

      if (response.data.success) {
        this._clearCache();
        return {
          success: true,
          data: response.data,
          message: 'Perfil eliminado exitosamente'
        };
      }

      throw new Error('Error en la respuesta del servidor al eliminar perfil');
    } catch (error) {
      if (error.response?.status === 404) {
        this._clearCache();
        return {
          success: true,
          data: { profileId },
          message: 'Perfil eliminado exitosamente'
        };
      }
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  handleError(error) {
    let errorMessage = 'Error inesperado';
    let errorDetails = null;

    if (error.response?.data?.error) {
      errorMessage = error.response.data.error;
      errorDetails = error.response.data.details;
    } else if (error.message) {
      errorMessage = error.message;
    }

    return {
      message: errorMessage,
      details: errorDetails
    };
  }

  async validateProfileData(profileData) {
    const errors = [];
  
    if (!profileData.companyName?.trim()) {
      errors.push('Nombre de empresa es requerido');
    }
    if (!profileData.industry?.trim()) {
      errors.push('Industria es requerida');
    }
    if (
      profileData.contactEmail &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.contactEmail)
    ) {
      errors.push('Formato de email inválido');
    }
    if (profileData.website && !/^https?:\/\/.+/.test(profileData.website)) {
      errors.push('URL del sitio web debe comenzar con http:// o https://');
    }
    if (profileData.business && !profileData.business.stage) {
      errors.push('Etapa del negocio es requerida');
    }
    if (!profileData.location?.trim()) {
      errors.push('Ubicación es requerida');
    }
  
    return {
      isValid: errors.length === 0,
      errors
    };
  }    

  async getProfileAnalytics(profileId) {
    try {
      if (!profileId) {
        throw new Error('Profile ID es requerido para obtener analytics');
      }

      const response = await this.api.get(`${API_CONFIG.ENDPOINTS.PROFILE}/${profileId}/analytics`);

      if (response.data.success) {
        return {
          success: true,
          data: response.data
        };
      }

      throw new Error('Error en la respuesta del servidor al obtener analytics');
    } catch (error) {
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }

  async refreshProfile() {
    try {
      const response = await this.api.get(API_CONFIG.ENDPOINTS.PROFILE);

      if (response.data.success && response.data.data) {
        this._updateCache(response.data.data);
        return {
          success: true,
          data: response.data.data
        };
      }

      throw new Error('Error actualizando datos del perfil');
    } catch (error) {
      return {
        success: false,
        error: this.handleError(error)
      };
    }
  }
}

const profileService = new ProfileService();
export default profileService;
