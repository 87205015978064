import React, { useState, useEffect, useCallback, useMemo, lazy, Suspense } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  IconButton,
  Tooltip,
  LinearProgress,
  Fade,
  Paper,
  Chip,
  Stack
} from '@mui/material';
import { styled, darken } from '@mui/material/styles';
import { 
  InfoOutlined, 
  LightbulbOutlined,
  ArrowBack,
  ArrowForward
} from '@mui/icons-material';

// Definimos una constante para el color azul que queremos usar en ambos modos
const blue = '#007BFE';

// Definición de la paleta de colores utilizando la misma variable 'blue'
const colors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859' // Éxito
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: blue,
      backgroundBlur: '#FFFFFF',
      base: '#F2F2F7' // Tonalidad clara para las tarjetas
    },
    grouped: {
      bgPrimaryBase: '#FFFFFF'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257' // Éxito en modo oscuro
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      // Usamos el mismo color azul definido en la constante
      accent: blue,
      backgroundBlur: '#282828',
      base: '#272729'
    },
    grouped: {
      bgPrimaryBase: '#000000'
    }
  }
};

// Eliminamos la sombra del diálogo (el componente Paper interno)
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '28px',
    padding: theme.spacing(2),
    overflow: 'hidden',
    boxShadow: 'none'
  }
}));

const StepContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  '&.slide-enter': {
    transform: 'translateX(100%)',
    opacity: 0
  },
  '&.slide-enter-active': {
    transform: 'translateX(0)',
    opacity: 1
  },
  '&.slide-exit': {
    transform: 'translateX(-100%)',
    opacity: 0
  }
}));

const StepIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1)
}));

const StepDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'completed'
})(({ theme, active, completed }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active 
    ? theme.palette.primary.main 
    : completed 
      ? theme.palette.primary.light
      : theme.palette.grey[300],
  transition: 'all 0.3s ease-in-out',
  transform: active ? 'scale(1.2)' : 'scale(1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)'
  }
}));

// Componente Tooltip sin sombra
const NoShadowTooltip = styled(Tooltip)(() => ({
  '& .MuiTooltip-tooltip': {
    boxShadow: 'none'
  },
  '& .MuiTooltip-arrow': {
    boxShadow: 'none'
  }
}));

// Nota: Se utiliza lazy para el resumen, pero como la función SummaryContent se declara a continuación,
// se envuelve en una Promise que se resuelve inmediatamente.
const LazySummary = lazy(() =>
  Promise.resolve({ default: SummaryContent })
);

// Componente de resumen modificado
function SummaryContent({ steps, profileData }) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Profile Summary
      </Typography>
      <Stack spacing={2}>
        {steps.map((step, index) => {
          const fieldParts = step.field.split('.');
          const value = fieldParts.length > 1
            ? (profileData[fieldParts[0]] ? profileData[fieldParts[0]][fieldParts[1]] : '')
            : profileData[step.field];
          return (
            <Paper
              key={index}
              sx={(theme) => ({
                p: 2,
                borderRadius: '16px',
                boxShadow: 'none',
                backgroundColor:
                  theme.palette.mode === 'light'
                    ? colors.light.ui.base
                    : theme.palette.background.paper
              })}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle1" color="primary">
                  {step.title}
                </Typography>
                {step.required && (
                  <Chip
                    size="small"
                    label="Required"
                    color="primary"
                    variant="outlined"
                  />
                )}
              </Box>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                {value || 'Not provided'}
              </Typography>
            </Paper>
          );
        })}
      </Stack>
    </Box>
  );
}

const ProfileSetupWizard = ({ open, onClose, profileData, onChange, onSubmit, onSaveDraft }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [locationTipShown, setLocationTipShown] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const steps = useMemo(() => [
    {
      title: 'Company Name',
      description: 'What is your company called?',
      required: true,
      field: 'companyName',
      minLength: 2,
      component: (
        <TextField
          fullWidth
          label="Company Name"
          name="companyName"
          value={profileData.companyName}
          onChange={onChange}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px'
            }
          }}
        />
      )
    },
    {
      title: 'Industry',
      description: 'Which industry does your company operate in?',
      required: true,
      field: 'industry',
      minLength: 1,
      component: (
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px'
            }
          }}
        >
          <InputLabel>Industry</InputLabel>
          <Select
            name="industry"
            value={profileData.industry}
            onChange={onChange}
            label="Industry"
          >
            <MenuItem value="technology">Technology</MenuItem>
            <MenuItem value="retail">Retail</MenuItem>
            <MenuItem value="manufacturing">Manufacturing</MenuItem>
            <MenuItem value="services">Services</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      )
    },
    {
      title: 'Business Stage',
      description: 'What stage is your business currently in?',
      required: true,
      field: 'business.stage',
      minLength: 1,
      component: (
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'start', gap: 1 }}>
          <FormControl
            fullWidth
            sx={{
              flex: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '28px'
              }
            }}
          >
            <InputLabel>Business Stage</InputLabel>
            <Select
              name="business.stage"
              value={profileData.business?.stage}
              onChange={onChange}
              label="Business Stage"
            >
              <MenuItem value="startup">Startup</MenuItem>
              <MenuItem value="growth">Growth</MenuItem>
              <MenuItem value="established">Established</MenuItem>
              <MenuItem value="expanding">Expanding</MenuItem>
              <MenuItem value="unknown">Not sure</MenuItem>
            </Select>
          </FormControl>
          <NoShadowTooltip
            arrow
            placement="right"
            title={
              <Box>
                <Typography variant="body2">
                  <strong>Startup:</strong> Early stage.
                </Typography>
                <Typography variant="body2">
                  <strong>Growth:</strong> Validated, seeking expansion.
                </Typography>
                <Typography variant="body2">
                  <strong>Established:</strong> Solid and stable.
                </Typography>
                <Typography variant="body2">
                  <strong>Expanding:</strong> New markets/products.
                </Typography>
                <Typography variant="body2">
                  <strong>Not sure:</strong> Can be changed later.
                </Typography>
              </Box>
            }
          >
            <IconButton size="small">
              <InfoOutlined fontSize="small" />
            </IconButton>
          </NoShadowTooltip>
        </Box>
      )
    },
    {
      title: 'Location',
      description: 'Where does your business operate?',
      required: true,
      field: 'location',
      minLength: 3,
      component: (
        <>
          <TextField
            fullWidth
            label="Location"
            name="location"
            value={profileData.location}
            onChange={onChange}
            onBlur={(e) => {
              if (e.target.value.trim() !== '') {
                setLocationTipShown(true);
              }
            }}
            onFocus={() => setLocationTipShown(false)}
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '28px'
              }
            }}
          />
          {locationTipShown && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <LightbulbOutlined fontSize="small" sx={{ mr: 1, color: blue }} />
              <Typography variant="body2" color="text.secondary">
                Tip: The more precise your location is, the more accurate our analyses will be.
              </Typography>
            </Box>
          )}
        </>
      )
    },
    {
      title: 'Business Description',
      description: 'Tell us about your business',
      required: false,
      field: 'companyDescription',
      component: (
        <TextField
          fullWidth
          label="Business Description (Optional)"
          name="companyDescription"
          value={profileData.companyDescription}
          onChange={onChange}
          multiline
          rows={4}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              borderRadius: '28px'
            }
          }}
        />
      )
    }
  ], [profileData, onChange, locationTipShown]);

  const validateStep = useCallback((stepIndex, value) => {
    const step = steps[stepIndex];
    if (!step.required) return true;
    
    if (!value || value.trim().length < (step.minLength || 1)) {
      return false;
    }
    return true;
  }, [steps]);

  const isStepValid = useCallback((stepIndex) => {
    const step = steps[stepIndex];
    if (!step.required) return true;

    const fieldParts = step.field.split('.');
    const value = fieldParts.length > 1
      ? (profileData[fieldParts[0]] ? profileData[fieldParts[0]][fieldParts[1]] : '')
      : profileData[step.field];

    return validateStep(stepIndex, value);
  }, [profileData, steps, validateStep]);

  const handleStepTransition = useCallback((nextStep) => {
    setTransitioning(true);
    const timeout = setTimeout(() => {
      setActiveStep(nextStep);
      setTransitioning(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  const handleNext = useCallback(async () => {
    if (!isStepValid(activeStep)) return;
    
    if (activeStep === steps.length - 1) {
      if (!showSummary) {
        setShowSummary(true);
        return;
      }
      setIsSubmitting(true);
      try {
        await onSubmit();
      } finally {
        setIsSubmitting(false);
      }
      return;
    }
    handleStepTransition(activeStep + 1);
  }, [activeStep, steps.length, showSummary, onSubmit, isStepValid, handleStepTransition]);

  const handleBack = useCallback(() => {
    if (showSummary) {
      setShowSummary(false);
      return;
    }
    handleStepTransition(activeStep - 1);
  }, [activeStep, showSummary, handleStepTransition]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleNext();
      } else if (e.key === 'Escape') {
        e.preventDefault();
        handleBack();
      }
    };
    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [open, handleNext, handleBack]);

  const progressText = `Step ${activeStep + 1} of ${steps.length}`;
  const summaryContent = useMemo(() => 
    <SummaryContent steps={steps} profileData={profileData} />, 
    [steps, profileData]
  );

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Fade}
      transitionDuration={300}
    >
      <Box sx={{ width: '100%', mb: 1 }}>
        <LinearProgress
          variant="determinate"
          value={((activeStep + (showSummary ? 1 : 0)) / steps.length) * 100}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'rgba(0,123,254,0.12)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: blue,
              transition: 'transform 0.5s ease-in-out'
            }
          }}
        />
        <Typography variant="caption" color="text.secondary" align="center" sx={{ display: 'block', mt: 0.5 }}>
          {progressText}
        </Typography>
      </Box>

      <StepIndicator>
        {steps.map((step, index) => (
          <NoShadowTooltip key={index} title={step.title}>
            <StepDot
              role="button"
              tabIndex={index < activeStep ? 0 : -1}
              aria-label={`Go to step ${index + 1}: ${step.title}`}
              active={index === activeStep}
              completed={index < activeStep}
              onClick={() => {
                if (index < activeStep) handleStepTransition(index);
              }}
            />
          </NoShadowTooltip>
        ))}
      </StepIndicator>

      <DialogTitle sx={{ pb: 1 }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
          {showSummary ? 'Review Your Profile' : steps[activeStep].title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          {showSummary 
            ? 'Please review your information before creating your profile'
            : steps[activeStep].description}
        </Typography>
      </DialogTitle>

      <StepContent sx={{ opacity: transitioning ? 0 : 1 }}>
        {showSummary ? (
          <Suspense fallback={<div>Loading summary...</div>}>
            <Fade in timeout={500} mountOnEnter unmountOnExit>
              <div>{summaryContent}</div>
            </Fade>
          </Suspense>
        ) : (
          steps[activeStep].component
        )}
      </StepContent>

      <DialogActions sx={{ px: 3, pb: 2, gap: 1 }}>
        {(activeStep > 0 || showSummary) && (
          <Button
            onClick={handleBack}
            startIcon={<ArrowBack sx={{ color: blue }} />}
            sx={{
              textTransform: 'none',
              borderRadius: '28px',
              color: blue,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            Back
          </Button>
        )}

        {onSaveDraft && (
          <Button
            onClick={onSaveDraft}
            sx={{
              textTransform: 'none',
              borderRadius: '28px'
            }}
          >
            Save Draft
          </Button>
        )}

        <Box sx={{ flex: 1 }} />

        <Button
          variant="contained"
          disableElevation
          onClick={handleNext}
          disabled={!isStepValid(activeStep) || isSubmitting}
          endIcon={!showSummary && <ArrowForward />}
          sx={{
            textTransform: 'none',
            borderRadius: '28px',
            backgroundColor: blue,
            color: 'white', // Texto en blanco para ambos modos
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: darken(blue, 0.2),
              boxShadow: 'none'
            }
          }}
        >
          {isSubmitting 
            ? 'Submitting...' 
            : showSummary 
              ? 'Create Profile' 
              : activeStep === steps.length - 1 
                ? 'Review' 
                : 'Continue'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ProfileSetupWizard;
