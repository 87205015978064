// D:\directiveai-react\frontend\src\components\AGTUps\shortcuts\SalesStrategyShortcut.js
import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  MenuItem,
  CircularProgress,
  Box,
  Divider,
  Alert,
  IconButton,
  Tooltip,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ToggleButton,
  ToggleButtonGroup,
  useTheme
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
  Help as HelpIcon,
  Search as SearchIcon,
  Sort as SortIcon 
} from '@mui/icons-material';
import { ProductContext } from '../../../context/ProductContext';
import SalesStrategyService from '../services/AGTUps-product-services/salesStrategyService';
import { toast } from 'react-toastify';

// Definimos colores consistentes
const COLORS = {
  primary: '#0385FF', // Color azul corporativo
  text: {
    light: '#0385FF',
    dark: '#0385FF'
  }
};

// Creamos un theme personalizado
const createCustomTheme = (mode) => createTheme({
  palette: {
    mode: mode,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          backgroundColor: COLORS.primary,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: COLORS.primary,
          },
          '&:disabled': {
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
          },
        },
        text: {
          color: COLORS.text[mode],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          '&.Mui-selected': {
            backgroundColor: COLORS.primary,
            color: '#ffffff',
            '&:hover': {
              backgroundColor: COLORS.primary,
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
          marginBottom: '8px',
          '&:hover': {
            backgroundColor: mode === 'light' ? 'rgba(3, 133, 255, 0.04)' : 'rgba(3, 133, 255, 0.08)',
          },
        },
      },
    },
  },
  typography: {
    h6: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
});

const SalesStrategyShortcut = ({ open, onClose }) => {
  const theme = useTheme();
  const customTheme = createCustomTheme(theme.palette.mode);
  const { products, updateProductList } = useContext(ProductContext);
  
  const [commonFields, setCommonFields] = useState({
    channel: 'direct',
    region: '',
    saleDate: new Date().toISOString().split('T')[0]
  });

  const [saleQuantities, setSaleQuantities] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('name');

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const loc = await SalesStrategyService.getUserLocation();
        setCommonFields((prev) => ({ ...prev, region: loc }));
      } catch (error) {
        setCommonFields((prev) => ({ ...prev, region: 'N/A' }));
      }
    };
    fetchLocation();
  }, []);

  // Filtrar y ordenar productos
  const filteredAndSortedProducts = products
    .filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortOrder === 'price') {
        return (b.price || 0) - (a.price || 0);
      }
      return a.name.localeCompare(b.name);
    });

  const handleQuantityChange = (productId, value) => {
    setSaleQuantities((prev) => ({ ...prev, [productId]: value }));
  };

  const handleCommonFieldChange = (e) => {
    const { name, value } = e.target;
    // En este shortcut, solo se permite modificar el canal
    if (name === 'channel') {
      setCommonFields((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Navegación por teclado
  const handleKeyNavigation = (event, productId, currentIndex) => {
    if (event.key === 'Enter' || event.key === 'ArrowDown') {
      event.preventDefault();
      const nextInput = document.querySelector(`input[data-index="${currentIndex + 1}"]`);
      if (nextInput) nextInput.focus();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevInput = document.querySelector(`input[data-index="${currentIndex - 1}"]`);
      if (prevInput) prevInput.focus();
    }
  };

  // Calcular total de revenue
  const calculateTotal = () => {
    return Object.entries(saleQuantities).reduce((total, [productId, quantity]) => {
      const product = products.find(p => p.id === productId);
      return total + (parseFloat(quantity || 0) * (product?.price || 0));
    }, 0);
  };

  const handleSubmit = async () => {
    setError('');
    setLoading(true);
    try {
      const salesPromises = products
        .map((product) => {
          const quantity = parseInt(saleQuantities[product.id] || '0', 10);
          if (quantity > 0) {
            const revenue = product.price ? quantity * product.price : 0;
            const saleData = {
              productId: product.id,
              quantity,
              revenue,
              channel: commonFields.channel,
              region: commonFields.region,
              saleDate: commonFields.saleDate
            };
            return SalesStrategyService.createSalesRecord(saleData);
          }
          return null;
        })
        .filter((p) => p !== null);

      if (salesPromises.length === 0) {
        throw new Error('Debe ingresar una cantidad en al menos un producto.');
      }

      await Promise.all(salesPromises);
      
      // Actualizamos el stock en el contexto para cada producto vendido
      products.forEach((product) => {
        const quantitySold = parseInt(saleQuantities[product.id] || '0', 10);
        if (quantitySold > 0 && product.stock !== undefined) {
          const currentStock = parseInt(product.stock, 10) || 0;
          const newStock = currentStock - quantitySold;
          const updatedProduct = { ...product, stock: newStock };
          // Actualizamos el producto en el contexto
          updateProductList(updatedProduct);
        }
      });

      toast.success('Ventas registradas exitosamente');
      setSaleQuantities({});
      onClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog 
        open={open} 
        onClose={onClose} 
        fullWidth 
        maxWidth="md"
        aria-labelledby="sales-dialog-title"
      >
        <DialogTitle 
          id="sales-dialog-title" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            pb: 2
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Registro Masivo de Ventas
          </Typography>
          <Tooltip title="Aquí puedes registrar múltiples ventas de productos simultáneamente">
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: '12px'
              }}
            >
              {error}
            </Alert>
          )}

          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
              Datos Comunes de la Venta
            </Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Canal de Venta"
                  name="channel"
                  value={commonFields.channel}
                  onChange={handleCommonFieldChange}
                  fullWidth
                  select
                >
                  <MenuItem value="direct">Directo</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="partners">Partners</MenuItem>
                  <MenuItem value="distributor">Distribuidor</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Región"
                  name="region"
                  value={commonFields.region}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs>
                <TextField
                  fullWidth
                  placeholder="Buscar productos..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <ToggleButtonGroup
                  value={sortOrder}
                  exclusive
                  onChange={(e, value) => value && setSortOrder(value)}
                  size="small"
                >
                  <ToggleButton value="name">
                    Nombre
                  </ToggleButton>
                  <ToggleButton value="price">
                    Precio
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>

          <List sx={{ 
            maxHeight: '400px', 
            overflow: 'auto',
            '& .MuiListItem-root': {
              padding: '16px',
            }
          }}>
            {filteredAndSortedProducts.map((product, index) => (
              <ListItem key={product.id}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {product.name}
                    </Typography>
                  }
                  secondary={`Precio: $${product.price?.toFixed(2) || '0.00'}`}
                />
                <TextField
                  type="number"
                  value={saleQuantities[product.id] || ''}
                  onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                  onKeyDown={(e) => handleKeyNavigation(e, product.id, index)}
                  data-index={index}
                  size="small"
                  sx={{ width: '120px' }}
                  InputProps={{
                    inputProps: { min: 0 }
                  }}
                />
              </ListItem>
            ))}
          </List>

          <Box sx={{ 
            mt: 2, 
            display: 'flex', 
            justifyContent: 'flex-end',
            '& .MuiTypography-root': {
              fontWeight: 600
            }
          }}>
            <Typography variant="h6">
              Total: ${calculateTotal().toFixed(2)}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={onClose} 
            disabled={loading}
            sx={{
              color: COLORS.text[theme.palette.mode],
            }}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={loading}
            sx={{
              bgcolor: COLORS.primary,
              '&:hover': {
                bgcolor: COLORS.primary,
              },
              color: '#ffffff',
            }}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Procesando...' : 'Registrar ventas'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default SalesStrategyShortcut;
