import React, { useState, useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  Box,
  Typography,
  Divider,
  InputAdornment,
  IconButton,
  Tooltip,
  Alert,
  useTheme
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Help as HelpIcon, Upload as UploadIcon, Edit as EditIcon } from '@mui/icons-material';
import { ProductContext } from '../../../context/ProductContext';
import ProductService from '../services/AGTUps-product-services/productService';
import { toast } from 'react-toastify';

// Definimos colores consistentes
const COLORS = {
  primary: '#0385FF',
  text: {
    light: '#0385FF',
    dark: '#0385FF'
  }
};

// Creamos un theme personalizado
const createCustomTheme = (mode) => createTheme({
  palette: {
    mode: mode,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          backgroundColor: COLORS.primary,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: COLORS.primary,
          },
          '&:disabled': {
            backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)',
          },
        },
        text: {
          color: COLORS.text[mode],
        },
        outlined: {
          borderRadius: '12px',
          borderColor: COLORS.primary,
          color: COLORS.primary,
          '&:hover': {
            borderColor: COLORS.primary,
            backgroundColor: mode === 'dark' ? 'rgba(3, 133, 255, 0.08)' : 'rgba(3, 133, 255, 0.04)',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
    button: {
      textTransform: 'none',
    },
  },
});

const defaultCategories = [
  { id: 'physical', name: 'Physical Products' },
  { id: 'digital', name: 'Digital Products' },
  { id: 'services', name: 'Services' },
  { id: 'subscription', name: 'Subscriptions' },
  { id: 'food', name: 'Food & Beverage' }
];

const ProductShortcut = ({ open, onClose }) => {
  const theme = useTheme();
  const customTheme = createCustomTheme(theme.palette.mode);
  const { updateProductList } = useContext(ProductContext);
  
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    stock: '',
    imageUrl: ''
  });
  
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setError(''); // Clear any previous errors
    }
  };

  const handleSubmit = async () => {
    setError('');
    setLoading(true);
    try {
      // Validaciones
      if (!newProduct.name.trim()) {
        throw new Error('Product name is required');
      }
      if (!newProduct.category) {
        throw new Error('Category is required');
      }
      if (!newProduct.price || parseFloat(newProduct.price) <= 0) {
        throw new Error('Valid price is required');
      }
      if ((newProduct.category === 'physical' || newProduct.category === 'food') && 
          (!newProduct.stock || parseInt(newProduct.stock) < 0)) {
        throw new Error('Valid stock quantity is required for physical products');
      }

      let imageUrl = newProduct.imageUrl;
      if (selectedImage) {
        imageUrl = await ProductService.uploadProductImage(selectedImage);
      }

      const productData = {
        ...newProduct,
        price: parseFloat(newProduct.price),
        stock: newProduct.category === 'physical' || newProduct.category === 'food'
          ? parseInt(newProduct.stock, 10)
          : undefined,
        imageUrl
      };

      const response = await ProductService.createProduct(productData);
      if (response?.success) {
        updateProductList(response.data);
        toast.success('Product created successfully');
        onClose();
        resetForm();
      } else {
        throw new Error('Failed to create product');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setNewProduct({
      name: '',
      description: '',
      category: '',
      price: '',
      stock: '',
      imageUrl: ''
    });
    setSelectedImage(null);
    setImagePreview(null);
    setError('');
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog 
        open={open} 
        onClose={onClose} 
        fullWidth 
        maxWidth="sm"
        aria-labelledby="product-dialog-title"
      >
        <DialogTitle 
          id="product-dialog-title" 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between',
            pb: 2
          }}
        >
          <Typography variant="h5">
            Quick Add Product
          </Typography>
          <Tooltip title="Fill in the basic details quickly">
            <IconButton size="small">
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>

        <DialogContent>
          {error && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
            >
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Product Name"
                name="name"
                value={newProduct.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Description"
                name="description"
                value={newProduct.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  name="category"
                  value={newProduct.category}
                  onChange={handleChange}
                  label="Category"
                >
                  {defaultCategories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Price"
                name="price"
                type="number"
                value={newProduct.price}
                onChange={handleChange}
                fullWidth
                required
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
              />
            </Grid>

            {(newProduct.category === 'physical' || newProduct.category === 'food') && (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Stock"
                  name="stock"
                  type="number"
                  value={newProduct.stock}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="outlined"
                  component="label"
                  startIcon={<UploadIcon />}
                >
                  {imagePreview ? 'Change Image' : 'Upload Image'}
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={handleImageChange}
                  />
                </Button>
              </Box>
              {imagePreview && (
                <Box
                  sx={{
                    mt: 2,
                    borderRadius: '12px',
                    overflow: 'hidden',
                    border: '1px solid',
                    borderColor: 'divider'
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Product Preview"
                    style={{
                      width: '100%',
                      height: 'auto',
                      display: 'block'
                    }}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={onClose} 
            disabled={loading}
            sx={{
              color: COLORS.text[theme.palette.mode],
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={loading}
            sx={{
              bgcolor: COLORS.primary,
              '&:hover': {
                bgcolor: COLORS.primary,
              },
              color: '#ffffff',
            }}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Creating...' : 'Create Product'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ProductShortcut;