import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Paper, Tooltip as MuiTooltip } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Check, Info, Lock } from 'lucide-react';
import { createProCheckoutSession } from '../services/AGTService';
import { useProStatus } from '../../context/ProContext';

const ACCENT_COLOR = '#007BFE';
const HOVER_COLOR = '#0069d9';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '12px',
  backgroundColor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#ffffff',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  width: '100%',
  height: '100%',
  minHeight: '400px',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#e0e0e0'}`,
}));

const ProductTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(1),
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  fontSize: '1.25rem',
}));

const PriceContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
}));

const CurrencySymbol = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 500,
  lineHeight: 1,
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  marginRight: theme.spacing(0.5),
  alignSelf: 'flex-start',
  marginTop: '0.5rem',
}));

const PriceTypography = styled(Typography)(({ theme }) => ({
  fontSize: '3rem',
  fontWeight: 500,
  lineHeight: 1,
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
}));

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666',
  marginLeft: theme.spacing(1),
  alignSelf: 'flex-end',
  marginBottom: '0.3rem',
  textAlign: 'left',
  lineHeight: 1,
}));

const FeatureList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginTop: theme.spacing(2.5),
  color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666',
  fontSize: '0.875rem',
}));

const FeatureItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(1),
}));

const FeatureText = styled('span')({
  display: 'inline',
  alignItems: 'center',
});

const InfoIconWrapper = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '4px',
  verticalAlign: 'text-top',
  position: 'relative',
  top: '3px',
});

const ButtonContent = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
}));

const WhiteLockIcon = styled(Lock)(() => ({
  width: 16,
  height: 16,
  color: '#ffffff',
}));

const StyledButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '10px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.875rem',
  backgroundColor: ACCENT_COLOR,
  color: '#ffffff',
  '&:hover': {
    backgroundColor: HOVER_COLOR,
  },
  marginTop: '20px',
  marginBottom: '20px',
}));

const ProStatusButton = styled(Button)(() => ({
  borderRadius: '30px',
  padding: '10px',
  fontWeight: 600,
  textTransform: 'none',
  fontSize: '0.875rem',
  backgroundColor: ACCENT_COLOR,
  color: '#ffffff',
  cursor: 'default',
  '&:hover': {
    backgroundColor: ACCENT_COLOR,
  },
  marginTop: '20px',
  marginBottom: '20px',
}));

const CustomTooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    color: '#fff',
    fontSize: 12,
    padding: '8px 12px',
    borderRadius: '4px',
    maxWidth: 220,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
  '& .MuiTooltip-arrow': {
    color: 'rgba(0, 0, 0, 1)',
  },
}));

const CheckIcon = styled(Check)(() => ({
  color: ACCENT_COLOR,
  width: 16,
  height: 16,
  flexShrink: 0,
  marginTop: '4px',
  marginRight: '8px',
}));

const InfoIcon = styled(Info)(({ theme }) => ({
  width: 14,
  height: 14,
  color: ACCENT_COLOR,
  cursor: 'help',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  },
}));

const AGTPromo = ({ onOpenAGTUps, onOpenAGTEdu, userId }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { isPro, checkStatus } = useProStatus();

  useEffect(() => {
    if (userId) {
      checkStatus(userId);
    }
  }, [userId, checkStatus]);

  const handleProCheckout = async () => {
    if (isPro) return;
    
    try {
      setIsLoading(true);
      const checkoutUrl = await createProCheckoutSession(userId);
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Obtenemos el flag para AGT Ups del localStorage
  const hasAGTUps = localStorage.getItem('showUpsInNav') === 'true';

  const renderProButton = () => {
    if (isPro) {
      return (
        <ProStatusButton 
          variant="contained" 
          fullWidth
          disableElevation
          startIcon={<Check />}
        >
          Yours
        </ProStatusButton>
      );
    }

    return (
      <StyledButton 
        variant="contained" 
        onClick={handleProCheckout}
        fullWidth
        disabled={isLoading}
      >
        <ButtonContent>
          {isLoading ? 'Processing...' : 'Add AGT Pro'}
          <WhiteLockIcon />
        </ButtonContent>
      </StyledButton>
    );
  };

  // Para AGT Ups, si el usuario ya activó la función, mostramos un botón inactivo; de lo contrario, el botón para activarlo.
  const renderUpsButton = () => {
    if (hasAGTUps) {
      return (
        <ProStatusButton 
          variant="contained" 
          fullWidth
          disableElevation
          startIcon={<Check />}
        >
          Yours
        </ProStatusButton>
      );
    }
    return (
      <StyledButton 
        variant="contained" 
        fullWidth
        onClick={onOpenAGTUps}
      >
        <ButtonContent>
          Add AGT Ups
          <WhiteLockIcon />
        </ButtonContent>
      </StyledButton>
    );
  };

  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto', padding: { xs: 2, sm: 3 }, backgroundColor: 'transparent' }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledPaper elevation={0}>
            <Box>
              <ProductTitle variant="h5">AGT Pro</ProductTitle>
              <PriceContainer>
                {!isPro ? (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                      <CurrencySymbol>$</CurrencySymbol>
                      <PriceTypography>15</PriceTypography>
                    </Box>
                    <SubtitleTypography>
                      USD/<br />month
                    </SubtitleTypography>
                  </>
                ) : (
                  <Typography variant="h6" sx={{ fontSize: '3rem', color: ACCENT_COLOR, fontWeight: 500, lineHeight: 1 }}>
                    Active
                  </Typography>
                )}
              </PriceContainer>
              <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666', mb: 2.5, fontSize: '0.875rem', mt: 1 }}>
                {isPro ? 'Enjoy all PRO features and take your experience to the next level' : 'Achieve more, faster, smarter with AI Pro – make every task effortless.'}
              </Typography>
              {renderProButton()}
            </Box>
            <FeatureList>
              <FeatureItem>
                <CheckIcon />
                Upload Larger Files
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                Solve complex tasks with precision
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  Multimodal Capabilities – Integrate documents, images, and more
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Seamlessly combine text, images, audio, and more in a single query. Our AI understands and processes multiple types of input to provide comprehensive solutions."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                Early Access to New Features
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  Priority Access & Increased Limits – Achieve more, without interruptions
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Enjoy faster response times, higher usage caps, and priority support. Increased limits on API calls, document processing, and computational resources to handle your most demanding tasks."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                Smarter Responses – Get precise, contextual answers instantly
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Search Pro, Maker Pro, and Video
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AGT Search Pro: Real-time, multi-image search with interactive maps and live updates. Maker Pro: Create dynamic presentations with animated diagrams, extensive reports, and seamless integration. Video: Instant, context-specific video demonstrations for hands-on learning."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </FeatureList>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledPaper elevation={0}>
            <Box>
              <ProductTitle variant="h5">AGT Edu</ProductTitle>
              <PriceContainer>
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <CurrencySymbol>$</CurrencySymbol>
                  <PriceTypography>20</PriceTypography>
                </Box>
                <SubtitleTypography>
                  USD/<br />month
                </SubtitleTypography>
              </PriceContainer>
              <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666', mb: 2.5, fontSize: '0.875rem', mt: 1 }}>
                Transform education now with collaborative, AI-powered learning.
              </Typography>
              <StyledButton variant="contained" fullWidth>
                <ButtonContent>
                  Add AGT Edu
                  <WhiteLockIcon />
                </ButtonContent>
              </StyledButton>
            </Box>
            <FeatureList>
              <FeatureItem>
                <CheckIcon />
                Access to AGT Pro (Search Pro, Maker Pro, and Video)
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  DirectiveAI Mode – Real-time group chats with seamless collaboration
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Up to 4 people collaborate in a single chat, searching, creating presentations, and generating content with all the tools for university life."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Academic – Advanced research tools, citation generation, and project planning
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Access cutting-edge research databases, generate accurate citations in multiple formats, and use AI-powered project planning tools to streamline your academic workflow."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Growth – Tailored learning strategies for every educational level
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-powered personalized learning paths adapt to your educational level and goals. Get customized study plans, interactive exercises, and progress tracking to accelerate your learning journey."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </FeatureList>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledPaper elevation={0}>
            <Box>
              <ProductTitle variant="h5">AGT Ups</ProductTitle>
              <PriceContainer>
                <Box sx={{ display: 'flex', alignItems: 'flex-start'}}>
                  <CurrencySymbol>$</CurrencySymbol>
                  <PriceTypography>30</PriceTypography>
                </Box>
                <SubtitleTypography>
                  USD/<br />month
                </SubtitleTypography>
              </PriceContainer>
              <Typography variant="body2" sx={{ color: theme.palette.mode === 'dark' ? '#a3a3a3' : '#666666', mb: 2.5, fontSize: '0.875rem', mt: 1 }}>
                Drive your startup's growth and success at every stage with AI tools.
              </Typography>
              {renderUpsButton()}
            </Box>
            <FeatureList>
              <FeatureItem>
                <CheckIcon />
                Access to AGT Pro (Search Pro, Maker Pro, and Video)
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Product – Optimize pricing, sales strategies, and market fit
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-driven product analysis: Categorizes your products, extracts valuable market data, and provides insights on pricing, customer behavior, and optimization strategies. Get tailored recommendations to improve your product's market position and performance."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Cash Flow – Upload receipts and automate financial updates instantly
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Effortless financial management: Upload photos of receipts, invoices, or quotes for automatic cash flow updates. AI detects income/expense nature and adjusts your cash flow instantly. Manual entries via chat are also supported. Real-time tracking and reporting for complete financial visibility."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Supplier Finder – Find and negotiate with suppliers through AI-powered tools
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-assisted supplier discovery and negotiation. Find optimal suppliers based on your criteria, get AI-generated negotiation strategies, and automate communication for better deals and partnerships."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Council – Get expert advice for complex business decisions
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="Access AI-simulated expert panels providing strategic insights, risk analysis, and tailored recommendations for critical business decisions, available 24/7."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
              <FeatureItem>
                <CheckIcon />
                <FeatureText>
                  AGT Growth – Strategies tailored to every stage of your startup's journey
                  <InfoIconWrapper>
                    <CustomTooltip
                      title="AI-powered growth strategies customized for your startup's stage. Get actionable insights on market expansion, customer acquisition, and scaling operations. Includes performance tracking and adaptive recommendations."
                      arrow
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomTooltip>
                  </InfoIconWrapper>
                </FeatureText>
              </FeatureItem>
            </FeatureList>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AGTPromo;
