// src/context/ProductContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import ProductService from '../components/AGTUps/services/AGTUps-product-services/productService';

export const ProductContext = createContext();

export const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const refreshProducts = useCallback(async () => {
    console.log('[ProductContext] refreshProducts: Iniciando actualización de productos');
    try {
      setLoading(true);
      setError(null);
      // Limpia la caché para forzar datos frescos:
      ProductService.clearCache();
      const response = await ProductService.getAllProducts();
      console.log('[ProductContext] refreshProducts: Respuesta del API:', response);
      if (response?.data?.products) {
        setProducts(response.data.products);
        console.log('[ProductContext] refreshProducts: Productos actualizados:', response.data.products);
      } else {
        console.log('[ProductContext] refreshProducts: No se encontraron productos en la respuesta');
      }
    } catch (err) {
      console.error('[ProductContext] refreshProducts: Error al actualizar productos:', err);
      setError(err.message);
    } finally {
      setLoading(false);
      console.log('[ProductContext] refreshProducts: Finalizada actualización');
    }
  }, []);  

  // Cargar productos inicialmente
  useEffect(() => {
    console.log('[ProductContext] useEffect: Cargando productos inicialmente');
    refreshProducts();
  }, [refreshProducts]);

  // Función para actualizar la lista después de crear/editar
  const updateProductList = useCallback((newProduct) => {
    console.log('[ProductContext] updateProductList: Actualizando producto:', newProduct);
    setProducts(prevProducts => {
      const exists = prevProducts.some(p => p.id === newProduct.id);
      if (exists) {
        const updated = prevProducts.map(p => 
          p.id === newProduct.id ? newProduct : p
        );
        console.log('[ProductContext] updateProductList: Producto actualizado', updated);
        return updated;
      }
      console.log('[ProductContext] updateProductList: Producto nuevo, agregándolo al inicio');
      return [newProduct, ...prevProducts];
    });
  }, []);

  return (
    <ProductContext.Provider value={{ 
      products, 
      refreshProducts, 
      loading, 
      error,
      updateProductList 
    }}>
      {children}
    </ProductContext.Provider>
  );
};
