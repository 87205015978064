// D:\directiveai-react\frontend\src\components\AGTUps\products\AGTProduct.js
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext
} from 'react';
import {
  Box,
  Snackbar,
  Tabs,
  Tab,
  Alert,
  useMediaQuery,
  CircularProgress
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { AlertCircle, ChevronLeft, ChevronRight } from 'lucide-react';

// Importar el chat flotante modificado
import AGTFloatingChat from './utils/AGTFloatingChat';

import MarketAnalysis from './AGTProduct-products/MarketAnalysis';
import PricingStrategy from './AGTProduct-products/PricingStrategy';
import SalesStrategy from './AGTProduct-products/SalesStrategy';
import ProductsManager from './AGTProduct-products/ProductsManager';
import Cost from './AGTProduct-products/Cost';

// IMPORTAR EL CONTEXTO GLOBAL DE PRODUCTOS
import { ProductContext } from '../../../context/ProductContext';

// ---------------------------------------------------------------------------
// Styled Components para la navegación
// ---------------------------------------------------------------------------
const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  }
}));

const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background:
      theme.palette.mode === 'dark'
        ? '#1C1C1E'
        : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden',
  }
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible'
  },
  '& .MuiTabs-indicator': {
    display: 'none'
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(118, 118, 128, 0.24)'
      : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow:
    theme.palette.mode === 'dark'
      ? 'none'
      : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  }
}));

// ---------------------------------------------------------------------------
// Componente principal: AGTProduct
// ---------------------------------------------------------------------------
const AGTProduct = ({ user, initialTab = 0, triggerAdd = false, onResetTriggerAdd, refreshProducts }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Consumir el contexto global de productos
  const { refreshProducts: refreshProductsFromContext, loading: productsLoading } = useContext(ProductContext);

  // Estado para la pestaña activa
  const [activeTab, setActiveTab] = useState(initialTab);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Estados para el scroll de tabs
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: 3 });
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);

  // Definición de tabs
  const navigationTabs = [
    { id: 0, label: 'Products' },
    { id: 1, label: 'Cost' },
    { id: 2, label: 'Market' },
    { id: 3, label: 'Pricing' },
    { id: 4, label: 'Sales' }
  ];

  // Al cambiar de tab se reinicia el scroll del contenedor principal
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  // Manejo de notificaciones
  const showNotification = useCallback((message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setShowSnackbar(true);
  }, []);

  // Ajustar el rango visible de tabs según el ancho de la pantalla
  useEffect(() => {
    const checkMobile = () => {
      const isMobileView = window.innerWidth <= 768;
      if (isMobileView !== isMobile) {
        setVisibleRange({ start: 0, end: 3 });
      }
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!isMobile) {
      setVisibleRange({ start: 0, end: navigationTabs.length });
      return;
    }
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return;
    if (activeIdx < 3) {
      setVisibleRange({ start: 0, end: 3 });
    } else if (activeIdx >= navigationTabs.length - 3) {
      setVisibleRange({
        start: navigationTabs.length - 3,
        end: navigationTabs.length
      });
    } else {
      setVisibleRange({
        start: activeIdx - 1,
        end: activeIdx + 2
      });
    }
  }, [activeTab, navigationTabs.length, isMobile]);

  // Estilo para el indicador animado
  const getIndicatorStyle = useCallback(() => {
    const activeIdx = navigationTabs.findIndex(tab => tab.id === activeTab);
    if (activeIdx === -1) return {};
    const visibleIdx = activeIdx - visibleRange.start;
    const offset = visibleIdx * (74 + 4);
    return { transform: `translateX(${offset}px)` };
  }, [activeTab, visibleRange.start, navigationTabs]);

  // Manejo del cambio de pestaña (evita doble clic rápido)
  const handleTabChange = useCallback((event, newValue) => {
    const now = Date.now();
    if (now - lastNavigationTime.current >= 300) {
      lastNavigationTime.current = now;
      setActiveTab(newValue);
    }
  }, []);

  // Efecto para resetear el flag "triggerAdd" una vez que se consuma la acción de auto-add.
  useEffect(() => {
    if (triggerAdd && onResetTriggerAdd) {
      // Al primer render con triggerAdd = true, se notifica para resetearlo
      onResetTriggerAdd();
    }
  }, [triggerAdd, onResetTriggerAdd]);

  // Renderizar contenido según la pestaña activa
  const renderContent = useCallback(() => {
    switch (activeTab) {
      case 0:
        return (
          <ProductsManager
            user={user}
            autoAdd={triggerAdd}
            onAutoAddConsumed={onResetTriggerAdd}
          />
        );
      case 1:
        return <Cost showNotification={showNotification} />;
      case 2:
        return <MarketAnalysis />;
      case 3:
        return <PricingStrategy />;
      case 4:
        return <SalesStrategy />;
      default:
        return null;
    }
  }, [activeTab, triggerAdd, user, showNotification, onResetTriggerAdd]);

  return (
    <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
      <NavigationContainer>
        <Box>
          {isMobile && activeTab !== 0 && (
            <NavigationButton
              onClick={() => handleTabChange(null, 0)}
              aria-label="Go back"
            >
              <ChevronLeft size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
          <StyledTabs
            ref={tabsRef}
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="product navigation tabs"
          >
            <TabIndicator style={getIndicatorStyle()} />
            {navigationTabs
              .slice(visibleRange.start, visibleRange.end)
              .map((tab) => (
                <StyledTab key={tab.id} label={tab.label} disableRipple value={tab.id} />
              ))}
          </StyledTabs>
          {isMobile && visibleRange.end < navigationTabs.length && (
            <NavigationButton
              onClick={() => {
                const nextTab = navigationTabs[visibleRange.end];
                handleTabChange(null, nextTab.id);
              }}
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          )}
        </Box>
      </NavigationContainer>
      
      <Box
        sx={{
          mt: 2,
          flexGrow: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        {renderContent()}
      </Box>

      {/* Se coloca el chat fijo en la parte inferior de la pantalla */}
      <AGTFloatingChat activeTab={activeTab} />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity} elevation={6} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      
      {productsLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255,255,255,0.5)'
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default AGTProduct;
