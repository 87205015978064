import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect
} from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
  Avatar,
  CardContent,
  IconButton,
  Alert,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
  FormHelperText,
  Tooltip,
  useMediaQuery
} from '@mui/material';

// Icons
import {
  Building2,
  Users,
  TrendingUp,
  Edit,
  Save,
  Upload
} from 'lucide-react';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import LightbulbOutlined from '@mui/icons-material/LightbulbOutlined';

// Importamos "styled" y "useTheme" para el diseño personalizado
import { styled, useTheme } from '@mui/material/styles';

import profileService from '../services/AGTUps-product-services/profileService';
import profileEnhancementService from '../services/AGTUps-product-services/profileEnhancementService';
import AGTEnhanceButton from './AGTEnhanceButton';
import ProfileSetupWizard from './Design-AGTProfile/ProfileSetupWizard';
import WelcomeAnimation from './Design-AGTProfile/WelcomeAnimation'; // Nuevo componente de animación

// ---------------------------------------------------------------------
// 1) CONTENEDOR CON SCROLL
// ---------------------------------------------------------------------
const StyledContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 180px - 16px)',
  overflowY: 'auto',
  width: '100%',
  padding: theme.spacing(3),
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
  }
}));

// ---------------------------------------------------------------------
// 2) ESTILOS CUSTOMIZADOS DEL NAVIGATION
// ---------------------------------------------------------------------
const NavigationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1872px',
  height: '52px',
  display: 'flex',
  justifyContent: 'center',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    height: '52px',
    background:
      theme.palette.mode === 'dark'
        ? '#1C1C1E'
        : 'rgba(118, 118, 128, 0.20)',
    borderRadius: '30px',
    overflow: 'hidden'
  }
}));

const StyledTabs = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  minHeight: '52px',
  height: '52px',
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  '& .tabs-flexContainer': {
    display: 'flex',
    gap: '4px',
    height: '35px',
    overflow: 'hidden'
  }
}));

const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '100px',
  borderRadius: '30px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(118, 118, 128, 0.24)'
      : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow:
    theme.palette.mode === 'dark'
      ? 'none'
      : '0 2px 8px rgba(0, 0, 0, 0.04)'
}));

const StyledTab = styled('button')(({ theme, $selected }) => ({
  position: 'relative',
  minHeight: '35px',
  height: '35px',
  width: '100px',
  minWidth: '100px',
  padding: '0 8px',
  borderRadius: '30px',
  border: 'none',
  backgroundColor: 'transparent',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  '&:hover': {
    color: '#007BFE'
  },
  ...($selected && {
    color: '#007BFE'
  })
}));

// ---------------------------------------------------------------------
// 3) COMPONENTE PRINCIPAL: AGTProfile
// ---------------------------------------------------------------------
const AGTProfile = ({ user, onProfileUpdate }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [profileData, setProfileData] = useState({
    profileId: null,
    founderName: '',
    founderRole: '',
    founderEmail: '',
    founderPhone: '',
    founderLinkedIn: '',
    companyName: '',
    companyDescription: '',
    industry: '',
    foundedDate: '',
    companySize: 'small',
    website: '',
    location: '',
    businessModel: '',
    targetMarket: '',
    revenue: '',
    stage: 'startup',
    goals: '',
    challenges: '',
    business: {
      model: '',
      stage: 'startup',
      targetMarket: '',
      goals: [],
      challenges: []
    }
  });
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    severity: 'success'
  });
  const [isEnhancing, setIsEnhancing] = useState(false);
  
  // Estados para las animaciones de bienvenida
  const [showWelcome, setShowWelcome] = useState(true);
  const [showCompletionAnimation, setShowCompletionAnimation] = useState(false);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const lastNavigationTime = useRef(Date.now());
  const tabsRef = useRef(null);

  // TABS PARA EL NAVBAR PERSONALIZADO
  const navigationTabs = [
    { id: 0, label: 'Entrepreneur', disabled: false },
    { id: 1, label: 'Company', disabled: false },
    { id: 2, label: 'Business', disabled: false }
  ];

  // Cargamos el perfil cuando el usuario está disponible
  useEffect(() => {
    if (user) {
      profileService.initialize({ uid: user.uid });
      profileEnhancementService.initialize({ uid: user.uid });
      loadProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const loadProfile = async () => {
    try {
      setIsLoading(true);
      const response = await profileService.getProfile();

      if (response && response.success) {
        if (!response.exists || response.isNewProfile) {
          setIsNewProfile(true);
          setProfileData((prev) => ({
            ...prev,
            founderEmail: user?.email || ''
          }));
        } else if (response.data?.profile) {
          const data = response.data.profile;
          const transformedData = {
            profileId: data.profileId || null,
            founderName: data['founder.name'] || '',
            founderRole: data['founder.role'] || '',
            founderEmail: data.contactEmail || user?.email || '',
            founderPhone: data['founder.phone'] || '',
            founderLinkedIn: data['founder.linkedin'] || '',
            companyName: data.companyName || '',
            companyDescription: data.description || '',
            industry: data.industry || '',
            foundedDate: data.foundingDate || '',
            companySize: data.size || 'small',
            website: data.website || '',
            location: data.location || '',
            businessModel: data['business.model'] || '',
            targetMarket: data['business.targetMarket'] || '',
            revenue: data['business.revenue'] || '',
            stage: data['business.stage'] || 'startup',
            goals: data['business.goals'] || [],
            challenges: data['business.challenges'] || [],
            business: {
              model: data['business.model'] || '',
              targetMarket: data['business.targetMarket'] || '',
              revenue: data['business.revenue'] || '',
              stage: data['business.stage'] || 'startup',
              goals: data['business.goals'] || [],
              challenges: data['business.challenges'] || []
            }
          };

          setProfileData(transformedData);
          setProfileImage(data.profileImage);
          setIsNewProfile(false);
          onProfileUpdate && onProfileUpdate(transformedData);
        }
      } else {
        throw new Error(response.error?.message || 'Error loading profile');
      }
    } catch (error) {
      console.error('[AGTProfile] Error loading profile:', error);
      setAlert({
        show: true,
        message: error.message,
        severity: 'error'
      });
      setIsNewProfile(true);
    } finally {
      setIsLoading(false);
      setInitialLoadComplete(true);
    }
  };

  // LÓGICA DEL NAVIGATION
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    const mainContainer = document.querySelector('main');
    if (mainContainer) {
      mainContainer.scrollTop = 0;
    }
  }, [activeTab]);

  const getIndicatorStyle = useCallback(() => {
    const offset = activeTab * (100 + 4); // 100px de cada tab + 4px de gap
    return {
      transform: `translateX(${offset}px)`
    };
  }, [activeTab]);

  const handleTabChange = useCallback(
    (event, newValue) => {
      const now = Date.now();
      if (now - lastNavigationTime.current >= 300) {
        const tab = navigationTabs.find((t) => t.id === newValue);
        if (tab?.disabled) return;
        lastNavigationTime.current = now;
        setActiveTab(newValue);
      }
    },
    [navigationTabs]
  );

  // LÓGICA DEL FORMULARIO / PERFIL
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setProfileData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setProfileData((prev) => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Función handleSave mejorada según sugerencia:
  const handleSave = async () => {
    try {
      setIsLoading(true);
      const profileToUpdate = {
        companyName: profileData.companyName,
        industry: profileData.industry,
        contactEmail: profileData.founderEmail,
        description: profileData.companyDescription,
        foundingDate: profileData.foundedDate,
        size: profileData.companySize,
        location: profileData.location,
        website: profileData.website,
        profileImage,
        founder: {
          name: profileData.founderName,
          role: profileData.founderRole,
          phone: profileData.founderPhone,
          linkedin: profileData.founderLinkedIn
        },
        business: {
          model: profileData.businessModel,
          targetMarket: profileData.targetMarket,
          revenue: profileData.revenue,
          stage: profileData.stage,
          goals: profileData.goals,
          challenges: profileData.challenges
        }
      };

      // Validaciones básicas
      if (
        !profileToUpdate.companyName ||
        !profileToUpdate.industry ||
        !profileToUpdate.contactEmail
      ) {
        throw new Error(
          'Please complete the required fields: Company Name, Industry, and Contact Email.'
        );
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(profileToUpdate.contactEmail)) {
        throw new Error('Please enter a valid email address.');
      }

      let result;
      if (isNewProfile) {
        result = await profileService.createProfile(profileToUpdate);
      } else {
        result = await profileService.updateProfile(
          profileData.profileId,
          profileToUpdate
        );
      }

      if (result.success) {
        const responseData = result.data.data;
        const transformedData = {
          profileId: responseData.profileId,
          founderName: responseData['founder.name'] || '',
          founderRole: responseData['founder.role'] || '',
          founderEmail: responseData.contactEmail || '',
          founderPhone: responseData['founder.phone'] || '',
          founderLinkedIn: responseData['founder.linkedin'] || '',
          companyName: responseData.companyName || '',
          companyDescription: responseData.description || '',
          industry: responseData.industry || '',
          foundedDate: responseData.foundingDate || '',
          companySize: responseData.size || 'small',
          website: responseData.website || '',
          location: responseData.location || '',
          businessModel: responseData['business.model'] || '',
          targetMarket: responseData['business.targetMarket'] || '',
          revenue: responseData['business.revenue'] || '',
          stage: responseData['business.stage'] || 'startup',
          goals: responseData['business.goals'] || [],
          challenges: responseData['business.challenges'] || [],
          business: {
            model: responseData['business.model'] || '',
            targetMarket: responseData['business.targetMarket'] || '',
            revenue: responseData['business.revenue'] || '',
            stage: responseData['business.stage'] || 'startup',
            goals: responseData['business.goals'] || [],
            challenges: responseData['business.challenges'] || []
          }
        };

        // Actualizamos el estado del perfil
        setProfileData(transformedData);
        
        if (isNewProfile) {
          // Para un perfil nuevo, disparamos la animación de finalización.
          setShowCompletionAnimation(true);
          // La función loadProfile y onProfileUpdate se llamarán una vez completada la animación.
        } else {
          await loadProfile();
          setIsEditing(false);
          onProfileUpdate && onProfileUpdate(transformedData);
        }

        setAlert({
          show: true,
          message: 'Profile saved successfully.',
          severity: 'success'
        });
      } else {
        throw new Error(result.error?.message || 'Error saving profile.');
      }
    } catch (error) {
      console.error('[AGTProfile] Error saving profile:', error);
      setAlert({
        show: true,
        message: error.message,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnhanceProfile = async () => {
    try {
      setIsEnhancing(true);
      setAlert({ show: false, message: '', severity: 'success' });

      const result = await profileEnhancementService.enhanceProfile(profileData);

      if (result.success) {
        const updatedProfile = result.data;
        setProfileData({
          ...profileData,
          companyDescription: updatedProfile.description,
          businessModel: updatedProfile['business.model'],
          business: {
            ...profileData.business,
            targetMarket: updatedProfile['business.targetMarket'],
            goals: updatedProfile['business.goals'],
            challenges: updatedProfile['business.challenges']
          }
        });

        setAlert({
          show: true,
          message: 'Profile enhanced successfully',
          severity: 'success'
        });
      } else {
        throw new Error(result.error || 'Error enhancing profile.');
      }
    } catch (error) {
      console.error('[AGTProfile] Error enhancing profile:', error);
      setAlert({
        show: true,
        message: error.message,
        severity: 'error'
      });
    } finally {
      setIsEnhancing(false);
    }
  };

  // SUBCOMPONENTES: Contenido según TAB
  const renderFounderInfo = () => (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={0}
          sx={{
            p: { xs: 1.5, sm: 2 },
            textAlign: 'center',
            borderRadius: { xs: '20px', sm: '28px' },
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(255,255,255,0.1)'
          }}
        >
          <Box sx={{ position: 'relative', display: 'inline-block' }}>
            <Avatar
              src={profileImage || profileData.profileImage}
              sx={{
                width: { xs: 80, sm: 100, md: 120 },
                height: { xs: 80, sm: 100, md: 120 },
                margin: '0 auto'
              }}
            />
            {isEditing && (
              <label htmlFor="profile-image">
                <input
                  type="file"
                  id="profile-image"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
                <IconButton
                  component="span"
                  sx={{
                    position: 'absolute',
                    bottom: -4,
                    right: -4,
                    backgroundColor: 'background.paper',
                    '&:hover': {
                      backgroundColor: 'background.default'
                    }
                  }}
                >
                  <Upload size={20} />
                </IconButton>
              </label>
            )}
          </Box>
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              fontSize: { xs: '1rem', sm: '1.25rem' }
            }}
          >
            {profileData.founderName || 'Your Name'}
          </Typography>
          <Typography color="textSecondary">
            {profileData.founderRole || 'Your Role'}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={8}>
        <Paper
          elevation={0}
          sx={{
            p: { xs: 1.5, sm: 2 },
            borderRadius: { xs: '20px', sm: '28px' },
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(255,255,255,0.1)'
          }}
        >
          <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
            >
              Personal Information
            </Typography>
            <Grid container spacing={{ xs: 1.5, sm: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Full Name"
                  name="founderName"
                  value={profileData.founderName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Role"
                  name="founderRole"
                  value={profileData.founderRole}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="founderEmail"
                  type="email"
                  value={profileData.founderEmail}
                  disabled
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone (Optional)"
                  name="founderPhone"
                  value={profileData.founderPhone}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="You can leave this blank if you want"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="LinkedIn (Optional)"
                  name="founderLinkedIn"
                  value={profileData.founderLinkedIn}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="If you have a LinkedIn profile, you can link it here"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderCompanyInfo = () => (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{
            p: { xs: 1.5, sm: 2 },
            borderRadius: { xs: '20px', sm: '28px' },
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(255,255,255,0.1)'
          }}
        >
          <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
            >
              Company Information
            </Typography>
            <Grid container spacing={{ xs: 1.5, sm: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Company Name"
                  name="companyName"
                  value={profileData.companyName}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  required
                  error={isEditing && !profileData.companyName}
                  helperText={
                    isEditing && !profileData.companyName
                      ? 'Company Name is required'
                      : ''
                  }
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Company Description (Optional)"
                  name="companyDescription"
                  value={profileData.companyDescription}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="If you don't have this yet, you can complete it later."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  required
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                >
                  <InputLabel>Industry</InputLabel>
                  <Select
                    name="industry"
                    value={profileData.industry}
                    onChange={handleInputChange}
                    label="Industry"
                    sx={{ borderRadius: '28px' }}
                  >
                    <MenuItem value="technology">Technology</MenuItem>
                    <MenuItem value="retail">Retail</MenuItem>
                    <MenuItem value="manufacturing">Manufacturing</MenuItem>
                    <MenuItem value="services">Services</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Founding Date (Optional)"
                  name="foundedDate"
                  type="date"
                  value={profileData.foundedDate}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="If you're not sure, leave it blank."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                >
                  <InputLabel>Company Size</InputLabel>
                  <Select
                    name="companySize"
                    value={profileData.companySize}
                    onChange={handleInputChange}
                    label="Company Size"
                    sx={{ borderRadius: '28px' }}
                  >
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText>
                  You can update this later
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Website (Optional)"
                  name="website"
                  value={profileData.website}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="Example: https://www.yourcompany.com"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Location"
                  name="location"
                  value={profileData.location}
                  onChange={handleInputChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText={`Location where the startup operates or plans to receive its sales, e.g: "United States, California, San Francisco on 91st Street".`}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderBusinessInfo = () => (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          sx={{
            p: { xs: 1.5, sm: 2 },
            borderRadius: { xs: '20px', sm: '28px' },
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? 'rgba(0,0,0,0.05)'
                : 'rgba(255,255,255,0.1)'
          }}
        >
          <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
            >
              Business Information
            </Typography>
            <Grid container spacing={{ xs: 1.5, sm: 2 }}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Business Model (Optional)"
                  name="businessModel"
                  multiline
                  rows={3}
                  value={profileData.businessModel}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="e.g. subscription, product sales, advertising, etc."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Target Market (Optional)"
                  name="business.targetMarket"
                  multiline
                  rows={2}
                  value={profileData.business.targetMarket}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="You can define your audience or leave it blank for now."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box sx={{ display: 'flex', alignItems: 'start', gap: 1 }}>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '28px'
                      }
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <InputLabel>Business Stage</InputLabel>
                      <Tooltip
                        arrow
                        placement="right"
                        title={
                          <Box>
                            <Typography variant="body2">
                              <strong>Startup:</strong> Early-stage.
                            </Typography>
                            <Typography variant="body2">
                              <strong>Growth:</strong> Validated by the market, looking to expand.
                            </Typography>
                            <Typography variant="body2">
                              <strong>Established:</strong> Solid and stable operation.
                            </Typography>
                            <Typography variant="body2">
                              <strong>Expanding:</strong> Exploring new markets or products.
                            </Typography>
                            <Typography variant="body2">
                              <strong>Not sure:</strong> You can change this later.
                            </Typography>
                          </Box>
                        }
                      >
                        <IconButton size="small" sx={{ ml: 1 }}>
                          <InfoOutlined fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Select
                      name="business.stage"
                      value={profileData.business?.stage}
                      onChange={handleInputChange}
                      label="Business Stage"
                      sx={{
                        borderRadius: '28px'
                      }}
                      disabled={!isEditing}
                    >
                      <MenuItem value="startup">Startup</MenuItem>
                      <MenuItem value="growth">Growth</MenuItem>
                      <MenuItem value="established">Established</MenuItem>
                      <MenuItem value="expanding">Expanding</MenuItem>
                      <MenuItem value="unknown">Not sure</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    arrow
                    placement="right"
                    title={
                      <Box>
                        <Typography variant="body2">
                          <strong>Startup:</strong> Early stage with a product in development.
                        </Typography>
                        <Typography variant="body2">
                          <strong>Growth:</strong> Product is validated by the market.
                        </Typography>
                        <Typography variant="body2">
                          <strong>Established:</strong> Solid and stable business.
                        </Typography>
                        <Typography variant="body2">
                          <strong>Expanding:</strong> Exploring new markets or product lines.
                        </Typography>
                        <Typography variant="body2">
                          <strong>Not sure:</strong> Not clear yet; can change later.
                        </Typography>
                      </Box>
                    }
                  >
                    <IconButton size="small" sx={{ mt: 1 }} disabled={!isEditing}>
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <FormHelperText>
                  You can update this later if you're not sure.
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Short/Medium-Term Goals (Optional)"
                  name="goals"
                  multiline
                  rows={3}
                  value={profileData.goals}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="e.g. increase sales by 20%, expand to another country, etc."
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Main Challenges (Optional)"
                  name="challenges"
                  multiline
                  rows={3}
                  value={profileData.challenges}
                  onChange={handleInputChange}
                  disabled={!isEditing}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '28px'
                    }
                  }}
                  helperText="e.g. lack of capital, strong competition, etc."
                />
              </Grid>
            </Grid>
          </CardContent>
        </Paper>
      </Grid>
    </Grid>
  );

  // Si estamos cargando o se trata de un perfil nuevo mostramos el wizard o la animación de bienvenida
  if (!initialLoadComplete || isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Condicionales para mostrar animaciones en el flujo de un perfil nuevo
  if (initialLoadComplete && isNewProfile) {
    if (showWelcome) {
      return (
        <WelcomeAnimation 
          onComplete={() => setShowWelcome(false)}
          stage="initial"
        />
      );
    }

    if (showCompletionAnimation) {
      return (
        <WelcomeAnimation 
          onComplete={() => {
            setShowCompletionAnimation(false);
            loadProfile();
            onProfileUpdate && onProfileUpdate(profileData);
          }}
          stage="completion"
        />
      );
    }

    return (
      <Box
        sx={{
          p: { xs: 2, sm: 3 },
          backgroundColor: 'transparent',
          color: 'text.primary'
        }}
      >
        {alert.show && (
          <Alert
            severity={alert.severity}
            sx={{ mb: 3, borderRadius: '28px' }}
            onClose={() =>
              setAlert({ show: false, message: '', severity: 'success' })
            }
          >
            {alert.message}
          </Alert>
        )}

        <ProfileSetupWizard
          open={true}
          onClose={() => {}} // No se permite cerrar ya que es obligatorio configurar el perfil
          profileData={profileData}
          onChange={handleInputChange}
          onSubmit={handleSave}
        />
      </Box>
    );
  }

  // Render principal para perfil existente (no nuevo)
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      {/* NAVIGATION fuera del área de scroll */}
      <NavigationContainer>
        <Box>
          <StyledTabs ref={tabsRef}>
            <TabIndicator style={getIndicatorStyle()} />
            <div className="tabs-flexContainer">
              {navigationTabs.map((tab) => (
                <StyledTab
                  key={tab.id}
                  $selected={tab.id === activeTab}
                  onClick={(e) => handleTabChange(e, tab.id)}
                  disabled={tab.disabled}
                >
                  {tab.label}
                </StyledTab>
              ))}
            </div>
          </StyledTabs>
        </Box>
      </NavigationContainer>

      {/* Área scrolleable */}
      <StyledContainer sx={{ mt: 2 }}>
        {/* Encabezado con botones Enhance/Edit */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            mb: 4
          }}
        >
          {!isSmallScreen && (
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              Business Profile
            </Typography>
          )}

          <Box sx={{ display: 'flex', gap: 2 }}>
            <AGTEnhanceButton
              onEnhance={handleEnhanceProfile}
              isLoading={isEnhancing}
              disabled={isEditing}
            />

            <Button
              variant={isEditing ? 'contained' : 'outlined'}
              startIcon={isEditing ? <Save /> : <Edit />}
              onClick={isEditing ? handleSave : () => setIsEditing(true)}
              sx={{
                textTransform: 'none',
                fontWeight: 500,
                borderRadius: '28px',
                color: isEditing ? '#fff' : '#0385FF',
                ...(isEditing
                  ? {
                      backgroundColor: '#0385FF',
                      '&:hover': {
                        backgroundColor: '#0266cc'
                      }
                    }
                  : {
                      borderColor: '#0385FF',
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(3,133,255,0.08)',
                        borderColor: '#0266cc'
                      }
                    })
              }}
            >
              {isEditing ? 'Save Changes' : 'Edit Profile'}
            </Button>
          </Box>
        </Box>

        {alert.show && (
          <Alert
            severity={alert.severity}
            sx={{ mb: 4, borderRadius: '28px' }}
            onClose={() =>
              setAlert({ show: false, message: '', severity: 'success' })
            }
          >
            {alert.message}
          </Alert>
        )}

        {/* Contenido de la pestaña activa */}
        <Box sx={{ mt: 4 }}>
          {activeTab === 0 && renderFounderInfo()}
          {activeTab === 1 && renderCompanyInfo()}
          {activeTab === 2 && renderBusinessInfo()}
        </Box>
      </StyledContainer>
    </Box>
  );
};

export default AGTProfile;
