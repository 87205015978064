import React, { useState, useCallback, useEffect, useContext } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  IconButton, 
  Fade, 
  Tooltip, 
  CircularProgress,
  useMediaQuery
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import { 
  BarChart3, 
  Receipt, 
  Users, 
  Brain, 
  TrendingUp, 
  Star, 
  Building2, 
  Plus, 
  Package, 
  DollarSign, 
  Calendar, 
  MessageCircle, 
  HelpCircle, 
  Lightbulb, 
  Upload, 
  Download, 
  PieChart, 
  ArrowUpDown, 
  UserPlus, 
  ListChecks, 
  MessageSquare,
  Home,
  ChevronRight,
  ChevronLeft,
  Lock,
  ShoppingCart
} from 'lucide-react';

import AGTProduct from './products/AGTProduct';
import AGTCashFlow from './products/AGTCashFlow';
import AGTSupplierFinder from './products/AGTSupplierFinder';
import AGTCouncil from './products/AGTCouncil';
import AGTGrowth from './products/AGTGrowth';
import AGTProfile from './products/AGTProfile';
import AGTFound from './extras/AGTFound';
import AGTFoundResults from './extras/AGTFoundResults';
import profileService from './services/AGTUps-product-services/profileService';
import AGTFoundService from './services/AGTUps-product-services/AGTFound/AGTFoundService';

// Importación de los shortcuts modales
import SalesStrategyShortcut from './shortcuts/SalesStrategyShortcut';
import ProductShortcut from './shortcuts/ProductShortcut';
import CheckInventoryShortcut from './shortcuts/CheckInventoryShortcut';
import ReviewPricesShortcut from './shortcuts/ReviewPricesShortcut';

// Importamos el contexto de productos
import { ProductContext } from '../../context/ProductContext';

const CACHE_DURATION = 24 * 60 * 60 * 1000;
const getAuthToken = () => sessionStorage.getItem('authToken');

const useProfile = (user) => {
  const [companyProfile, setCompanyProfile] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);

  const STORAGE_KEY = user ? `agtups_profile_${user.uid}` : null;

  const getStoredProfile = useCallback(() => {
    if (!STORAGE_KEY) return null;
    const storedData = localStorage.getItem(STORAGE_KEY);
    if (storedData) {
      const { profile, timestamp } = JSON.parse(storedData);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      if (!isExpired) return profile;
      localStorage.removeItem(STORAGE_KEY);
    }
    return null;
  }, [STORAGE_KEY]);

  const storeProfile = useCallback((profile) => {
    if (!STORAGE_KEY) return;
    const dataToStore = { profile, timestamp: Date.now() };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(dataToStore));
  }, [STORAGE_KEY]);

  const fetchAndUpdateProfile = useCallback(async () => {
    if (!user) return;
    try {
      setIsProfileLoading(true);
      setProfileError(null);
      const token = getAuthToken();
      if (!token) throw new Error('No authentication token found');
      const response = await profileService.getProfile({
        uid: user.uid,
        token,
        email: user.email
      });
      if (response.success) {
        setCompanyProfile(response.data);
        storeProfile(response.data);
        return response.data;
      } else {
        throw new Error(response.error || 'Error al cargar el perfil');
      }
    } catch (error) {
      setProfileError(error.message);
      throw error;
    } finally {
      setIsProfileLoading(false);
    }
  }, [user, storeProfile]);

  useEffect(() => {
    const initializeProfile = async () => {
      if (!user) {
        setCompanyProfile(null);
        setIsProfileLoading(false);
        return;
      }
      try {
        setIsProfileLoading(true);
        const storedProfile = getStoredProfile();
        if (storedProfile) {
          setCompanyProfile(storedProfile);
          setIsProfileLoading(false);
          fetchAndUpdateProfile().catch(console.error);
        } else {
          await fetchAndUpdateProfile();
        }
      } catch (error) {
        console.error('Error initializing profile:', error);
        setProfileError(error.message);
        setIsProfileLoading(false);
      }
    };
    initializeProfile();
  }, [user, getStoredProfile, fetchAndUpdateProfile]);

  return {
    companyProfile,
    isProfileLoading,
    profileError,
    refreshProfile: fetchAndUpdateProfile
  };
};

// =============================================================================================
// Estilos adaptados a modo claro/oscuro
// =============================================================================================
const AppContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#000000',
}));

const ContentContainer = styled(Box)(({ theme, activeComponent }) => ({
  flexGrow: 1,
  padding: activeComponent ? 0 : theme.spacing(2),
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: activeComponent && ['product', 'cashflow', 'supplier', 'council', 'growth', 'profile'].includes(activeComponent)
    ? 'hidden'
    : 'auto',
  [theme.breakpoints.up('md')]: {
    padding: activeComponent ? 0 : theme.spacing(3),
  },
}));

const TopBar = styled(Box)(({ theme, activeComponent }) => ({
  display: 'flex',
  position: 'relative',
  marginBottom: activeComponent ? 0 : theme.spacing(3),
  width: '100%',
  [theme.breakpoints.up('md')]: {
    marginBottom: activeComponent ? 0 : theme.spacing(4),
  },
  '& .top-wrapper': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  '& .navigation-row': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1400px',
    padding: '0 16px',
    height: '48px',
    opacity: 1,
    overflow: 'hidden',
    transition: 'all 0.3s ease',
    position: 'relative',
  },
  '& .navigation-controls': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2,
  },
  '& .home-button': {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: theme.spacing(2),
    zIndex: 2,
  },
  '& .component-title': {
    color: theme.palette.text.primary,
    fontWeight: 500,
    fontSize: '1.5rem',
    letterSpacing: '0.5px',
    lineHeight: '1.5',
    margin: '0 8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem',
    },
  },
  '& .actions': {
    display: 'flex',
    gap: theme.spacing(1),
    position: 'absolute',
    right: theme.spacing(2),
    zIndex: 2,
  },
  '& .search-container': {
    width: '100%',
    maxWidth: '800px',
    padding: '0 16px',
    marginTop: theme.spacing(2),
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 500,
  color: theme.palette.text.primary,
  maxWidth: '1400px',
  padding: theme.spacing(0, 2),
}));

const Separator = styled(Box)(({ theme }) => ({
  maxWidth: '1400px',
  margin: '40px auto',
  height: '1px',
  background: `linear-gradient(to right, 
    ${alpha(theme.palette.text.primary, 0.1)}, 
    ${alpha(theme.palette.text.primary, 0.15)}, 
    ${alpha(theme.palette.text.primary, 0.1)}
  )`,
}));

const ShortcutsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    overflowX: 'auto',
  },
  '&::-webkit-scrollbar': {
    height: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: alpha(theme.palette.text.primary, 0.1),
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: alpha(theme.palette.text.primary, 0.2),
    borderRadius: '4px',
  },
}));

const ShortcutGroup = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    minWidth: '300px',
  },
}));

const ShortcutTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

const ShortcutsGrid = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.mode === 'light' ? '#f2f2f2' : '#333333',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

// Actualizamos el ActionButtonWrapper para incluir position: relative
const ActionButtonWrapper = styled(Box)(({ theme, color }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(1),
  cursor: 'pointer',
  '& .icon-wrapper': {
    background: `linear-gradient(
      to top, 
      rgba(0, 0, 0, 0.4), 
      transparent
    ), ${color || theme.palette.primary.main}`,
    borderRadius: '50%',
    width: 36,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '& .action-text': {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    textAlign: 'center',
    lineHeight: 1.2,
    minHeight: '2.4em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  }
}));

// Componente para el punto rojo de notificación sin borde
const NotificationDot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -4,
  right: -4,
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: 'red',
}));

const ModuleOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(0,0,0,0.4)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 3,
  pointerEvents: 'all'
}));

const ModuleContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  width: '100%',
  maxWidth: '1400px',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  },
}));

const ModuleCard = styled(Paper)(({ theme, color }) => ({
  position: 'relative',
  borderRadius: theme.spacing(2),
  overflow: 'hidden',
  backgroundColor: color || theme.palette.primary.main,
  border: 'none',
  padding: 0,
  height: '100px',
  cursor: 'pointer',
  transition: 'transform 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  [theme.breakpoints.up('sm')]: {
    height: '120px',
  },
  '& .content-wrapper': {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    background: `linear-gradient(to top, rgba(0,0,0,0.4) 0%, transparent 100%)`,
    zIndex: 1
  },
  '& .title-wrapper': {
    position: 'absolute',
    top: theme.spacing(1.5),
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    zIndex: 2,
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(2),
    }
  },
  '& .title': {
    color: '#fff',
    fontWeight: 500,
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    }
  },
  '& .description': {
    color: 'rgba(255, 255, 255, 0.7)',
    fontSize: '0.75rem',
    position: 'absolute',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
    lineHeight: 1.2,
    maxWidth: '90%',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.875rem',
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      right: theme.spacing(3),
      maxWidth: '80%',
    }
  },
}));

const ContentSection = styled(Box)(({ theme, activeComponent }) => ({
  maxWidth: '1400px',
  margin: '0 auto',
  padding: activeComponent ? 0 : '0 8px',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: activeComponent ? 'hidden' : 'visible',
  height: activeComponent ? '100%' : 'auto',
  [theme.breakpoints.up('sm')]: {
    padding: activeComponent ? 0 : '0 16px',
  },
}));

const CircularIconWrapper = styled(Box)(({ theme, color }) => ({
  background: `linear-gradient(
    to top, 
    rgba(0, 0, 0, 0.4), 
    transparent
  ), ${color || theme.palette.primary.main}`,
  borderRadius: '50%',
  width: 36,
  height: 36,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

// ---------------------------------------------------------------------------------------
// Definición de arrays estáticos
// ---------------------------------------------------------------------------------------
const shortcuts = [
  {
    id: 'product',
    title: 'AGT Product',
    color: '#415B7E',
    actions: [
      { icon: Plus, label: 'New Product' },
      { icon: ShoppingCart, label: 'New Sales' },
      { icon: Package, label: 'Check Inventory' },
      { icon: DollarSign, label: 'Review Prices' }
    ]
  },
  {
    id: 'council',
    title: 'AGT Council',
    color: '#4A4F81',
    actions: [
      { icon: Calendar, label: 'Schedule Meeting' },
      { icon: MessageCircle, label: 'Quick Question' },
      { icon: HelpCircle, label: 'Decision Help' },
      { icon: Lightbulb, label: 'Get Advice' }
    ]
  },
  {
    id: 'cashflow',
    title: 'AGT Cash Flow',
    color: '#DC9844',
    actions: [
      { icon: Upload, label: 'Quick Receipt' },
      { icon: Download, label: 'Current Flow' },
      { icon: PieChart, label: 'View Reports' },
      { icon: ArrowUpDown, label: 'Transactions' }
    ]
  },
  {
    id: 'supplier',
    title: 'AGT Supplier Finder',
    color: '#25505A',
    actions: [
      { icon: MessageSquare, label: 'Active Deals' },
      { icon: UserPlus, label: 'New Supplier' },
      { icon: ListChecks, label: 'View All' }
    ]
  }
];

const modules = [
  {
    id: 'product',
    title: 'AGT Product',
    description: 'Optimize pricing, sales strategies, and market fit',
    color: '#415B7E',
    icon: BarChart3,
    component: AGTProduct,
    actions: shortcuts.find(s => s.id === 'product')?.actions || []
  },
  {
    id: 'cashflow',
    title: 'AGT Cash Flow',
    description: 'Upload receipts and automate financial updates instantly',
    color: '#DC9844',
    icon: Receipt,
    component: AGTCashFlow,
    actions: []
  },
  {
    id: 'supplier',
    title: 'AGT Supplier Finder',
    description: 'Find and negotiate with suppliers through AI',
    color: '#25505A',
    icon: Users,
    component: AGTSupplierFinder,
    actions: []
  },
  {
    id: 'council',
    title: 'AGT Council',
    description: 'Get expert advice for complex business decisions',
    color: '#4A4F81',
    icon: Brain,
    component: AGTCouncil,
    actions: []
  },
  {
    id: 'growth',
    title: 'AGT Growth',
    description: "Strategies tailored to every stage of your startup's journey",
    color: '#FFB692',
    icon: TrendingUp,
    component: AGTGrowth,
    actions: []
  },
  {
    id: 'profile',
    title: 'AGT Profile',
    description: 'Manage your company profile and founding team',
    color: '#BC4C4A',
    icon: Building2,
    component: AGTProfile,
    actions: []
  },
];

const getResponsiveTitle = (fullTitle, isMobile) => {
  if (!isMobile) return fullTitle;
  const titleMap = {
    'AGT Product': 'Product',
    'AGT Cash Flow': 'Cash',
    'AGT Supplier Finder': 'Supplier',
    'AGT Council': 'Council',
    'AGT Growth': 'Growth',
    'AGT Profile': 'Profile'
  };
  return titleMap[fullTitle] || fullTitle;
};

// ---------------------------------------------------------------------------------------
// Componente principal: AGTUps
// ---------------------------------------------------------------------------------------
const AGTUps = ({ user }) => {
  const { products, refreshProducts } = useContext(ProductContext);

  // Calcular notificaciones para "Check Inventory"
  const stockProducts = (products || []).filter(p => p.category === 'physical' || p.category === 'food');
  const nonStockProducts = (products || []).filter(p => !(p.category === 'physical' || p.category === 'food'));
  const userType = stockProducts.length === 0 ? 'digital' : nonStockProducts.length === 0 ? 'physical' : 'mixed';
  const hasInventoryNotification = (userType === 'physical' || userType === 'mixed') &&
    stockProducts.some(p => (parseInt(p.stock, 10) || 0) < 10);

  const [activeSection, setActiveSection] = useState(() => localStorage.getItem('activeSection') || 'home');
  const [companyProfile, setCompanyProfile] = useState(null);
  const [activeComponent, setActiveComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [favorites, setFavorites] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [productFlow, setProductFlow] = useState({ initialTab: 0, triggerAdd: false });
  
  // Estados para controlar la apertura de los modales de los shortcuts
  const [openSalesShortcutModal, setOpenSalesShortcutModal] = useState(false);
  const [openProductShortcutModal, setOpenProductShortcutModal] = useState(false);
  const [openCheckInventoryShortcutModal, setOpenCheckInventoryShortcutModal] = useState(false);
  const [openReviewPricesShortcutModal, setOpenReviewPricesShortcutModal] = useState(false);

  const handleProfileUpdate = (updatedProfile) => {
    setCompanyProfile(updatedProfile);
    if (!companyProfile && updatedProfile) {
      setActiveSection('home');
      localStorage.setItem('activeSection', 'home');
    }
  };

  useEffect(() => {
    if (!companyProfile && activeSection !== 'profile') {
      setActiveSection('profile');
      localStorage.setItem('activeSection', 'profile');
    }
  }, [companyProfile, activeSection]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const handleSearch = async (query) => {
    setIsSearching(true);
    try {
      const result = await AGTFoundService.search(query, {
        uid: user.uid,
        token: sessionStorage.getItem('authToken')
      });
      if (result.recommendations) {
        setSearchResults(result.recommendations);
        setActiveComponent('searchResults');
      }
    } catch (error) {
      console.error('Error en búsqueda:', error);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  const handleNavigate = useCallback((direction) => {
    const currentIndex = modules.findIndex(p => p.id === activeComponent);
    let nextIndex;
    if (direction === 'next') {
      nextIndex = currentIndex + 1 >= modules.length ? 0 : currentIndex + 1;
    } else {
      nextIndex = currentIndex - 1 < 0 ? modules.length - 1 : currentIndex - 1;
    }
    setActiveComponent(modules[nextIndex].id);
  }, [activeComponent]);

  const handleHome = useCallback(() => {
    setActiveComponent(null);
    setSearchResults([]);
    setSearchQuery('');
  }, []);

  const handleProductClick = useCallback((moduleId) => {
    if (moduleId === 'product') {
      setProductFlow(prev => ({ ...prev, triggerAdd: false }));
    }
    setActiveComponent(moduleId);
  }, []);

  const toggleFavorite = useCallback((moduleId) => {
    setFavorites(prev =>
      prev.includes(moduleId)
        ? prev.filter(id => id !== moduleId)
        : [...prev, moduleId]
    );
  }, []);

  const handleResultClick = (result) => {
    setActiveComponent(result.id);
  };

  const handleSearchResults = useCallback(async (result) => {
    if (result.error) {
      console.error(result.error);
      return;
    }
    setIsSearching(true);
    try {
      if (result.recommendations) {
        setSearchResults(result.recommendations);
        setActiveComponent('searchResults');
      }
    } catch (error) {
      console.error('Error processing search results:', error);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleProductShortcut = useCallback((actionLabel) => {
    if (actionLabel === 'New Product') {
      setOpenProductShortcutModal(true);
    } else if (actionLabel === 'New Sales') {
      setOpenSalesShortcutModal(true);
    } else if (actionLabel === 'Check Inventory') {
      setOpenCheckInventoryShortcutModal(true);
    } else if (actionLabel === 'Review Prices') {
      setOpenReviewPricesShortcutModal(true);
    }
  }, []);

  const handleResetProductFlowTrigger = useCallback(() => {
    setProductFlow(prev => ({ ...prev, triggerAdd: false }));
  }, []);

  const renderContent = () => {
    if (activeSection === 'profile') {
      return (
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
          <AGTProfile user={user} onProfileUpdate={handleProfileUpdate} />
        </Box>
      );
    }
    if (activeComponent === 'searchResults') {
      return (
        <AGTFoundResults 
          results={searchResults}
          isLoading={isSearching}
          searchQuery={searchQuery}
          onSearchChange={handleSearchChange}
          onSearchResults={handleSearchResults}
          onResultClick={handleResultClick}
          setActiveComponent={setActiveComponent}
          user={user}
        />
      );
    }
    
    const activeModule = modules.find(p => p.id === activeComponent);
    if (activeModule) {
      const Component = activeModule.component;
      if (activeModule.id === 'product') {
        return (
          <Component 
            user={user} 
            initialTab={productFlow.initialTab} 
            triggerAdd={productFlow.triggerAdd} 
            refreshProducts={refreshProducts}
            onResetTriggerAdd={handleResetProductFlowTrigger}
          />
        );
      }
      return <Component user={user} />;
    }
    
    return (
      <ContentSection>
        <SectionTitle sx={{ mb: 2 }}>Shortcuts</SectionTitle>
        <ShortcutsContainer>
          {shortcuts.map((group) => (
            <ShortcutGroup key={group.id}>
              <ShortcutTitle>{group.title}</ShortcutTitle>
              <Box position="relative">
                <ShortcutsGrid>
                  {group.actions.map((action, index) => (
                    <ActionButtonWrapper
                      key={index}
                      color={group.color}
                      onClick={group.id === 'product' ? () => handleProductShortcut(action.label) : undefined}
                    >
                      <div className="icon-wrapper">
                        <action.icon size={20} color="white" />
                        {group.id === 'product' && action.label === 'Check Inventory' && hasInventoryNotification && (
                          <NotificationDot />
                        )}
                      </div>
                      <Typography className="action-text">{action.label}</Typography>
                    </ActionButtonWrapper>
                  ))}
                </ShortcutsGrid>
                {group.id !== 'product' && (
                  <ModuleOverlay onClick={(e) => e.stopPropagation()}>
                    <Lock size={24} color="white" />
                  </ModuleOverlay>
                )}
              </Box>
            </ShortcutGroup>
          ))}
        </ShortcutsContainer>

        <Separator />

        <SectionTitle sx={{ mb: 2 }}>AGT Suggestions</SectionTitle>
        <ModuleContainer>
          {modules.map((module) => {
            const blockedModules = ['cashflow', 'supplier', 'council', 'growth'];
            return (
              <ModuleCard
                key={module.id}
                onClick={() => handleProductClick(module.id)}
                color={module.color}
              >
                <div className="title-wrapper">
                  <Typography className="title" variant="h6">{module.title}</Typography>
                </div>
                <Typography className="description">{module.description}</Typography>
                <div className="content-wrapper" />
                {blockedModules.includes(module.id) && (
                  <ModuleOverlay onClick={(e) => e.stopPropagation()}>
                    <Lock size={32} color="white" />
                  </ModuleOverlay>
                )}
              </ModuleCard>
            );
          })}
        </ModuleContainer>
      </ContentSection>
    );
  };

  if (isLoading) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <AppContainer activeComponent={activeComponent}>
      <ContentContainer activeComponent={activeComponent}>
        <TopBar activeComponent={activeComponent}>
          <div className="top-wrapper">
            {activeComponent && (
              <div className="navigation-row">
                <div className="home-button">
                  <Tooltip title="Home">
                    <CircularIconWrapper color="#A2A3A5" onClick={handleHome}>
                      <Home size={20} color="white" />
                    </CircularIconWrapper>
                  </Tooltip>
                </div>
                {activeComponent !== 'searchResults' ? (
                  <div className="navigation-controls">
                    <IconButton onClick={() => handleNavigate('prev')}>
                      <ChevronLeft />
                    </IconButton>
                    <Typography className="component-title">
                      {getResponsiveTitle(
                        modules.find(p => p.id === activeComponent)?.title,
                        isMobile
                      )}
                    </Typography>
                    <IconButton onClick={() => handleNavigate('next')}>
                      <ChevronRight />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ 
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%'
                  }}>
                    <Typography className="component-title">Search</Typography>
                  </div>
                )}
                <div className="actions">
                  <IconButton onClick={() => toggleFavorite(activeComponent)}>
                    <Star fill={favorites.includes(activeComponent) ? 'currentColor' : 'none'} />
                  </IconButton>
                </div>
              </div>
            )}
            {!activeComponent && activeSection !== 'profile' && (
              <Fade in={true}>
                <Box className="search-container">
                  <AGTFound
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onResults={handleSearchResults}
                    placeholder="¿Qué necesita tu negocio hoy?"
                    user={user}
                  />
                </Box>
              </Fade>
            )}
          </div>
        </TopBar>
        {activeComponent ? (
          <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '100%' }}>
            {renderContent()}
          </Box>
        ) : (
          <ContentSection activeComponent={activeComponent}>
            {renderContent()}
          </ContentSection>
        )}
      </ContentContainer>
      {/* Modal para el shortcut "New Sales" */}
      <SalesStrategyShortcut 
        open={openSalesShortcutModal} 
        onClose={() => setOpenSalesShortcutModal(false)} 
      />
      {/* Modal para el shortcut "New Product" */}
      <ProductShortcut 
        open={openProductShortcutModal} 
        onClose={() => setOpenProductShortcutModal(false)} 
      />
      {/* Modal para el shortcut "Check Inventory" */}
      <CheckInventoryShortcut 
        open={openCheckInventoryShortcutModal} 
        onClose={() => setOpenCheckInventoryShortcutModal(false)} 
      />
      {/* Modal para el shortcut "Review Prices" */}
      <ReviewPricesShortcut 
        open={openReviewPricesShortcutModal}
        onClose={() => setOpenReviewPricesShortcutModal(false)}
      />
    </AppContainer>
  );
};

export default AGTUps;
