import { api } from './config'
import API_CONFIG from './config'

class CooldownError extends Error {
  constructor(message) {
    super(message)
    this.name = 'CooldownError'
  }
}

class SmartRateLimiter {
  constructor() {
    this.activityLog = new Map()
    this.suspiciousPatterns = new Map()
    this.cleanupInterval = setInterval(() => this.cleanup(), 30 * 60 * 1000)
  }

  cleanup() {
    const now = Date.now()
    const timeWindow = 30 * 60 * 1000

    for (const [key, activities] of this.activityLog.entries()) {
      const recentActivities = activities.filter(activity =>
        now - activity.timestamp < timeWindow
      )

      if (recentActivities.length === 0) {
        this.activityLog.delete(key)
        this.suspiciousPatterns.delete(key)
      } else {
        this.activityLog.set(key, recentActivities)
      }
    }
  }

  logActivity(userId, action, details) {
    if (!this.activityLog.has(userId)) {
      this.activityLog.set(userId, [])
    }

    const activities = this.activityLog.get(userId)
    activities.push({
      timestamp: Date.now(),
      action,
      details
    })

    this.analyzePattern(userId)
  }

  analyzePattern(userId) {
    const activities = this.activityLog.get(userId)
    if (!activities || activities.length < 5) return false

    const recentActivities = activities.slice(-10)

    const suspiciousFactors = {
      rapidActions: 0,
      repeatedPatterns: 0,
      deleteAfterCreate: 0,
      multipleEdits: 0
    }

    for (let i = 1; i < recentActivities.length; i++) {
      const timeDiff = recentActivities[i].timestamp - recentActivities[i - 1].timestamp

      if (timeDiff < 1000) {
        suspiciousFactors.rapidActions++
      }

      if (
        recentActivities[i].action === 'create' &&
        recentActivities[i - 1].action === 'delete'
      ) {
        suspiciousFactors.deleteAfterCreate++
      }

      if (
        recentActivities[i].action === 'update' &&
        recentActivities[i - 1].action === 'update' &&
        recentActivities[i].details.productId === recentActivities[i - 1].details.productId
      ) {
        suspiciousFactors.multipleEdits++
      }
    }

    const isSuspicious =
      suspiciousFactors.rapidActions > 5 ||
      suspiciousFactors.deleteAfterCreate > 3 ||
      suspiciousFactors.multipleEdits > 4

    this.suspiciousPatterns.set(userId, {
      isSuspicious,
      factors: suspiciousFactors,
      lastUpdate: Date.now()
    })

    return isSuspicious
  }

  canPerformAction(userId, action, details) {
    this.logActivity(userId, action, details)

    const pattern = this.suspiciousPatterns.get(userId)
    if (!pattern) return true

    if (pattern.isSuspicious) {
      const cooldownPeriod = 5 * 60 * 1000
      if (Date.now() - pattern.lastUpdate < cooldownPeriod) {
        return false
      }
      this.suspiciousPatterns.delete(userId)
    }

    return true
  }

  getActivityStats(userId) {
    const activities = this.activityLog.get(userId) || []
    const pattern = this.suspiciousPatterns.get(userId)

    return {
      totalActivities: activities.length,
      recentActivities: activities.slice(-10),
      isSuspicious: pattern?.isSuspicious || false,
      factors: pattern?.factors || null,
      recommendedCooldown: pattern?.isSuspicious
        ? Math.max(0, 5 * 60 * 1000 - (Date.now() - pattern.lastUpdate))
        : 0
    }
  }

  destroy() {
    clearInterval(this.cleanupInterval)
    this.activityLog.clear()
    this.suspiciousPatterns.clear()
  }
}

class Cache {
  constructor() {
    this.cache = new Map()
    this.maxAge = 30 * 60 * 1000
    this.cleanupInterval = setInterval(() => this.cleanup(), 60 * 1000)
  }

  cleanup() {
    const now = Date.now()
    for (const [key, entry] of this.cache.entries()) {
      if (now - entry.timestamp > this.maxAge) {
        this.cache.delete(key)
      }
    }
  }

  set(key, value) {
    this.cache.set(key, {
      value,
      timestamp: Date.now()
    })
  }

  get(key) {
    const entry = this.cache.get(key)
    if (!entry) return null

    if (Date.now() - entry.timestamp > this.maxAge) {
      this.cache.delete(key)
      return null
    }

    return entry.value
  }

  clear() {
    this.cache.clear()
  }

  destroy() {
    clearInterval(this.cleanupInterval)
    this.clear()
  }
}

const smartLimiter = new SmartRateLimiter()
const cache = new Cache()

const formatCooldownTime = (milliseconds) => {
  const minutes = Math.ceil(milliseconds / 60000)
  if (minutes < 1) return 'menos de un minuto'
  if (minutes === 1) return '1 minuto'
  return `${minutes} minutos`
}

const BASE_URL = API_CONFIG.ENDPOINTS.PRODUCTS

class ProductService {
  static normalizeProduct(product) {
    if (!product) {
      throw new Error('Los datos del producto son requeridos para la normalización')
    }

    return {
      id: product.productId || product.id,
      name: product.name || '',
      description: product.description || '',
      category: product.category || '',
      price: typeof product.price === 'number' ? product.price : 0,
      stock: product.stock !== undefined ? product.stock : 0,
      customFields: product.customFields || {},
      status: product.status || 'active',
      createdAt: product.createdAt || new Date().toISOString(),
      updatedAt: product.updatedAt || new Date().toISOString(),
      imageUrl: product.imageUrl || null
    }
  }

  static async getAllProducts(params = {}) {
    const cacheKey = `products:${JSON.stringify(params)}`
    let cachedData = cache.get(cacheKey)

    if (cachedData) {
      return { ...cachedData, fromCache: true }
    }

    try {
      const response = await api.get(BASE_URL, { params })

      if (!response?.data?.success) {
        throw new Error('Respuesta inválida del servidor')
      }

      const productsRaw = response.data.data?.products
      const normalized = Array.isArray(productsRaw)
        ? productsRaw.map(this.normalizeProduct)
        : []

      const result = {
        success: true,
        data: {
          products: normalized,
          nextToken: response.data.data?.nextToken || null,
          total: response.data.data?.total || 0,
          hasMore: response.data.data?.hasMore || false
        }
      }

      cache.set(cacheKey, result)
      return result
    } catch (error) {
      const cachedResult = cache.get(cacheKey)
      if (cachedResult) {
        return { ...cachedResult, fromCache: true }
      }
      throw new Error(`Error al obtener productos: ${error.message}`)
    }
  }

  static async getProductById(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    const cacheKey = `product:${productId}`
    let cachedData = cache.get(cacheKey)

    if (cachedData) {
      return { ...cachedData, fromCache: true }
    }

    try {
      const response = await api.get(`${BASE_URL}/${productId}`)

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Producto no encontrado')
      }

      if (response.data.fromCache) {
        return response.data
      }

      const result = {
        success: true,
        data: this.normalizeProduct(response.data.data)
      }

      cache.set(cacheKey, result)
      return result
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('Producto no encontrado')
      }
      const cachedResult = cache.get(cacheKey)
      if (cachedResult) {
        return { ...cachedResult, fromCache: true }
      }
      throw new Error(`Error al obtener el producto: ${error.message}`)
    }
  }

  static async createProduct(productData) {
    if (!productData) {
      throw new Error('Los datos del producto son requeridos')
    }

    try {
      const formattedData = this.formatProductData(productData)

      const response = await api.post(BASE_URL, formattedData)

      if (!response?.data?.success || !response?.data?.data) {
        throw new Error('Respuesta inválida del servidor')
      }

      cache.clear()

      return {
        success: true,
        data: this.normalizeProduct(response.data.data),
        message: 'Producto creado exitosamente'
      }
    } catch (error) {
      throw new Error(`Error al crear el producto: ${error.message}`)
    }
  }

  static async updateProduct(productId, updateData) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    if (!updateData) {
      throw new Error('Los datos de actualización son requeridos')
    }

    try {
      const formattedData = this.formatProductData(updateData)

      const response = await api.put(`${BASE_URL}/${productId}`, formattedData)

      if (!response?.data?.success || !response?.data?.data) {
        throw new Error('Respuesta inválida del servidor')
      }

      cache.clear()

      return {
        success: true,
        data: this.normalizeProduct(response.data.data),
        message: 'Producto actualizado exitosamente'
      }
    } catch (error) {
      if (error.response?.status === 404) {
        throw new Error('El producto a actualizar no existe')
      }
      throw new Error(`Error al actualizar el producto: ${error.message}`)
    }
  }

  static async deleteProduct(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    try {
      const response = await api.delete(`${BASE_URL}/${productId}`)

      if (!response?.data?.success && response.status !== 204) {
        throw new Error('Error al eliminar el producto')
      }

      cache.clear()

      return {
        success: true,
        data: { productId },
        message: 'Producto eliminado exitosamente'
      }
    } catch (error) {
      if (error.response?.status === 404) {
        cache.clear()
        return {
          success: true,
          data: { productId },
          message: 'Producto eliminado exitosamente'
        }
      }
      throw error
    }
  }

  /**
   * NUEVO MÉTODO: Sube una imagen de producto
   */
  static async uploadProductImage(file) {
    if (!file) {
      throw new Error('No se proporcionó ningún archivo.');
    }
  
    const formData = new FormData();
    formData.append('image', file);
  
    try {
      // Nota: usamos la ruta /upload-image para coincidir con el backend.
      const response = await api.post(`${BASE_URL}/upload-image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (!response?.data?.success) {
        throw new Error('Error al subir la imagen');
      }
  
      // Ahora, retornamos la URL en response.data.imageUrl (no response.data.data.imageUrl)
      return response.data.imageUrl;
    } catch (error) {
      throw new Error(`Error en uploadProductImage: ${error.message}`);
    }
  }    

  // NUEVO: Actualizamos la función de validación para incluir el stock
  static validateProduct(productData) {
    const errors = []

    if (!productData?.name?.trim()) {
      errors.push('El nombre del producto es requerido')
    }

    if (productData.status !== 'draft') {
      if (!productData?.category?.trim()) {
        errors.push('La categoría del producto es requerida')
      }
    }

    if (typeof productData?.price !== 'number' || productData.price < 0) {
      errors.push('El precio del producto debe ser un número positivo')
    }

    if (['physical', 'food'].includes(productData?.category?.toLowerCase())) {
      if (
        productData.stock === undefined ||
        productData.stock === null ||
        productData.stock === ''
      ) {
        errors.push('El stock es requerido para productos físicos y de comida')
      } else if (
        isNaN(parseInt(productData.stock, 10)) ||
        parseInt(productData.stock, 10) < 0
      ) {
        errors.push('El stock debe ser un número entero no negativo')
      }
    }

    if (productData?.description && typeof productData.description !== 'string') {
      errors.push('La descripción debe ser texto')
    }

    if (productData?.customFields && typeof productData.customFields !== 'object') {
      errors.push('Los campos personalizados deben ser un objeto')
    }

    if (productData?.status && !['active', 'inactive', 'draft'].includes(productData.status)) {
      errors.push('El estado del producto debe ser: active, inactive o draft')
    }

    return {
      isValid: errors.length === 0,
      errors
    }
  }

  // NUEVO: Función que formatea los datos para incluir el stock
  static formatProductData(productData) {
    if (!productData) {
      throw new Error('Los datos del producto son requeridos para el formateo')
    }

    const formattedData = {
      id: productData.id || null,
      name: productData.name?.trim() || '',
      description: productData.description?.trim() || '',
      category: productData.category?.trim() || '',
      price:
        typeof productData.price === 'number'
          ? productData.price
          : typeof productData.price === 'string'
          ? parseFloat(productData.price) || 0
          : 0,
      stock: productData.stock ? parseInt(productData.stock, 10) : undefined,
      customFields: productData.customFields || {},
      status: productData.status || 'active',
      imageUrl: productData.imageUrl || null
    }

    const validation = this.validateProduct(formattedData)
    if (!validation.isValid) {
      throw new Error(validation.errors.join(', '))
    }

    return formattedData
  }

  static async searchProducts({ query, category }) {
    const cacheKey = `search:${query}:${category}`
    let cachedData = cache.get(cacheKey)

    if (cachedData) {
      return { ...cachedData, fromCache: true }
    }

    try {
      const response = await api.get('/products/search', {
        params: {
          query: query || '',
          category: category || ''
        }
      })

      if (!response?.data?.success) {
        throw new Error('Invalid search response')
      }

      const result = {
        success: true,
        data: response.data.data
      }

      cache.set(cacheKey, result)
      return result
    } catch (error) {
      throw new Error(`Error searching products: ${error.message}`)
    }
  }

  static async getProductsByCategory(category) {
    if (!category?.trim()) {
      throw new Error('La categoría es requerida')
    }

    const cacheKey = `category:${category.trim()}`
    let cachedData = cache.get(cacheKey)

    if (cachedData) {
      return { ...cachedData, fromCache: true }
    }

    try {
      const response = await api.get(BASE_URL, {
        params: {
          category: category.trim(),
          limit: 50
        }
      })

      if (!response?.data?.success && !response?.data?.fromCache) {
        throw new Error('Respuesta inválida para la categoría')
      }

      if (response.data.fromCache) {
        return response.data
      }

      const result = {
        success: true,
        data: {
          products: Array.isArray(response.data.data?.products)
            ? response.data.data.products.map(this.normalizeProduct)
            : [],
          total: response.data.data?.total || 0
        }
      }

      cache.set(cacheKey, result)
      return result
    } catch (error) {
      const cachedResult = cache.get(cacheKey)
      if (cachedResult) {
        return { ...cachedResult, fromCache: true }
      }
      throw new Error(`Error al obtener productos por categoría: ${error.message}`)
    }
  }

  static async healthCheck() {
    try {
      const response = await api.get(`${BASE_URL}/health`)
      return {
        success: true,
        data: response.data
      }
    } catch (error) {
      throw new Error(`Error en health check: ${error.message}`)
    }
  }

  static getUsageStats() {
    const userId = localStorage.getItem('userId') || 'anonymous'
    return smartLimiter.getActivityStats(userId)
  }

  static clearCache() {
    cache.clear()
  }

  static clearRestrictions() {
    const userId = localStorage.getItem('userId') || 'anonymous'
    smartLimiter.suspiciousPatterns.delete(userId)
  }

  static resetAll() {
    this.clearCache()
    this.clearRestrictions()
  }

  /**
   * MÉTODOS PARA FUNCIONALIDAD DE MEJORAS (AI/LLM)
   */
  static async enhanceProduct(productId, enhanceType) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    const validEnhanceTypes = ['description', 'category', 'price', 'marketing']
    if (!validEnhanceTypes.includes(enhanceType)) {
      throw new Error('Tipo de mejora inválido')
    }

    try {
      // Primero obtenemos el producto actual para verificar que existe
      const currentProduct = await this.getProductById(productId)
      if (!currentProduct?.data) {
        throw new Error('Producto no encontrado')
      }

      const response = await api.post(
        `${BASE_URL}/${productId}/enhance/${enhanceType}`,
        currentProduct.data
      )

      if (!response?.data?.success) {
        throw new Error('Error al mejorar el producto con IA')
      }

      // Limpiar caché si la mejora implica cambios en el producto
      if (enhanceType !== 'marketing') {
        cache.clear()
      }

      return {
        success: true,
        data: response.data.data,
        message: `Producto mejorado exitosamente con ${enhanceType}`
      }
    } catch (error) {
      if (error.response?.status === 429) {
        throw new CooldownError(
          'Demasiadas solicitudes de mejora. Por favor espere unos minutos.'
        )
      }
      throw new Error(`Error al mejorar el producto: ${error.message}`)
    }
  }

  static async getEnhancementStatus(productId, enhanceType) {
    try {
      const response = await api.get(
        `${BASE_URL}/${productId}/enhance/${enhanceType}/status`
      )

      return {
        success: true,
        data: response.data
      }
    } catch (error) {
      throw new Error(`Error al obtener estado de mejora: ${error.message}`)
    }
  }

  static async batchEnhance(productIds, enhanceTypes) {
    if (!Array.isArray(productIds) || productIds.length === 0) {
      throw new Error('Se requiere al menos un ID de producto')
    }

    if (!Array.isArray(enhanceTypes) || enhanceTypes.length === 0) {
      throw new Error('Se requiere al menos un tipo de mejora')
    }

    try {
      const response = await api.post(`${BASE_URL}/batch-enhance`, {
        productIds,
        enhanceTypes
      })

      if (!response?.data?.success) {
        throw new Error('Error en mejora por lotes')
      }

      cache.clear()

      return {
        success: true,
        data: response.data.data,
        message: 'Mejora por lotes iniciada exitosamente'
      }
    } catch (error) {
      throw new Error(`Error en mejora por lotes: ${error.message}`)
    }
  }

  static async getRecommendedEnhancements(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    try {
      const response = await api.get(
        `${BASE_URL}/${productId}/recommended-enhancements`
      )

      return {
        success: true,
        data: response.data.data,
        message: 'Recomendaciones obtenidas exitosamente'
      }
    } catch (error) {
      throw new Error(`Error al obtener recomendaciones: ${error.message}`)
    }
  }

  static async getEnhancementHistory(productId) {
    if (!productId) {
      throw new Error('El ID del producto es requerido')
    }

    try {
      const response = await api.get(
        `${BASE_URL}/${productId}/enhancement-history`
      )

      return {
        success: true,
        data: response.data.data,
        message: 'Historial de mejoras obtenido exitosamente'
      }
    } catch (error) {
      throw new Error(`Error al obtener historial de mejoras: ${error.message}`)
    }
  }

  static canEnhanceProduct(product, enhanceType) {
    const userId = localStorage.getItem('userId') || 'anonymous'

    const canPerform = smartLimiter.canPerformAction(userId, 'enhance', {
      productId: product.id,
      enhanceType
    })

    if (!canPerform) {
      const stats = smartLimiter.getActivityStats(userId)
      const cooldownTime = formatCooldownTime(stats.recommendedCooldown)

      throw new CooldownError(
        `Por favor espere ${cooldownTime} antes de solicitar más mejoras.`
      )
    }

    switch (enhanceType) {
      case 'description':
        return {
          canEnhance: true
        }
      case 'category':
        return {
          canEnhance: !!product.name,
          reason: !product.name ? 'Se requiere un nombre de producto' : null
        }
      case 'price':
        return {
          canEnhance: !!product.category && !!product.description,
          reason: !product.category
            ? 'Se requiere una categoría'
            : !product.description
            ? 'Se requiere una descripción'
            : null
        }
      case 'marketing':
        return {
          canEnhance: !!product.description,
          reason: !product.description ? 'Se requiere una descripción' : null
        }
      default:
        return {
          canEnhance: false,
          reason: 'Tipo de mejora inválido'
        }
    }
  }
}

window.addEventListener('beforeunload', () => {
  cache.destroy()
  smartLimiter.destroy()
})

export default ProductService
export { CooldownError }
export { Cache, SmartRateLimiter }
