import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import DirectiveLogo from '../../common/DirectiveLogo';
import { getThemeStyles } from '../../themes/themeConfig';

/**
 * Animaciones
 */
const shimmerAnimation = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

const waveAnimation = keyframes`
  0% { transform: scaleY(0.3); opacity: 0.5; }
  25% { transform: scaleY(0.7); opacity: 0.8; }
  50% { transform: scaleY(1); opacity: 1; }
  75% { transform: scaleY(0.7); opacity: 0.8; }
  100% { transform: scaleY(0.3); opacity: 0.5; }
`;

const movePupilAnimation = keyframes`
  0%, 100% { transform: translate(-3px, 0); }
  25% { transform: translate(0, -1px); }
  50% { transform: translate(3px, 0); }
  75% { transform: translate(0, 1px); }
`;

/**
 * Animaciones para la transición con glow (solo para ultra)
 */
const fadeOutGlow = keyframes`
  0% {
    opacity: 1;
    filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0));
  }
  50% {
    opacity: 0;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 1));
  }
  100% {
    opacity: 0;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 1));
  }
`;

const fadeInGlow = keyframes`
  0% {
    opacity: 0;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 1));
  }
  50% {
    opacity: 0;
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 1));
  }
  100% {
    opacity: 1;
    filter: drop-shadow(0 0 0px rgba(255, 255, 255, 0));
  }
`;

/**
 * Styled Components
 */

// Contenedor principal del mensaje
const MessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  position: 'relative',
  width: '100%',
  maxWidth: '100%',
  paddingLeft: '16px',
  paddingRight: '0',
  marginBottom: '8px',
  paddingBottom: '8px',
  opacity: 1,
  '&:last-child': {
    marginBottom: '12px',
  },
  '@media (max-width: 1200px)': {
    paddingLeft: '0',
    paddingRight: '16px',
  },
  '@media (max-width: 600px)': {
    paddingLeft: '0',
    paddingRight: '8px',
  },
  ...getThemeStyles('MessageContainer', theme.palette.mode),
}));

// Contenedor del mensaje con el ícono
const MessageWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  marginLeft: '0',
  paddingRight: '0',
  paddingTop: '8px',
});

// Ícono (avatar) del asistente
const AssistantIconContainer = styled(Box)(({ theme }) => ({
  flexShrink: 0,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '10px',
  marginLeft: '0',
  marginTop: '10px',
  opacity: 1,
  '@media (max-width: 1200px)': {
    marginRight: '4px',
  },
  '@media (max-width: 600px)': {
    marginRight: '0px',
  },
  backgroundColor:
    theme.palette.mode === 'dark' ? '#000000 !important' : '#fff !important',
  border:
    theme.palette.mode === 'dark'
      ? '1px solid rgba(255, 255, 255, 0.1)'
      : '1px solid rgba(0, 0, 0, 0.1)',
  ...getThemeStyles('AssistantIconContainer', theme.palette.mode),
}));

// Contenedor del contenido (texto)
const MessageContent = styled(Box)(({ theme }) => ({
  flexGrow: 0,
  width: 'calc(100% - 44px)',
  padding: '12px 16px',
  borderRadius: '30px',
  position: 'relative',
  marginLeft: '0',
  marginRight: '0',
  lineHeight: '1.75',
  overflow: 'hidden',
  '@media (max-width: 1200px)': {
    width: 'calc(100% - 44px)',
  },
  '@media (max-width: 600px)': {
    width: 'calc(100% - 34px)',
  },
  ...getThemeStyles('MessageContent', theme.palette.mode),
}));

// Contenedor para el texto (y otros elementos animados)
const TextContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

// Componente para texto en modo no-ultra (con shimmer)
const StyledText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(
    90deg,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 0%,
    ${theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.5)'
      : 'rgba(0,0,0,0.5)'} 50%,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 100%
  )`,
  backgroundSize: '200% auto',
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  animation: `${shimmerAnimation} 2s linear infinite`,
  fontWeight: 400,
}));

// Componente para texto en modo ultra, que combina el shimmer con la transición de fade (in/out con glow)
const TransitionText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'fade',
})(({ fade, theme }) => ({
  fontWeight: 400,
  background: `linear-gradient(
    90deg,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 0%,
    ${theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.5)'
      : 'rgba(0,0,0,0.5)'} 50%,
    ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 100%
  )`,
  backgroundSize: '200% auto',
  color: 'transparent',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  // Se aplica siempre el shimmer; si existe "fade" se añade la animación de transición
  animation: `${shimmerAnimation} 2s linear infinite${
    fade ? `, ${fade === 'out' ? fadeOutGlow : fadeInGlow} 0.5s forwards` : ''
  }`,
}));

// Contenedor con barra lateral y altura fija (3 líneas).
// Se ha eliminado el pseudo-elemento ::after para quitar la sombra/desvanecimiento.
const ThinkingBarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'block',
  height: '4.5em', // 3 líneas (asumiendo line-height ~ 1.5)
  overflow: 'hidden',
  // Barra vertical a la izquierda
  paddingLeft: '12px',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    width: '3px',
    backgroundColor: theme.palette.mode === 'dark' ? '#888' : '#aaa',
    borderRadius: '2px',
  },
}));

// Contenedor para el indicador de ondas (audio)
const WaveContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
  height: '15px',
  marginLeft: '4px',
});

const WaveBar = styled(Box)(({ delay }) => ({
  width: '2px',
  height: '100%',
  background: '#666',
  animation: `${waveAnimation} 0.8s ease-in-out infinite`,
  animationDelay: `${delay}ms`,
  transformOrigin: 'center',
}));

// Contenedores para el efecto de ojo (imágenes)
const EyeContainer = styled(Box)({
  position: 'relative',
  marginLeft: '4px',
  width: '18px',
  height: '18px',
});

const StyledEye = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  '& svg': {
    strokeWidth: '3.5px',
    fill: 'transparent',
  },
  opacity: 0.85,
  '& path': {
    background: `linear-gradient(
      90deg,
      ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 0%,
      ${theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,0.5)'
        : 'rgba(0,0,0,0.5)'} 50%,
      ${theme.palette.mode === 'dark' ? '#fff' : '#000'} 100%
    )`,
    backgroundSize: '200% auto',
    strokeWidth: '3px',
    animation: `${shimmerAnimation} 2s linear infinite`,
  },
}));

const Pupil = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '35%',
  width: '6px',
  height: '6px',
  marginTop: '-3px',
  borderRadius: '50%',
  border: `2.5px solid ${
    theme.palette.mode === 'dark' ? '#fff' : '#000000de'
  }`,
  background: 'transparent',
  backgroundSize: '200% auto',
  animation: `
    ${movePupilAnimation} 3s ease-in-out infinite,
    ${shimmerAnimation} 3s linear infinite
  `,
  opacity: theme.palette.mode === 'dark' ? 1 : 0.9,
}));

/**
 * Componente indicador de ondas para audio
 */
const AudioWaveIndicator = () => {
  return (
    <WaveContainer>
      {[0, 1, 2].map((index) => (
        <WaveBar key={index} delay={index * 100} />
      ))}
    </WaveContainer>
  );
};

/**
 * Componente de ojo animado para imágenes
 */
const AnimatedEye = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <EyeContainer>
      <StyledEye>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={18}
          height={18}
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path
            d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"
            stroke={isDarkMode ? '#ffffff' : '#000000de'}
          />
        </svg>
      </StyledEye>
      <Pupil />
    </EyeContainer>
  );
};

/**
 * Obtiene las frases por defecto según tipo de mensaje + modelTier
 */
function getCustomPhrases(info) {
  const {
    isAudio,
    isImage,
    isPdf,
    isExcel,
    isProjectChat,
    modelTier,
  } = info || {};

  // Casos especiales de archivos/audio/imágenes
  if (isAudio) {
    return [
      ['Listening to audio', 'Transcribing audio...'],
      ['Converting speech to text...'],
      ['Refining audio input...'],
    ];
  }

  if (isImage) {
    return [
      ['Observing image data', 'Enhancing visual elements'],
      ['Extracting image features'],
      ['Combining text & image context'],
    ];
  }

  if (isPdf && isExcel) {
    return [
      ['Reading PDF & Excel', 'Combining PDF + XLS insights'],
      ['Cross-referencing documents...', 'Merging document data...'],
      ['Extracting combined insights'],
    ];
  }

  if (isPdf) {
    return [
      ['Reading PDF', 'Extracting PDF data'],
      ['Summarizing PDF content', 'Analyzing document structure'],
      ['Extracting key information'],
    ];
  }

  if (isExcel) {
    return [
      ['Analyzing Excel sheet', 'Processing spreadsheet'],
      ['Building data tables', 'Reading spreadsheet contents'],
      ['Processing numerical data'],
    ];
  }

  if (isProjectChat) {
    return [
      ['Processing project-specific data', 'Loading project context'],
      ['Analyzing tasks & context', 'Reviewing project objectives'],
      ['Integrating project files'],
    ];
  }

  // Según la "tier" del modelo
  if (modelTier === 'ultra') {
    return [
      ['Reasoning'], // Si no se proveen frases personalizadas, se usará "Reasoning"
    ];
  }
  if (modelTier === 'pro') {
    return [
      ['Thinking with Pro', 'Leveraging advanced model'],
      ['Refining approach', 'Optimizing response for clarity'],
      ['Combining best knowledge'],
    ];
  }
  if (modelTier === 'agt') {
    return [
      ['Thinking', 'Processing your request'],
      ['Identifying key variables', 'Analyzing data'],
      ['Formulating response', 'Building analysis'],
    ];
  }

  // Si no coincidió nada
  return [
    ['Analyzing input data', 'Processing information'],
    ['Identifying key variables', 'Establishing relationships'],
    ['Formulating response', 'Building analysis'],
  ];
}

/**
 * Componente principal que muestra las frases de "pensamiento"
 */
const ThinkingIcon = ({
  startTime,
  messageTypeInfo = {},
  thinkingPhrases = [],
}) => {
  const theme = useTheme();
  const isUltra = messageTypeInfo.modelTier === 'ultra';
  const isAudioOrImage = messageTypeInfo.isAudio || messageTypeInfo.isImage;

  // Seleccionar frases: si se pasan thinkingPhrases se usan; sino se obtienen por defecto
  const phrases = useMemo(() => {
    if (thinkingPhrases && thinkingPhrases.length > 0) {
      return thinkingPhrases.map((thought) => [thought]);
    }
    return getCustomPhrases(messageTypeInfo);
  }, [thinkingPhrases, messageTypeInfo]);

  // Estados comunes
  const [phaseIndex, setPhaseIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [pauseTimer, setPauseTimer] = useState(0);

  // Estado extra para la transición en modo ultra ('in' o 'out')
  const [fade, setFade] = useState('in');

  // Inicializar la frase al cambiar startTime o la lista de frases
  useEffect(() => {
    setPhaseIndex(0);
    if (isUltra) {
      setFade('in');
      const firstBlock = phrases[0] || [];
      setCurrentPhrase(firstBlock[0] || '');
    } else {
      setPauseTimer(0);
      const firstBlock = phrases[0] || [];
      setCurrentPhrase(firstBlock[0] || '');
    }
  }, [startTime, phrases, isUltra]);

  // Lógica de actualización para modo ultra:
  // Cada 4 segundos se dispara la transición: primero fade out y luego, 500ms después, se actualiza la frase con fade in.
  useEffect(() => {
    if (isAudioOrImage || !isUltra) return;
    const interval = setInterval(() => {
      setFade('out');
      setTimeout(() => {
        const nextIndex = (phaseIndex + 1) % phrases.length;
        setPhaseIndex(nextIndex);
        const currentBlock = phrases[nextIndex] || [];
        const randomIndex = Math.floor(Math.random() * currentBlock.length);
        const newPhrase = currentBlock[randomIndex] || '';
        setCurrentPhrase(newPhrase);
        setFade('in');
      }, 500);
    }, 4000);
    return () => clearInterval(interval);
  }, [isUltra, phaseIndex, phrases, isAudioOrImage]);

  // Lógica para modos que no sean ultra (mantiene la lógica original)
  useEffect(() => {
    if (isAudioOrImage || isUltra) return;
    const interval = setInterval(() => {
      setPhaseIndex((prevIndex) => {
        if (pauseTimer > 0) {
          setPauseTimer((p) => p - 1);
          return prevIndex;
        }
        const nextIndex = Math.min(prevIndex + 1, phrases.length - 1);
        if (nextIndex < phrases.length - 1) {
          const shouldPause = Math.random() < 0.3;
          if (shouldPause) {
            const randomPause = Math.floor(Math.random() * 3) + 1;
            setPauseTimer(randomPause);
          }
        }
        return nextIndex;
      });
    }, 600);
    return () => clearInterval(interval);
  }, [isAudioOrImage, pauseTimer, phrases, isUltra]);

  useEffect(() => {
    if (isAudioOrImage || isUltra) return;
    const currentBlock = phrases[phaseIndex] || [];
    const randomIndex = Math.floor(Math.random() * currentBlock.length);
    const newPhrase = currentBlock[randomIndex] || '';
    setCurrentPhrase(newPhrase);
  }, [phaseIndex, phrases, isAudioOrImage, isUltra]);

  // Para audio o imagen se fuerza el mensaje "Listening" u "Observing"
  useEffect(() => {
    if (isAudioOrImage) {
      const newPhrase = messageTypeInfo.isAudio ? 'Listening' : 'Observing';
      setCurrentPhrase(newPhrase);
    }
  }, [isAudioOrImage, messageTypeInfo]);

  // En ultra siempre mostramos la barra lateral
  const showBar = isUltra;

  return (
    <MessageContainer>
      <MessageWrapper>
        <AssistantIconContainer>
          <DirectiveLogo
            size={18}
            className="text-current"
            style={{
              color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
            }}
          />
        </AssistantIconContainer>

        <MessageContent>
          {showBar ? (
            <ThinkingBarContainer>
              <TextContainer>
                {isUltra ? (
                  <TransitionText variant="body1" fade={fade}>
                    {currentPhrase}
                  </TransitionText>
                ) : (
                  <StyledText variant="body1">{currentPhrase}</StyledText>
                )}
                {messageTypeInfo.isAudio && <AudioWaveIndicator />}
                {messageTypeInfo.isImage && <AnimatedEye />}
              </TextContainer>
            </ThinkingBarContainer>
          ) : (
            <Box>
              <TextContainer>
                {isUltra ? (
                  <TransitionText variant="body1" fade={fade}>
                    {currentPhrase}
                  </TransitionText>
                ) : (
                  <StyledText variant="body1">{currentPhrase}</StyledText>
                )}
                {messageTypeInfo.isAudio && <AudioWaveIndicator />}
                {messageTypeInfo.isImage && <AnimatedEye />}
              </TextContainer>
            </Box>
          )}
        </MessageContent>
      </MessageWrapper>
    </MessageContainer>
  );
};

export default ThinkingIcon;
