// src/components/AGTUps/services/AGTUps-product-services/agtChatService.js

import { api } from './config';
import API_CONFIG from './config';

class AGTChatService {
  constructor() {
    this.baseURL = `${API_CONFIG.BASE_URL}/agt`;
    this.conversations = new Map();
  }

  async sendMessage(message, context = {}) {
    try {
      const conversationId = this._getConversationId(context);
      
      const response = await api.post(`${this.baseURL}/chat`, {
        message,
        conversationId,
        context: {
          activeTab: context.activeTab,
          products: context.currentProducts,
          timestamp: context.timestamp
        }
      });

      if (!response?.data?.success) {
        throw new Error('Failed to get response from AGT');
      }

      // Actualizar el contexto de la conversación
      this._updateConversationContext(conversationId, {
        lastMessage: message,
        lastResponse: response.data,
        ...context
      });

      return response;
    } catch (error) {
      console.error('Error communicating with AGT:', error);
      throw error;
    }
  }

  // Método privado para obtener/generar ID de conversación
  _getConversationId(context) {
    // Si ya existe una conversación activa para este usuario/sesión, usarla
    const existingConversation = Array.from(this.conversations.entries())
      .find(([_, ctx]) => ctx.userId === context.userId);

    if (existingConversation) {
      return existingConversation[0];
    }

    // Si no, crear nueva conversación
    const conversationId = `conv_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    this.conversations.set(conversationId, {
      userId: context.userId,
      startTime: new Date().toISOString(),
      activeTab: context.activeTab
    });

    return conversationId;
  }

  // Método privado para actualizar el contexto de la conversación
  _updateConversationContext(conversationId, newContext) {
    const currentContext = this.conversations.get(conversationId) || {};
    this.conversations.set(conversationId, {
      ...currentContext,
      ...newContext,
      lastUpdated: new Date().toISOString()
    });
  }

  // Método para limpiar conversaciones antiguas
  cleanupOldConversations(maxAge = 30 * 60 * 1000) { // 30 minutos por defecto
    const now = Date.now();
    for (const [id, context] of this.conversations.entries()) {
      const conversationAge = now - new Date(context.startTime).getTime();
      if (conversationAge > maxAge) {
        this.conversations.delete(id);
      }
    }
  }
}

const agtChatService = new AGTChatService();

// Limpiar conversaciones antiguas cada 15 minutos
setInterval(() => {
  agtChatService.cleanupOldConversations();
}, 15 * 60 * 1000);

export default agtChatService;
