import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { Tabs, Tab, Box, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus, ChevronLeft, ChevronRight } from 'lucide-react';

// -------------------------------------
// Estilos de la barra de Tabs
// -------------------------------------
const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '52px',
  height: '52px',
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  padding: '8.5px',
  borderRadius: '30px',
  position: 'relative',
  '& .MuiTabs-scroller': {
    overflow: 'visible',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-flexContainer': {
    gap: '4px',
    height: '35px',
  },
}));

// -------------------------------------
// El "fondo" animado debajo del tab activo
// -------------------------------------
const TabIndicator = styled('div')(({ theme }) => ({
  position: 'absolute',
  height: '35px',
  width: '74px',
  borderRadius: '30px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(118, 118, 128, 0.24)'
      : '#FFFFFF',
  transition: 'transform 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  zIndex: 0,
  boxShadow:
    theme.palette.mode === 'dark'
      ? 'none'
      : '0 2px 8px rgba(0, 0, 0, 0.04)',
}));

// -------------------------------------
// Estilos de cada Tab individual
// -------------------------------------
const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '74px',
  minWidth: '74px',
  padding: '0 8px',
  borderRadius: '30px',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : '#666666',
  fontSize: '14px',
  fontWeight: 500,
  textTransform: 'none',
  zIndex: 1,
  transition: 'all 0.2s',
  backgroundColor: 'transparent',
  '&.Mui-selected': {
    color: '#007BFE',
  },
  '&:hover:not(.Mui-selected)': {
    color: '#007BFE',
    backgroundColor: 'transparent',
  },
}));

// -------------------------------------
// Botones de flecha / más (+)
// -------------------------------------
const NavigationButton = styled('button')(({ theme }) => ({
  minHeight: '35px',
  height: '35px',
  width: '35px',
  minWidth: '35px',
  padding: '0',
  marginRight: '8.5px',
  marginLeft: '8.5px',
  border: 'none',
  borderRadius: '30px',
  backgroundColor: 'transparent',
  color: '#007BFE',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  zIndex: 1,
  transition: 'all 0.2s',
  outline: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
    opacity: 0.8,
  },
  '&:active': {
    transform: 'scale(0.95)',
    backgroundColor: 'transparent',
  },
}));

// -------------------------------------
// Componente principal
// -------------------------------------
const NavigationBar = ({
  onNavigate,
  activeSection,
  showUpsInNav,
  showEduInNav,
}) => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);

  // Número de ítems visibles en móvil
  const VISIBLE_COUNT = 2;

  // Controla el rango de ítems visibles en móvil
  const [visibleRange, setVisibleRange] = useState({ start: 0, end: VISIBLE_COUNT });

  // Prevención de doble clic rápido (300ms)
  const lastNavigationTime = useRef(Date.now());

  // Para referenciar el contenedor de tabs (si se necesita)
  const tabsRef = useRef(null);

  // Menú base + condicional
  const baseMenuItems = ['Home', 'Chat'];
  const menuItems = useMemo(
    () => [
      ...baseMenuItems,
      ...(showUpsInNav ? ['Ups'] : []),
      ...(showEduInNav ? ['Edu'] : []),
    ],
    [showUpsInNav, showEduInNav]
  );

  // En desktop se muestran todos; en móvil se mostrará según visibleRange
  const visibleMenuItems = isMobile
    ? menuItems.slice(visibleRange.start, visibleRange.end)
    : menuItems;

  // Mostramos la flecha izquierda si en móvil hay ítems ocultos a la izquierda
  const showLeftArrow = isMobile && visibleRange.start > 0;
  // Mostramos la flecha derecha si en móvil hay ítems ocultos a la derecha
  const showRightArrow = isMobile && visibleRange.end < menuItems.length;

  // ------------------------------------------------------------------
  // Calcula la posición horizontal del indicador animado
  // ------------------------------------------------------------------
  const getIndicatorStyle = useCallback(() => {
    const activeIdx = getRelativeActiveIndex();
    if (activeIdx === false) return {};
    // Cada tab mide 74px de ancho, con un gap horizontal de 4px
    const offset = activeIdx * (74 + 4);
    return { transform: `translateX(${offset}px)` };
  }, [menuItems, visibleRange.start, activeSection]);

  // ------------------------------------------------------------------
  // Detecta modo mobile en resize
  // ------------------------------------------------------------------
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // ------------------------------------------------------------------
  // Ajusta el rango visible de tabs en móvil para que el activo siempre se vea
  // ------------------------------------------------------------------
  useEffect(() => {
    if (!isMobile) {
      // En desktop mostramos todos los tabs
      setVisibleRange({ start: 0, end: menuItems.length });
      return;
    }
    const activeIdx = menuItems.findIndex(
      (item) => item.toLowerCase() === activeSection
    );
    if (activeIdx === -1) return;

    // Se busca que el ítem activo quede centrado (o lo más centrado posible)
    let start = activeIdx - Math.floor(VISIBLE_COUNT / 2);
    if (start < 0) start = 0;
    let end = start + VISIBLE_COUNT;
    if (end > menuItems.length) {
      end = menuItems.length;
      start = end - VISIBLE_COUNT;
      if (start < 0) start = 0;
    }
    setVisibleRange({ start, end });
  }, [activeSection, menuItems, isMobile]);

  // ------------------------------------------------------------------
  // Manejo de clicks con anti-double-click
  // ------------------------------------------------------------------
  const handleNavigationWithDebounce = useCallback(
    (section) => {
      const now = Date.now();
      if (now - lastNavigationTime.current >= 300) {
        lastNavigationTime.current = now;
        onNavigate(section);
      }
    },
    [onNavigate]
  );

  // Atajo para ir a Home
  const handleHomeNavigation = () => {
    handleNavigationWithDebounce('home');
  };

  // ------------------------------------------------------------------
  // Tab MUI -> valor = índice relativo (dentro de visibleRange)
  // ------------------------------------------------------------------
  const handleTabChange = useCallback(
    (event, newValue) => {
      const realIndex = newValue + visibleRange.start;
      const section = menuItems[realIndex].toLowerCase();
      handleNavigationWithDebounce(section);
    },
    [menuItems, visibleRange, handleNavigationWithDebounce]
  );

  // ------------------------------------------------------------------
  // Calcula el índice relativo dentro del rango visible
  // ------------------------------------------------------------------
  const getRelativeActiveIndex = () => {
    const absoluteIndex = menuItems.findIndex(
      (item) => item.toLowerCase() === activeSection
    );
    if (absoluteIndex === -1) return false;
    return absoluteIndex - visibleRange.start;
  };

  // ------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------
  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '1872px',
        height: '52px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '52px',
          background:
            theme.palette.mode === 'dark'
              ? '#1C1C1E'
              : 'rgba(118, 118, 128, 0.20)',
          borderRadius: '30px',
          overflow: 'hidden',
        }}
      >
        {/* Flecha izquierda (sólo mobile) si hay ítems ocultos a la izquierda */}
        {showLeftArrow && (
          <NavigationButton
            onClick={handleHomeNavigation}
            aria-label="Go to Home"
          >
            <ChevronLeft size={16} strokeWidth={2.5} />
          </NavigationButton>
        )}

        {/* Contenedor de Tabs */}
        <StyledTabs
          ref={tabsRef}
          value={getRelativeActiveIndex() >= 0 ? getRelativeActiveIndex() : 0}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="navigation tabs"
        >
          {/* Indicador animado */}
          <TabIndicator style={getIndicatorStyle()} />

          {/* Renderiza sólo los tabs visibles en móvil, o todos en desktop */}
          {visibleMenuItems.map((item, index) => (
            <StyledTab
              key={item}
              label={item}
              disableRipple
              value={index}
            />
          ))}
        </StyledTabs>

        {/* Flecha derecha o botón + */}
        {isMobile ? (
          showRightArrow ? (
            <NavigationButton
              onClick={() =>
                handleNavigationWithDebounce(
                  menuItems[visibleRange.end].toLowerCase()
                )
              }
              aria-label="Next section"
            >
              <ChevronRight size={16} strokeWidth={2.5} />
            </NavigationButton>
          ) : (
            <NavigationButton
              onClick={() => handleNavigationWithDebounce('promo')}
              aria-label="Add new"
            >
              <Plus size={16} strokeWidth={2.5} />
            </NavigationButton>
          )
        ) : (
          <NavigationButton
            onClick={() => handleNavigationWithDebounce('promo')}
            aria-label="Add new"
          >
            <Plus size={16} strokeWidth={2.5} />
          </NavigationButton>
        )}
      </Box>
    </Box>
  );
};

export default NavigationBar;
