import React, { useState } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  IconButton,
  Alert,
  Collapse,
  Card,
  CardContent,
  Tooltip,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Edit, Check, X, AlertCircle, Info } from 'lucide-react';
import { alpha } from '@mui/material/styles';

const CompetitorPriceResearch = ({
  competitors,
  competitorPrices,
  onCompetitorPriceChange,
  onSaveCompetitorPrices,
  navColors
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSave = async () => {
    await onSaveCompetitorPrices();
    setIsEditing(false);
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <Box sx={{ display: 'grid', rowGap: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Competitor Price Research
          </Typography>
          <Tooltip title="Track and compare competitor prices to inform your pricing strategy">
            <Info size={16} style={{ color: navColors.textSecondary, cursor: 'pointer' }} />
          </Tooltip>
        </Box>
        {competitors?.length > 0 && !isEditing && (
          <Button
            startIcon={!isMobile && <Edit size={16} />}
            variant="contained"
            size="small"
            onClick={() => setIsEditing(true)}
            sx={{ 
              textTransform: 'none',
              backgroundColor: navColors.accent,
              color: 'white',
              borderRadius: '24px',
              px: 3,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: alpha(navColors.accent, 0.9),
                boxShadow: 'none'
              }
            }}
          >
            {isMobile ? 'Update' : 'Update Prices'}
          </Button>
        )}
      </Box>

      <Collapse in={showSuccess}>
        <Alert 
          icon={<Check />}
          severity="success"
          sx={{ 
            mb: 2, 
            borderRadius: '16px',
            '& .MuiAlert-message': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          Competitor prices updated successfully
        </Alert>
      </Collapse>

      {competitors?.length > 0 ? (
        <Box sx={{ display: 'grid', gap: 2 }}>
          {competitors.map((comp, index) => (
            <Card 
              key={index}
              elevation={0}
              sx={{ 
                borderRadius: '16px',
                backgroundColor: alpha(navColors.cardBackground, 0.5)
              }}
            >
              <CardContent sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between',
                '&:last-child': { pb: 2 }
              }}>
                <Box>
                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                    {comp.name}
                  </Typography>
                  {!isEditing && (
                    <Typography variant="h6" sx={{ color: navColors.accent }}>
                      ${competitorPrices[comp.name] ?? comp.price ?? '0.00'}
                    </Typography>
                  )}
                </Box>
                {isEditing && (
                  <TextField
                    label="Price"
                    type="number"
                    size="small"
                    value={competitorPrices[comp.name] ?? comp.price ?? ''}
                    onChange={(e) => onCompetitorPriceChange(comp.name, e.target.value)}
                    InputProps={{ 
                      startAdornment: '$',
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        backgroundColor: 'transparent',
                        '& fieldset': {
                          borderColor: alpha(navColors.accent, 0.3)
                        },
                        '&:hover fieldset': {
                          borderColor: alpha(navColors.accent, 0.5)
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: navColors.accent
                        }
                      }
                    }}
                  />
                )}
              </CardContent>
            </Card>
          ))}

          {isEditing && (
            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button
                variant="contained"
                startIcon={<Check size={16} />}
                onClick={handleSave}
                sx={{ 
                  flex: 1,
                  textTransform: 'none',
                  backgroundColor: navColors.accent,
                  color: 'white',
                  borderRadius: '24px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: alpha(navColors.accent, 0.9),
                    boxShadow: 'none'
                  }
                }}
              >
                Save Changes
              </Button>
              <Button
                variant="contained"
                startIcon={<X size={16} />}
                onClick={handleCancel}
                sx={{ 
                  flex: 1,
                  textTransform: 'none',
                  backgroundColor: alpha(navColors.error, 0.1),
                  color: navColors.error,
                  borderRadius: '24px',
                  boxShadow: 'none',
                  '&:hover': {
                    backgroundColor: alpha(navColors.error, 0.2),
                    boxShadow: 'none'
                  }
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box 
          sx={{ 
            p: 4, 
            textAlign: 'center', 
            borderRadius: '16px',
            backgroundColor: alpha(navColors.cardBackground, 0.5)
          }}
        >
          <AlertCircle 
            size={32} 
            style={{ 
              color: navColors.textSecondary,
              marginBottom: '16px'
            }} 
          />
          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
            No Competitor Data
          </Typography>
          <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
            Start tracking your competitors by adding their pricing information
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsEditing(true)}
            sx={{ 
              textTransform: 'none',
              backgroundColor: navColors.accent,
              color: 'white',
              borderRadius: '24px',
              px: 4,
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: alpha(navColors.accent, 0.9),
                boxShadow: 'none'
              }
            }}
          >
            Add Competitors
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default CompetitorPriceResearch;