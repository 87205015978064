import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  Slider,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  useTheme,
  Divider
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import {
  TrendingUp,
  TrendingDown,
  Download,
  AlertCircle,
  Check,
  ShoppingCart,
  RefreshCw,
  Settings,
  Package,
  Info,
  Building2
} from 'lucide-react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  Legend,
  BarChart as RechartsBarChart,
  Bar,
  Cell
} from 'recharts';

import PricingStrategyService from '../../services/AGTUps-product-services/pricingStrategyService';
import ProductSelector from './ProductSelector';
import CompetitorPriceResearch from './Design-AGTPricingStrategy/CompetitorPriceResearch';

// <<-- Importamos el contexto global de productos -->
import { ProductContext } from '../../../../context/ProductContext';

// Colores y estilos (sin cambios)
const appleColors = {
  light: {
    label: { primary: '#000000' },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      green: '#31C859',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#FFFFFF' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#007BFE', base: '#F2F2F7' }
  },
  dark: {
    label: { primary: '#FFFFFF' },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      green: '#2DD257',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#000000' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#0385FF', base: '#272729' }
  }
};

const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30',
    warning: '#FF9500'
  };
};

const StyledContainer = styled(Box)(() => ({
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  width: '100%',
  padding: '24px',
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-track': { background: 'transparent' },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(20px)',
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    transition: 'all 0.3s ease',
    boxShadow: 'none'
  };
});

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0, 0, 0, 0.5)'
    : 'rgba(255, 255, 255, 0.7)',
  zIndex: 1,
  borderRadius: '16px'
}));

const PriceSlider = styled(Slider)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    '& .MuiSlider-track': {
      backgroundColor: navColors.accent
    },
    '& .MuiSlider-thumb': {
      backgroundColor: navColors.accent,
      border: `2px solid ${navColors.cardBackground}`
    },
    '& .MuiSlider-rail': {
      opacity: 0.3
    },
    '& .MuiSlider-markLabel': {
      fontSize: '0.75rem',
      color: navColors.textSecondary
    },
    '& .MuiSlider-valueLabel': {
      background: alpha(navColors.accent, 0.8)
    }
  };
});

const initialKPIs = {
  revenue: 0,
  profit: 0,
  marginPercentage: 0,
  breakEvenUnits: 0,
  competitorDiff: 0,
  marketMetrics: {
    currentVolume: 0,
    potentialTotalVolume: 0
  },
  totalCost: 0
};

const initialMarketAnalysis = {
  marketShare: 0,
  competitors: [],
  marketData: {
    previousShare: 0,
    currentPrice: 0,
    growthRate: 0
  }
};

const PricingStrategy = () => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const { products, loading: productsLoading } = useContext(ProductContext);

  // Estados principales
  const [selectedProduct, setSelectedProduct] = useState(null);
  // Inicialización de currentPrice en 0
  const [currentPrice, setCurrentPrice] = useState(0);
  const [pricingModel, setPricingModel] = useState('fixed');
  const [marginTarget, setMarginTarget] = useState(40);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [priceHistory, setPriceHistory] = useState(null);
  const [optimalPrice, setOptimalPrice] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [businessKPIs, setBusinessKPIs] = useState(initialKPIs);
  const [priceDeviation, setPriceDeviation] = useState(0);
  const [marketAnalysis, setMarketAnalysis] = useState(initialMarketAnalysis);
  const [enterpriseKPIs, setEnterpriseKPIs] = useState(null);

  // Estado para los precios de competidores
  const [competitorPrices, setCompetitorPrices] = useState({});

  // Estado para controlar si los datos están listos
  const [isDataReady, setIsDataReady] = useState(false);

  // Función para calcular KPIs del negocio
  const calculateKPIs = (price, salesMetrics, costs) => {
    const volume = Number(salesMetrics?.totalSalesAlltime || 0);
    const revenue = price * volume;
    const variableCostPerUnit = Number(costs?.perUnit || 0);
    const monthlyFixedCost = Number(costs?.fixed || 0);
    
    const totalCost = (variableCostPerUnit * volume) + monthlyFixedCost;
    const profit = revenue - totalCost;
    const marginPercentage = revenue > 0 ? (profit / revenue) * 100 : 0;
    
    const breakEvenUnits = price > variableCostPerUnit 
      ? Math.ceil(monthlyFixedCost / (price - variableCostPerUnit))
      : Infinity;
  
    return {
      revenue,
      profit,
      marginPercentage,
      breakEvenUnits,
      totalCost,
      unitCost: volume > 0 ? totalCost / volume : 0,
      marketMetrics: { currentVolume: volume }
    };
  };

  // Función para calcular KPIs empresariales
  const calculateEnterpriseKPIs = (updatedBusinessKPIs, currentStrategyData) => {
    if (!products || !currentStrategyData) return null;

    const enterpriseTotalRevenue = products.reduce((acc, product) => {
      let productRevenue = 0;
      if (product.id === selectedProduct?.id && currentStrategyData.executiveSummary?.salesMetrics) {
        productRevenue = (product.price || 0) * Number(currentStrategyData.executiveSummary.salesMetrics.totalSalesAlltime || 0);
      } else {
        productRevenue = (product.price || 0) * (product.volume || 0);
      }
      return acc + productRevenue;
    }, 0);

    const enterpriseTotalCost = products.reduce((acc, product) => {
      let productCost = 0;
      if (product.id === selectedProduct?.id && currentStrategyData.executiveSummary?.salesMetrics) {
        productCost = updatedBusinessKPIs.totalCost || 0;
      } else {
        productCost = (product.cost || 0) * (product.volume || 0) + (product.fixedCost || 0);
      }
      return acc + productCost;
    }, 0);

    const enterpriseTotalProfit = enterpriseTotalRevenue - enterpriseTotalCost;
    const enterpriseAvgMargin = enterpriseTotalRevenue > 0
      ? (enterpriseTotalProfit / enterpriseTotalRevenue) * 100
      : 0;

    const currentProductRevenue = updatedBusinessKPIs.revenue || 0;
    const currentProductCost = updatedBusinessKPIs.totalCost || 0;

    const revenueShare = enterpriseTotalRevenue > 0
      ? (currentProductRevenue / enterpriseTotalRevenue) * 100
      : 0;

    const costShare = enterpriseTotalCost > 0
      ? (currentProductCost / enterpriseTotalCost) * 100
      : 0;

    const productsByRevenue = [...products].sort((a, b) => {
      const revenueA = (a.price || 0) * (a.volume || 0);
      const revenueB = (b.price || 0) * (b.volume || 0);
      return revenueB - revenueA;
    });
    const currentProductRank = productsByRevenue.findIndex(
      p => p.id === selectedProduct?.id
    ) + 1;

    const avgRevenuePerProduct = products.length > 0
      ? enterpriseTotalRevenue / products.length
      : 0;
    const vsAvgRevenue = avgRevenuePerProduct > 0
      ? ((currentProductRevenue - avgRevenuePerProduct) / avgRevenuePerProduct) * 100
      : 0;
    const vsAvgMargin = enterpriseAvgMargin > 0
      ? ((updatedBusinessKPIs.marginPercentage - enterpriseAvgMargin) / enterpriseAvgMargin) * 100
      : 0;

    return {
      totalRevenue: enterpriseTotalRevenue,
      totalCost: enterpriseTotalCost,
      productCostPercentage: costShare,
      averageMargin: enterpriseAvgMargin,
      revenueShare,
      revenueRank: currentProductRank,
      totalProducts: products.length,
      performance: {
        vsAvgRevenue,
        vsAvgMargin
      }
    };
  };

  // Fusiona la data de marketAnalysis.competitors con los precios ingresados por el usuario
  const updatedCompetitors = marketAnalysis?.competitors?.map(comp => ({
    ...comp,
    price: competitorPrices[comp.name] !== undefined
            ? parseFloat(competitorPrices[comp.name])
            : comp.price
  })) || [];

  // Se añade el precio del producto del usuario como una entrada adicional en el gráfico
  const competitorData = [...updatedCompetitors];
  if (currentPrice !== null && currentPrice !== undefined) {
    competitorData.push({ name: "Your Product", price: currentPrice });
  }

  const formatNum = (val, decimals = 0) => {
    const num = Number(val);
    if (num === 0) return '0';
    if (isNaN(num)) return '0';
    return num.toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    });
  };

  // Función loadProductData actualizada para asegurar el orden correcto
  const loadProductData = async (productId) => {
    try {
      setLoading(true);
      setError(null);
      setIsDataReady(false);

      const [strategyResponse, historyResponse] = await Promise.all([
        PricingStrategyService.getPricingStrategiesByProduct(productId),
        PricingStrategyService.analyzePriceHistory(productId)
      ]);

      if (strategyResponse?.data) {
        setStrategyData(strategyResponse.data);
        
        // 1. Primero establecemos el precio óptimo
        let optimal = 0;
        if (strategyResponse.data.aiAnalysis?.currentAnalysis?.optimal?.price) {
          optimal = Number(strategyResponse.data.aiAnalysis.currentAnalysis.optimal.price);
          setOptimalPrice(optimal);
        }

        // 2. Luego establecemos el precio actual (usando el óptimo como respaldo)
        let priceToUse = optimal;
        if (strategyResponse.data.owned?.[0]) {
          const ownedStrategy = strategyResponse.data.owned[0];
        
          const ownedPrice = Number(ownedStrategy.currentPrice);
          if (!isNaN(ownedPrice) && ownedPrice > 0) {
            priceToUse = ownedPrice;
          }
          setPricingModel(ownedStrategy.pricingModel);
          setMarginTarget(ownedStrategy.marginTarget);
        
          // Extraer y actualizar competitorPrices
          if (ownedStrategy.competitorPrices && Array.isArray(ownedStrategy.competitorPrices)) {
            const cpState = {};
            ownedStrategy.competitorPrices.forEach(item => {
              cpState[item.name] = item.price;
            });
            setCompetitorPrices(cpState);
          }
        }        
        
        // 3. Actualizamos el precio actual
        setCurrentPrice(priceToUse);

        // 4. Calculamos los KPIs utilizando el precio final determinado
        if (
          strategyResponse.data.executiveSummary?.salesMetrics &&
          strategyResponse.data.executiveSummary?.costs
        ) {
          const newKPIs = calculateKPIs(
            priceToUse,
            strategyResponse.data.executiveSummary.salesMetrics,
            strategyResponse.data.executiveSummary.costs
          );
          setBusinessKPIs(newKPIs);
          const newEnterpriseKPIs = calculateEnterpriseKPIs(newKPIs, strategyResponse.data);
          setEnterpriseKPIs(newEnterpriseKPIs);
        }

        // Resto del código para market analysis (si lo hubiera)...
        if (strategyResponse.data.marketAnalysis) {
          setMarketAnalysis({
            marketShare: Number(strategyResponse.data.marketAnalysis.marketShare || 0),
            competitors: strategyResponse.data.marketAnalysis.competitors || [],
            marketData: {
              previousShare: Number(strategyResponse.data.marketAnalysis.marketData?.previousPeriodShare || 0),
              currentPrice: Number(strategyResponse.data.marketAnalysis.marketData?.pricing || 0),
              growthRate: Number(strategyResponse.data.marketAnalysis.marketData?.growthRate || 0)
            }
          });
        }
      }

      if (historyResponse?.data) {
        setPriceHistory(historyResponse.data);
      }

      setLastUpdated(new Date());
      // 5. Marcamos los datos como listos solo después de que todos los estados se hayan actualizado
      setTimeout(() => setIsDataReady(true), 0);
    } catch (err) {
      setError(`Error loading data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct?.id) {
      loadProductData(selectedProduct.id);
    } else {
      setStrategyData(null);
      setOptimalPrice(null);
      setCurrentPrice(0);
      setPriceHistory(null);
      setLastUpdated(null);
      setBusinessKPIs(initialKPIs);
      setPriceDeviation(0);
      setMarketAnalysis(initialMarketAnalysis);
      setEnterpriseKPIs(null);
      setCompetitorPrices({});
      setIsDataReady(false);
    }
  }, [selectedProduct]);

  // useEffect para actualizar KPIs solo cuando los datos estén listos
  useEffect(() => {
    if (
      isDataReady &&
      currentPrice !== null &&
      strategyData?.executiveSummary?.salesMetrics &&
      strategyData?.executiveSummary?.costs
    ) {
      const newKPIs = calculateKPIs(
        currentPrice,
        strategyData.executiveSummary.salesMetrics,
        strategyData.executiveSummary.costs
      );
      setBusinessKPIs(newKPIs);
  
      if (optimalPrice && optimalPrice > 0) {
        const diff = ((currentPrice - optimalPrice) / optimalPrice) * 100;
        setPriceDeviation(diff);
      }
  
      const newEnterpriseKPIs = calculateEnterpriseKPIs(newKPIs, strategyData);
      setEnterpriseKPIs(newEnterpriseKPIs);
    }
  }, [currentPrice, strategyData, optimalPrice, products, isDataReady]);

  const handlePriceChange = (event, newValue) => {
    setCurrentPrice(newValue);
  };

  const handleRefreshData = async () => {
    if (!selectedProduct?.id) return;
    try {
      setUpdating(true);
      await loadProductData(selectedProduct.id);
    } finally {
      setUpdating(false);
    }
  };

  const handleCalculateStrategy = async () => {
    if (!selectedProduct?.id) return;
    try {
      setLoading(true);
      setError(null);

      const response = await PricingStrategyService.calculateOptimalPrice(
        selectedProduct.id,
        { currentPrice, pricingModel, marginTarget }
      );

      if (response?.data) {
        setOptimalPrice(Number(response.data.suggested || 0));
      }
    } catch (err) {
      console.error('[FRONTEND][COMPONENT] Error al calcular estrategia:', err);
      setError(`Error calculating strategy: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleExportData = () => {
    if (!selectedProduct?.id) return;
    try {
      const data = {
        product: selectedProduct,
        strategy: strategyData,
        priceHistory,
        marketAnalysis,
        businessKPIs,
        enterpriseKPIs,
        exportDate: new Date().toISOString()
      };
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `pricing-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error('[FRONTEND][COMPONENT] Error exportando datos:', err);
      setError(`Error exporting data: ${err.message}`);
    }
  };

  // Handlers para la sección de Competitor Price Analysis
  const handleCompetitorPriceChange = (competitorName, newPrice) => {
    setCompetitorPrices((prev) => ({
      ...prev,
      [competitorName]: newPrice
    }));
  };

  const handleSaveCompetitorPrices = async () => {
    if (!selectedProduct?.id) return;
    try {
      const cpArray = Object.keys(competitorPrices).map(name => ({
        name,
        price: parseFloat(competitorPrices[name])
      }));
      const response = await PricingStrategyService.updateCompetitorPrices(selectedProduct.id, cpArray);
      if (response?.data) {
        const updatedCP = {};
        response.data.competitorPrices.forEach(item => {
          updatedCP[item.name] = item.price;
        });
        setCompetitorPrices(updatedCP);
      }
    } catch (err) {
      console.error('[FRONTEND][COMPONENT] Error updating competitor prices:', err);
      setError(`Error updating competitor prices: ${err.message}`);
    }
  };

  // Componente para renderizar el slider de precio
  const PriceSliderSection = () => {
    if (!isDataReady || !optimalPrice || optimalPrice <= 0) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={24} />
        </Box>
      );
    }

    const minPrice = optimalPrice * 0.5;
    const maxPrice = optimalPrice * 1.5;

    return (
      <PriceSlider
        value={currentPrice}
        onChange={handlePriceChange}
        min={minPrice}
        max={maxPrice}
        step={0.01}
        marks={[
          { value: minPrice, label: '-50%' },
          { value: optimalPrice * 0.75, label: '-25%' },
          { value: optimalPrice, label: 'Optimal' },
          { value: optimalPrice * 1.25, label: '+25%' },
          { value: maxPrice, label: '+50%' }
        ]}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `$${formatNum(value, 2)}`}
        disabled={loading}
      />
    );
  };

  return (
    <StyledContainer>
      <Box sx={{ '& > *:not(:last-child)': { mb: 4 } }}>
        {/* ProductSelector actualizado para usar products y productsLoading */}
        <ProductSelector
          products={products}
          selectedProduct={selectedProduct}
          onProductSelect={setSelectedProduct}
          loading={productsLoading}
          compact
        />

        {/* Indicador de carga global */}
        {productsLoading && (
          <Box sx={{ textAlign: 'center', py: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Manejo de errores */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{
              borderRadius: '12px',
              mt: 2,
              backdropFilter: 'blur(10px)',
              backgroundColor: alpha(navColors.error, 0.1),
              color: navColors.error
            }}
          >
            {error}
          </Alert>
        )}

        {!selectedProduct ? (
          <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
            <Package
              size={48}
              style={{
                marginBottom: '16px',
                color: alpha(navColors.textSecondary, 0.6)
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
              Select a Product
            </Typography>
            <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
              Choose a product to view and configure its pricing strategy
            </Typography>
          </StyledPaper>
        ) : (
          <>
            {!strategyData ? (
              <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  No Pricing Strategy Found
                </Typography>
                <Typography variant="body2" sx={{ mb: 3 }}>
                  Create a pricing strategy to start optimizing your product's price
                </Typography>
                <Button 
                  variant="contained" 
                  onClick={handleCalculateStrategy} 
                  disabled={loading}
                  sx={{
                    borderRadius: '12px',
                    backgroundColor: navColors.accent,
                    '&:hover': { backgroundColor: alpha(navColors.accent, 0.9) }
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Create Strategy'}
                </Button>
              </StyledPaper>
            ) : (
              <Grid container spacing={4}>
                {/* Executive Summary (Product) */}
                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" sx={{ fontWeight: 700, mb: 2 }}>
                      Executive Summary (Product)
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Revenue
                            <Tooltip title="Current Price × Sales Volume">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(businessKPIs.revenue)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Profit
                            <Tooltip title="Revenue - Total Costs">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography 
                            variant="h6" 
                            sx={{ 
                              fontWeight: 600,
                              color: businessKPIs.profit >= 0 ? navColors.success : navColors.error 
                            }}
                          >
                            ${formatNum(businessKPIs.profit)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Margin
                            <Tooltip title="(Profit ÷ Revenue) × 100">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(businessKPIs.marginPercentage, 1)}%
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Break-even
                            <Tooltip title="Units needed to cover fixed costs">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {Number.isFinite(businessKPIs.breakEvenUnits)
                              ? formatNum(businessKPIs.breakEvenUnits)
                              : '∞'}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>

                {/* Sección: Sales Metrics */}
                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" sx={{ fontWeight: 700, mb: 2 }}>
                      Sales Metrics
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Revenue Today</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(strategyData.executiveSummary.salesMetrics?.totalRevenueToday || 0, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Revenue Monthly</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(strategyData.executiveSummary.salesMetrics?.totalRevenueMonthly || 0, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Revenue Alltime</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(strategyData.executiveSummary.salesMetrics?.totalRevenueAlltime || 0, 2)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Sales Today</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(strategyData.executiveSummary.salesMetrics?.totalSalesToday || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Sales Monthly</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(strategyData.executiveSummary.salesMetrics?.totalSalesMonthly || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Box>
                          <Typography variant="body2">Total Sales Alltime</Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(strategyData.executiveSummary.salesMetrics?.totalSalesAlltime || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>

                {/* Executive Summary (Enterprise) */}
                <Grid item xs={12}>
                  <StyledPaper>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                      <Building2 size={24} style={{ color: navColors.accent }} />
                      <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Executive Summary (Enterprise)
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Total Revenue
                            <Tooltip title="Sum of all products revenues">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(enterpriseKPIs?.totalRevenue || 0)}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Total Cost
                            <Tooltip title="Sum of all products costs">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            ${formatNum(enterpriseKPIs?.totalCost || 0)}
                          </Typography>
                          <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                            Product cost: {formatNum(enterpriseKPIs?.productCostPercentage || 0, 1)}%
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Avg. Margin
                            <Tooltip title="Enterprise average margin">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(enterpriseKPIs?.averageMargin || 0, 1)}%
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box>
                          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                            Revenue Share
                            <Tooltip title="Product's revenue share vs total revenue">
                              <Info size={16} style={{ cursor: 'pointer' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {formatNum(enterpriseKPIs?.revenueShare || 0, 1)}%
                          </Typography>
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: enterpriseKPIs?.revenueRank === 1 ? navColors.success : navColors.textSecondary 
                            }}
                          >
                            Rank #{enterpriseKPIs?.revenueRank || '-'} of {enterpriseKPIs?.totalProducts || '-'}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ my: 2 }} />
                        <Box>
                          <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 1 }}>
                            Performance Comparison
                          </Typography>
                          <Tooltip title="How this product's revenue compares to the average revenue across all products">
                            <Typography variant="body2" sx={{ 
                              color: enterpriseKPIs?.performance?.vsAvgRevenue > 0 ? navColors.success : navColors.error 
                            }}>
                              vs Avg. Revenue: {formatNum(enterpriseKPIs?.performance?.vsAvgRevenue || 0, 1)}%
                            </Typography>
                          </Tooltip>
                          <Tooltip title="How this product's margin compares to the average margin across all products">
                            <Typography variant="body2" sx={{ 
                              color: enterpriseKPIs?.performance?.vsAvgMargin > 0 ? navColors.success : navColors.error 
                            }}>
                              vs Avg. Margin: {formatNum(enterpriseKPIs?.performance?.vsAvgMargin || 0, 1)}%
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>

                {/* Sección: Price Optimization */}
                <Grid item xs={12} md={8}>
                  <StyledPaper sx={{ position: 'relative' }}>
                    {updating && (
                      <LoadingOverlay>
                        <CircularProgress />
                      </LoadingOverlay>
                    )}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                      <Box>
                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 0.5 }}>
                          {selectedProduct.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Price Optimization
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', gap: 1 }}>
                        <Tooltip title={lastUpdated ? `Last updated: ${lastUpdated.toLocaleString()}` : 'Never updated'}>
                          <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                            <RefreshCw size={20} style={{ color: navColors.accent }} />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={handleExportData} disabled={loading}>
                          <Download size={20} style={{ color: navColors.accent }} />
                        </IconButton>
                        <IconButton>
                          <Settings size={20} style={{ color: navColors.accent }} />
                        </IconButton>
                      </Box>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="subtitle2">Current Price Point</Typography>
                        <Typography variant="h5" sx={{ fontWeight: 600, color: navColors.accent }}>
                          ${formatNum(currentPrice, 2)}
                        </Typography>
                      </Box>
                      <PriceSliderSection />
                    </Box>

                    {optimalPrice && (
                      <>
                        <Typography variant="h5" sx={{ fontWeight: 600, color: navColors.accent, mb: 2 }}>
                          Your Price: ${formatNum(optimalPrice, 2)}
                        </Typography>
                        <Alert 
                          severity={Math.abs(priceDeviation) < 1 ? 'success' : 'info'} 
                          icon={Math.abs(priceDeviation) < 1 ? <Check /> : <AlertCircle />}
                          sx={{ mb: 4, borderRadius: '12px' }}
                        >
                          {Math.abs(priceDeviation) < 1 
                            ? "You're at your chosen price!"
                            : `Your price is ${formatNum(Math.abs(priceDeviation), 1)}% ${priceDeviation > 0 ? 'above' : 'below'} your target`}
                        </Alert>
                      </>
                    )}

                    <Box sx={{ height: 300 }}>
                      {priceHistory?.priceHistory?.data ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart data={priceHistory.priceHistory.data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <RechartsTooltip />
                            <Area 
                              type="monotone" 
                              dataKey="price" 
                              stroke={navColors.accent}
                              fill={alpha(navColors.accent, 0.2)}
                              name="Price"
                            />
                            <Area
                              type="monotone"
                              dataKey="optimal"
                              stroke={navColors.success}
                              fill={alpha(navColors.success, 0.1)}
                              strokeDasharray="5 5"
                              name="Optimal"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      ) : (
                        <Box sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          alignItems: 'center', 
                          justifyContent: 'center',
                          color: navColors.textSecondary 
                        }}>
                          <Typography>No price history available</Typography>
                        </Box>
                      )}
                    </Box>
                  </StyledPaper>
                </Grid>

                {/* Sección: Competitor Price Analysis */}
                <Grid item xs={12}>
                  <StyledPaper>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Competitor Price Analysis
                      </Typography>
                      <IconButton onClick={handleExportData} disabled={loading}>
                        <Download size={20} style={{ color: navColors.accent }} />
                      </IconButton>
                    </Box>

                    <Grid container spacing={4}>
                      {/* Primera columna: Gráfico de competidores */}
                      <Grid item xs={12} md={6}>
                        <Box sx={{ height: 320 }}>
                          {competitorData.length > 0 ? (
                            <ResponsiveContainer width="100%" height="100%">
                              <RechartsBarChart data={competitorData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <RechartsTooltip formatter={(value) => [`$${formatNum(value)}`, 'Price']} />
                                <Bar dataKey="price">
                                  {competitorData.map((entry, index) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={
                                        entry.name === "Your Product"
                                          ? navColors.warning
                                          : alpha(navColors.accent, 0.6)
                                      }
                                    />
                                  ))}
                                </Bar>
                              </RechartsBarChart>
                            </ResponsiveContainer>
                          ) : (
                            <Box sx={{ 
                              height: '100%', 
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'center',
                              color: navColors.textSecondary 
                            }}>
                              <Typography>No competitor data available</Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>

                      {/* Segunda columna: Competitor Price Research mejorado */}
                      <Grid item xs={12} md={6}>
                        <CompetitorPriceResearch
                          competitors={marketAnalysis?.competitors}
                          competitorPrices={competitorPrices}
                          onCompetitorPriceChange={handleCompetitorPriceChange}
                          onSaveCompetitorPrices={handleSaveCompetitorPrices}
                          navColors={navColors}
                        />
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </StyledContainer>
  );
};

export default PricingStrategy;
