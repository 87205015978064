// src/App.js
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography, Box } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Provider } from 'react-redux';
import { store } from './components/services/BackgroundSummaryService';
import { getUserBackgroundPreference, setUserBackgroundPreference } from './components/services/AGTService';
import { useMediaQuery } from '@mui/material';
import { getTheme, cssVariables } from './theme';
import loadingLogo from './assets/images/loading.svg';
import { ProProvider, useProStatus } from './context/ProContext';

import LimitedDashboard from './components/dashboard/LimitedDashboard';
import FullDashboard from './components/dashboard/FullDashboard/FullDashboard';
import UserProfile from './components/auth/UserProfile';
import HomeInput from './components/home/HomeInput/HomeInput';

// Importamos el ProductProvider para disponer del contexto global de productos
import { ProductProvider } from './context/ProductContext';

function AppContent() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backgroundType, setBackgroundType] = useState('system');
  const [activeSection, setActiveSection] = useState('home');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { checkStatus, isPro } = useProStatus();

  const theme = useMemo(() => {
    const currentTheme =
      backgroundType === 'system'
        ? prefersDarkMode ? 'dark' : 'light'
        : backgroundType;
    return createTheme(getTheme(currentTheme));
  }, [backgroundType, prefersDarkMode]);

  const enrichedUser = useMemo(() => {
    if (!user) return null;
    return {
      ...user,
      isPro
    };
  }, [user, isPro]);

  useEffect(() => {
    const checkProStatus = async () => {
      if (user?.uid) {
        try {
          await checkStatus(user.uid);
        } catch (error) {
          console.error('Error checking pro status:', error);
        }
      }
    };

    checkProStatus();
  }, [user?.uid, checkStatus]);

  useEffect(() => {
    const currentTheme =
      backgroundType === 'system'
        ? prefersDarkMode ? 'dark' : 'light'
        : backgroundType;

    document.documentElement.setAttribute('data-theme', currentTheme);

    const style = document.createElement('style');
    style.innerHTML = cssVariables;
    document.head.appendChild(style);

    return () => document.head.removeChild(style);
  }, [backgroundType, prefersDarkMode]);

  useEffect(() => {
    let timeout;

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        setUser(currentUser);
        if (currentUser) {
          const preference = await getUserBackgroundPreference(currentUser.uid);
          setBackgroundType(preference === null ? 'system' : preference);
        } else {
          setBackgroundType('system');
        }
      } catch (error) {
        console.error('Error during authentication or preference loading:', error);
        setError('An error occurred while setting up the application. Please try again.');
      } finally {
        setLoading(false);
        clearTimeout(timeout);
      }
    });

    timeout = setTimeout(() => {
      setLoading(false);
      setError('Authentication timed out. Please check your connection and try again.');
    }, 10000);

    return () => {
      unsubscribe();
      clearTimeout(timeout);
    };
  }, [auth]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (location.pathname === '/auth/success' && token) {
      localStorage.setItem('token', token);
      navigate('/');
    }
  }, [location, navigate]);

  const handleLogin = useCallback((user) => {
    setUser(user);
    setActiveSection('home');
    navigate('/');
  }, [navigate]);

  const handleLogout = useCallback(() => {
    auth.signOut().then(() => {
      setUser(null);
      setBackgroundType('system');
      setActiveSection('home');
      localStorage.removeItem('token');
      navigate('/');
    }).catch((error) => {
      console.error('Error al cerrar sesión:', error);
    });
  }, [auth, navigate]);

  const handleBackgroundChange = useCallback(async (newBackgroundType) => {
    if (user) {
      try {
        await setUserBackgroundPreference(user.uid, newBackgroundType);
        setBackgroundType(newBackgroundType);
        const currentTheme =
          newBackgroundType === 'system'
            ? prefersDarkMode ? 'dark' : 'light'
            : newBackgroundType;
        document.documentElement.setAttribute('data-theme', currentTheme);
      } catch (error) {
        console.error('Error setting user background preference:', error);
      }
    } else {
      setBackgroundType(newBackgroundType);
      const currentTheme =
        newBackgroundType === 'system'
          ? prefersDarkMode ? 'dark' : 'light'
          : newBackgroundType;
      document.documentElement.setAttribute('data-theme', currentTheme);
    }
  }, [user, prefersDarkMode]);

  const handleNavigate = useCallback((section) => {
    setActiveSection(section);
    navigate(`/${section}`);
  }, [navigate]);

  const handleSendMessage = useCallback((message) => {
    setActiveSection('chat');
    navigate('/chat');
  }, [navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: theme.palette.colors.bg.primaryElevated,
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Box
            component="img"
            src={loadingLogo}
            sx={{
              width: 128,
              height: 128,
              filter: theme.palette.mode === 'light' ? 'invert(1)' : 'none',
              transition: 'filter 0.3s ease',
              animation: 'fadeIn 0.5s ease-in-out',
              '@keyframes fadeIn': {
                '0%': {
                  opacity: 0,
                },
                '100%': {
                  opacity: 1,
                },
              },
            }}
            alt="Loading"
          />
        </Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          padding: 2,
          textAlign: 'center',
          backgroundColor: theme.palette.colors.bg.primaryElevated,
        }}
      >
        <Typography
          color="error"
          variant="h6"
          sx={{
            color: theme.palette.colors.label.primary,
          }}
        >
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          minHeight: '100vh',
          backgroundColor: theme.palette.colors.bg.primaryElevated,
          color: theme.palette.colors.label.primary,
          transition: 'background-color 0.3s ease',
        }}
      >
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Routes>
            <Route
              path="/"
              element={
                user ? (
                  <FullDashboard
                    user={enrichedUser}
                    onLogout={handleLogout}
                    backgroundType={backgroundType}
                    onBackgroundChange={handleBackgroundChange}
                    activeSection={activeSection}
                    onNavigate={handleNavigate}
                    onSendMessage={handleSendMessage}
                  />
                ) : (
                  <LimitedDashboard
                    onLogin={handleLogin}
                    backgroundType={backgroundType}
                    onBackgroundChange={handleBackgroundChange}
                  />
                )
              }
            />
            <Route
              path="/home"
              element={
                user ? (
                  <FullDashboard
                    user={enrichedUser}
                    onLogout={handleLogout}
                    backgroundType={backgroundType}
                    onBackgroundChange={handleBackgroundChange}
                    activeSection="home"
                    onNavigate={handleNavigate}
                    onSendMessage={handleSendMessage}
                  />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/chat"
              element={
                user ? (
                  <FullDashboard
                    user={enrichedUser}
                    onLogout={handleLogout}
                    backgroundType={backgroundType}
                    onBackgroundChange={handleBackgroundChange}
                    activeSection="chat"
                    onNavigate={handleNavigate}
                    onSendMessage={handleSendMessage}
                  />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path="/profile"
              element={
                user ? (
                  <UserProfile
                    user={enrichedUser}
                    onLogout={handleLogout}
                    backgroundType={backgroundType}
                    onBackgroundChange={handleBackgroundChange}
                  />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route path="/auth/success" element={<Navigate to="/" />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ProProvider>
        <ProductProvider>
          <Router>
            <CssBaseline />
            <AppContent />
          </Router>
        </ProductProvider>
      </ProProvider>
    </Provider>
  );
}

export default App;
