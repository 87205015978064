import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  Grid,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  X,
  Images,
  FileText,
  Pencil,
  Tag,
  DollarSign,
  Truck,
  Package,
  Palette,
  Lightbulb,
  Box as LucideBox  // Ícono para stock
} from 'lucide-react';

const EditCard = styled(Box)(({ theme }) => ({
  borderRadius: '16px',
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.02)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
  cursor: 'pointer',
  height: '160px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[4]
  }
}));

const EditDialog = ({ open, onClose, title, children, onSave }) => (
  <Dialog 
    open={open} 
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    PaperProps={{
      sx: {
        borderRadius: '28px',
        padding: 2
      }
    }}
  >
    <DialogTitle>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton size="small" onClick={onClose}>
          <X size={20} />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent sx={{ pt: 2 }}>
      {children}
    </DialogContent>
    <DialogActions sx={{ px: 3, pb: 3 }}>
      <Button 
        onClick={onClose}
        sx={{ 
          color: '#0385FF',
          borderRadius: '20px',
          paddingX: '24px',
          textTransform: 'none',
          '&:hover': { 
            bgcolor: 'rgba(3, 133, 255, 0.04)'
          }
        }}
      >
        Cancel
      </Button>
      <Button 
        variant="contained" 
        onClick={onSave}
        sx={{ 
          bgcolor: '#0385FF',
          borderRadius: '20px',
          paddingX: '24px',
          textTransform: 'none',
          color: '#FFFFFF',
          boxShadow: 'none',
          '&:hover': { 
            bgcolor: '#0374e1',
            color: '#FFFFFF',
            boxShadow: 'none'
          },
          '&:active': {
            bgcolor: '#0268c8',
            color: '#FFFFFF',
            boxShadow: 'none'
          }
        }}
      >
        Save changes
      </Button>
    </DialogActions>
  </Dialog>
);

const stepIcons = {
  name: Pencil,
  image: Images,
  description: FileText,
  category: Tag,
  price: DollarSign,
  shipping: Truck,
  inventory: Package,
  variants: Palette,
  marketing: Lightbulb,
  stock: LucideBox
};

const ProductEditGrid = ({
  open,
  onClose,
  steps,
  productData,
  onSave,
  enhanceLoading,
  onEnhanceDescription,
  onEnhanceCategory,
  onEnhancePrice,
  onGetMarketingTips,
  imagePreview,
  onImageUpload,
  onSelectGalleryImage,
  initialStep // nueva prop para seleccionar automáticamente el paso
}) => {
  const [selectedStep, setSelectedStep] = useState(initialStep || null);
  const [editedData, setEditedData] = useState(productData);

  // Actualiza el estado si cambia initialStep o productData
  useEffect(() => {
    if (initialStep && steps[initialStep]) {
      setSelectedStep(initialStep);
    }
    setEditedData(productData);
  }, [initialStep, productData, steps]);

  const handleStepClick = (stepKey) => {
    setSelectedStep(stepKey);
    setEditedData(productData);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSaveStep = async () => {
    try {
      // Crear un objeto con los datos actualizados para el campo correspondiente
      const updatedProduct = {
        ...productData,
        [steps[selectedStep].field]: editedData[steps[selectedStep].field]
      };

      // Llamar a onSave con el producto actualizado
      await onSave(updatedProduct);
      
      // Cerrar el diálogo de edición del step
      setSelectedStep(null);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  const renderStepContent = (stepKey) => {
    const step = steps[stepKey];
    if (!step) return null;
    const componentProps = {
      value: editedData[step.field],
      onChange: handleEditChange,
      onEnhance: {
        description: onEnhanceDescription,
        category: onEnhanceCategory,
        price: onEnhancePrice,
        marketing: onGetMarketingTips
      }[stepKey],
      isLoading: enhanceLoading?.[stepKey],
      imagePreview,
      onImageUpload,
      onSelectGalleryImage: (url) => {
        // Actualizamos el estado editable para reflejar el cambio en el campo imageUrl
        setEditedData((prev) => ({
          ...prev,
          imageUrl: url
        }));
        // Llamamos al callback externo, si se proporcionó, para actualizar el estado global
        if (onSelectGalleryImage) {
          onSelectGalleryImage(url);
        }
      },
      name: step.field
    };

    return step.component(componentProps);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '28px',
          p: 3
        }
      }}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h5" sx={{ fontWeight: 600 }}>Edit Product</Typography>
          <IconButton onClick={onClose}>
            <X size={20} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          {Object.entries(steps).map(([key, step]) => {
            const StepIcon = stepIcons[key] || FileText;
            return (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <EditCard onClick={() => handleStepClick(key)}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
                    <StepIcon size={24} style={{ marginRight: '12px', marginTop: '4px' }} />
                    <Box>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {step.title}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.4em',
                          height: '2.8em'
                        }}
                      >
                        {step.description}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography 
                    sx={{ 
                      color: '#0385FF',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      fontSize: '0.875rem',
                      pl: { xs: 2, sm: '36px' }
                    }}
                  >
                    Edit {step.title.toLowerCase()} →
                  </Typography>
                </EditCard>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>

      {selectedStep && steps[selectedStep] && (
        <EditDialog
          open={Boolean(selectedStep)}
          onClose={() => setSelectedStep(null)}
          title={steps[selectedStep].title}
          onSave={handleSaveStep}
        >
          {renderStepContent(selectedStep)}
        </EditDialog>
      )}
    </Dialog>
  );
};

export default ProductEditGrid;
