// src/components/AGTUps/services/AGTUps-product-services/costService.js
import { api } from './config';

class CostService {
  /**
   * Crea un nuevo costo asociado a un productId específico
   * @param {string} productId - El ID del producto
   * @param {object} costData - Datos del costo (amount, description, etc.)
   * @returns {object} Respuesta del backend (data)
   */
  static async createCost(productId, costData) {
    try {
      const response = await api.post(`/cost/${productId}`, costData);
      return response.data;
    } catch (error) {
      throw new Error(`Error creating cost: ${error.message}`);
    }
  }

  /**
   * Obtiene los costos asociados a un productId,
   * con parámetros opcionales de categoría y ordenamiento
   * @param {string} productId - El ID del producto
   * @param {object} options - { category, sortBy, order }
   * @returns {object} Respuesta del backend (data), que incluye { success, data: { items, summary } }
   */
  static async getCostsByProduct(productId, options = {}) {
    try {
      const { category, sortBy, order } = options;
      let url = `/cost/${productId}`;

      // Construimos parámetros de query si se requieren
      const params = new URLSearchParams();
      if (category) params.append('category', category);
      if (sortBy) params.append('sortBy', sortBy);
      if (order) params.append('order', order);

      if (params.toString()) {
        url += `?${params.toString()}`;
      }

      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching costs: ${error.message}`);
    }
  }

  /**
   * Obtiene la lista de categorías predefinidas disponibles
   * @returns {object} { success, data: { predefined: [...], ... } }
   */
  static async getCategories() {
    try {
      const response = await api.get('/cost/categories');
      return response.data;
    } catch (error) {
      throw new Error(`Error fetching categories: ${error.message}`);
    }
  }

  /**
   * Actualiza un costo existente (PUT /cost/:costId)
   * @param {string} costId - El ID del costo a actualizar
   * @param {object} updateData - Los campos a actualizar
   * @returns {object} Respuesta del backend (data)
   */
  static async updateCost(costId, updateData) {
    try {
      const response = await api.put(`/cost/${costId}`, updateData);
      return response.data;
    } catch (error) {
      throw new Error(`Error updating cost: ${error.message}`);
    }
  }

  /**
   * Elimina un costo existente (DELETE /cost/:costId)
   * @param {string} costId - El ID del costo a eliminar
   * @returns {object} Respuesta del backend (data)
   */
  static async deleteCost(costId) {
    try {
      const response = await api.delete(`/cost/${costId}`);
      return response.data;
    } catch (error) {
      throw new Error(`Error deleting cost: ${error.message}`);
    }
  }
}

export default CostService;
