import React, { useState, useEffect, useCallback, useRef, useContext, useMemo } from 'react';
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Alert,
  CircularProgress,
  Snackbar,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Package,
  MoreVertical,
  Edit,
  Trash2,
  Plus,
  DollarSign,
  ShoppingBag,
  Briefcase,
  Smartphone,
  Coffee,
  AlertCircle,
  Search,
  RefreshCw,
  Sparkles,
  Upload
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import ProductService from '../../services/AGTUps-product-services/productService';
import { galleryImages } from './galleryImages';
import { ProductContext } from '../../../../context/ProductContext';
import ProductSetupWizard from './Design-AGTProductsManager/ProductSetupWizard';
import ProductEditGrid from './Design-AGTProductsManager/ProductEditGrid';

// ---------------------------------------------------------------------------
// Función para unificar ciertos estilos (tema claro/oscuro)
const navigationColors = (theme) => ({
  background:
    theme.palette.mode === 'dark'
      ? 'rgba(40, 40, 42, 0.94)'
      : 'rgba(242, 242, 247, 1)',
  textDefault: theme.palette.mode === 'dark' ? '#FFFFFF' : '#666666',
  textPlaceholder:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.7)' : '#666666',
  iconColor: '#007BFE'
});

// ---------------------------------------------------------------------------
// Botón personalizado para IA
const AGTEnhanceButton = ({
  onEnhance,
  isLoading,
  disabled = false,
  label = 'Enhance with AGT',
  tooltip = 'Automatically enhance this field using AI',
  icon = <Sparkles size={20} />,
  sx = {}
}) => {
  return (
    <Tooltip title={tooltip} arrow placement="top">
      <span>
        <Button
          variant="outlined"
          startIcon={isLoading ? <CircularProgress size={20} /> : icon}
          onClick={onEnhance}
          disabled={disabled || isLoading}
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderRadius: '28px',
            color: '#0385FF',
            borderColor: '#0385FF',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(3,133,255,0.08)',
              borderColor: '#0266cc'
            },
            ...sx
          }}
        >
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

// ---------------------------------------------------------------------------
// Contenedor principal, con scroll interno
const StyledContainer = styled(Box)(() => ({
  backgroundColor: 'transparent',
  padding: '24px',
  width: '100%',
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
  }
}));

// ---------------------------------------------------------------------------
// SearchBar con estilos unificados
const StyledSearchBar = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: navigationColors(theme).background,
    '& fieldset': { border: 'none' },
    '& input': {
      color: navigationColors(theme).textDefault,
      '&::placeholder': {
        color: navigationColors(theme).textPlaceholder,
        opacity: 1
      }
    }
  }
}));

// ---------------------------------------------------------------------------
// Select con estilos unificados (filtro principal)
const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: navigationColors(theme).background,
  color: navigationColors(theme).textDefault,
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px'
  },
  '& .MuiSelect-select': {
    borderRadius: '12px',
    padding: '8px 16px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    borderRadius: '12px'
  },
  '& .MuiMenu-paper': {
    borderRadius: '12px',
    marginTop: '8px'
  },
  '& .MuiMenu-list': {
    borderRadius: '12px'
  }
}));

// ---------------------------------------------------------------------------
// MenuItem con estilos unificados
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  color: navigationColors(theme).textDefault,
  padding: '8px 16px',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.04)'
  },
  '&:first-of-type': {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px'
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  }
}));

// ---------------------------------------------------------------------------
// Botón de acciones (Add, Refresh, etc.)
const StyledActionButton = styled(Button)(({ theme }) => ({
  backgroundColor: navigationColors(theme).iconColor,
  borderRadius: '30px',
  color: '#FFFFFF',
  textTransform: 'none',
  padding: '6px 16px',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: '#005FDB'
  }
}));

// ---------------------------------------------------------------------------
// Tarjeta para cada producto
const ProductCard = styled(Box)(({ bgcolor, imageUrl }) => ({
  position: 'relative',
  borderRadius: '16px',
  aspectRatio: '1',
  overflow: 'hidden',
  background: imageUrl
    ? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imageUrl})`
    : `linear-gradient(45deg, ${bgcolor}, ${bgcolor}88)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: '16px',
  transition: 'transform 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:hover': {
    transform: 'scale(1.02)'
  }
}));

// ---------------------------------------------------------------------------
// Diálogo con estilos personalizados (para otros diálogos)
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: navigationColors(theme).background,
    color: navigationColors(theme).textDefault,
    borderRadius: '16px'
  }
}));

// ---------------------------------------------------------------------------
// Categorías por defecto
const defaultCategories = [
  { id: 'physical', name: 'Physical Products', icon: Package, color: '#4f46e5' },
  { id: 'digital', name: 'Digital Products', icon: Smartphone, color: '#0891b2' },
  { id: 'services', name: 'Services', icon: Briefcase, color: '#059669' },
  { id: 'subscription', name: 'Subscriptions', icon: ShoppingBag, color: '#7c3aed' },
  { id: 'food', name: 'Food & Beverage', icon: Coffee, color: '#db2777' }
];

const ProductsManager = ({ onProductCreate, onProductUpdate, onProductDelete, autoAdd = false, user }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const { products, refreshProducts, loading: contextLoading, updateProductList } = useContext(ProductContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const mounted = useRef(true);

  // Estado para el modo edición o creación
  const [isEditing, setIsEditing] = useState(false);

  // Estado para el producto a crear/editar
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    stock: '',
    customFields: {},
    imageUrl: ''
  });

  // Estados para el archivo de imagen (selección y preview)
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  // Estados para IA
  const [enhanceLoading, setEnhanceLoading] = useState({
    description: false,
    category: false,
    price: false
  });
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);

  // Para marketing tips
  const [marketingTips, setMarketingTips] = useState(null);
  const [showMarketingDialog, setShowMarketingDialog] = useState(false);

  // Nuevo estado para indicar el paso inicial (en este caso "stock")
  const [preselectedStep, setPreselectedStep] = useState(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const showNotification = useCallback((message, severity = 'success') => {
    if (mounted.current) {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setShowSnackbar(true);
    }
  }, []);

  const handleError = useCallback(
    (error) => {
      if (mounted.current) {
        if (error.name === 'CooldownError') {
          setError({
            name: 'CooldownError',
            message: error.message
          });
        } else {
          setError({
            name: 'Error',
            message: error.message || 'An unexpected error occurred'
          });
          showNotification(error.message || 'An unexpected error occurred', 'error');
        }
      }
    },
    [showNotification]
  );

  const handleRefresh = useCallback(() => {
    refreshProducts();
  }, [refreshProducts]);

  const filteredProducts = products.filter((product) => {
    if (categoryFilter && product.category !== categoryFilter) return false;
    if (searchQuery && !product.name.toLowerCase().includes(searchQuery.toLowerCase())) return false;
    return true;
  });

  const loadProductDetails = useCallback(
    async (productId) => {
      if (!mounted.current || !productId) return null;
      try {
        setLoading(true);
        setError(null);
        const response = await ProductService.getProductById(productId);
        if (!response?.data) {
          throw new Error('Product data not found');
        }
        return response.data;
      } catch (error) {
        handleError(error);
        return null;
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    },
    [handleError]
  );

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/png', 'image/jpeg'];
      const maxSize = 2 * 1024 * 1024; // 2MB
      if (!validTypes.includes(file.type)) {
        showNotification('Only PNG and JPEG images are allowed.', 'warning');
        return;
      }
      if (file.size > maxSize) {
        showNotification('Image size exceeds 2MB limit.', 'warning');
        return;
      }
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenMenu = useCallback((event, product) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setProductToDelete(product);
  }, []);

  const handleCloseMenu = useCallback(() => {
    if (mounted.current) {
      setAnchorEl(null);
    }
  }, []);

  const handleDeleteClick = useCallback(
    (product) => {
      if (!mounted.current) return;
      if (!product?.id) {
        showNotification('Invalid product selected for deletion', 'error');
        return;
      }
      const productExists = products.some((p) => p.id === product.id);
      if (!productExists) {
        showNotification('Product no longer exists', 'info');
        handleCloseMenu();
        return;
      }
      setProductToDelete(product);
      setDeleteConfirmOpen(true);
      handleCloseMenu();
    },
    [handleCloseMenu, showNotification, products]
  );

  const handleCloseDeleteDialog = useCallback(() => {
    if (mounted.current) {
      setDeleteConfirmOpen(false);
      setProductToDelete(null);
      handleCloseMenu();
    }
  }, [handleCloseMenu]);

  const handleDeleteProduct = useCallback(async () => {
    if (!mounted.current || !productToDelete?.id) {
      return;
    }
    try {
      const deletingProductId = productToDelete.id;
      const deletingProductName = productToDelete.name;
      setLoadingStates((prev) => ({ ...prev, [deletingProductId]: true }));
      const response = await ProductService.deleteProduct(deletingProductId);
      if (response.success && mounted.current) {
        showNotification(
          `Product "${deletingProductName}" has been deleted successfully`,
          'success'
        );
        if (typeof onProductDelete === 'function') {
          onProductDelete(deletingProductId);
        }
        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
        setError(null);
        refreshProducts();
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        if (productToDelete?.id) {
          setLoadingStates((prev) => ({
            ...prev,
            [productToDelete.id]: false
          }));
        }
        setDeleteConfirmOpen(false);
        setProductToDelete(null);
        handleCloseMenu();
      }
    }
  }, [
    productToDelete,
    onProductDelete,
    handleCloseMenu,
    showNotification,
    handleError,
    refreshProducts,
    products
  ]);

  // ---------- DEFINICIÓN DE canUseAI Y ensureProductInDB ----------
  const canUseAI = (field) => {
    try {
      const canEnhance = ProductService.canEnhanceProduct(newProduct, field);
      if (!canEnhance.canEnhance) {
        showNotification(canEnhance.reason, 'warning');
        return false;
      }
      return true;
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      }
      return false;
    }
  };

  const ensureProductInDB = useCallback(async () => {
    if (newProduct.id) {
      return newProduct;
    }
    const generatedId = `prod_${uuidv4()}`;
    const draftData = {
      ...newProduct,
      id: generatedId,
      status: 'draft'
    };
    try {
      const response = await ProductService.createProduct(draftData);
      if (response?.success && response?.data) {
        setNewProduct(response.data);
        showNotification(`Draft created with ID: ${response.data.id}`);
        return response.data;
      } else {
        showNotification('Failed to create draft product', 'error');
        return null;
      }
    } catch (error) {
      handleError(error);
      return null;
    }
  }, [newProduct, showNotification, handleError]);
  // ---------------------------------------------------------------

  const handleOpenDialog = useCallback(
    async (product = null) => {
      if (!mounted.current) return;
      try {
        setError(null);
        setEnhanceLoading({ description: false, category: false, price: false });
        setIsTyping(false);

        if (product && product.id) {
          setIsEditing(true);
          // Asignamos inmediatamente el producto recibido para que newProduct tenga ya la categoría
          setNewProduct(product);
          const productDetails = await loadProductDetails(product.id);
          if (productDetails && mounted.current) {
            setNewProduct(productDetails);
            setImagePreview(productDetails.imageUrl || null);
          }
        } else {
          setIsEditing(false);
          setNewProduct({
            name: '',
            description: '',
            category: '',
            price: '',
            stock: '',
            customFields: {},
            imageUrl: ''
          });
          setSelectedImage(null);
          setImagePreview(null);
        }

        if (mounted.current) {
          setOpenDialog(true);
        }
      } catch (error) {
        handleError(error);
      }
    },
    [loadProductDetails, handleError]
  );

  const handleCloseDialog = useCallback(() => {
    if (mounted.current) {
      setOpenDialog(false);
      setNewProduct({
        name: '',
        description: '',
        category: '',
        price: '',
        stock: '',
        customFields: {},
        imageUrl: ''
      });
      setError(null);
      setEnhanceLoading({ description: false, category: false, price: false });
      setIsTyping(false);
      setSelectedImage(null);
      setImagePreview(null);
      setPreselectedStep(null); // Limpiar el paso preseleccionado
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    }
  }, []);

  const handleAddProduct = useCallback(async () => {
    if (!mounted.current) return;
    try {
      setLoading(true);
      setError(null);
      let imageUrl = newProduct.imageUrl || '';
      if (selectedImage) {
        imageUrl = await ProductService.uploadProductImage(selectedImage);
      }
      const formattedProduct = {
        ...ProductService.formatProductData(newProduct),
        imageUrl
      };
      let response;
      if (newProduct.id) {
        response = await ProductService.updateProduct(newProduct.id, formattedProduct);
      } else {
        response = await ProductService.createProduct(formattedProduct);
      }
      if (!response?.data) {
        throw new Error('Invalid server response');
      }
      updateProductList({ ...response.data, imageUrl });
      ProductService.clearCache();
      handleCloseDialog();
      if (newProduct.id) {
        if (typeof onProductUpdate === 'function') {
          onProductUpdate(response.data);
        }
        showNotification('Product updated successfully');
      } else {
        if (typeof onProductCreate === 'function') {
          onProductCreate(response.data);
        }
        showNotification('Product created successfully');
      }
    } catch (error) {
      handleError(error);
    } finally {
      if (mounted.current) {
        setLoading(false);
      }
    }
  }, [
    newProduct,
    selectedImage,
    onProductUpdate,
    onProductCreate,
    handleCloseDialog,
    showNotification,
    handleError,
    updateProductList
  ]);

  const handleEnhanceDescription = async () => {
    if (isTyping) return;
    if (!canUseAI('description')) return;
    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;
      setEnhanceLoading((prev) => ({ ...prev, description: true }));
      const response = await ProductService.enhanceProduct(product.id, 'description');
      if (response.success && response.data.field === 'description') {
        setNewProduct((prev) => ({
          ...prev,
          description: response.data.value
        }));
        showNotification('Description has been enhanced by AGT');
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to enhance description', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, description: false }));
    }
  };

  const handleEnhanceCategory = async () => {
    if (!canUseAI('category')) return;
    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;
      setEnhanceLoading((prev) => ({ ...prev, category: true }));
      const response = await ProductService.enhanceProduct(product.id, 'category');
      if (response.success && response.data.field === 'category') {
        const suggestedCategory = response.data.value;
        const validCategory = defaultCategories.find(
          (cat) => cat.id === suggestedCategory
        );
        if (!validCategory) {
          throw new Error('Invalid category suggestion');
        }
        setNewProduct((prev) => ({
          ...prev,
          category: suggestedCategory
        }));
        showNotification(`Category set to: ${validCategory.name}`);
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to suggest category', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, category: false }));
    }
  };

  const handleEnhancePrice = async () => {
    if (!canUseAI('price')) return;
    try {
      const product = await ensureProductInDB();
      if (!product?.id) return;
      setEnhanceLoading((prev) => ({ ...prev, price: true }));
      const response = await ProductService.enhanceProduct(product.id, 'price');
      if (response.success && response.data.field === 'price') {
        const suggestedPrice = parseFloat(response.data.value);
        if (isNaN(suggestedPrice) || suggestedPrice < 0) {
          throw new Error('Invalid price suggestion');
        }
        setNewProduct((prev) => ({
          ...prev,
          price: suggestedPrice
        }));
        showNotification(`Price set to: $${suggestedPrice.toFixed(2)}`);
      }
    } catch (error) {
      if (error.name === 'CooldownError') {
        showNotification(error.message, 'warning');
      } else {
        showNotification(error.message || 'Failed to suggest price', 'error');
      }
    } finally {
      setEnhanceLoading((prev) => ({ ...prev, price: false }));
    }
  };

  const handleGetMarketingTips = async () => {
    const product = await ensureProductInDB();
    if (!product?.id) {
      showNotification('Save the product first to get marketing tips', 'info');
      return;
    }
    try {
      setLoading(true);
      const response = await ProductService.enhanceProduct(product.id, 'marketing');
      if (response.success && response.data.field === 'marketingTips') {
        setMarketingTips(response.data.value);
        setShowMarketingDialog(true);
      }
    } catch (error) {
      showNotification(error.message || 'Failed to get marketing tips', 'error');
    } finally {
      setLoading(false);
    }
  };

  const autoAddRef = useRef(false);
  useEffect(() => {
    if (autoAdd && !autoAddRef.current) {
      handleOpenDialog(null);
      autoAddRef.current = true;
    }
  }, [autoAdd, handleOpenDialog]);

  // Función para abrir el modal de edición directamente en el paso "stock"
  const handleStockModal = (product) => {
    setPreselectedStep('stock');
    handleOpenDialog(product);
  };

  // ------------------------------
  // Calcular los steps para el grid de edición (ProductEditGrid)
  // El step de "stock" se añade solo si la categoría es "physical" o "food"
  const computedProductSteps = useMemo(() => {
    const steps = {
      name: {
        title: 'Product Name',
        description: 'Edit the name of your product',
        field: 'name',
        component: ({ value, onChange }) => (
          <TextField
            fullWidth
            label="Product Name"
            name="name"
            value={value}
            onChange={onChange}
            required
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': { borderRadius: '28px' }
            }}
          />
        )
      },
      category: {
        title: 'Category',
        description: 'Change product category',
        field: 'category',
        component: ({ value, onChange, onEnhance, isLoading }) => (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={value}
                onChange={onChange}
                sx={{ borderRadius: '28px' }}
              >
                {defaultCategories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(cat.icon, {
                        size: 16,
                        style: { color: cat.color }
                      })}
                      {cat.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={onEnhance}
                isLoading={isLoading}
                label="Let AGT Decide"
                tooltip="Have AI suggest the best category"
              />
            </Box>
          </Box>
        )
      },
      description: {
        title: 'Description',
        description: 'Modify product description',
        field: 'description',
        component: ({ value, onChange, onEnhance, isLoading }) => (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              name="description"
              value={value}
              onChange={onChange}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={onEnhance}
                isLoading={isLoading}
                label="Enhance with AGT"
                tooltip="Automatically enhance the product description using AI"
              />
            </Box>
          </Box>
        )
      },
      price: {
        title: 'Price',
        description: 'Update product price',
        field: 'price',
        component: ({ value, onChange, onEnhance, isLoading }) => (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={value}
              onChange={onChange}
              InputProps={{
                startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={onEnhance}
                isLoading={isLoading}
                label="Let AGT Decide"
                tooltip="Have AI suggest a suitable price"
              />
            </Box>
          </Box>
        )
      },
      image: {
        title: 'Image',
        description: 'Change product image',
        field: 'imageUrl',
        component: ({ imagePreview, onImageUpload, onSelectGalleryImage }) => (
          <Box sx={{ mt: 2 }}>
            {/* Upload Section */}
            <Box
              sx={{
                border: '2px dashed',
                borderColor: 'rgba(0,0,0,0.1)',
                borderRadius: '12px',
                padding: '24px',
                textAlign: 'center',
                mb: 4
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                Upload Your Image
              </Typography>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={onImageUpload}
                style={{ display: 'none' }}
                id="product-image-upload"
              />
              <label htmlFor="product-image-upload">
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={imagePreview ? <Edit /> : <Upload />}
                  sx={{ borderRadius: '28px', mb: 2 }}
                >
                  {imagePreview ? 'Change Image' : 'Upload Image'}
                </Button>
              </label>
              {imagePreview && (
                <Box
                  sx={{
                    mt: 2,
                    position: 'relative',
                    maxWidth: '200px',
                    margin: '0 auto'
                  }}
                >
                  <img
                    src={imagePreview}
                    alt="Product Preview"
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '8px'
                    }}
                  />
                </Box>
              )}
            </Box>

            {/* Gallery Section */}
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}
              >
                Or choose from DirectiveAI gallery
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                  gap: 2
                }}
              >
                {galleryImages.map((url, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      aspectRatio: '1',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.05)'
                      }
                    }}
                    onClick={() => onSelectGalleryImage(url)}
                  >
                    <img
                      src={url}
                      alt={`Gallery ${index}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )
      },
      marketing: {
        title: 'Marketing',
        description: 'Get marketing suggestions',
        field: 'marketingTips',
        component: ({ onGetMarketingTips, loading }) => (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
              Let AGT analyze your product and provide tailored marketing suggestions
            </Typography>
            <AGTEnhanceButton
              onEnhance={onGetMarketingTips}
              isLoading={loading}
              label="Get Marketing Tips"
              tooltip="Generate marketing tips for this product"
              sx={{ minWidth: '200px' }}
            />
          </Box>
        )
      }
    };
    // Agregamos el step "stock" solo si la categoría es "physical" o "food"
    if (
      newProduct.category &&
      ['physical', 'food'].includes(newProduct.category.toLowerCase())
    ) {
      steps.stock = {
        title: 'Stock',
        description: 'Edit the available stock',
        field: 'stock',
        component: ({ value, onChange }) => (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Stock"
              name="stock"
              type="number"
              value={value}
              onChange={onChange}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ mt: 1, display: 'block' }}
            >
              Your stock is automatically updated with your sales. Use this module to correct stock errors, simulate stock, or add stock.
            </Typography>
          </Box>
        )
      };
    }       
    return steps;
  }, [newProduct.category]);

  // ------------------------------
  return (
    <StyledContainer>
      {/* Notificaciones de error global */}
      <Box sx={{ marginBottom: 4 }}>
        {error && (
          <Alert
            severity={error.name === 'CooldownError' ? 'warning' : 'error'}
            onClose={() => setError(null)}
            icon={
              error.name === 'CooldownError' ? (
                <AlertCircle size={20} color="#FFFFFF" />
              ) : undefined
            }
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => setError(null)}
                sx={{ color: '#FFFFFF' }}
              >
                {error.name === 'CooldownError' ? 'DISMISS' : 'CLOSE'}
              </Button>
            }
            sx={{
              marginBottom: 2,
              backgroundColor:
                error.name === 'CooldownError'
                  ? 'transparent'
                  : 'rgba(255,0,0,0.1)',
              color: '#FFFFFF',
              border: 'none',
              '& .MuiAlert-icon': {
                color: '#FFFFFF'
              },
              '& .MuiAlert-message': {
                color: '#FFFFFF'
              }
            }}
          >
            {error.message}
          </Alert>
        )}

        {/* Search, Filtros, Botones */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <StyledSearchBar
              fullWidth
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter'}
              InputProps={{
                startAdornment: (
                  <Search
                    size={20}
                    style={{
                      color: navColors.textPlaceholder,
                      marginRight: 8,
                      borderRadius: '28px'
                    }}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={12} sm="auto">
            <FormControl sx={{ minWidth: 120, width: '100%' }}>
              <StyledSelect
                variant="outlined"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                displayEmpty
                fullWidth
                MenuProps={{
                  PaperProps: {
                    sx: {
                      borderRadius: '12px'
                    }
                  }
                }}
              >
                <StyledMenuItem value="">All Categories</StyledMenuItem>
                {defaultCategories.map((category) => (
                  <StyledMenuItem key={category.id} value={category.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(category.icon, {
                        size: 16,
                        style: { color: category.color }
                      })}
                      {category.name}
                    </Box>
                  </StyledMenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm="auto">
            <StyledActionButton
              onClick={() => handleOpenDialog(null)}
              startIcon={<Plus size={20} />}
              disabled={loading}
            >
              Add Product
            </StyledActionButton>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Tooltip title="Refresh">
              <IconButton
                onClick={handleRefresh}
                disabled={loading}
                sx={{ color: navColors.iconColor }}
              >
                <RefreshCw size={20} className={loading ? 'animate-spin' : ''} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {/* Grid de productos */}
      <Grid container spacing={2}>
        {contextLoading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 4,
                color: '#FFFFFF'
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </Grid>
        ) : filteredProducts.length === 0 ? (
          <Grid item xs={12}>
            <Box
              sx={{
                p: 4,
                textAlign: 'center',
                backgroundColor: 'rgba(255,255,255,0.1)',
                borderRadius: '16px',
                color: '#FFFFFF'
              }}
            >
              <AlertCircle size={48} style={{ marginBottom: '16px', opacity: 0.7 }} />
              <Typography variant="h6" gutterBottom>
                No products found
              </Typography>
              <Typography variant="body2" sx={{ opacity: 0.7 }}>
                {searchQuery
                  ? 'No products match your search'
                  : 'Get started by adding your first product'}
              </Typography>
            </Box>
          </Grid>
        ) : (
          filteredProducts.map((product) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
              <ProductCard
                bgcolor={
                  defaultCategories.find((c) => c.id === product.category)?.color || '#666'
                }
                imageUrl={product.imageUrl}
              >
                {/* HEADER: Ícono de categoría y stock */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                    right: 16,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {React.createElement(
                      defaultCategories.find((c) => c.id === product.category)?.icon || Package,
                      { size: 24, color: '#FFFFFF' }
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      backgroundColor: 'rgba(255,255,255,0.2)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {(['physical', 'food'].includes(product.category))
                      ? (
                        <Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: 600 }}>
                          {product.stock}
                        </Typography>
                      )
                      : (
                        <Typography variant="subtitle1" sx={{ color: '#FFFFFF', fontWeight: 600 }}>
                          ∞
                        </Typography>
                      )
                    }
                  </Box>
                </Box>

                {/* Overlay for out of stock */}
                {(['physical', 'food'].includes(product.category) && parseInt(product.stock, 10) === 0) && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      zIndex: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 2
                    }}
                  >
                    <Typography variant="h6" sx={{ color: '#fff', mb: 2, textAlign: 'center' }}>
                      Out of Stock
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleStockModal(product);
                      }}
                      sx={{
                        borderRadius: '28px',
                        textTransform: 'none',
                        backgroundColor: '#0385FF',
                        color: '#FFFFFF', // Asegura que el texto sea blanco en ambos modos
                        '&:hover': { backgroundColor: '#0385FF' }
                      }}
                    >
                      Add Stock
                    </Button>
                  </Box>
                )}

                {/* FOOTER: Título, Descripción, Precio y botón MoreVertical */}
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 16,
                    left: 16,
                    right: 16,
                    color: '#FFFFFF'
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        {product.name}
                      </Typography>
                      <Typography variant="body2" sx={{ opacity: 0.8 }}>
                        {product.description
                          ? product.description.length > 50
                            ? product.description.substring(0, 50) + '...'
                            : product.description
                          : 'No description available'}
                      </Typography>
                      <Typography variant="subtitle2">
                        ${typeof product.price === 'number' ? product.price.toFixed(2) : '0.00'}
                      </Typography>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={(e) => handleOpenMenu(e, product)}
                      disabled={loadingStates[product.id]}
                      sx={{ color: '#FFFFFF' }}
                    >
                      {loadingStates[product.id] ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <MoreVertical size={20} />
                      )}
                    </IconButton>
                  </Box>
                </Box>
              </ProductCard>
            </Grid>
          ))
        )}
      </Grid>

      {/* Renderizado condicional del diálogo según el modo (edición o creación) */}
      {isEditing ? (
        <ProductEditGrid
          open={openDialog}
          onClose={handleCloseDialog}
          productData={newProduct}
          steps={computedProductSteps}
          onSave={async (updatedProduct) => {
            try {
              setLoading(true);
              setError(null);
              let imageUrl = updatedProduct.imageUrl || '';

              if (selectedImage) {
                imageUrl = await ProductService.uploadProductImage(selectedImage);
              }

              const formattedProduct = {
                ...ProductService.formatProductData(updatedProduct),
                imageUrl
              };

              const response = await ProductService.updateProduct(
                updatedProduct.id,
                formattedProduct
              );

              if (!response?.data) {
                throw new Error('Invalid server response');
              }

              updateProductList({ ...response.data, imageUrl });
              ProductService.clearCache();

              showNotification('Product updated successfully');
              if (typeof onProductUpdate === 'function') {
                onProductUpdate(response.data);
              }

              handleCloseDialog();
            } catch (error) {
              handleError(error);
            } finally {
              setLoading(false);
            }
          }}
          enhanceLoading={enhanceLoading}
          onEnhanceDescription={handleEnhanceDescription}
          onEnhanceCategory={handleEnhanceCategory}
          onEnhancePrice={handleEnhancePrice}
          onGetMarketingTips={handleGetMarketingTips}
          imagePreview={imagePreview}
          onImageUpload={handleImageChange}
          onSelectGalleryImage={(url) => {
            setImagePreview(url);
            setSelectedImage(null);
          }}
          initialStep={preselectedStep}
        />
      ) : (
        <ProductSetupWizard
          open={openDialog}
          onClose={handleCloseDialog}
          productData={newProduct}
          onChange={(e) => {
            const { name, value } = e.target;
            setNewProduct((prev) => ({
              ...prev,
              [name]: value
            }));
          }}
          onSubmit={handleAddProduct}
          onImageUpload={handleImageChange}
          imagePreview={imagePreview}
          enhanceLoading={enhanceLoading}
          onEnhanceDescription={handleEnhanceDescription}
          onEnhanceCategory={handleEnhanceCategory}
          onEnhancePrice={handleEnhancePrice}
          onGetMarketingTips={handleGetMarketingTips}
          loading={loading}
          isTyping={isTyping}
          onSelectGalleryImage={(url) => {
            setImagePreview(url);
            setSelectedImage(null);
            setNewProduct((prev) => ({
              ...prev,
              imageUrl: url  // Actualizamos el campo imageUrl en el estado del producto
            }));
          }}
        />
      )}

      {/* Menú contextual (Editar/Borrar) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            backgroundColor: navigationColors(theme).background,
            color: navigationColors(theme).textDefault,
            borderRadius: '12px'
          }
        }}
      >
        <StyledMenuItem
          onClick={() => {
            handleOpenDialog(productToDelete);
            handleCloseMenu();
          }}
        >
          <Edit size={16} style={{ marginRight: 8 }} />
          Edit Product
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            if (productToDelete) {
              handleDeleteClick(productToDelete);
            }
          }}
          sx={{ color: '#ff4444' }}
        >
          <Trash2 size={16} style={{ marginRight: 8 }} />
          Delete Product
        </StyledMenuItem>
      </Menu>

      {/* Diálogo para marketing tips */}
      <StyledDialog
        open={showMarketingDialog}
        onClose={() => setShowMarketingDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ color: navigationColors(theme).textDefault }}>
          Marketing Tips
        </DialogTitle>
        <DialogContent>
          {marketingTips ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {/* Target Audience */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navigationColors(theme).textDefault, mb: 1, fontWeight: 600 }}
                >
                  Target Audience
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {marketingTips.targetAudience.map((audience, index) => (
                    <Chip
                      key={index}
                      label={audience}
                      sx={{
                        backgroundColor: 'rgba(3, 133, 255, 0.1)',
                        color: '#0385FF',
                        borderRadius: '16px'
                      }}
                    />
                  ))}
                </Box>
              </Box>

              {/* Selling Points */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navigationColors(theme).textDefault, mb: 1, fontWeight: 600 }}
                >
                  Key Selling Points
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.sellingPoints.map((point, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 2
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: '24px',
                          height: '24px',
                          borderRadius: '12px',
                          backgroundColor: 'rgba(3, 133, 255, 0.1)',
                          color: '#0385FF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 600,
                          fontSize: '14px'
                        }}
                      >
                        {index + 1}
                      </Box>
                      <Typography sx={{ color: navigationColors(theme).textDefault }}>
                        {point}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Marketing Channels */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navigationColors(theme).textDefault, mb: 1, fontWeight: 600 }}
                >
                  Recommended Channels
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.channels.map((channel, index) => (
                    <Typography
                      key={index}
                      sx={{
                        color: navigationColors(theme).textDefault,
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        padding: '8px 16px',
                        borderRadius: '8px'
                      }}
                    >
                      {channel}
                    </Typography>
                  ))}
                </Box>
              </Box>

              {/* Keywords */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navigationColors(theme).textDefault, mb: 1, fontWeight: 600 }}
                >
                  Suggested Keywords
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {marketingTips.keywords.map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword}
                      sx={{
                        backgroundColor: 'rgba(29, 185, 84, 0.1)',
                        color: '#1DB954',
                        borderRadius: '16px'
                      }}
                    />
                  ))}
                </Box>
              </Box>

              {/* Promotional Ideas */}
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: navigationColors(theme).textDefault, mb: 1, fontWeight: 600 }}
                >
                  Promotional Ideas
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {marketingTips.promotionalIdeas.map((idea, index) => (
                    <Box
                      key={index}
                      sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                    >
                      <Sparkles size={16} color="#1DB954" />
                      <Typography sx={{ color: navigationColors(theme).textDefault }}>
                        {idea}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography sx={{ color: navigationColors(theme).textDefault }}>
              No tips available.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <StyledActionButton onClick={() => setShowMarketingDialog(false)}>
            Close
          </StyledActionButton>
        </DialogActions>
      </StyledDialog>

      {/* Diálogo de confirmación para borrar */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          sx: {
            borderRadius: '24px'
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this product?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              textTransform: 'capitalize',
              borderRadius: '16px'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteProduct}
            color="error"
            sx={{
              textTransform: 'capitalize',
              borderRadius: '16px'
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
};

export default ProductsManager;
