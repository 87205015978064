// D:\directiveai-react\frontend\src\components\AGTUps\services\AGTUps-product-services\config.js

import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Verificar si estamos en el navegador
const isClient = typeof window !== 'undefined';

const API_CONFIG = {
  BASE_URL: process.env.NODE_ENV === 'development' 
    ? `${process.env.REACT_APP_API_URL}/api/agtups`
    : `${process.env.REACT_APP_PROD_UPS_API_URL}/api/agtups`,

  ENDPOINTS: {
    PRODUCTS: '/products',
    MARKET: '/market',
    PRICING: '/pricing',
    SALES: '/sales',
    PROFILE: '/profile',
    FOUND: '/found'
  },

  createAxiosInstance: () => {
    const instance = axios.create({
      baseURL: API_CONFIG.BASE_URL,
      timeout: 120000,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    // Interceptor para agregar el token a cada request
    instance.interceptors.request.use(
      async (config) => {
        if (isClient) {
          const token = sessionStorage.getItem('authToken');
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Interceptor para manejar errores de autenticación
    instance.interceptors.response.use(
      response => response,
      async (error) => {
        if (error.response?.status === 401 && isClient) {
          // Si el token expiró, intentar renovarlo
          try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
              const newToken = await user.getIdToken(true);
              sessionStorage.setItem('authToken', newToken);
              error.config.headers.Authorization = `Bearer ${newToken}`;
              return axios(error.config);
            }
          } catch (refreshError) {
            // Si no se puede renovar el token, redirigir al login
            if (isClient) {
              sessionStorage.removeItem('authToken');
              window.location.href = '/login';
            }
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  },

  // Utilidad para manejar el almacenamiento del token de forma segura
  tokenStorage: {
    setToken: (token) => {
      if (isClient) {
        sessionStorage.setItem('authToken', token);
      }
    },
    
    getToken: () => {
      if (isClient) {
        return sessionStorage.getItem('authToken');
      }
      return null;
    },
    
    removeToken: () => {
      if (isClient) {
        sessionStorage.removeItem('authToken');
      }
    }
  },

  ERROR_HANDLERS: {
    formatErrorMessage: (error) => {
      if (error?.response?.data?.error) {
        return error.response.data.error;
      }
      return error.message || 'An unexpected error occurred';
    }
  }
};

// Crear una instancia por defecto
const api = API_CONFIG.createAxiosInstance();

export { api };
export default API_CONFIG;