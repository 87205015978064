/** D:\directiveai-react\frontend\src\components\services\AGTService.js */

import axios from 'axios';

/**
 * Según tu lógica actual, se define la URL base dependiendo del entorno.
 */
const AGT_API_URL = process.env.NODE_ENV === 'production'
  ? 'https://lkoitc8uk3.execute-api.us-east-2.amazonaws.com/prod'
  : 'http://localhost:5000';

/**
 * Asumimos un timeout estándar de 5 minutos (300000 ms)
 */
const STANDARD_TIMEOUT = 300000; // 5 minutos en milisegundos

/**
 * Creamos la instancia de axios con la URL base y el timeout definido.
 * `withCredentials: true` para permitir cookies o tokens de sesión.
 */
const api = axios.create({
  baseURL: AGT_API_URL,
  timeout: STANDARD_TIMEOUT,
  withCredentials: true
});

/**
 * Interceptor para manejar errores globalmente. Aquí podrías filtrar
 * ciertos códigos de error (por ej. 429).
 */
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 429) {
        // <--- Marcarlo
        error.is429 = true;
      }
    }
    return Promise.reject(error);
  }
);

/**
 * Definimos los tipos de fondo válidos para las preferencias del usuario.
 */
const VALID_BACKGROUND_TYPES = ['system', 'light', 'dark'];

/**
 * Función auxiliar para convertir Blob a Base64.
 */
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const uploadPdfToS3 = async (pdfData, userId) => {
  try {
    // Convert PDF to base64 if needed
    const pdfBase64 = pdfData.data instanceof Blob
      ? await blobToBase64(pdfData.data)
      : pdfData.data;

    const payload = {
      pdf: pdfBase64,
      userId,
      fileName: pdfData.name,
      projectId: pdfData.projectId
    };

    const response = await api.post('/api/pdfs/upload', payload);

    if (!response.data || !response.data.success) {
      throw new Error('Failed to upload PDF');
    }

    // Return object with correct structure including s3Key
    return {
      s3Key: response.data.key, // <-- IMPORTANTE: s3Key
      s3Url: response.data.url,
      bucket: response.data.bucket,
      originalName: pdfData.name,
      type: pdfData.type,
      extractedText: response.data.extractedText
    };
  } catch (error) {
    throw new Error('Failed to upload PDF');
  }
};

/* =========================================================================
   RESTO DE SERVICIOS
   ========================================================================= */

/**
 * Obtiene la lista de chats del usuario.
 * @param {string} userId
 * @param {object} options
 * @returns {object} { chats, hasMore, lastEvaluatedKey }
 */
export const getChats = async (userId, options = {}) => {
  const { 
    limit = 20, 
    lastEvaluatedKey = null, 
    projectId = null,
    searchTerm = null,    // <- Nuevo parámetro
    category = null       // <- Nuevo parámetro
  } = options;

  try {
    const params = new URLSearchParams({
      limit: limit.toString()
    });

    if (lastEvaluatedKey) {
      params.append('lastEvaluatedKey', JSON.stringify(lastEvaluatedKey));
    }

    if (projectId) {
      params.append('projectId', projectId);
    }

    // Añadir searchTerm a los query params si existe
    if (searchTerm) {
      params.append('search', searchTerm.trim());
    }

    // Añadir category a los query params si existe
    if (category) {
      params.append('category', category);
    }

    const response = await api.get(`/api/chats/${userId}?${params}`);

    const chats = Array.isArray(response.data.chats)
      ? response.data.chats
          .filter(chat => chat && chat.id)
          .map(chat => ({
            ...chat,
            title: chat.title || chat.name || "New chat",
            updatedAt: chat.updatedAt || chat.createdAt,
            category: chat.category || 'personal',
            projectId: chat.projectId || null
          }))
      : [];

    return {
      chats,
      hasMore: response.data.hasMore,
      lastEvaluatedKey: response.data.lastEvaluatedKey,
      total: response.data.total // <- Nuevo: total de chats que coinciden con la búsqueda
    };
  } catch (error) {
    console.error('Error in getChats:', error);
    return {
      chats: [],
      hasMore: false,
      lastEvaluatedKey: null,
      total: 0
    };
  }
};

/**
 * Obtiene los mensajes de un chat.
 * @param {string} userId
 * @param {string} chatId
 * @returns {Array} messages
 */
export const getChatMessages = async (userId, chatId) => {
  try {
    if (!chatId) {
      return [];
    }
    const response = await api.get(`/api/chat-messages/${userId}/${chatId}`);
    const messages = response.data.messages || [];
    return messages;
  } catch (error) {
    return [];
  }
};

/**
 * Obtiene las categorías personalizadas del usuario.
 * @param {string} userId
 * @returns {Array} customCategories
 */
export const getUserCategories = async (userId) => {
  try {
    const response = await api.get(`/api/user-categories/${userId}?uid=${userId}`);
    const customCategories = response.data.customCategories || [];
    return customCategories;
  } catch (error) {
    return [];
  }
};

/**
 * Obtiene los chats asociados a un proyecto específico.
 * @param {string} userId
 * @param {string} projectId
 * @returns {Array} chats
 */
export const getProjectChats = async (userId, projectId) => {
  try {
    const response = await api.get(`/api/projects/${projectId}/chats?userId=${userId}`);
    return {
      chats: response.data.chats,
      metadata: response.data.metadata
    };
  } catch (error) {
    return {
      chats: [],
      metadata: { totalChats: 0 }
    };
  }
};

/**
 * Crea una categoría personalizada.
 * @param {string} userId
 * @param {string} categoryName
 * @param {string} iconName
 * @returns {Array} Lista actualizada de customCategories
 */
export const createUserCategory = async (userId, categoryName, iconName) => {
  try {
    const payload = { userId, categoryName, iconName };
    const response = await api.post('/api/user-categories', payload);
    return response.data.customCategories || [];
  } catch (error) {
    throw error;
  }
};

/**
 * Elimina una categoría personalizada y reasigna los chats a otra categoría.
 * @param {string} userId
 * @param {string} categoryName
 * @param {string} reassignTo
 * @returns {Array} Lista actualizada de customCategories
 */
export const deleteUserCategory = async (userId, categoryName, reassignTo) => {
  try {
    const payload = { userId, categoryName, reassignTo };
    const response = await api.delete('/api/user-categories', { data: payload });
    return response.data.customCategories || [];
  } catch (error) {
    throw error;
  }
};

/* =========================================================================
   ENVÍO DE MENSAJE PRINCIPAL (texto, audio, imágenes, PDFs)
   ========================================================================= */

/**
 * Envía un mensaje al backend de AGT, que a su vez invoca Anthropics/OpenAI
 * dependiendo de la configuración (modelo, etc.).
 * @param {object|string} message  - El mensaje o su contenido
 * @param {string} userId          - ID del usuario
 * @param {string} chatId          - ID del chat
 * @param {object} context         - Contexto conversacional
 * @param {string} selectedModel   - Modelo (Claude, etc.)
 * @param {string} personality     - Personalidad
 * @param {string} userName        - Nombre de usuario
 * @param {string} selectedVoice   - Voz TTS (por defecto "alloy")
 * @returns {object} { reply, audioBlob, updatedContext, title, chatId, selectedVoice }
 */
export const sendMessageToAGT = async (
  message,
  userId,
  chatId,
  context,
  selectedModel,
  personality = 'normal',
  userName = '',
  selectedVoice = 'alloy'
) => {
  try {
    let processedMessage = message;

    // ----------------------------------------------------------------------
    // Caso: Mensaje es un objeto con archivos (images / PDFs / etc.)
    // ----------------------------------------------------------------------
    if (typeof message === 'object' && message.files && message.files.length > 0) {
      const processedFiles = await Promise.all(
        message.files.map(async (file) => {
          if (file.type.startsWith('image/')) {
            try {
              const uploadResult = await uploadImageToS3(file, userId);
              return {
                s3Key: uploadResult.key,
                s3Url: uploadResult.url,
                bucket: uploadResult.bucket,
                originalName: uploadResult.originalName,
                type: file.type,
                data: file.data,
                name: file.name,
                source: file.source || message.source,
                captureType: message.captureType
              };
            } catch (error) {
              return {
                type: file.type,
                data: file.data,
                name: file.name,
                source: file.source || message.source,
                captureType: message.captureType
              };
            }
          } else if (file.type === 'application/pdf'
            || file.type === 'application/msword'
            || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            || file.type === 'application/vnd.ms-excel'
            || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            || file.type === 'text/csv'
            || file.type === 'text/plain'
          ) {
            try {
              const pdfUploadResult = await uploadPdfToS3(file, userId);
              return {
                s3Key: pdfUploadResult.s3Key,
                pdfText: pdfUploadResult.extractedText || '',
                pdfUrl: pdfUploadResult.url,
                bucket: pdfUploadResult.bucket,
                originalName: pdfUploadResult.originalName,
                type: file.type,
                data: file.data,
                name: file.name,
                source: file.source || message.source,
                captureType: message.captureType
              };
            } catch (error) {
              return {
                type: file.type,
                data: file.data,
                name: file.name,
                source: file.source || message.source,
                captureType: message.captureType,
                pdfError: true
              };
            }
          } else {
            return file;
          }
        })
      );

      processedMessage = {
        text: message.text || '',
        files: processedFiles.map(file => ({
          type: file.type,
          data: file.data,
          name: file.name,
          source: file.source || message.source,
          captureType: message.captureType
        })),
        storage: processedFiles.map(file => ({
          s3Key: file.s3Key,
          s3Url: file.s3Url,
          bucket: file.bucket,
          originalName: file.originalName,
          type: file.type
        })),
        pdfs: processedFiles
          .filter(f =>
            f.type === 'application/pdf' ||
            f.type === 'application/msword' ||
            f.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            f.type === 'application/vnd.ms-excel' ||
            f.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            f.type === 'text/csv' ||
            f.type === 'text/plain'
          )
          .map(f => ({
            pdfText: f.pdfText,
            pdfUrl: f.pdfUrl,
            name: f.name,
            originalName: f.originalName
          })),
        voicePreference: selectedVoice,
        source: message.source,
        captureType: message.captureType,
        metadata: {
          source: message.source,
          captureType: message.captureType,
          deviceInfo: 'web',
          timestamp: new Date().toISOString()
        }
      };
    }
    // ----------------------------------------------------------------------
    // Caso: Mensaje es un objeto con type: 'audio_message'
    // ----------------------------------------------------------------------
    else if (typeof message === 'object') {
      if (message.type === 'audio_message' || (message.text && message.text.type === 'audio_message')) {
        const audioData = message.type === 'audio_message' ? message : message.text;
        const audioResponse = await uploadAudioFile(audioData.audioBlob, userId);

        processedMessage = {
          type: 'audio_message',
          text: audioData.text,
          audioUrl: audioResponse.audioUrl,
          duration: audioData.duration,
          audioReference: audioResponse.audioReference,
          timestamp: new Date().toISOString(),
          voicePreference: selectedVoice,
          source: message.source,
          captureType: message.captureType,
          metadata: {
            source: message.source,
            captureType: message.captureType,
            deviceInfo: 'web',
            timestamp: new Date().toISOString()
          }
        };
      } else if (message.text) {
        processedMessage = {
          text: message.text,
          voicePreference: selectedVoice,
          source: message.source,
          captureType: message.captureType,
          metadata: {
            source: message.source,
            captureType: message.captureType,
            deviceInfo: 'web',
            timestamp: new Date().toISOString()
          }
        };
      }
    }
    // ----------------------------------------------------------------------
    // Caso: Mensaje es un string a secas
    // ----------------------------------------------------------------------
    else if (typeof message === 'string') {
      processedMessage = {
        text: processedMessage,
        voicePreference: selectedVoice,
        source: message.source,
        captureType: message.captureType,
        metadata: {
          source: message.source,
          captureType: message.captureType,
          deviceInfo: 'web',
          timestamp: new Date().toISOString()
        }
      };
    }

    const payload = {
      message: processedMessage,
      userId,
      uid: userId,
      chatId,
      context,
      model: selectedModel,
      personality,
      userName,
      voicePreference: selectedVoice,
      source: message.source,
      projectId: message.projectId
    };

    const response = await api.post('/api/chat', payload);

    return {
      reply: response.data.reply,
      audioBlob: response.data.audioBlob,
      updatedContext: response.data.updatedContext,
      ultraThoughts: response.data.ultraThoughts || [],
      title: response.data.title,
      chatId: response.data.chatId || (response.data.chat && response.data.chat.id),
      selectedVoice
    };
  } catch (error) {
    if (error.is429) {
      // 1) Retornamos un objeto "controlado"
      return {
        isRateLimit: true,
        message: error.response.data?.message || 'Has alcanzado el límite de mensajes por hora',
        resetIn: error.response.data?.resetIn || 3600
      };
    }
    // Si NO es 429, se hace throw
    throw error;
  }
};

/**
 * Transcribe audio
 */
export const transcribeAudio = async (audioData) => {
  try {
    const response = await api.post(
      '/api/transcribe',
      {
        audio: audioData
      },
      {
        timeout: 60000,
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.data || !response.data.transcription) {
      throw new Error('No transcription data received');
    }

    return response.data.transcription;
  } catch (error) {
    if (error.response?.status === 413) {
      throw new Error(
        'El archivo de audio es demasiado grande. Por favor, intenta con una grabación más corta.'
      );
    } else if (error.code === 'ECONNABORTED') {
      throw new Error(
        'La transcripción está tomando más tiempo de lo esperado. Por favor, intenta de nuevo.'
      );
    }
    throw new Error('Error en la transcripción. Por favor, intenta de nuevo.');
  }
};

/**
 * Crea un nuevo chat con categoría dada (por defecto "personal").
 * @param {string} userId
 * @param {string} category
 * @param {string} projectId
 * @returns {object} {id, title, name, createdAt, updatedAt, category}
 */
export const createNewChat = async (userId, category = 'personal', projectId = null) => {
  try {
    const payload = {
      userId,
      uid: userId,
      category,
      projectId,
      type: projectId ? 'project' : 'regular'
    };

    const response = await api.post('/api/create-chat', payload);

    let chatData = null;

    if (response.data && response.data.id) {
      chatData = response.data;
    } else if (response.data && response.data.success && response.data.chat) {
      chatData = response.data.chat;
    } else if (response.data && response.data.chat && response.data.chat.id) {
      chatData = response.data.chat;
    }

    if (!chatData || !chatData.id) {
      throw new Error('Failed to create chat: Invalid chat data received');
    }

    return {
      id: chatData.id,
      title: chatData.title || chatData.name || "New chat",
      name: chatData.name || chatData.title || "New chat",
      createdAt: chatData.createdAt,
      updatedAt: chatData.updatedAt || chatData.createdAt,
      category: chatData.category || category,
      projectId: chatData.projectId || projectId,
      type: chatData.type || (projectId ? 'project' : 'regular')
    };
  } catch (error) {
    if (error.response) {
      throw error;
    } else {
      throw error;
    }
  }
};

/**
 * Actualiza la categoría de un chat.
 * @param {string} userId
 * @param {string} chatId
 * @param {string} newCategory
 */
export const updateChatCategory = async (userId, chatId, newCategory) => {
  try {
    const payload = {
      userId,
      uid: userId,
      chatId,
      newCategory
    };
    const response = await api.put('/api/update-chat-category', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update chat category');
  }
};

/**
 * Obtiene el contexto de un chat (historial reducido o summary).
 * @param {string} userId
 * @param {string} chatId
 * @returns {object|null} context
 */
export const getChatContext = async (userId, chatId) => {
  try {
    if (!chatId) {
      return null;
    }
    const response = await api.get(`/api/chat-context/${userId}/${chatId}`);
    return response.data.context;
  } catch (error) {
    return null;
  }
};

/**
 * Obtiene historial anónimo de un sessionId (si aplica).
 */
export const getAnonymousChatHistory = async (sessionId) => {
  try {
    const response = await api.get(`/api/anonymous-chat/${sessionId}`);
    const messages = response.data.messages || [];
    return messages;
  } catch (error) {
    return [];
  }
};

/**
 * Actualiza el título de un chat
 */
export const updateChatTitle = async (userId, chatId, newTitle) => {
  try {
    const payload = {
      userId,
      uid: userId,
      chatId,
      newTitle
    };

    const response = await api.put('/api/update-chat-title', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to update chat title');
  }
};

/**
 * Genera un resumen para un chat
 */
export const generateChatSummary = async (userId, chatId, generateSummary = false, chatTitle) => {
  try {
    const payload = {
      userId,
      uid: userId,
      chatId,
      generateSummary,
      chatTitle,
      isFinal: false
    };

    const response = await api.post('/api/generate-summary', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to generate chat summary');
  }
};

/**
 * Elimina un chat específico.
 */
export const deleteChat = async (userId, chatId) => {
  try {
    const response = await api.delete(`/api/delete-chat/${userId}/${chatId}?uid=${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete chat');
  }
};

/**
 * Envía feedback sobre un mensaje en particular (like/dislike + detalles)
 */
export const sendFeedback = async (userId, messageId, feedbackType, details, reason) => {
  if (!userId || !messageId || !feedbackType) {
    throw new Error('userId, messageId, and feedbackType are required');
  }

  try {
    const payload = {
      userId,
      uid: userId,
      messageId,
      feedbackType,
      details,
      reason
    };

    const response = await api.post('/api/feedback', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to send feedback');
  }
};

/**
 * Ajusta la preferencia de fondo de usuario (system, light o dark).
 */
export const setUserBackgroundPreference = async (userId, backgroundType) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  if (!VALID_BACKGROUND_TYPES.includes(backgroundType)) {
    backgroundType = 'system';
  }

  try {
    const payload = {
      userId,
      uid: userId,
      backgroundType
    };

    const response = await api.put('/api/user-background', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to set user background preference');
  }
};

/**
 * Obtiene la preferencia de fondo de un usuario.
 */
export const getUserBackgroundPreference = async (userId) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const response = await api.get(`/api/user-background/${userId}?uid=${userId}`);
    const backgroundType = response.data.backgroundType;

    if (!backgroundType || !VALID_BACKGROUND_TYPES.includes(backgroundType)) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }

    return backgroundType;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      await setUserBackgroundPreference(userId, 'system');
      return 'system';
    }
    return 'system';
  }
};

/**
 * Actualiza el timestamp de un chat a la hora actual.
 */
export const updateChatTimestamp = async (userId, chatId) => {
  try {
    const response = await api.put(`/api/update-chat-timestamp/${userId}/${chatId}?uid=${userId}`);
    return response.data.updatedAt;
  } catch (error) {
    throw new Error('Failed to update chat timestamp');
  }
};

/**
 * Crea una sesión de checkout para suscribirse a la versión PRO.
 */
export const createProCheckoutSession = async (userId) => {
  try {
    const payload = {
      userId,
      uid: userId,
      planType: 'pro'
    };

    const response = await api.post('/api/create-checkout-session', payload);
    return response.data.url;
  } catch (error) {
    throw new Error('Failed to create checkout session');
  }
};

/**
 * Verifica si el usuario es PRO.
 */
export const checkProStatus = async (userId) => {
  try {
    const response = await api.get(`/api/check-pro-status/${userId}?uid=${userId}`);
    return {
      isPro: response.data.isPro,
      proExpirationDate: response.data.proExpirationDate
    };
  } catch (error) {
    return { isPro: false };
  }
};

/**
 * Obtiene la preferencia de "personalidad" del usuario (normal, stoic, etc.)
 */
export const getUserPersonalityPreference = async (userId) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const response = await api.get(`/api/user-personality/${userId}?uid=${userId}`);
    const preference = response.data.personalityPreference;
    return preference;
  } catch (error) {
    return 'normal';
  }
};

/**
 * Ajusta la preferencia de personalidad del usuario.
 */
export const setUserPersonalityPreference = async (userId, personalityPreference) => {
  if (!userId) {
    throw new Error('Invalid userId');
  }

  try {
    const payload = {
      userId,
      uid: userId,
      personalityPreference
    };

    const response = await api.put('/api/user-personality', payload);
    return response.data;
  } catch (error) {
    throw new Error('Failed to set user personality preference');
  }
};

/* =========================================================================
   SUBIDA DE IMÁGENES A S3 
   ========================================================================= */
export const uploadImageToS3 = async (imageData, userId) => {
  try {
    const payload = {
      image: imageData.data,
      userId,
      fileName: imageData.name,
      contentType: imageData.type,
      projectId: imageData.projectId
    };

    const response = await api.post('/api/images/upload', payload);

    if (!response.data || !response.data.success) {
      throw new Error('Failed to upload image');
    }

    return {
      ...response.data,
      originalName: imageData.name,
      type: imageData.type
    };
  } catch (error) {
    throw new Error('Failed to upload image');
  }
};

/* =========================================================================
   SUBIDA DE AUDIO (Ejemplo) 
   ========================================================================= */
export const uploadAudioFile = async (audioBlob, userId) => {
  try {
    const base64Audio = await blobToBase64(audioBlob);

    const response = await api.post('/api/audio/upload', {
      audio: base64Audio,
      userId: userId
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to upload audio file');
  }
};

/* =========================================================================
   SÍNTESIS DE VOZ (TTS) Y PROCESAR AUDIO+TEXTO (dependiendo de tu uso)
   ========================================================================= */
export const synthesizeSpeech = async (text, options = {}) => {
  try {
    const { voice = 'alloy', model = 'tts-1' } = options;

    const response = await api.post(
      '/api/voice/synthesize',
      {
        text,
        voice,
        model
      },
      {
        responseType: 'blob'
      }
    );

    return response.data;
  } catch (error) {
    throw new Error('Failed to synthesize speech');
  }
};

export const processAudioAndText = async (text, userId) => {
  try {
    const audioBlob = await synthesizeSpeech(text);
    const message = {
      text: text.trim(),
      audioBlob: audioBlob
    };
    return message;
  } catch (error) {
    throw new Error('Failed to process audio and text');
  }
};

// Crear nuevo proyecto
export const createProject = async (userId, name, category) => {
  try {
    const response = await api.post('/api/projects', {
      userId,
      name,
      category
    });
    return response.data.project;
  } catch (error) {
    throw new Error('Failed to create project');
  }
};

// Obtener proyectos
export const getProjects = async (userId, category = null) => {
  try {
    const url = `/api/projects/${userId}${category ? `?category=${category}` : ''}`;
    const response = await api.get(url);
    return response.data.projects;
  } catch (error) {
    return [];
  }
};

// Actualizar proyecto
export const updateProject = async (userId, projectId, name) => {
  try {
    const response = await api.put(`/api/projects/${projectId}`, {
      userId,
      name
    });
    return response.data.project;
  } catch (error) {
    throw new Error('Failed to update project');
  }
};

// Eliminar proyecto
export const deleteProject = async (userId, projectId) => {
  try {
    const response = await api.delete(`/api/projects/${projectId}?userId=${userId}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to delete project');
  }
};

export const updateProjectContext = async ({
  userId,
  projectId,
  contextTitle,
  contextContent,
}) => {
  try {
    const payload = {
      userId,
      contextTitle,
      contextContent
    };

    const response = await api.put(`/api/projects/${projectId}/context`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateProjectInstructions = async ({
  userId,
  projectId,
  instructionsTitle,
  instructionsContent
}) => {
  try {
    const payload = {
      userId,
      instructionsTitle,
      instructionsContent
    };

    const response = await api.put(`/api/projects/${projectId}/instructions`, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Obtiene la lista de archivos (imágenes, PDFs, etc.) de un proyecto en S3.
 * @param {string} userId    - El ID del usuario
 * @param {string} projectId - El ID del proyecto
 * @returns {Array} Lista de archivos [{ key, url, size, lastModified, fileType }, ...]
 */
export const getProjectFiles = async (userId, projectId) => {
  try {
    // Llamamos a la ruta que creamos en el backend (server.js)
    const response = await api.get(`/api/projects/${projectId}/files?userId=${userId}`);

    // Retornamos el array de archivos
    return response.data.files || [];
  } catch (error) {
    console.error('Error fetching project files:', error);
    return [];
  }
};

export const deleteFile = async (fileType, key, userId, projectId = null) => {
  try {
    const queryParams = new URLSearchParams({
      userId,
      ...(projectId && { projectId })
    });

    const response = await api.delete(
      `/api/files/${fileType}/${encodeURIComponent(key)}?${queryParams}`
    );

    return response.data;
  } catch (error) {
    console.error('Error deleting file:', error);
    throw error;
  }
};

export const saveUserEmailToPreferences = async ({ userId, email }) => {
  try {
    // Llamamos a un endpoint en tu backend para guardar la info
    const payload = {
      userId,
      email,
    };
    const response = await api.post('/api/user-preferences', payload);
    return response.data;
  } catch (error) {
    console.error('Failed to save user preferences:', error);
    throw error;
  }
};

/* =========================================================================
   EXPORT POR DEFECTO
   ========================================================================= */
export default {
  getChats,
  getChatMessages,
  sendMessageToAGT,
  createNewChat,
  getChatContext,
  getAnonymousChatHistory,
  updateChatTitle,
  generateChatSummary,
  deleteChat,
  sendFeedback,
  setUserBackgroundPreference,
  getUserBackgroundPreference,
  updateChatTimestamp,
  createProCheckoutSession,
  checkProStatus,
  getUserPersonalityPreference,
  setUserPersonalityPreference,
  transcribeAudio,
  uploadImageToS3,
  uploadPdfToS3,
  uploadAudioFile,
  synthesizeSpeech,
  processAudioAndText,
  getProjectChats,
  updateProjectContext,
  updateProjectInstructions,
  getProjectFiles,
  deleteFile,
  saveUserEmailToPreferences,
};
