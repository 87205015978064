import React, { useState, useEffect } from 'react';
import { Box, Typography, Fade } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const wordChange = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px) scale(0.95);
  }
  50% {
    opacity: 0.5;
    transform: translateY(5px) scale(0.97);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
`;

const WelcomeAnimation = ({ onComplete, stage = 'initial' }) => {
  const [currentPhase, setCurrentPhase] = useState(0);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  // Palabras en minúsculas excepto "AGT Ups"
  const words = ['success', 'prosper', 'advance', 'achieve', 'AGT Ups'];
  
  const phrases = {
    initial: [
      { text: "Hello", duration: 2000 },
      { text: "Let's create your business story", duration: 4000 }
    ]
  };

  useEffect(() => {
    // Evita el scroll durante la animación
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    if (stage === 'initial') {
      const currentPhrases = phrases[stage];
      if (currentPhase >= currentPhrases.length) {
        const timer = setTimeout(() => {
          onComplete?.();
        }, 800);
        return () => clearTimeout(timer);
      }

      const timer = setTimeout(() => {
        setCurrentPhase(prev => prev + 1);
      }, currentPhrases[currentPhase].duration);

      return () => clearTimeout(timer);
    } else if (stage === 'completion') {
      const wordInterval = setInterval(() => {
        setCurrentWordIndex(prev => {
          if (prev === words.length - 2) {
            clearInterval(wordInterval);
            setTimeout(() => {
              setCurrentWordIndex(prev + 1);
              setTimeout(onComplete, 1000);
            }, 500);
          }
          return prev + 1;
        });
      }, 1000); // Intervalo de 1 segundo

      return () => clearInterval(wordInterval);
    }
  }, [currentPhase, stage, onComplete, words.length]);

  if (stage === 'completion') {
    return (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: theme => (theme.palette.mode === 'dark' ? '#000' : '#fff'),
          color: theme => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
          overflow: 'hidden',
          userSelect: 'none'
        }}
      >
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: '2rem',
                sm: '3rem',
                md: '3.5rem'
              },
              whiteSpace: 'pre'
            }}
          >
            Welcome to{' '}
          </Typography>
          <Typography
            component="span"
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: '2rem',
                sm: '3rem',
                md: '3.5rem'
              },
              whiteSpace: 'pre',
              // Ancho responsivo para evitar problemas en móviles
              width: {
                xs: '150px',
                sm: '200px',
                md: '250px'
              },
              flexShrink: 0,
              textAlign: 'center',
              animation: `${wordChange} 0.5s ease-out`,
              background:
                currentWordIndex === words.length - 1
                  ? 'none'
                  : theme =>
                      theme.palette.mode === 'dark'
                        ? 'linear-gradient(135deg, #007AFF, #00E5FF)'
                        : 'linear-gradient(135deg, #007AFF, #0096FF)',
              WebkitBackgroundClip:
                currentWordIndex === words.length - 1 ? 'none' : 'text',
              WebkitTextFillColor:
                currentWordIndex === words.length - 1 ? 'initial' : 'transparent'
            }}
          >
            {words[currentWordIndex]}
          </Typography>
        </Box>
      </Box>
    );
  }

  const currentPhrases = phrases[stage];

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme => (theme.palette.mode === 'dark' ? '#000' : '#fff'),
        color: theme => (theme.palette.mode === 'dark' ? '#fff' : '#000'),
        overflow: 'hidden',
        userSelect: 'none'
      }}
    >
      {currentPhrases.map((phrase, index) => (
        <Fade 
          key={index} 
          in={currentPhase === index}
          timeout={1000}
          mountOnEnter
          unmountOnExit
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 700,
              animation: `${index === 0 ? fadeScale : fadeUp} 1.2s cubic-bezier(0.16, 1, 0.3, 1)`,
              textAlign: 'center',
              position: 'absolute',
              width: '100%',
              px: 4,
              fontSize: {
                xs: index === 0 ? '2.5rem' : '1.5rem',
                sm: index === 0 ? '3.75rem' : '2rem',
                md: index === 0 ? '4.5rem' : '2.5rem'
              }
            }}
          >
            {phrase.text}
          </Typography>
        </Fade>
      ))}
    </Box>
  );
};

export default WelcomeAnimation;
