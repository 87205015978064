import API_CONFIG from '../config';

const AGTFoundService = {
  async search(query, { uid, token } = {}) {
    try {
      if (!query?.trim()) {
        throw new Error('Query is required');
      }

      const response = await API_CONFIG.createAxiosInstance().post('/found/search', {
        query,
        uid
      });

      if (!response.data.success) {
        throw new Error(response.data.error || 'Error en la búsqueda');
      }

      return response.data;

    } catch (error) {
      console.error('Error en AGTFoundService:', error);
      if (error.response?.status === 500) {
        return {
          success: false,
          error: 'Error interno del servidor',
          recommendations: []
        };
      }
      throw error;
    }
  }
};

export default AGTFoundService;