// D:\directiveai-react\frontend\src\components\AGTUps\products\AGTProduct-products\SalesStrategy.js

import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  LinearProgress,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  AvatarGroup,
  IconButton,
  Tooltip,
  Alert,
  CircularProgress,
  Button,
  Badge,
  Tab,
  Tabs,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import {
  RefreshCw,
  Download,
  Settings,
  ArrowUp,
  ArrowDown,
  UserPlus,
  Zap,
  Star,
  Package,
  Award,
  Target,
  DollarSign,
  Users,
  Globe,
  Calendar,
  UserCheck,
  Info
} from 'lucide-react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Legend
} from 'recharts';

import SalesStrategyService from '../../services/AGTUps-product-services/salesStrategyService';
import ProductSelector from './ProductSelector';
import { toast } from 'react-toastify';

// <<-- Importamos el contexto global de productos -->
import { ProductContext } from '../../../../context/ProductContext';

/* -------------------------------------------------------------------------
   Sistema de colores al estilo Apple (light/dark).
------------------------------------------------------------------------- */
const appleColors = {
  light: {
    label: { primary: '#000000' },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      green: '#31C859',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#FFFFFF' },
    fill: { tertiary: '#767680' },
    ui: {
      accent: '#007BFE',
      base: '#F2F2F7'
    }
  },
  dark: {
    label: { primary: '#FFFFFF' },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      green: '#2DD257',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#000000' },
    fill: { tertiary: '#767680' },
    ui: {
      accent: '#0385FF',
      base: '#272729'
    }
  }
};

const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30',
    warning: '#FF9500'
  };
};

/* -------------------------------------------------------------------------
   Paleta de colores adicional para gráficas.
------------------------------------------------------------------------- */
const CHART_COLORS = {
  primary: '#4f46e5',
  secondary: '#0891b2',
  success: '#059669',
  warning: '#eab308',
  error: '#dc2626',
  purple: '#7c3aed',
  pink: '#db2777'
};

/* -------------------------------------------------------------------------
   Styled components
------------------------------------------------------------------------- */
const StyledContainer = styled(Box)(() => ({
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  width: '100%',
  padding: '24px',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(20px)',
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    transition: 'all 0.3s ease',
    boxShadow: 'none'
  };
});

const MetricCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2),
    borderRadius: '12px',
    backdropFilter: 'blur(10px)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'translateY(-2px)'
    }
  };
});

const ChannelCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2),
    borderRadius: '12px',
    transition: 'all 0.2s ease-in-out',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      backgroundColor: alpha(navColors.cardBackground, 0.7)
    }
  };
});

const LoadingOverlay = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(0, 0, 0, 0.5)'
        : 'rgba(255, 255, 255, 0.7)',
    zIndex: 1,
    borderRadius: '16px'
  };
});

/* -------------------------------------------------------------------------
   Componente principal: SalesStrategy
------------------------------------------------------------------------- */
// Se elimina la prop "products" ya que ahora se consume desde el contexto global.
const SalesStrategy = () => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // <<-- Consumimos el contexto global de productos -->
  const { products, loading: productsLoading, updateProductList } = useContext(ProductContext);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [timeRange, setTimeRange] = useState('6m');
  const [activeTab, setActiveTab] = useState(0);
  const [selectedChannel, setSelectedChannel] = useState('all');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [salesPerformance, setSalesPerformance] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  // Estados para la ubicación del usuario
  const [userLocation, setUserLocation] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(true);
  // Estados para el análisis generado
  const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
  const [analysisError, setAnalysisError] = useState(null);
  const [topRegions, setTopRegions] = useState(null);
  const [growthOpportunities, setGrowthOpportunities] = useState(null);
  const [seasonalPatterns, setSeasonalPatterns] = useState(null);
  const [customerSegments, setCustomerSegments] = useState(null);
  // Estados para el formulario de venta
  const [saleForm, setSaleForm] = useState({
    quantity: 1,
    revenue: 0,
    channel: 'direct',
    region: '',
    saleDate: new Date().toISOString().split('T')[0]
  });
  const [editingSaleDate, setEditingSaleDate] = useState(false);
  // Estado para el modal de acciones recomendadas
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', description: '', actionLabel: '' });

  // Efecto para cargar la ubicación del usuario
  useEffect(() => {
    const loadUserLocation = async () => {
      setLoadingLocation(true);
      try {
        const location = await SalesStrategyService.getUserLocation();
        setUserLocation(location);
        if (location !== 'N/A') {
          setSaleForm(prev => ({ ...prev, region: location }));
        }
      } catch (error) {
        // No bloquea la carga en caso de error
      } finally {
        setLoadingLocation(false);
      }
    };
    loadUserLocation();
  }, []);

  // Asignar precio predeterminado al formulario al seleccionar un producto
  useEffect(() => {
    if (selectedProduct?.price) {
      setSaleForm(prev => ({ ...prev, revenue: selectedProduct.price }));
    }
  }, [selectedProduct]);

  // Al cambiar producto o timeRange, recargar datos y reiniciar análisis
  useEffect(() => {
    if (selectedProduct) {
      loadSalesData();
      setTopRegions(null);
      setGrowthOpportunities(null);
      setSeasonalPatterns(null);
      setCustomerSegments(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, timeRange]);

  const loadSalesData = async () => {
    try {
      setLoading(true);
      setError(null);

      const endDate = new Date();
      let startDate = new Date();

      // Ajusta startDate según timeRange
      switch (timeRange) {
        case '1m':
          startDate.setMonth(endDate.getMonth() - 1);
          break;
        case '3m':
          startDate.setMonth(endDate.getMonth() - 3);
          break;
        case '6m':
          startDate.setMonth(endDate.getMonth() - 6);
          break;
        case '1y':
          startDate.setFullYear(endDate.getFullYear() - 1);
          break;
        default:
          startDate.setMonth(endDate.getMonth() - 6);
      }

      const [strategyResponse, performanceResponse] = await Promise.all([
        SalesStrategyService.getSalesStrategiesByProduct(selectedProduct.id, { timeRange }),
        SalesStrategyService.analyzeSalesPerformance(selectedProduct.id, {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        })
      ]);

      setStrategyData(strategyResponse.data);

      if (strategyResponse.data.analysis) {
        const analysis = strategyResponse.data.analysis;
        if (analysis.topRegions) setTopRegions(analysis.topRegions);
        if (analysis.opportunities) setGrowthOpportunities(analysis.opportunities);
        if (analysis.seasonal) setSeasonalPatterns(analysis.seasonal);
        if (analysis.segments) setCustomerSegments(analysis.segments);
      }

      setSalesPerformance(performanceResponse.data);
      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error loading sales data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadSalesData();
    } finally {
      setUpdating(false);
    }
  };

  const handleExportData = async () => {
    try {
      if (!selectedProduct) return;
      const data = {
        strategy: strategyData,
        performance: salesPerformance,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `sales-strategy-${selectedProduct.id}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error exporting data: ${err.message}`);
    }
  };

  const handleGenerateAnalysis = async () => {
    if (!selectedProduct) {
      toast.error('Please select a product first');
      return;
    }
    if (!salesPerformance || !salesPerformance.metrics?.totalSalesAlltime) {
      toast.error('No sales data available for analysis');
      return;
    }
    try {
      setIsGeneratingAnalysis(true);
      setAnalysisError(null);

      const response = await SalesStrategyService.generateAnalysisWithClaude(selectedProduct.id);

      if (response.success) {
        const analysis = response.data.analysis;
        if (analysis.topRegions) setTopRegions(analysis.topRegions);
        if (analysis.opportunities) setGrowthOpportunities(analysis.opportunities);
        if (analysis.seasonal) setSeasonalPatterns(analysis.seasonal);
        if (analysis.segments) setCustomerSegments(analysis.segments);

        toast.success('Analysis generated successfully');
      }
    } catch (error) {
      setAnalysisError('Failed to generate analysis. Please try again.');
      toast.error('Error generating analysis');
    } finally {
      setIsGeneratingAnalysis(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOpenModal = (actionType) => {
    let title = '';
    let description = '';
    let actionLabel = '';

    switch (actionType) {
      case 'lead':
        title = 'Lead Generation Campaign';
        description =
          'En una campaña de generación de leads, el objetivo es atraer clientes potenciales a través de estrategias de marketing digital, optimización de contenidos y publicidad dirigida. Se busca recopilar información de contacto para nutrirlos posteriormente en el embudo de ventas.';
        actionLabel = 'Coming Soon';
        break;
      case 'conversion':
        title = 'Conversion Optimization Program';
        description =
          'El programa de optimización de conversión se centra en mejorar la tasa de conversión de visitantes a clientes, a través de mejoras en la experiencia de usuario, pruebas A/B y ajustes en el proceso de compra.';
        actionLabel = 'Coming Soon';
        break;
      case 'retention':
        title = 'Customer Retention Program';
        description =
          'El programa de retención de clientes tiene como objetivo fidelizar a los clientes existentes, implementando estrategias como programas de lealtad, ofertas exclusivas y un excelente servicio postventa para aumentar la recurrencia de compra.';
        actionLabel = 'Coming Soon';
        break;
      default:
        title = 'Detalles de la Acción';
        description = 'Más información sobre esta acción se mostrará aquí.';
        actionLabel = 'Start';
    }
    setModalContent({ title, description, actionLabel });
    setModalOpen(true);
  };

  const handleStartAction = () => {
    setModalOpen(false);
  };

  const formattedSalesData = useMemo(() => {
    if (!salesPerformance) return null;
    const formatted = SalesStrategyService.formatChartData(salesPerformance);
    return formatted;
  }, [salesPerformance]);

  const handleSaleInputChange = (e) => {
    const { name, value } = e.target;
    setSaleForm(prev => ({ ...prev, [name]: value }));
  };

  const handleCreateSale = async (e) => {
    e.preventDefault();
    setError(null);

    if (!selectedProduct) {
      setError('No product selected for sale');
      return;
    }

    try {
      await SalesStrategyService.createSalesRecord({
        ...saleForm,
        productId: selectedProduct.id
      });
      
      // Actualización inmediata del stock en el contexto:
      const category = (selectedProduct.category || '').toLowerCase();
      if (['physical', 'food'].includes(category)) {
        const currentStock = parseInt(selectedProduct.stock, 10) || 0;
        const saleQuantity = parseInt(saleForm.quantity, 10) || 0;
        const newStock = currentStock - saleQuantity;
        const updatedProduct = { ...selectedProduct, stock: newStock };
        updateProductList(updatedProduct);
      }
      
      setSaleForm({
        quantity: 1,
        revenue: 0,
        channel: 'direct',
        region: '',
        saleDate: new Date().toISOString().split('T')[0]
      });
      setEditingSaleDate(false);
      
      await handleRefreshData();
    } catch (err) {
      console.error("Error in handleCreateSale:", err);
      let errorMsg = "";
      if (err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error;
      } else {
        errorMsg = err.message;
      }
      if (errorMsg.includes("Insufficient stock")) {
        setError(`Oops! Your stock has run out. Please add more stock to your product "${selectedProduct?.name}" to complete the sale.`);
      } else {
        setError(errorMsg);
      }
    }
  };

  return (
    <StyledContainer>
      <Box sx={{ '& > *:not(:last-child)': { mb: 4 } }}>
        {/* ProductSelector actualizado para usar el contexto global */}
        <ProductSelector
          products={products}
          selectedProduct={selectedProduct}
          onProductSelect={setSelectedProduct}
          loading={productsLoading}
          compact
        />

        {/* Indicador de carga global */}
        {productsLoading && (
          <Box sx={{ textAlign: 'center', py: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{
              borderRadius: '12px',
              backgroundColor: alpha(navColors.error, 0.1),
              backdropFilter: 'blur(10px)',
              mt: 2,
              color: navColors.error
            }}
          >
            {error}
          </Alert>
        )}

        {/* Si hay un producto seleccionado, mostramos el formulario para registrar una venta */}
        {selectedProduct && (
          <StyledPaper sx={{ mb: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
              Register a New Sale
            </Typography>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleCreateSale}
              sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}
            >
              {/* Quantity */}
              <TextField
                name="quantity"
                label="Quantity"
                type="number"
                value={saleForm.quantity}
                onChange={handleSaleInputChange}
                sx={{ width: '120px', borderRadius: '28px' }}
              />

              {/* Revenue */}
              <TextField
                name="revenue"
                label="Revenue"
                type="number"
                value={saleForm.revenue}
                onChange={handleSaleInputChange}
                sx={{ width: '120px', borderRadius: '28px' }}
              />

              {/* Channel */}
              <FormControl sx={{ width: '160px', borderRadius: '28px' }}>
                <InputLabel>Channel</InputLabel>
                <Select
                  name="channel"
                  label="Channel"
                  value={saleForm.channel}
                  onChange={handleSaleInputChange}
                  sx={{ borderRadius: '28px' }}
                >
                  <MenuItem value="direct">Direct</MenuItem>
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="partners">Partners</MenuItem>
                  <MenuItem value="distributor">Distributor</MenuItem>
                </Select>
              </FormControl>

              {/* Region */}
              <TextField
                name="region"
                label="Region"
                value={saleForm.region}
                onChange={handleSaleInputChange}
                placeholder={loadingLocation ? 'Loading location...' : userLocation}
                helperText={
                  loadingLocation 
                    ? 'Loading your default region...'
                    : userLocation !== 'N/A'
                      ? `Your default region: ${userLocation}`
                      : 'No default region available'
                }
                sx={{ width: '160px', borderRadius: '28px' }}
                disabled={loadingLocation}
              />

              {/* Sale Date */}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <TextField
                  name="saleDate"
                  label="Sale Date"
                  type="date"
                  value={saleForm.saleDate}
                  onChange={handleSaleInputChange}
                  sx={{ width: '160px', borderRadius: '28px' }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split('T')[0]
                  }}
                  disabled={!editingSaleDate}
                />
                <Button
                  variant="outlined"
                  onClick={() => setEditingSaleDate(prev => !prev)}
                  sx={{ borderRadius: '28px', textTransform: 'none' }}
                >
                  {editingSaleDate ? 'Done' : 'Change Date'}
                </Button>
              </Box>

              {/* Save Sale Button */}
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  borderRadius: '28px',
                  textTransform: 'none',
                  fontWeight: 600,
                  alignSelf: 'center'
                }}
              >
                Save Sale
              </Button>
            </Box>
          </StyledPaper>
        )}

        {/* Si no hay producto seleccionado, mostramos un mensaje */}
        {!selectedProduct ? (
          <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
            <Package
              size={48}
              style={{
                marginBottom: '16px',
                color: alpha(navColors.textSecondary, 0.6)
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
              Select a Product
            </Typography>
            <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
              Choose a product from above to view sales strategy
            </Typography>
          </StyledPaper>
        ) : (
          <Grid container spacing={4}>
            {/* Fila de controles: Time Range, Channel, Refresh, Export, Settings */}
            <Grid item xs={12} container spacing={3} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: navColors.textSecondary,
                      '&.Mui-focused': { color: navColors.accent }
                    }}
                  >
                    Time Range
                  </InputLabel>
                  <Select
                    value={timeRange}
                    label="Time Range"
                    onChange={(e) => setTimeRange(e.target.value)}
                    disabled={loading}
                    sx={{
                      backgroundColor: alpha(navColors.cardBackground, 0.5),
                      borderRadius: '12px'
                    }}
                  >
                    <MenuItem value="1m">Last Month</MenuItem>
                    <MenuItem value="3m">Last 3 Months</MenuItem>
                    <MenuItem value="6m">Last 6 Months</MenuItem>
                    <MenuItem value="1y">Last Year</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: navColors.textSecondary,
                      '&.Mui-focused': { color: navColors.accent }
                    }}
                  >
                    Sales Channel
                  </InputLabel>
                  <Select
                    value={selectedChannel}
                    label="Sales Channel"
                    onChange={(e) => setSelectedChannel(e.target.value)}
                    disabled={loading}
                    sx={{
                      backgroundColor: alpha(navColors.cardBackground, 0.5),
                      borderRadius: '12px'
                    }}
                  >
                    <MenuItem value="all">All Channels</MenuItem>
                    <MenuItem value="direct">Direct Sales</MenuItem>
                    <MenuItem value="online">Online</MenuItem>
                    <MenuItem value="partners">Partners</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}
              >
                <Tooltip
                  title={
                    lastUpdated
                      ? `Last updated: ${lastUpdated.toLocaleString()}`
                      : 'Never updated'
                  }
                >
                  <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                    <RefreshCw
                      size={20}
                      style={{
                        color: navColors.accent,
                        animation: updating ? 'spin 1s linear infinite' : 'none'
                      }}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export Strategy">
                  <IconButton onClick={handleExportData} disabled={loading}>
                    <Download size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Settings">
                  <IconButton disabled={loading}>
                    <Settings size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            {/* Columna izquierda: Sales Performance Overview */}
            <Grid item xs={12} md={8}>
              <StyledPaper sx={{ position: 'relative' }}>
                {updating && (
                  <LoadingOverlay>
                    <CircularProgress />
                  </LoadingOverlay>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 3
                  }}
                >
                  <Box>
                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 0.5 }}>
                      {selectedProduct.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                      Sales Performance Overview
                    </Typography>
                  </Box>
                  <Badge color="primary" badgeContent={salesPerformance?.status || 'Active'}>
                    <Chip
                      icon={<DollarSign size={16} />}
                      label={`$${selectedProduct.price || 0}`}
                      variant="outlined"
                      sx={{
                        borderRadius: '8px',
                        color: navColors.accent
                      }}
                    />
                  </Badge>
                </Box>

                <Box sx={{ height: 300 }}>
                  {loading ? (
                    <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  ) : formattedSalesData ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart data={formattedSalesData.salesTrend}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                        />
                        <YAxis />
                        <RechartsTooltip
                          formatter={(value) => value}
                          labelFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                        />
                        <Legend />
                        <Bar dataKey="quantity" name="Quantity Sold" fill={CHART_COLORS.primary} />
                      </BarChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <Typography>No sales trend data available</Typography>
                    </Box>
                  )}
                </Box>
              </StyledPaper>
            </Grid>

            {/* Columna derecha: Performance Metrics */}
            <Grid item xs={12} md={4}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <StyledPaper>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                      Performance Metrics
                    </Typography>
                    {salesPerformance?.metrics ? (
                      <Box
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
                          gap: 2
                        }}
                      >
                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Revenue Today
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            ${salesPerformance.metrics.totalRevenueToday.toLocaleString()}
                          </Typography>
                        </MetricCard>

                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Revenue Monthly
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            ${salesPerformance.metrics.totalRevenueMonthly.toLocaleString()}
                          </Typography>
                        </MetricCard>

                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Revenue Alltime
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            ${salesPerformance.metrics.totalRevenueAlltime.toLocaleString()}
                          </Typography>
                        </MetricCard>

                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Sales Today
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            {salesPerformance.metrics.totalSalesToday.toLocaleString()}
                          </Typography>
                        </MetricCard>

                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Sales Monthly
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            {salesPerformance.metrics.totalSalesMonthly.toLocaleString()}
                          </Typography>
                        </MetricCard>

                        <MetricCard>
                          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                            Total Sales Alltime
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 700 }}>
                            {salesPerformance.metrics.totalSalesAlltime.toLocaleString()}
                          </Typography>
                        </MetricCard>
                      </Box>
                    ) : (
                      <Box sx={{ textAlign: 'center', py: 2 }}>
                        <CircularProgress />
                      </Box>
                    )}
                  </StyledPaper>
                </Grid>
              </Grid>
            </Grid>

            {/* Fila inferior: Sales Channel Distribution + Sales Funnel */}
            <Grid item xs={12} md={6}>
              <StyledPaper>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Sales Channel Distribution
                </Typography>
                <Box sx={{ height: 320 }}>
                  {formattedSalesData?.channelDistribution?.length ? (
                    <ResponsiveContainer width="100%" height="100%">
                      <RechartsPieChart>
                        <Pie
                          data={formattedSalesData.channelDistribution}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={100}
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {formattedSalesData.channelDistribution.map((entry, idx) => (
                            <Cell
                              key={`cell-${idx}`}
                              fill={
                                Object.values(CHART_COLORS)[
                                  idx % Object.keys(CHART_COLORS).length
                                ]
                              }
                            />
                          ))}
                        </Pie>
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: alpha(navColors.cardBackground, 0.8),
                            borderRadius: '8px',
                            color: navColors.textDefault
                          }}
                        />
                        <Legend wrapperStyle={{ color: navColors.textDefault }} />
                      </RechartsPieChart>
                    </ResponsiveContainer>
                  ) : (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: navColors.textSecondary
                      }}
                    >
                      <Typography>No channel distribution data available</Typography>
                    </Box>
                  )}
                </Box>
              </StyledPaper>
            </Grid>

            <Grid item xs={12} md={6}>
              <StyledPaper>
                <Typography
                  variant="h6"
                  sx={{
                    mb: 2,
                    fontWeight: 600,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  Sales Funnel
                  <Tooltip
                    title="El embudo de ventas muestra las etapas que atraviesa un cliente potencial, desde el primer contacto hasta la compra. Permite identificar cuellos de botella y optimizar la conversión."
                    arrow
                  >
                    <IconButton size="small" sx={{ padding: 0 }}>
                      <Info size={16} style={{ color: navColors.accent }} />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <Box sx={{ display: 'grid', rowGap: 2 }}>
                  {salesPerformance?.funnel ? (
                    Object.entries(salesPerformance.funnel).map(([stage, data]) => (
                      <Box key={stage}>
                        <Box
                          sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}
                        >
                          <Typography variant="body2">{stage}</Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2">{data.count}</Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color:
                                  data.conversionRate >= data.target
                                    ? navColors.success
                                    : navColors.error
                              }}
                            >
                              {data.conversionRate}%
                            </Typography>
                          </Box>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={data.conversionRate}
                          sx={{
                            height: 6,
                            borderRadius: 3,
                            backgroundColor: alpha(navColors.borderColor, 0.2),
                            '& .MuiLinearProgress-bar': {
                              borderRadius: 3,
                              backgroundColor:
                                data.conversionRate >= data.target
                                  ? navColors.success
                                  : navColors.warning
                            }
                          }}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ textAlign: 'center', py: 2 }}>
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </StyledPaper>
            </Grid>

            {/* Recommended Actions */}
            <Grid item xs={12}>
              <StyledPaper>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Recommended Actions
                </Typography>
                <Grid container spacing={3}>
                  {/* Lead Generation */}
                  <Grid item xs={12} md={4}>
                    <ChannelCard>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <UserPlus size={20} style={{ color: navColors.accent }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          Lead Generation
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                        {salesPerformance?.recommendations?.leadGen ||
                          'Increase lead generation efforts in direct sales channel'}
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={loading}
                        onClick={() => handleOpenModal('lead')}
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: navColors.accent,
                          color: '#fff',
                          textTransform: 'none',
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: alpha(navColors.accent, 0.9)
                          }
                        }}
                      >
                        View Campaign
                      </Button>
                    </ChannelCard>
                  </Grid>

                  {/* Conversion Optimization */}
                  <Grid item xs={12} md={4}>
                    <ChannelCard>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <Zap size={20} style={{ color: '#AF52DE' }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          Conversion Optimization
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                        {salesPerformance?.recommendations?.conversion ||
                          'Implement new sales training program to improve close rates'}
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={loading}
                        onClick={() => handleOpenModal('conversion')}
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: navColors.accent,
                          color: '#fff',
                          textTransform: 'none',
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: alpha(navColors.accent, 0.9)
                          }
                        }}
                      >
                        View Program
                      </Button>
                    </ChannelCard>
                  </Grid>

                  {/* Customer Retention */}
                  <Grid item xs={12} md={4}>
                    <ChannelCard>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <Star size={20} style={{ color: '#FFD700' }} />
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          Customer Retention
                        </Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: navColors.textSecondary, mb: 2 }}>
                        {salesPerformance?.recommendations?.retention ||
                          'Launch customer loyalty program to increase repeat sales'}
                      </Typography>
                      <Button
                        variant="contained"
                        fullWidth
                        disabled={loading}
                        onClick={() => handleOpenModal('retention')}
                        sx={{
                          borderRadius: '12px',
                          backgroundColor: navColors.accent,
                          color: '#fff',
                          textTransform: 'none',
                          fontWeight: 600,
                          '&:hover': {
                            backgroundColor: alpha(navColors.accent, 0.9)
                          }
                        }}
                      >
                        View Program
                      </Button>
                    </ChannelCard>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>

            {/* Tabs: Geographic / Seasonal / Customer Segments */}
            <Grid item xs={12}>
              <StyledPaper>
                <Box
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  alignItems={isMobile ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={2}
                >
                  {isMobile ? (
                    <>
                      <Box display="flex" justifyContent="flex-end" gap={2} mb={1}>
                        <Button
                          variant="contained"
                          onClick={handleGenerateAnalysis}
                          disabled={isGeneratingAnalysis}
                          sx={{
                            borderRadius: '12px',
                            backgroundColor: navColors.accent,
                            color: '#fff',
                            textTransform: 'none',
                            fontWeight: 600,
                            '&:hover': {
                              backgroundColor: alpha(navColors.accent, 0.9)
                            }
                          }}
                        >
                          {isGeneratingAnalysis ? (
                            <>
                              <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
                              Generating Analysis...
                            </>
                          ) : (
                            'Generate Analysis with AGT'
                          )}
                        </Button>
                        <IconButton onClick={() => {}} sx={{ color: navColors.accent }}>
                          <RefreshCw size={20} />
                        </IconButton>
                        <IconButton onClick={() => {}} sx={{ color: navColors.accent }}>
                          <Download size={20} />
                        </IconButton>
                      </Box>
                      <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                          '& .MuiTab-root': { textTransform: 'none' },
                          '& .MuiTabs-indicator': { backgroundColor: navColors.accent }
                        }}
                      >
                        <Tab icon={<Globe size={16} />} label="Geographic" iconPosition="start" />
                        <Tab icon={<Calendar size={16} />} label="Seasonal" iconPosition="start" />
                        <Tab icon={<UserCheck size={16} />} label="Customer Segments" iconPosition="start" />
                      </Tabs>
                    </>
                  ) : (
                    <>
                      <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                          '& .MuiTab-root': { textTransform: 'none' },
                          '& .MuiTabs-indicator': { backgroundColor: navColors.accent }
                        }}
                      >
                        <Tab icon={<Globe size={16} />} label="Geographic" iconPosition="start" />
                        <Tab icon={<Calendar size={16} />} label="Seasonal" iconPosition="start" />
                        <Tab icon={<UserCheck size={16} />} label="Customer Segments" iconPosition="start" />
                      </Tabs>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Button
                          variant="contained"
                          onClick={handleGenerateAnalysis}
                          disabled={isGeneratingAnalysis}
                          sx={{
                            borderRadius: '12px',
                            backgroundColor: navColors.accent,
                            color: '#fff',
                            textTransform: 'none',
                            fontWeight: 600,
                            '&:hover': {
                              backgroundColor: alpha(navColors.accent, 0.9)
                            }
                          }}
                        >
                          {isGeneratingAnalysis ? (
                            <>
                              <CircularProgress size={16} color="inherit" sx={{ mr: 1 }} />
                              Generating Analysis...
                            </>
                          ) : (
                            'Generate Analysis with AGT'
                          )}
                        </Button>
                        <IconButton onClick={() => {}} sx={{ color: navColors.accent }}>
                          <RefreshCw size={20} />
                        </IconButton>
                        <IconButton onClick={() => {}} sx={{ color: navColors.accent }}>
                          <Download size={20} />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Box>
                {analysisError && (
                  <Alert
                    severity="error"
                    onClose={() => setAnalysisError(null)}
                    sx={{
                      borderRadius: '12px',
                      backgroundColor: alpha(navColors.error, 0.1),
                      color: navColors.error,
                      mt: 2
                    }}
                  >
                    {analysisError}
                  </Alert>
                )}

                {activeTab === 0 && (
                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Geographic Distribution of Sales
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: '12px',
                            backgroundColor: alpha(navColors.accent, 0.1)
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                            Top Performing Regions
                          </Typography>
                          <Box sx={{ display: 'grid', rowGap: 2 }}>
                            {(topRegions || salesPerformance?.geographic?.topRegions)?.map((region) => (
                              <Box key={region.name}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 0.5
                                  }}
                                >
                                  <Typography variant="body2">{region.name}</Typography>
                                  <Typography variant="body2" sx={{ color: navColors.accent }}>
                                    {region.contribution}%
                                  </Typography>
                                </Box>
                                <LinearProgress
                                  variant="determinate"
                                  value={region.contribution}
                                  sx={{
                                    height: 6,
                                    borderRadius: 3,
                                    backgroundColor: alpha(navColors.borderColor, 0.2),
                                    '& .MuiLinearProgress-bar': {
                                      borderRadius: 3,
                                      backgroundColor: navColors.accent
                                    }
                                  }}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            p: 2,
                            borderRadius: '12px',
                            backgroundColor: alpha(navColors.success, 0.1)
                          }}
                        >
                          <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: 600 }}>
                            Growth Opportunities
                          </Typography>
                          {(growthOpportunities || salesPerformance?.geographic?.opportunities)?.map((opp) => (
                            <Box
                              key={opp.region}
                              sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}
                            >
                              <Target size={16} style={{ color: navColors.success }} />
                              <Typography variant="body2">{opp.description}</Typography>
                            </Box>
                          ))}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {activeTab === 1 && (
                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Seasonal Sales Patterns
                    </Typography>
                    <Box sx={{ height: 300 }}>
                      {(seasonalPatterns || formattedSalesData?.seasonal)?.length ? (
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={seasonalPatterns || formattedSalesData.seasonal}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="period" />
                            <YAxis />
                            <RechartsTooltip />
                            <Bar dataKey="sales" fill={CHART_COLORS.primary} />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Typography>No seasonal data available</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}

                {activeTab === 2 && (
                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                      Customer Segment Analysis
                    </Typography>
                    <Grid container spacing={3}>
                      {(customerSegments || salesPerformance?.segments)?.map((segment) => (
                        <Grid item xs={12} md={4} key={segment.name}>
                          <MetricCard>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                              <Users
                                size={20}
                                style={{
                                  color: CHART_COLORS[segment.color] || navColors.accent
                                }}
                              />
                              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                {segment.name}
                              </Typography>
                            </Box>
                            <Typography variant="h5" sx={{ fontWeight: 700, mb: 0.5 }}>
                              {segment.revenue}%
                            </Typography>
                            <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
                              Revenue Share
                            </Typography>
                          </MetricCard>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </StyledPaper>
            </Grid>
          </Grid>
        )}
      </Box>

      {/* Modal para mostrar detalles de la acción recomendada */}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        PaperProps={{
          style: { borderRadius: 16, padding: '24px', maxWidth: '500px' }
        }}
      >
        <DialogTitle>{modalContent.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{modalContent.description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStartAction}
            sx={{ borderRadius: '28px', textTransform: 'none', fontWeight: 600 }}
            variant="contained"
            color="primary"
          >
            {modalContent.actionLabel}
          </Button>
          <Button
            onClick={() => setModalOpen(false)}
            sx={{ borderRadius: '28px', textTransform: 'none', fontWeight: 600 }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default SalesStrategy;
