import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  Alert,
  Paper,
  IconButton,
  Tooltip,
  Tabs,
  Tab,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Card,
  CardContent,
  Chip,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  useTheme,
  CircularProgress,
  Fade,
  Badge
} from '@mui/material';
import {
  Package,
  RefreshCw,
  DollarSign,
  Plus,
  ChevronLeft,
  Clock,
  Tag,
  TrendingUp,
  MoreVertical,
  Info,
} from 'lucide-react';
import { styled, alpha } from '@mui/material/styles';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import CostService from '../../services/AGTUps-product-services/costService';
import ProductSelector from './ProductSelector';
import { ProductContext } from '../../../../context/ProductContext';

/* -------------------------------------------------------------------------
   Color system (light/dark)
------------------------------------------------------------------------- */
const appleColors = {
  light: {
    label: { primary: '#000000' },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859',
    },
    bg: { primaryElevated: '#FFFFFF' },
    fill: { tertiary: '#767680' },
    ui: {
      accent: '#0385FF',
      base: '#F2F2F7',
    },
  },
  dark: {
    label: { primary: '#FFFFFF' },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257',
    },
    bg: { primaryElevated: '#000000' },
    fill: { tertiary: '#767680' },
    ui: {
      accent: '#0385FF',
      base: '#272729',
    },
  },
};

const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30',
    warning: '#FF9500',
  };
};

/* -------------------------------------------------------------------------
   Styled Components
------------------------------------------------------------------------- */
const StyledScrollContainer = styled(Box)(() => ({
  backgroundColor: 'transparent',
  padding: '24px',
  width: '100%',
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': { width: '8px' },
  '&::-webkit-scrollbar-track': { background: 'transparent' },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    padding: theme.spacing(3),
    borderRadius: '12px',
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(10px)',
    color: navColors.textDefault,
    transition: 'all 0.3s ease',
    boxShadow: 'none',
  };
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
  },
}));

const StyledCard = styled(Card)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    borderRadius: '12px',
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    backdropFilter: 'blur(10px)',
    marginBottom: theme.spacing(2),
    transition: 'all 0.2s ease',
    color: navColors.textDefault,
    boxShadow: 'none',
  };
});

/* -------------------------------------------------------------------------
   Main Component: Cost
------------------------------------------------------------------------- */
const Cost = ({ showNotification, entrepreneurLevel = 'beginner' }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const { products, loading: productsLoading } = useContext(ProductContext);

  // Main states
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [costData, setCostData] = useState({
    items: [],
    summary: { totalCosts: 0, costsByCategory: {}, totalItems: 0 },
  });
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  // State para las notificaciones (Badge) en las pestañas
  const [notifications, setNotifications] = useState({
    overview: false,
    history: false,
  });

  // Estados para el formulario "Add Cost"
  // costType puede ser 'wholesale', 'unit' o 'other'
  const [costType, setCostType] = useState(null);
  const [formData, setFormData] = useState({
    amount: '',
    description: '',
    categoryId: '',
    frequency: 'one-time',
    customCategory: false,
    newCategoryName: '',
    units: '',
    costType: '',
  });

  // Diálogos para editar y eliminar
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [costToEdit, setCostToEdit] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [costToDelete, setCostToDelete] = useState(null);

  // Menú contextual "More"
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCostForMenu, setSelectedCostForMenu] = useState(null);

  // Estado para las categorías
  const [categories, setCategories] = useState([]);

  // Refs para las transiciones Fade
  const fadeOverviewRef = useRef(null);
  const fadeAddCostRef = useRef(null);
  const fadeHistoryRef = useRef(null);

  // Función auxiliar para obtener el ID del producto
  const getProductId = (product) => product?.productId || product?.id || '';

  // Funciones para asignar colores dinámicos
  const getColorFromKey = (key) => {
    const colors = ['#0385FF', '#10B981', '#FF3B30', '#FF9500', '#8E8E93', '#A855F7'];
    let hash = 0;
    for (let i = 0; i < key.length; i++) {
      hash = key.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  };

  const getColorForCost = (cost) => {
    const key = cost.category?.id || cost.category?.name || '';
    return getColorFromKey(key);
  };

  /* -------------------------------------------------------------------------
     Cargar categorías predefinidas
  ------------------------------------------------------------------------- */
  useEffect(() => {
    const loadCategories = async () => {
      try {
        const response = await CostService.getCategories();
        if (response?.data?.predefined) {
          setCategories(response.data.predefined);
        }
      } catch (err) {
        console.error('Error loading categories:', err);
      }
    };
    loadCategories();
  }, []);

  /* -------------------------------------------------------------------------
     Cargar costos para un producto
  ------------------------------------------------------------------------- */
  const loadCosts = async () => {
    if (!selectedProduct) return;
    try {
      setLoading(true);
      setError(null);
      const response = await CostService.getCostsByProduct(getProductId(selectedProduct));
      if (response.success) {
        setCostData(response.data);
      } else {
        setError('Error loading costs');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct) {
      loadCosts();
    } else {
      setCostData({
        items: [],
        summary: { totalCosts: 0, costsByCategory: {}, totalItems: 0 },
      });
      setError(null);
    }
  }, [selectedProduct]);

  /* -------------------------------------------------------------------------
     Cambiar a la pestaña "Add Cost" si no existen costos
  ------------------------------------------------------------------------- */
  useEffect(() => {
    if (costData.items.length === 0) {
      setTabValue(1);
    }
  }, [costData.items.length]);

  /* -------------------------------------------------------------------------
     Actualizar unidades en formData basándose en el stock del producto seleccionado
  ------------------------------------------------------------------------- */
  useEffect(() => {
    if (selectedProduct && selectedProduct.stock != null) {
      setFormData((prev) => ({ ...prev, units: selectedProduct.stock }));
    }
  }, [selectedProduct]);

  /* -------------------------------------------------------------------------
     Forzar costType a 'other' si la categoría del producto no es 'physical' o 'food'
  ------------------------------------------------------------------------- */
  useEffect(() => {
    if (selectedProduct && !(['physical', 'food'].includes(selectedProduct.category))) {
      setCostType('other');
    }
  }, [selectedProduct]);

  // Determinar si el producto debe mostrar múltiples tipos de costo según su categoría
  const isMultiCostType =
    selectedProduct && ['physical', 'food'].includes(selectedProduct.category);

  /* -------------------------------------------------------------------------
     Renderizar grid para seleccionar el tipo de costo
  ------------------------------------------------------------------------- */
  const renderCostTypeGrid = () => (
    <Grid container spacing={3} sx={{ mb: 4 }}>
      <Grid item xs={12} sm={6} md={4}>
        <StyledCard
          onClick={() => {
            setCostType('wholesale');
            setFormData((prev) => ({ ...prev, costType: 'wholesale' }));
          }}
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: (theme) => `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
            },
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 2 }}>
              <Package size={48} color="#0385FF" />
              <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
                Wholesale Cost
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Enter the total cost for multiple units.
              </Typography>
              <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary', fontStyle: 'italic' }}>
                E.g., $1000 for 100 units
              </Typography>
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <StyledCard
          onClick={() => {
            setCostType('unit');
            setFormData((prev) => ({ ...prev, costType: 'unit' }));
          }}
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: (theme) => `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
            },
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 2 }}>
              <Tag size={48} color="#10B981" />
              <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
                Unit Cost
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Enter the cost per individual unit.
              </Typography>
              <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary', fontStyle: 'italic' }}>
                E.g., $10 per unit
              </Typography>
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <StyledCard
          onClick={() => {
            setCostType('other');
            setFormData((prev) => ({ ...prev, costType: 'other' }));
          }}
          sx={{
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: (theme) => `0 4px 20px ${alpha(theme.palette.common.black, 0.1)}`,
            },
          }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', p: 2 }}>
              <DollarSign size={48} color="#A855F7" />
              <Typography variant="h6" sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
                Other Cost
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Enter any miscellaneous cost.
              </Typography>
              <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary', fontStyle: 'italic' }}>
                E.g., service fees, additional charges, etc.
              </Typography>
            </Box>
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>
  );

  /* -------------------------------------------------------------------------
     Manejar la creación de un nuevo costo (adaptado según el tipo de costo)
  ------------------------------------------------------------------------- */
  const handleAddCost = async () => {
    const numericAmount = Number(formData.amount);
    if (!formData.amount || isNaN(numericAmount)) {
      setError('Amount is required and must be a number');
      return;
    }
    if (costType === 'wholesale' && (!formData.units || isNaN(Number(formData.units)))) {
      setError('Number of units is required and must be a number');
      return;
    }
    try {
      setError(null);
      const payload = {
        ...formData,
        amount: numericAmount,
        categoryId: formData.customCategory ? 'custom' : formData.categoryId,
        categoryName: formData.customCategory ? formData.newCategoryName : undefined,
        costType: costType,
        units: costType === 'wholesale' ? Number(formData.units) : undefined,
        unitCost:
          costType === 'wholesale'
            ? numericAmount / Number(formData.units)
            : numericAmount,
      };

      await CostService.createCost(getProductId(selectedProduct), payload);
      showNotification && showNotification('Cost added successfully');
      // Reiniciar el formulario y volver a la selección de tipo de costo
      setFormData({
        amount: '',
        description: '',
        categoryId: '',
        frequency: 'one-time',
        customCategory: false,
        newCategoryName: '',
        units: selectedProduct?.stock || '',
        costType: '',
      });
      setCostType(null);
      // Activar las notificaciones (badge) en Overview y History si la pestaña no está activa
      setNotifications({
        overview: tabValue !== 0,
        history: tabValue !== 2,
      });
      loadCosts();
    } catch (err) {
      setError(err.message);
    }
  };

  /* -------------------------------------------------------------------------
     Refrescar datos
  ------------------------------------------------------------------------- */
  const handleRefreshData = async () => {
    try {
      setUpdating(true);
      await loadCosts();
    } finally {
      setUpdating(false);
    }
  };

  /* -------------------------------------------------------------------------
     Edit Dialog Handlers
  ------------------------------------------------------------------------- */
  const handleOpenEditDialog = (cost) => {
    setCostToEdit({
      ...cost,
      amount: cost.amount?.toString() || '',
    });
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
    setCostToEdit(null);
  };

  const handleSaveEdit = async () => {
    if (!costToEdit) return;
    try {
      const updatedData = {
        ...costToEdit,
        amount: Number(costToEdit.amount),
      };
      await CostService.updateCost(costToEdit.costId, updatedData);
      showNotification && showNotification('Cost updated successfully');
      handleCloseEditDialog();
      loadCosts();
    } catch (err) {
      setError(err.message);
    }
  };

  /* -------------------------------------------------------------------------
     Delete Dialog Handlers
  ------------------------------------------------------------------------- */
  const handleOpenDeleteDialog = (cost) => {
    setCostToDelete(cost);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setCostToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (!costToDelete) return;
    try {
      await CostService.deleteCost(costToDelete.costId);
      showNotification && showNotification('Cost deleted successfully');
      handleCloseDeleteDialog();
      loadCosts();
    } catch (err) {
      setError(err.message);
    }
  };

  /* -------------------------------------------------------------------------
     Handlers para el menú contextual "More"
  ------------------------------------------------------------------------- */
  const handleMenuOpen = (event, cost) => {
    setAnchorEl(event.currentTarget);
    setSelectedCostForMenu(cost);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCostForMenu(null);
  };

  const handleMenuEdit = () => {
    handleMenuClose();
    if (selectedCostForMenu) {
      handleOpenEditDialog(selectedCostForMenu);
    }
  };

  const handleMenuDelete = () => {
    handleMenuClose();
    if (selectedCostForMenu) {
      handleOpenDeleteDialog(selectedCostForMenu);
    }
  };

  /* -------------------------------------------------------------------------
     Render "Overview" con transición Fade
  ------------------------------------------------------------------------- */
  const renderOverview = () => {
    const { summary } = costData;
    const chartData = Object.entries(summary?.costsByCategory || {}).map(
      ([category, amount]) => ({
        category,
        amount,
      })
    );

    return (
      <Fade in={true} timeout={500} nodeRef={fadeOverviewRef}>
        <div ref={fadeOverviewRef}>
          <Grid container spacing={3}>
            {/* Total Costs Card */}
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <DollarSign size={24} color="#0385FF" style={{ marginRight: 8 }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Total Costs
                    </Typography>
                    <Tooltip title="Total sum of all costs recorded for this product">
                      <span>
                        <Info size={16} style={{ marginLeft: 4, cursor: 'pointer' }} />
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0385FF' }}>
                    ${summary?.totalCosts?.toFixed(2) || '0.00'}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Total Items Card */}
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Package size={24} color="#10B981" style={{ marginRight: 8 }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Total Items
                    </Typography>
                    <Tooltip title="Total number of items with recorded costs">
                      <span>
                        <Info size={16} style={{ marginLeft: 4, cursor: 'pointer' }} />
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#10B981' }}>
                    {summary?.totalItems || 0}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Average Cost per Item Card */}
            <Grid item xs={12} md={4}>
              <StyledCard>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TrendingUp size={24} color="#10B981" style={{ marginRight: 8 }} />
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      Avg Cost per Item
                    </Typography>
                    <Tooltip title="Average cost calculated by dividing total cost by number of items">
                      <span>
                        <Info size={16} style={{ marginLeft: 4, cursor: 'pointer' }} />
                      </span>
                    </Tooltip>
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      color: getColorFromKey(`average-${getProductId(selectedProduct)}`),
                    }}
                  >
                    $
                    {summary?.totalItems
                      ? (summary.totalCosts / summary.totalItems).toFixed(2)
                      : '0.00'}
                  </Typography>
                </CardContent>
              </StyledCard>
            </Grid>

            {/* Bar Chart */}
            <Grid item xs={12}>
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 4 }}>
                    Costs by Category
                  </Typography>
                  <Box sx={{ width: '100%', overflowX: 'auto' }}>
                    <Box sx={{ minWidth: 500, height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={chartData}>
                          <XAxis dataKey="category" />
                          <YAxis />
                          <RechartsTooltip />
                          <Bar dataKey="amount">
                            {chartData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={getColorFromKey(entry.category)} />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                </CardContent>
              </StyledCard>
            </Grid>
          </Grid>
        </div>
      </Fade>
    );
  };

  /* -------------------------------------------------------------------------
     Render "History" con transición Fade
  ------------------------------------------------------------------------- */
  const renderHistory = () => {
    const getChipStyle = (type) => ({
      bgcolor: '#0385FF',
      color: '#FFFFFF',
      fontSize: '0.65rem',
      fontWeight: 600,
      padding: '2px 8px',
      borderRadius: '28px',
      '& .MuiChip-label': {
        fontSize: '0.65rem',
        padding: '0 4px',
      },
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: 'translateY(-1px)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        bgcolor:
          type === 'date'
            ? 'rgba(3, 133, 255, 0.18)'
            : type === 'category'
            ? 'rgba(139, 92, 246, 0.18)'
            : 'rgba(34, 197, 94, 0.18)',
      },
      '& .MuiChip-icon': {
        color: 'inherit',
        marginRight: '4px',
      },
    });

    const sortedCosts = [...costData.items].sort(
      (a, b) => new Date(b.metadata.createdAt) - new Date(a.metadata.createdAt)
    );

    return (
      <Fade in={true} timeout={500} nodeRef={fadeHistoryRef}>
        <div ref={fadeHistoryRef}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <List>
              {sortedCosts.map((cost, index) => (
                <React.Fragment key={cost.costId}>
                  <ListItem>
                    <StyledCard sx={{ width: '100%' }}>
                      <CardContent>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}
                        >
                          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
                            <Typography variant="h6">{cost.description}</Typography>
                            <Box sx={{ display: 'flex', gap: 1, mt: 2, flexWrap: 'wrap' }}>
                              <Chip
                                size="small"
                                icon={<Clock size={14} />}
                                label={new Date(cost.metadata.createdAt).toLocaleDateString()}
                                sx={getChipStyle('date')}
                              />
                              <Chip
                                size="small"
                                icon={<Tag size={14} />}
                                label={cost.category.name}
                                sx={getChipStyle('category')}
                              />
                              <Chip
                                size="small"
                                label={cost.frequency}
                                sx={getChipStyle('frequency')}
                              />
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography
                              variant="h6"
                              sx={{
                                color: getColorForCost(cost),
                                whiteSpace: 'nowrap',
                                fontWeight: 600,
                              }}
                            >
                              ${cost.amount.toFixed(2)}
                            </Typography>
                            <IconButton
                              aria-label="more options"
                              onClick={(e) => handleMenuOpen(e, cost)}
                              size="small"
                              sx={{
                                color: 'text.secondary',
                                '&:hover': { color: 'text.primary' },
                              }}
                            >
                              <MoreVertical size={18} />
                            </IconButton>
                          </Box>
                        </Box>
                      </CardContent>
                    </StyledCard>
                  </ListItem>
                  {index < sortedCosts.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {costData.items.length === 0 && (
                <Typography variant="body1" sx={{ textAlign: 'center', color: 'text.secondary', py: 4 }}>
                  No cost history available
                </Typography>
              )}
            </List>
          )}
        </div>
      </Fade>
    );
  };

  /* -------------------------------------------------------------------------
     Caso 1: No se ha seleccionado un producto
  ------------------------------------------------------------------------- */
  if (!selectedProduct) {
    return (
      <StyledScrollContainer>
        <ProductSelector
          products={products}
          selectedProduct={selectedProduct}
          onProductSelect={setSelectedProduct}
          loading={productsLoading}
          compact
        />
        <StyledPaper sx={{ textAlign: 'center', py: 8, mt: 3 }}>
          <Package size={48} style={{ marginBottom: '16px', color: alpha(navColors.textSecondary, 0.6) }} />
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
            Select a Product
          </Typography>
          <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
            Choose a product above to view costs.
          </Typography>
        </StyledPaper>
      </StyledScrollContainer>
    );
  }

  /* -------------------------------------------------------------------------
     Caso 2: Producto seleccionado → Mostrar contenido
  ------------------------------------------------------------------------- */
  return (
    <StyledScrollContainer>
      <StyledPaper sx={{ mb: 3 }}>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ mb: { xs: 1, sm: 0 } }}>
            <Typography variant="h5" sx={{ fontWeight: 700, letterSpacing: '-0.02em' }}>
              {selectedProduct.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Cost Management
            </Typography>
            {entrepreneurLevel === 'beginner' && (
              <Typography variant="caption" color="text.secondary">
                Tip: Use the tabs below to manage and analyze your costs. Please fill in all fields carefully.
              </Typography>
            )}
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Refresh costs">
              <IconButton onClick={handleRefreshData} disabled={updating} aria-label="refresh">
                <RefreshCw
                  size={20}
                  style={{
                    animation: updating ? 'spin 1s linear infinite' : 'none',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Button
              disableElevation
              variant="contained"
              onClick={() => setSelectedProduct(null)}
              startIcon={<ChevronLeft size={16} />}
              sx={{
                backgroundColor: '#0385FF',
                color: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '12px',
                textTransform: 'none',
                fontWeight: 600,
                boxShadow: 'none',
                '&:hover': { backgroundColor: '#0385FF', boxShadow: 'none' },
              }}
            >
              Change Product
            </Button>
          </Box>
        </Box>

        {/* Error global */}
        {error && (
          <Alert severity="error" sx={{ mb: 3, borderRadius: '12px' }} onClose={() => setError(null)}>
            {error}
          </Alert>
        )}

        {/* Pestañas */}
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => {
            setTabValue(newValue);
            // Al cambiar a una pestaña, se oculta el badge correspondiente
            if (newValue === 0) {
              setNotifications((prev) => ({ ...prev, overview: false }));
            } else if (newValue === 2) {
              setNotifications((prev) => ({ ...prev, history: false }));
            }
          }}
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{ style: { backgroundColor: '#0385FF' } }}
          sx={{
            mb: 3,
            '& .MuiTab-root': {
              color: '#0385FF',
              textTransform: 'none',
              fontWeight: 600,
            },
            '& .Mui-selected': { color: '#0385FF' },
          }}
        >
          <Tab
            label={
              <Badge color="error" variant="dot" invisible={!notifications.overview}>
                Overview
              </Badge>
            }
          />
          <Tab label="Add Cost" />
          <Tab
            label={
              <Badge color="error" variant="dot" invisible={!notifications.history}>
                History
              </Badge>
            }
          />
        </Tabs>

        {/* Contenido según la pestaña seleccionada */}
        {tabValue === 0 && renderOverview()}
        {tabValue === 1 && (
          <Fade in={true} timeout={500} nodeRef={fadeAddCostRef}>
            <div ref={fadeAddCostRef}>
              {isMultiCostType ? (
                // Para productos con categoría "physical" o "food", mostrar selección completa de tipos de costo
                !costType ? (
                  renderCostTypeGrid()
                ) : (
                  <Box sx={{ maxWidth: 600, mx: 'auto' }}>
                    <Box sx={{ mb: 3 }}>
                      <Button
                        startIcon={<ChevronLeft />}
                        onClick={() => {
                          setCostType(null);
                          setFormData({
                            amount: '',
                            description: '',
                            categoryId: '',
                            frequency: 'one-time',
                            customCategory: false,
                            newCategoryName: '',
                            units: selectedProduct?.stock || '',
                            costType: '',
                          });
                        }}
                        sx={{
                          color: 'text.secondary',
                          '&:hover': { backgroundColor: 'transparent' },
                        }}
                      >
                        Back to cost types
                      </Button>
                    </Box>
                    <Grid container spacing={3}>
                      {costType === 'wholesale' && (
                        <>
                          <Grid item xs={12} md={6}>
                            <StyledTextField
                              fullWidth
                              label="Total Cost"
                              placeholder="Enter the total cost for all units"
                              type="number"
                              value={formData.amount}
                              onChange={(e) => {
                                const amount = e.target.value;
                                setFormData((prev) => ({
                                  ...prev,
                                  amount,
                                  unitCost: selectedProduct?.stock ? amount / selectedProduct.stock : 0,
                                }));
                              }}
                              InputProps={{
                                startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <StyledTextField
                              fullWidth
                              label="Number of Units"
                              placeholder="Product stock"
                              type="number"
                              value={selectedProduct?.stock || formData.units}
                              disabled
                            />
                          </Grid>
                          {formData.amount && selectedProduct?.stock && (
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <Typography variant="caption" color="text.secondary">
                                  Each unit costs ${(Number(formData.amount) / Number(selectedProduct.stock)).toFixed(2)}. Is this correct?
                                </Typography>
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => {
                                    setCostType('unit');
                                    setFormData((prev) => ({ ...prev, costType: 'unit' }));
                                  }}
                                  sx={{
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    padding: 0,
                                    ml: 1,
                                  }}
                                >
                                  Try Unit Cost instead.
                                </Button>
                              </Box>
                            </Grid>
                          )}
                        </>
                      )}
                      {costType === 'unit' && (
                        <>
                          <Grid item xs={12}>
                            <StyledTextField
                              fullWidth
                              label="Cost per Unit"
                              placeholder="Enter the cost per unit"
                              type="number"
                              value={formData.amount}
                              onChange={(e) =>
                                setFormData({ ...formData, amount: e.target.value })
                              }
                              InputProps={{
                                startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />,
                              }}
                            />
                          </Grid>
                          {formData.amount && (
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                <Typography variant="caption" color="text.secondary">
                                  Each unit costs ${Number(formData.amount).toFixed(2)}. Is this correct?
                                </Typography>
                                <Button
                                  variant="text"
                                  size="small"
                                  onClick={() => {
                                    setCostType('wholesale');
                                    setFormData((prev) => ({ ...prev, costType: 'wholesale' }));
                                  }}
                                  sx={{
                                    textTransform: 'none',
                                    textDecoration: 'underline',
                                    padding: 0,
                                    ml: 1,
                                  }}
                                >
                                  Try Total Cost instead.
                                </Button>
                              </Box>
                            </Grid>
                          )}
                        </>
                      )}
                      {costType === 'other' && (
                        <>
                          <Grid item xs={12}>
                            <StyledTextField
                              fullWidth
                              label="Cost"
                              placeholder="Enter the cost of the product or service"
                              type="number"
                              value={formData.amount}
                              onChange={(e) =>
                                setFormData({ ...formData, amount: e.target.value })
                              }
                              InputProps={{
                                startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />,
                              }}
                            />
                          </Grid>
                        </>
                      )}
                      {/* Campos comunes */}
                      <Grid item xs={12}>
                        <StyledTextField
                          fullWidth
                          label="Description"
                          placeholder="Describe your cost. E.g., how the cost was incurred."
                          value={formData.description}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                          <InputLabel>Category</InputLabel>
                          <Select
                            value={formData.categoryId}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                categoryId: e.target.value,
                                customCategory: e.target.value === 'custom',
                              })
                            }
                            label="Category"
                          >
                            <MenuItem value="">Select a category</MenuItem>
                            {categories.map((category) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.name}
                              </MenuItem>
                            ))}
                            <MenuItem value="custom">+ Create custom category</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {formData.customCategory && (
                        <Grid item xs={12} md={6}>
                          <StyledTextField
                            fullWidth
                            label="New Category Name"
                            placeholder="Enter a new category name"
                            value={formData.newCategoryName}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                newCategoryName: e.target.value,
                              })
                            }
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>Frequency</InputLabel>
                          <Select
                            value={formData.frequency}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                frequency: e.target.value,
                              })
                            }
                            label="Frequency"
                          >
                            <MenuItem value="one-time">One-time</MenuItem>
                            <MenuItem value="monthly">Monthly</MenuItem>
                            <MenuItem value="yearly">Yearly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={handleAddCost}
                          startIcon={<Plus size={16} />}
                          disabled={loading}
                          sx={{
                            borderRadius: '12px',
                            textTransform: 'none',
                            py: 1.5,
                            backgroundColor: '#0385FF',
                            color: '#fff',
                            fontWeight: 600,
                            '&:hover': { backgroundColor: '#0385FF' },
                          }}
                        >
                          {loading ? 'Adding...' : 'Add Cost'}
                        </Button>
                      </Grid>
                    </Grid>
                    {entrepreneurLevel === 'beginner' && (
                      <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
                        Please ensure all fields are completed for accurate cost tracking.
                      </Typography>
                    )}
                  </Box>
                )
              ) : (
                // Para productos que NO estén en las categorías 'physical' o 'food', se fuerza el formulario "Other Cost"
                <Box sx={{ maxWidth: 600, mx: 'auto' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Cost"
                        placeholder="Enter the cost of the product or service"
                        type="number"
                        value={formData.amount}
                        onChange={(e) =>
                          setFormData({ ...formData, amount: e.target.value })
                        }
                        InputProps={{
                          startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Description"
                        placeholder="Describe the cost, e.g., how it was incurred."
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({ ...formData, description: e.target.value })
                        }
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                          value={formData.categoryId}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              categoryId: e.target.value,
                              customCategory: e.target.value === 'custom',
                            })
                          }
                          label="Category"
                        >
                          <MenuItem value="">Select a category</MenuItem>
                          {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                          <MenuItem value="custom">+ Create custom category</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {formData.customCategory && (
                      <Grid item xs={12} md={6}>
                        <StyledTextField
                          fullWidth
                          label="New Category Name"
                          placeholder="Enter a new category name"
                          value={formData.newCategoryName}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              newCategoryName: e.target.value,
                            })
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel>Frequency</InputLabel>
                        <Select
                          value={formData.frequency}
                          onChange={(e) =>
                            setFormData({ ...formData, frequency: e.target.value })
                          }
                          label="Frequency"
                        >
                          <MenuItem value="one-time">One-time</MenuItem>
                          <MenuItem value="monthly">Monthly</MenuItem>
                          <MenuItem value="yearly">Yearly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={handleAddCost}
                        startIcon={<Plus size={16} />}
                        disabled={loading}
                        sx={{
                          borderRadius: '12px',
                          textTransform: 'none',
                          py: 1.5,
                          backgroundColor: '#0385FF',
                          color: '#fff',
                          fontWeight: 600,
                          '&:hover': { backgroundColor: '#0385FF' },
                        }}
                      >
                        {loading ? 'Adding...' : 'Add Cost'}
                      </Button>
                    </Grid>
                  </Grid>
                  {entrepreneurLevel === 'beginner' && (
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
                      Please ensure all fields are completed for accurate cost tracking.
                    </Typography>
                  )}
                </Box>
              )}
            </div>
          </Fade>
        )}
        {tabValue === 2 && renderHistory()}
      </StyledPaper>

      {/* Menú contextual "More" */}
      <MuiMenu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MuiMenuItem onClick={handleMenuEdit}>Edit</MuiMenuItem>
        <MuiMenuItem onClick={handleMenuDelete}>Delete</MuiMenuItem>
      </MuiMenu>

      {/* Edit Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          elevation: 0,
          sx: { boxShadow: 'none', borderRadius: '16px' }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Edit Cost</DialogTitle>
        <DialogContent dividers>
          {costToEdit && (
            <>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  Cost ID: {costToEdit.costId}
                </Typography>
              </Box>
              <StyledTextField
                fullWidth
                label="Cost"
                placeholder="Update the cost as necessary"
                type="number"
                sx={{ mb: 3 }}
                value={costToEdit.amount}
                onChange={(e) =>
                  setCostToEdit({
                    ...costToEdit,
                    amount: e.target.value,
                  })
                }
                InputProps={{
                  startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />,
                }}
              />
              <StyledTextField
                fullWidth
                label="Description"
                placeholder="Update the description. E.g., details about how the cost was incurred."
                multiline
                rows={2}
                sx={{ mb: 3 }}
                value={costToEdit.description}
                onChange={(e) =>
                  setCostToEdit({
                    ...costToEdit,
                    description: e.target.value,
                  })
                }
              />
              <FormControl fullWidth>
                <InputLabel>Frequency</InputLabel>
                <Select
                  value={costToEdit.frequency}
                  onChange={(e) =>
                    setCostToEdit({
                      ...costToEdit,
                      frequency: e.target.value,
                    })
                  }
                  label="Frequency"
                >
                  <MenuItem value="one-time">One-time</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditDialog}
            sx={{
              fontWeight: 600,
              borderRadius: '12px',
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveEdit}
            sx={{
              fontWeight: 600,
              borderRadius: '12px',
              textTransform: 'none',
              boxShadow: 'none'
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        PaperProps={{
          elevation: 0,
          sx: { boxShadow: 'none', borderRadius: '16px' }
        }}
      >
        <DialogTitle sx={{ fontWeight: 600 }}>Delete Cost</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            Are you sure you want to delete this cost?
          </Typography>
          {costToDelete && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              ID: {costToDelete.costId} - Description: {costToDelete.description}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            sx={{
              fontWeight: 600,
              borderRadius: '12px',
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmDelete}
            sx={{
              fontWeight: 600,
              borderRadius: '12px',
              textTransform: 'none',
              boxShadow: 'none'
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </StyledScrollContainer>
  );
};

export default Cost;
