// D:\directiveai-react\frontend\src\components\AGTUps\services\AGTUps-product-services\salesStrategyService.js

import { api } from './config'; // Ajusta la ruta si tu config está en otro lugar

// Endpoint base para el módulo de ventas
const SALES_BASE = '/sales';

class SalesStrategyService {
  /** ---------------------------------------------------------------------------
   *  REGISTRO DE VENTAS REALES
   *  ---------------------------------------------------------------------------
   */
  static async createSalesRecord(recordData) {
    try {
      const response = await api.post(`${SALES_BASE}/record`, recordData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        throw new Error(error.response.data.error);
      }
      throw new Error(`Error creating sales record: ${error.message}`);
    }
  }  

  /**
   * Obtener la ubicación del usuario desde su perfil
   */
  static async getUserLocation() {
    try {
      const response = await api.get(`${SALES_BASE}/user-location`);
      return response.data.location;
    } catch (error) {
      console.error('Error fetching user location:', error);
      return 'N/A';
    }
  }

  /** ---------------------------------------------------------------------------
   *  CREAR ESTRATEGIA DE VENTAS
   *  ---------------------------------------------------------------------------
   *  Registra la estrategia en la tabla AGTUpsSalesStrategy (ej. metas, period, etc.)
   */
  static async createSalesStrategy(strategyData) {
    try {
      const validatedData = this.formatStrategyData(strategyData);
      const validation = this.validateStrategyData(validatedData);

      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.post(`${SALES_BASE}`, validatedData);
      return response.data; // { success, data: {...} }
    } catch (error) {
      throw new Error(`Error creating sales strategy: ${error.message}`);
    }
  }

  /**
   *  OBTENER ESTRATEGIA POR ID
   */
  static async getSalesStrategyById(strategyId, productId) {
    try {
      const response = await api.get(`${SALES_BASE}/${strategyId}/${productId}`);
      return response.data; // { success, data, access }
    } catch (error) {
      throw new Error(`Error fetching sales strategy: ${error.message}`);
    }
  }

  /**
   *  OBTENER ESTRATEGIAS POR PRODUCTO
   */
  static async getSalesStrategiesByProduct(productId, params = {}) {
    try {
      const response = await api.get(`${SALES_BASE}/product/${productId}`, { params });
      return response.data; // { success, data: { owned: [...], shared: [...] } }
    } catch (error) {
      throw new Error(`Error fetching sales strategies: ${error.message}`);
    }
  }

  /**
   *  ANALIZAR RENDIMIENTO DE VENTAS
   *  Esto llama GET /sales/performance/:productId, que en el backend ahora
   *  combina datos de AGTUpsSalesRecords con la estrategia (opcional).
   */
  static async analyzeSalesPerformance(productId, params = {}) {
    try {
      const response = await api.get(`${SALES_BASE}/performance/${productId}`, { params });
      // El backend retorna: { success, data: { timeseries, byChannel, byRegion, overall, strategy, ... } }
      return response.data;
    } catch (error) {
      throw new Error(`Error analyzing sales performance: ${error.message}`);
    }
  }

  /**
   *  ACTUALIZAR ESTRATEGIA DE VENTAS
   */
  static async updateSalesStrategy(strategyId, productId, updateData) {
    try {
      const validatedData = this.formatStrategyData(updateData);
      const validation = this.validateStrategyData(validatedData);

      if (!validation.isValid) {
        throw new Error(`Validation error: ${validation.errors.join(', ')}`);
      }

      const response = await api.put(`${SALES_BASE}/${strategyId}/${productId}`, validatedData);
      return response.data; // { success, data: {...} }
    } catch (error) {
      throw new Error(`Error updating sales strategy: ${error.message}`);
    }
  }

  /**
   *  ELIMINAR ESTRATEGIA DE VENTAS
   */
  static async deleteSalesStrategy(strategyId, productId) {
    try {
      const response = await api.delete(`${SALES_BASE}/${strategyId}/${productId}`);
      return response.data; // { success, message: 'Sales strategy deleted' }
    } catch (error) {
      throw new Error(`Error deleting sales strategy: ${error.message}`);
    }
  }

  /**
   * GENERAR ANÁLISIS CON CLAUDE
   * Llama a la API para generar el análisis utilizando Claude para el producto indicado.
   */
  static async generateAnalysisWithClaude(productId) {
    try {
      const response = await api.post(`${SALES_BASE}/analysis/${productId}/generate`);
      // Se espera que el backend retorne: { success, data: { analysis: { topRegions, opportunities, seasonal, segments } } }
      return response.data;
    } catch (error) {
      if (error.response?.status === 429) {
        throw new Error('Rate limit exceeded. Please try again later.');
      }
      if (error.response?.status === 400) {
        throw new Error('Insufficient data for analysis.');
      }
      throw new Error(`Error generating analysis: ${error.message}`);
    }
  }

  /** ---------------------------------------------------------------------------
   *  FORMATEAR DATOS PARA GRÁFICOS (Frontend)
   *  ---------------------------------------------------------------------------
   *  El backend en analyzeSalesPerformance puede retornar un objeto:
   *    {
   *      timeseries: [ { date, volume, target, channel, region }, ... ],
   *      byChannel: { direct: { totalRevenue, ... }, online: {...} },
   *      byRegion: {...},
   *      overall: { totalRevenue, totalQuantity, avgOrderValue, targetAchievement },
   *      strategy: {...}, (opcional)
   *      period: { start, end },
   *      metadata: { dataPoints, coverage }
   *    }
   */
  static formatChartData(performanceData) {
    
    if (!performanceData) {
      return null;
    }
  
    try {
      const {
        timeseries = [],
        byChannel = {},
        byRegion = {},
        overall = null
      } = performanceData;
  
      // Queremos mostrar un rango de un mes completo: desde hace un mes (incluido) hasta hoy.
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Establecemos la hora a 00:00:00 para evitar problemas de comparación.
      
      const startDate = new Date(today);
      startDate.setMonth(startDate.getMonth() - 1); // Hace un mes atrás
  
      // Generamos un arreglo de días entre startDate y today (cada día representado como timestamp)
      let dateArray = [];
      for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
        // Usamos una copia de la fecha para evitar que se modifique d en la siguiente iteración
        dateArray.push(new Date(d).getTime());
      }
  
      // Agrupar las ventas registradas (timeseries) por día.
      // Suponemos que cada elemento de timeseries tiene { date, quantity, ... }
      const salesByDay = {};
      timeseries.forEach(item => {
        const d = new Date(item.date);
        d.setHours(0, 0, 0, 0);
        const dayTimestamp = d.getTime();
        if (!salesByDay[dayTimestamp]) {
          salesByDay[dayTimestamp] = 0;
        }
        // Sumamos la cantidad; nos aseguramos de convertir a entero.
        salesByDay[dayTimestamp] += parseInt(item.quantity, 10);
      });
  
      // Creamos el arreglo salesTrend que incluye todos los días del rango, asignando 0 si no hay ventas.
      const salesTrend = dateArray.map(dayTimestamp => ({
        date: dayTimestamp,
        quantity: salesByDay[dayTimestamp] || 0
      }));
  
      // Se mantienen las conversiones para los demás gráficos (por canal y región)
      const channelDistribution = Object.entries(byChannel).map(([channelName, info]) => ({
        name: channelName.charAt(0).toUpperCase() + channelName.slice(1),
        value: parseFloat(info.totalRevenue || 0)
      }));
  
      const regionDistribution = Object.entries(byRegion).map(([regionName, info]) => ({
        name: regionName,
        value: parseFloat(info.totalRevenue || 0)
      }));
  
      return {
        // Ordenamos por fecha de forma ascendente (aunque ya se generó en orden cronológico)
        salesTrend: salesTrend.sort((a, b) => a.date - b.date),
        channelDistribution,
        regionDistribution,
        overall: {
          ...overall,
          totalRevenue: parseFloat(overall?.totalRevenue || 0),
          totalQuantity: parseInt(overall?.totalQuantity || 0, 10),
          avgOrderValue: parseFloat(overall?.avgOrderValue || 0),
          totalOrders: parseInt(overall?.totalOrders || 0, 10)
        }
      };
    } catch (error) {
      console.error('Error formatting chart data:', error);
      return null;
    }
  }   

  /**
   *  VALIDAR DATOS DE ESTRATEGIA
   *  Ajusta según si quieres exigir "salesTargets" obligatorio o permitir sin él
   */
  static validateStrategyData(strategyData) {
    const errors = [];

    if (!strategyData.productId) {
      errors.push('Product ID is required');
    }
    // Podrías no exigir salesTargets si quieres permitirlo vacío
    if (!strategyData.salesTargets || Object.keys(strategyData.salesTargets).length === 0) {
      errors.push('Sales targets are required');
    }

    if (strategyData.channelDistribution && !Array.isArray(strategyData.channelDistribution)) {
      errors.push('Channel distribution must be an array if provided');
    }

    return {
      isValid: errors.length === 0,
      errors
    };
  }

  /**
   *  FORMATEAR DATOS DE ESTRATEGIA PARA CREATE/UPDATE
   */
  static formatStrategyData(strategyData) {
    return {
      productId: strategyData.productId,
      salesTargets: strategyData.salesTargets || {},
      channelDistribution: Array.isArray(strategyData.channelDistribution)
        ? strategyData.channelDistribution
        : [],
      salesFunnel: strategyData.salesFunnel || {},
      performanceMetrics: strategyData.performanceMetrics || {},
      teamPerformance: Array.isArray(strategyData.teamPerformance)
        ? strategyData.teamPerformance
        : [],
      geographicPerformance: strategyData.geographicPerformance || {},
      seasonalTrends: Array.isArray(strategyData.seasonalTrends)
        ? strategyData.seasonalTrends
        : [],
      customerSegments: Array.isArray(strategyData.customerSegments)
        ? strategyData.customerSegments
        : []
    };
  }

  /**
   * (Opcional) Calcular métricas de rendimiento en el frontend
   * si quisieras, pero normalmente las calculamos en el backend.
   */
  static calculatePerformanceMetrics(salesData, targets) {
    // Ejemplo: tu backend ya lo hace; esto sería un fallback
    return {
      achievement: (salesData.total / targets.total) * 100,
      // ...
    };
  }
}

export default SalesStrategyService;
