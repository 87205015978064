import React, { useState, useEffect, useCallback, useMemo, Suspense } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Tooltip,
  LinearProgress,
  Fade,
  Paper,
  Grid
} from '@mui/material';
import { styled, darken } from '@mui/material/styles';
import { galleryImages } from '../galleryImages';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import {
  Package,
  DollarSign,
  ShoppingBag,
  Briefcase,
  Smartphone,
  Coffee,
  Edit,
  Upload
} from 'lucide-react';
import AGTEnhanceButton from './AGTEnhanceButton';

const blue = '#007BFE';

// Styled Dialog sin sombra
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '28px',
    padding: theme.spacing(2),
    overflow: 'hidden',
    boxShadow: 'none'
  }
}));

const StepContent = styled(DialogContent)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  '&.slide-enter': {
    transform: 'translateX(100%)',
    opacity: 0
  },
  '&.slide-enter-active': {
    transform: 'translateX(0)',
    opacity: 1
  },
  '&.slide-exit': {
    transform: 'translateX(-100%)',
    opacity: 0
  }
}));

const StepIndicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1)
}));

const StepDot = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'completed'
})(({ theme, active, completed }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active
    ? theme.palette.primary.main
    : completed
      ? theme.palette.primary.light
      : theme.palette.grey[300],
  transition: 'all 0.3s ease-in-out',
  transform: active ? 'scale(1.2)' : 'scale(1)',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.1)'
  }
}));

const NoShadowTooltip = styled(Tooltip)(() => ({
  '& .MuiTooltip-tooltip': {
    boxShadow: 'none'
  }
}));

// Componente para mostrar el resumen del producto
const SummaryContent = ({ steps, productData }) => {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Product Summary
      </Typography>
      <Grid container spacing={2}>
        {steps.map((step, index) => (
          <Grid item xs={12} key={index}>
            <Paper sx={{ p: 2, borderRadius: '16px', boxShadow: 'none' }}>
              <Typography variant="subtitle1" color="primary">
                {step.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                {step.getValue(productData)}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Categorías por defecto
const defaultCategories = [
  { id: 'physical', name: 'Physical Products', icon: Package, color: '#4f46e5' },
  { id: 'digital', name: 'Digital Products', icon: Smartphone, color: '#0891b2' },
  { id: 'services', name: 'Services', icon: Briefcase, color: '#059669' },
  { id: 'subscription', name: 'Subscriptions', icon: ShoppingBag, color: '#7c3aed' },
  { id: 'food', name: 'Food & Beverage', icon: Coffee, color: '#db2777' }
];

// Agregamos un valor por defecto en el parámetro productData para evitar que sea undefined.
const ProductSetupWizard = ({
  open,
  onClose,
  productData = {
    name: '',
    description: '',
    category: '',
    price: '',
    stock: '',
    customFields: {},
    imageUrl: ''
  },
  onChange,
  onSubmit,
  onImageUpload,
  imagePreview,
  enhanceLoading,
  onEnhanceDescription,
  onEnhanceCategory,
  onEnhancePrice,
  onGetMarketingTips,
  onSelectGalleryImage,
  loading,
  isTyping
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [transitioning, setTransitioning] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [priceEnhanced, setPriceEnhanced] = useState(false);

  const handleEnhancePrice = useCallback(async () => {
    await onEnhancePrice();
    setPriceEnhanced(true);
  }, [onEnhancePrice]);

  // Construcción condicional de los pasos
  const steps = useMemo(() => {
    const baseSteps = [
      {
        title: 'Product Name',
        description: 'What is your product called?',
        required: true,
        field: 'name',
        getValue: (data) => data.name || 'Not provided',
        component: (
          <TextField
            fullWidth
            label="Product Name"
            name="name"
            value={productData.name}
            onChange={onChange}
            required
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': { borderRadius: '28px' }
            }}
          />
        )
      },
      {
        title: 'Category',
        description: 'What type of product is this?',
        required: true,
        field: 'category',
        getValue: (data) => {
          const category = defaultCategories.find(c => c.id === data.category);
          return category ? category.name : 'Not provided';
        },
        component: (
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={productData.category}
                onChange={onChange}
                sx={{ borderRadius: '28px' }}
              >
                {defaultCategories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {React.createElement(cat.icon, {
                        size: 16,
                        style: { color: cat.color }
                      })}
                      {cat.name}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={onEnhanceCategory}
                isLoading={enhanceLoading.category}
                disabled={enhanceLoading.category}
                label="Let AGT Decide"
                tooltip="Have AI suggest the best category"
              />
            </Box>
          </Box>
        )
      }
    ];

    // Agregar el paso "Stock" solo para Physical Products o Food & Beverage
    if (productData.category === 'physical' || productData.category === 'food') {
      baseSteps.push({
        title: 'Stock',
        description: 'The quantity of this product you have at the time of creation',
        required: true,
        field: 'stock',
        getValue: (data) => data.stock || 'Not provided',
        component: (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Stock"
              name="stock"
              type="number"
              value={productData.stock}
              onChange={onChange}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
          </Box>
        )
      });
    }

    // Agregar los pasos restantes
    baseSteps.push(
      {
        title: 'Description',
        description: 'Describe your product in detail',
        required: true,
        field: 'description',
        getValue: (data) => data.description || 'Not provided',
        component: (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Description"
              name="description"
              value={productData.description}
              onChange={onChange}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={onEnhanceDescription}
                isLoading={enhanceLoading.description}
                disabled={enhanceLoading.description || isTyping}
                label="Enhance with AGT"
                tooltip="Automatically enhance the product description using AI"
              />
            </Box>
          </Box>
        )
      },
      {
        title: 'Price',
        description: 'Set your product price',
        required: true,
        field: 'price',
        getValue: (data) => data.price ? `$${data.price}` : 'Not provided',
        component: (
          <Box sx={{ mt: 2 }}>
            <TextField
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={productData.price}
              onChange={onChange}
              InputProps={{
                startAdornment: <DollarSign size={16} style={{ marginRight: 8 }} />
              }}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '28px' } }}
            />
            {priceEnhanced && (
              <Typography variant="caption" sx={{ color: blue, mt: 1 }}>
                Note: AGT's accuracy depends on the specific details of the product. Please double-check.
              </Typography>
            )}
            <Box sx={{ mt: 2 }}>
              <AGTEnhanceButton
                onEnhance={handleEnhancePrice}
                isLoading={enhanceLoading.price}
                disabled={enhanceLoading.price}
                label="Let AGT Decide"
                tooltip="Have AI suggest a suitable price"
              />
            </Box>
          </Box>
        )
      },
      {
        title: 'Image',
        description: 'Add a product image',
        required: false,
        field: 'imageUrl',
        getValue: (data) => data.imageUrl ? 'Image provided' : 'No image',
        component: (
          <Box sx={{ mt: 2 }}>
            {/* Upload Section */}
            <Box
              sx={{
                border: '2px dashed',
                borderColor: 'rgba(0,0,0,0.1)',
                borderRadius: '12px',
                padding: '24px',
                textAlign: 'center',
                mb: 4
              }}
            >
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
                Upload Your Image
              </Typography>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={onImageUpload}
                style={{ display: 'none' }}
                id="product-image-upload"
              />
              <label htmlFor="product-image-upload">
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={imagePreview ? <Edit /> : <Upload />}
                  sx={{ borderRadius: '28px', mb: 2 }}
                >
                  {imagePreview ? 'Change Image' : 'Upload Image'}
                </Button>
              </label>
              {imagePreview && (
                <Box sx={{ mt: 2, position: 'relative', maxWidth: '200px', margin: '0 auto' }}>
                  <img
                    src={imagePreview}
                    alt="Product Preview"
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '8px'
                    }}
                  />
                </Box>
              )}
            </Box>

            {/* Gallery Section */}
            <Box sx={{ mt: 4 }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, fontWeight: 600, textAlign: 'center' }}
              >
                Or choose from DirectiveAI gallery
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
                  gap: 2,
                  justifyContent: 'center'
                }}
              >
                {galleryImages.map((url, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      aspectRatio: '1',
                      borderRadius: '8px',
                      overflow: 'hidden',
                      border:
                        productData.imageUrl === url
                          ? '3px solid #1DB954'
                          : '2px solid transparent',
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      '&:hover': { transform: 'scale(1.05)' }
                    }}
                    onClick={() => {
                      onChange({
                        target: { name: 'imageUrl', value: url }
                      });
                      if (typeof onSelectGalleryImage === 'function') {
                        onSelectGalleryImage(url);
                      }
                    }}
                  >
                    <img
                      src={url}
                      alt={`Gallery ${index}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        )
      },
      {
        title: 'Need marketing tips?',
        description: '',
        required: false,
        field: 'marketingTips',
        getValue: () => 'Optional marketing tips',
        component: (
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
              Let AGT analyze your product and provide tailored marketing suggestions to help boost your sales
            </Typography>
            <AGTEnhanceButton
              onEnhance={onGetMarketingTips}
              isLoading={loading}
              disabled={loading}
              label="Get Marketing Tips"
              tooltip="Generate marketing tips for this product"
              sx={{ minWidth: '200px' }}
            />
          </Box>
        )
      }
    );

    return baseSteps;
  }, [
    productData,
    onChange,
    enhanceLoading,
    onEnhanceDescription,
    onEnhanceCategory,
    handleEnhancePrice,
    imagePreview,
    onImageUpload,
    isTyping,
    onGetMarketingTips,
    loading,
    priceEnhanced
  ]);

  const validateStep = useCallback(
    (stepIndex) => {
      const step = steps[stepIndex];
      if (!step) return true; // Si el paso no existe, se retorna true (o false, según la lógica deseada)
      if (!step.required) return true;
      const value = productData[step.field];
      return value !== undefined && value !== '';
    },
    [steps, productData]
  );  

  const handleStepTransition = useCallback((nextStep) => {
    setTransitioning(true);
    const timeout = setTimeout(() => {
      setActiveStep(nextStep);
      setTransitioning(false);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  const handleNext = useCallback(async () => {
    if (!validateStep(activeStep)) return;
    if (activeStep === steps.length - 1) {
      if (!showSummary) {
        setShowSummary(true);
        return;
      }
      setIsSubmitting(true);
      try {
        await onSubmit();
      } finally {
        setIsSubmitting(false);
      }
      return;
    }
    handleStepTransition(activeStep + 1);
  }, [activeStep, steps.length, showSummary, onSubmit, validateStep, handleStepTransition]);

  const handleBack = useCallback(() => {
    if (showSummary) {
      setShowSummary(false);
      return;
    }
    handleStepTransition(activeStep - 1);
  }, [activeStep, showSummary, handleStepTransition]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleNext();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleNext]);

  useEffect(() => {
    if (!open) {
      setActiveStep(0);
      setShowSummary(false);
    }
  }, [open]);

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Fade}
      transitionDuration={300}
    >
      <Box sx={{ width: '100%', mb: 1 }}>
        <LinearProgress
          variant="determinate"
          value={((activeStep + (showSummary ? 1 : 0)) / steps.length) * 100}
          sx={{
            height: 6,
            borderRadius: 3,
            backgroundColor: 'rgba(0,123,254,0.12)',
            '& .MuiLinearProgress-bar': { backgroundColor: blue }
          }}
        />
        <Typography
          variant="caption"
          color="text.secondary"
          align="center"
          sx={{ display: 'block', mt: 0.5 }}
        >
          Step {activeStep + 1} of {steps.length}
        </Typography>
      </Box>

      <StepIndicator>
        {steps.map((step, index) => (
          <NoShadowTooltip key={index} title={step.title}>
            <StepDot
              active={index === activeStep}
              completed={index < activeStep}
              onClick={() => {
                if (index < activeStep) handleStepTransition(index);
              }}
            />
          </NoShadowTooltip>
        ))}
      </StepIndicator>

      <DialogTitle>
        <Typography variant="h5" component="div" sx={{ fontWeight: 600 }}>
          {showSummary ? 'Review Your Product' : steps[activeStep].title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
          {showSummary
            ? 'Please review your product information before creating'
            : steps[activeStep].description}
        </Typography>
      </DialogTitle>

      <StepContent sx={{ opacity: transitioning ? 0 : 1 }}>
        {showSummary ? (
          <Suspense fallback={<Box sx={{ p: 3 }}>Loading summary...</Box>}>
            <SummaryContent steps={steps} productData={productData} />
          </Suspense>
        ) : (
          steps[activeStep].component
        )}
      </StepContent>

      <DialogActions sx={{ px: 3, pb: 2, gap: 1 }}>
        {(activeStep > 0 || showSummary) && (
          <Button
            onClick={handleBack}
            startIcon={<ArrowBack sx={{ color: blue }} />}
            sx={{
              textTransform: 'none',
              borderRadius: '28px',
              color: blue,
              '&:hover': { backgroundColor: 'transparent' }
            }}
          >
            Back
          </Button>
        )}

        <Box sx={{ flex: 1 }} />

        <Button
          variant="contained"
          disableElevation
          onClick={handleNext}
          disabled={!validateStep(activeStep) || isSubmitting}
          endIcon={!showSummary && <ArrowForward />}
          sx={{
            textTransform: 'none',
            borderRadius: '28px',
            backgroundColor: blue,
            color: 'white',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: darken(blue, 0.2),
              boxShadow: 'none'
            }
          }}
        >
          {isSubmitting
            ? 'Creating Product...'
            : showSummary
              ? 'Create Product'
              : activeStep === steps.length - 1
                ? 'Review'
                : 'Continue'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ProductSetupWizard;
