import React, { useState, useRef, useEffect, useContext, useCallback, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { Global, css } from '@emotion/react';
import {
  Box,
  Paper,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  styled,
  CircularProgress,
  alpha,
  InputBase,
  ClickAwayListener
} from '@mui/material';
import { keyframes } from '@mui/system';
import {
  MessageCircle,
  Minimize2,
  CheckCircle,
  Frown,
  ArrowUp,
  PlusCircle,
  ClipboardList,
  Trash2
} from 'lucide-react';
import { ProductContext } from '../../../../context/ProductContext';
import agtChatService from '../../services/AGTUps-product-services/agtChatService';

// Inyección de estilos globales para animaciones personalizadas
const GlobalStyles = () => (
  <Global
    styles={css`
      @keyframes fade_eiknz_10 {
        0% {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      ._fadeIn_eiknz_10 {
        animation: fade_eiknz_10 var(--duration, 0s) ease forwards calc(10ms + var(--delay, 0s));
        opacity: 0;
      }
      @keyframes fade_1frq2_1 {
        0% {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      ._markdown_1frq2_10.markdown .katex-display {
        animation: fade_1frq2_1 0.4s ease 50ms forwards;
        opacity: 0;
      }
    `}
  />
);

// Sistema de colores (mantenido igual)
const appleColors = {
  light: {
    label: { primary: '#000000' },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      green: '#31C859',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#FFFFFF' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#007BFE', base: '#F2F2F7' }
  },
  dark: {
    label: { primary: '#FFFFFF' },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      green: '#2DD257',
      white: '#FFFFFF',
      black: '#000000'
    },
    bg: { primaryElevated: '#000000' },
    fill: { tertiary: '#767680' },
    ui: { accent: '#0385FF', base: '#272729' }
  }
};

const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const c = mode === 'light' ? appleColors.light : appleColors.dark;
  return {
    background: c.bg.primaryElevated,
    textDefault: c.label.primary,
    textSecondary: c.system.grey,
    cardBackground: c.ui.base,
    borderColor: c.system.grey3,
    accent: c.ui.accent,
    success: c.system.green,
    error: '#FF3B30',
    warning: '#FF9500'
  };
};

// Keyframe para la animación de fade in (usado en AnimatedWord)
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Componente para palabra animada
const AnimatedWord = styled('span')(({ theme, delay, isPlaying }) => ({
  display: 'inline-block',
  marginRight: '0.25em',
  fontSize: 'inherit',
  opacity: isPlaying ? 0 : 1,
  animation: isPlaying ? `${fadeIn} 0.4s ease forwards ${delay}s` : 'none'
}));

// NUEVO COMPONENTE: FormattedAnimatedMessage
const FormattedAnimatedMessage = ({ content, isPlaying }) => {
  const wordCountRef = useRef(0);

  // Función auxiliar para envolver cada palabra en AnimatedWord
  const wrapWordsInAnimation = (text) => {
    return text.split(' ').map((word) => {
      const currentIndex = wordCountRef.current++;
      return (
        <AnimatedWord
          key={`${word}-${currentIndex}`}
          delay={0.05 * currentIndex}
          isPlaying={true}
        >
          {word}{' '}
        </AnimatedWord>
      );
    });
  };

  // Utilizamos useMemo para memorizar el contenido animado y evitar re-procesarlo en cada render
  const animatedContent = useMemo(() => {
    // Reiniciamos el contador solo cuando el contenido cambia
    wordCountRef.current = 0;
    return (
      <Box style={{ '--duration': '0.4s', '--delay': '0s' }}>
        <ReactMarkdown
          components={{
            p: ({ children }) => (
              <p>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </p>
            ),
            strong: ({ children }) => (
              <strong>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </strong>
            ),
            em: ({ children }) => (
              <em>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </em>
            ),
            li: ({ children }) => (
              <li>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </li>
            ),
            h1: ({ children }) => (
              <h1>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </h1>
            ),
            h2: ({ children }) => (
              <h2>
                {React.Children.map(children, (child) => {
                  if (typeof child === 'string') {
                    return wrapWordsInAnimation(child);
                  }
                  return child;
                })}
              </h2>
            )
          }}
        >
          {content}
        </ReactMarkdown>
      </Box>
    );
  }, [content]);

  if (isPlaying) {
    return animatedContent;
  }

  return <ReactMarkdown>{content}</ReactMarkdown>;
};

// Hook para manejar el último mensaje del asistente
const useLatestMessage = () => {
  const [latestMessageId, setLatestMessageId] = useState(null);

  const updateLatestMessage = useCallback((messages) => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.type === 'agt') {
      setLatestMessageId(messages.length - 1);
      // Aumentamos el timeout a 10000ms para permitir que la animación finalice
      setTimeout(() => {
        setLatestMessageId(null);
      }, 10000);
    }
  }, []);

  return { latestMessageId, updateLatestMessage };
};

// Botón circular para enviar, con transición en opacidad
const CircularSendButton = styled(IconButton)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    background: navColors.accent,
    color: navColors.textSecondary,
    borderRadius: '50%',
    width: 28,
    height: 28,
    padding: 4,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.short,
      easing: theme.transitions.easing.easeInOut
    }),
    '&:hover': {
      background: alpha(navColors.accent, 0.9)
    }
  };
});

// Componente unificado para input/botón
const UnifiedInputButton = styled(Box)(({ theme, isexpanded }) => {
  const navColors = navigationColors(theme);
  return {
    width: '360px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: navColors.cardBackground,
    borderRadius: '20px',
    padding: '12px',
    transition: theme.transitions.create(['box-shadow', 'background-color'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut
    }),
    cursor: 'pointer',
    pointerEvents: 'auto',
    border: `1px solid ${navColors.borderColor}`,
    '& *': {
      cursor: isexpanded === 'true' ? 'text' : 'pointer'
    }
  };
});

// Input estilizado
const StyledInputBase = styled(InputBase)(({ theme, isexpanded }) => {
  const navColors = navigationColors(theme);
  return {
    width: '100%',
    color: navColors.textDefault,
    '& .MuiInputBase-input': {
      padding: '0px',
      cursor: isexpanded === 'true' ? 'text' : 'pointer',
      '&::placeholder': {
        color: navColors.textSecondary,
        opacity: 1
      }
    }
  };
});

// Chat container sin sombra
const ChatContainer = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    borderRadius: theme.shape.borderRadius * 2,
    background: navColors.background,
    width: '360px',
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
    border: `1px solid ${navColors.borderColor}`,
    transition: theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.complex,
      easing: theme.transitions.easing.easeInOut
    })
  };
});

// Encabezado del chat
const ChatHeader = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    padding: theme.spacing(2),
    background: navColors.background,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  };
});

// Área de mensajes con scroll suave
const MessageArea = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flexGrow: 1,
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '&::-webkit-scrollbar': {
    width: 6,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: alpha(theme.palette.mode === 'light' ? '#000' : '#FFF', 0.4),
    borderRadius: 3,
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  }
}));

// Componente Message con radio adaptativo según el largo del contenido
const Message = styled(Box)(({ theme, variant, content }) => {
  const navColors = navigationColors(theme);

  const getMessageRadius = (messageContent) => {
    const contentLength = messageContent?.length || 0;
    if (contentLength < 20) {
      return '9999px';
    } else if (contentLength < 50) {
      return '24px';
    } else {
      return '16px';
    }
  };

  const baseStyles = {
    maxWidth: '80%',
    padding: theme.spacing(1.5),
    borderRadius: getMessageRadius(content),
    color: navColors.textDefault,
    textTransform: 'none'
  };

  if (variant === 'user') {
    return {
      ...baseStyles,
      alignSelf: 'flex-end',
      background: navColors.cardBackground,
    };
  }

  return {
    ...baseStyles,
    alignSelf: 'flex-start',
    background: 'transparent',
    color: theme.palette.mode === 'light' ? '#000000' : '#FFFFFF',
  };
});

// Contenedor de prompts para mobile
const PromptContainerMobile = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(1),
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  gap: theme.spacing(2),
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

// Contenedor de prompts para desktop
const PromptContainerDesktop = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(1),
  flexWrap: 'wrap'
}));

// Tarjeta de prompt
const PromptCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    background: navColors.cardBackground,
    color: navColors.textDefault,
    padding: theme.spacing(1),
    borderRadius: '9999px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    flexShrink: 0
  };
});

// Texto de footer
const FooterText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: theme.spacing(1),
  fontSize: '0.65rem',
  color: '#808080'
}));

// Estilos para el contenido Markdown, ajustando también las listas
const MarkdownContent = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    '& > *:first-child': {
      marginTop: 0
    },
    '& > *:last-child': {
      marginBottom: 0
    },
    '& p': {
      margin: 0,
      color: navColors.textDefault,
      fontSize: '0.875rem',
      lineHeight: 1.43
    },
    '& ul, & ol': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      '& li': {
        fontSize: '0.875rem',
        marginBottom: '0.25em',
        '&::marker': {
          fontSize: '0.875rem',
          color: navColors.textDefault
        }
      }
    },
    '& code': {
      backgroundColor: alpha(navColors.textDefault, 0.1),
      padding: '2px 4px',
      borderRadius: 4,
      fontSize: '0.875rem'
    },
    '& pre': {
      backgroundColor: theme.palette.mode === 'dark'
        ? alpha(navColors.textDefault, 0.15)
        : alpha(navColors.textDefault, 0.1),
      padding: theme.spacing(1),
      borderRadius: 4,
      overflow: 'auto',
      '& code': {
        backgroundColor: 'transparent',
        padding: 0,
        fontSize: '0.875rem',
        color: theme.palette.mode === 'dark' ? '#e6e6e6' : 'inherit'
      }
    },
    '& blockquote': {
      borderLeft: `4px solid ${alpha(navColors.textDefault, 0.2)}`,
      margin: theme.spacing(1, 0),
      padding: theme.spacing(0, 1),
      fontSize: '0.875rem'
    },
    '& a': {
      color: navColors.accent,
      textDecoration: 'none',
      fontSize: '0.875rem',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

// FUNCION: renderMessageContent actualizada
const renderMessageContent = (message, navColors, assistantTextColor, isNewMessage) => {
  const tagMapping = {
    '<agt_success_create>': { bg: navColors.success, text: 'Product created', icon: <CheckCircle size={16} color="white" /> },
    '<agt_success_update>': { bg: navColors.success, text: 'Product updated', icon: <CheckCircle size={16} color="white" /> },
    '<agt_success_delete>': { bg: navColors.success, text: 'Product deleted', icon: <CheckCircle size={16} color="white" /> },
    '<agt_fail_create>': { bg: navColors.error, text: 'Creation failed', icon: <Frown size={16} color="white" /> },
    '<agt_fail_update>': { bg: navColors.error, text: 'Update failed', icon: <Frown size={16} color="white" /> },
    '<agt_fail_delete>': { bg: navColors.error, text: 'Deletion failed', icon: <Frown size={16} color="white" /> }
  };

  // Si el mensaje es de tipo "agt" y coincide con un tag especial
  if (message.type === 'agt' && tagMapping[message.content]) {
    const { bg, text, icon } = tagMapping[message.content];
    return (
      <Box
        sx={{
          background: bg,
          borderRadius: '9999px',
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          animation: isNewMessage ? `${fadeIn} 0.4s ease forwards 0.05s` : 'none',
          opacity: isNewMessage ? 0 : 1
        }}
      >
        {icon}
        <Typography variant="body2" sx={{ ml: 1, color: '#FFFFFF', textTransform: 'none' }}>
          {text}
        </Typography>
      </Box>
    );
  }

  if (message.type === 'agt') {
    return (
      <MarkdownContent className="markdown _markdown_1frq2_10">
        <FormattedAnimatedMessage content={message.content} isPlaying={isNewMessage} />
      </MarkdownContent>
    );
  }

  return (
    <Typography variant="body2" sx={{ color: assistantTextColor, textTransform: 'none' }}>
      {message.content}
    </Typography>
  );
};

// Función para animar el scroll de forma gradual
const animateScroll = (container, start, end, duration) => {
  const startTime = performance.now();
  const animate = (currentTime) => {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    container.scrollTop = start + (end - start) * progress;
    if (progress < 1) {
      requestAnimationFrame(animate);
    }
  };
  requestAnimationFrame(animate);
};

// Componente principal AGTFloatingChat
const AGTFloatingChat = ({ activeTab }) => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { products, refreshProducts } = useContext(ProductContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const messageAreaRef = useRef(null);
  const inputRef = useRef(null);
  const { latestMessageId, updateLatestMessage } = useLatestMessage();

  const assistantTextColor = theme.palette.mode === 'light' ? '#000000' : '#FFFFFF';
  const arrowColor = inputValue.trim() ? 'white' : navColors.textSecondary;

  // Función para hacer scroll inmediato hasta el final (para mensajes no animados)
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (messages.length > 0) {
      updateLatestMessage(messages);
      const lastMessage = messages[messages.length - 1];
      // Si el mensaje es del asistente con fade in, animamos el scroll
      if (lastMessage.type === 'agt') {
        const wordsCount = lastMessage.content.split(' ').length;
        const totalAnimationTime = 0.05 * wordsCount * 1000; // tiempo total en ms
        if (messageAreaRef.current) {
          const container = messageAreaRef.current;
          animateScroll(container, container.scrollTop, container.scrollHeight, totalAnimationTime);
        }
      } else {
        // Para mensajes del usuario o sin animación, hacemos scroll inmediato
        scrollToBottom();
      }
    }
  }, [messages, updateLatestMessage]);

  useEffect(() => {
    if (isExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isExpanded]);

  useEffect(() => {
    const lastMsg = messages[messages.length - 1];
    if (lastMsg && lastMsg.type === 'agt') {
      const msgContent = lastMsg.content.trim();
      if (
        msgContent === '<agt_success_create>' ||
        msgContent === '<agt_success_update>' ||
        msgContent === '<agt_success_delete>'
      ) {
        refreshProducts();
      }
    }
  }, [messages, refreshProducts]);

  const promptOptions = [
    { label: 'Create a product', icon: <PlusCircle size={16} /> },
    { label: 'Check my inventory', icon: <ClipboardList size={16} /> },
    { label: 'Delete a product', icon: <Trash2 size={16} /> }
  ];

  const handlePromptClick = async (promptText) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      setMessages((prev) => [...prev, { type: 'user', content: promptText }]);
      const response = await agtChatService.sendMessage(promptText, {
        activeTab,
        currentProducts: products,
        timestamp: new Date().toISOString()
      });
      setMessages((prev) => [
        ...prev,
        { type: 'agt', content: response.data.data.message }
      ]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          type: 'agt',
          content:
            'I apologize, but I encountered an error. Could you please try again?'
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!inputValue.trim() || isLoading) return;
    try {
      setIsLoading(true);
      setMessages((prev) => [...prev, { type: 'user', content: inputValue }]);
      setInputValue('');
      const response = await agtChatService.sendMessage(inputValue, {
        activeTab,
        currentProducts: products,
        timestamp: new Date().toISOString()
      });
      setMessages((prev) => [
        ...prev,
        { type: 'agt', content: response.data.data.message }
      ]);
    } catch (error) {
      setMessages((prev) => [
        ...prev,
        {
          type: 'agt',
          content:
            'I apologize, but I encountered an error. Could you please try again?'
        }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <>
      <GlobalStyles />
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: theme.zIndex.drawer + 1
        }}
      >
        <ClickAwayListener onClickAway={() => setIsExpanded(false)}>
          <Box>
            {isExpanded && (
              <Box sx={{ marginBottom: 2 }}>
                <ChatContainer onMouseDown={(e) => e.stopPropagation()}>
                  <ChatHeader>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textTransform: 'none',
                        color: navColors.textDefault,
                        fontWeight: 500
                      }}
                    >
                      AGT Assistant
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsExpanded(false);
                      }}
                      sx={{
                        color: navColors.textDefault,
                        position: 'absolute',
                        right: theme.spacing(2)
                      }}
                    >
                      <Minimize2 size={18} />
                    </IconButton>
                  </ChatHeader>

                  <MessageArea ref={messageAreaRef} onMouseDown={(e) => e.stopPropagation()}>
                    {messages.map((message, index) => (
                      <Message key={index} variant={message.type} content={message.content}>
                        {renderMessageContent(
                          message,
                          navColors,
                          assistantTextColor,
                          index === latestMessageId
                        )}
                      </Message>
                    ))}
                    {isLoading && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress size={24} />
                      </Box>
                    )}
                    <div ref={messagesEndRef} />
                  </MessageArea>

                  {messages.length === 0 &&
                    (isMobile ? (
                      <PromptContainerMobile onMouseDown={(e) => e.stopPropagation()}>
                        {promptOptions.map((option, idx) => (
                          <PromptCard
                            key={idx}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              handlePromptClick(option.label);
                            }}
                          >
                            {option.icon}
                            <Typography variant="body2" sx={{ textTransform: 'none' }}>
                              {option.label}
                            </Typography>
                          </PromptCard>
                        ))}
                      </PromptContainerMobile>
                    ) : (
                      <PromptContainerDesktop onMouseDown={(e) => e.stopPropagation()}>
                        {promptOptions.map((option, idx) => (
                          <PromptCard
                            key={idx}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                              handlePromptClick(option.label);
                            }}
                          >
                            {option.icon}
                            <Typography variant="body2" sx={{ textTransform: 'none' }}>
                              {option.label}
                            </Typography>
                          </PromptCard>
                        ))}
                      </PromptContainerDesktop>
                    ))}

                  <FooterText>Double-check AGT actions.</FooterText>
                </ChatContainer>
              </Box>
            )}

            <UnifiedInputButton
              isexpanded={isExpanded.toString()}
              onMouseDown={(e) => {
                e.stopPropagation();
                if (!isExpanded) {
                  setIsExpanded(true);
                }
              }}
            >
              <StyledInputBase
                inputRef={inputRef}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="How can AGT help you?"
                disabled={!isExpanded}
                multiline
                maxRows={4}
                isexpanded={isExpanded.toString()}
                sx={{ pointerEvents: isExpanded ? 'auto' : 'none' }}
              />
              {isExpanded ? (
                <CircularSendButton
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleSendMessage();
                  }}
                  disabled={!inputValue.trim() || isLoading}
                >
                  <ArrowUp size={20} color={arrowColor} />
                </CircularSendButton>
              ) : (
                <ArrowUp size={20} color={arrowColor} />
              )}
            </UnifiedInputButton>
          </Box>
        </ClickAwayListener>
      </Box>
    </>
  );
};

export default AGTFloatingChat;
