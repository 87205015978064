import API_CONFIG from './config';

class ProfileEnhancementService {
  constructor() {
    this.api = null;
    this.ENDPOINTS = {
      ...API_CONFIG.ENDPOINTS,
      ENHANCE: '/profile/enhance'
    };
  }

  initialize({ uid }) {
    this.api = API_CONFIG.createAxiosInstance();
    this.uid = uid;
  }

  async enhanceProfile(profileData) {
    try {
      const enhanceableFields = {
        companyDescription: profileData.companyDescription,
        businessModel: profileData.businessModel,
        targetMarket: profileData.business?.targetMarket,
        goals: profileData.goals,
        challenges: profileData.challenges
      };

      const context = {
        companyName: profileData.companyName,
        industry: profileData.industry,
        foundedDate: profileData.foundedDate,
        companySize: profileData.companySize,
        website: profileData.website,
        location: profileData.location,
        stage: profileData.business?.stage
      };

      const response = await this.api.post(
        `${this.ENDPOINTS.PROFILE}/${profileData.profileId}/enhance`,
        {
          enhanceableFields,
          context
        }
      );

      if (response.data.success) {
        return {
          success: true,
          data: response.data.updatedProfile
        };
      }

      throw new Error(response.data.error || 'Error improving profile');
    } catch (error) {
      return {
        success: false,
        error: error.message
      };
    }
  }
}

const profileEnhancementService = new ProfileEnhancementService();
export default profileEnhancementService;
