/*******************************************************
 * ChatViewNest.js COMPLETO - Versión con hideSidebar
 * (para ocultar el sidebar si se desea) Y hideFooter
 * (para ocultar el footer).
 *
 * NOTA IMPORTANTE: Se ha agregado la lógica para
 * DESSELECCIONAR chat cuando se selecciona un proyecto,
 * y viceversa, de modo que nunca haya dos selecciones
 * simultáneas en el sidebar.
 *******************************************************/

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  List,
  ListSubheader,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  useMediaQuery,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Plus,
  Search,
  Filter,
  XCircle,
  RefreshCw,
  User,
  Briefcase,
  Users,
  MinusCircle,
  Puzzle,
  X,
} from 'lucide-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import {
  format,
  isAfter,
  isBefore,
  parseISO,
  isToday,
  isYesterday,
  isThisWeek,
  isThisMonth,
  isThisYear,
  subDays,
  startOfMonth,
  endOfMonth,
  isValid
} from 'date-fns';
import {
  getChats,
  getChatMessages,
  sendMessageToAGT,
  createNewChat,
  getChatContext,
  updateChatTitle,
  deleteChat,
  updateChatTimestamp,
  getUserPersonalityPreference,
  setUserPersonalityPreference,
  getUserCategories,
  createUserCategory,
  deleteUserCategory
} from '../../services/AGTService';
import ThinkingIcon from '../messages/ThinkingIcon';
import PromptCards from '../input/PromptCards';
import MagicalMessage from '../messages/MagicalMessage';
import AGTMaker from '../AGTMaker/AGTMaker';
import OptimizedInput from '../input/OptimizedInput';
import CategorizedChatList from '../list/CategorizedChatList';
import { getThemeStyles } from '../../themes/themeConfig';
import { v4 as uuidv4 } from 'uuid';
import ProjectsSection from '../projects/ProjectsSection';
import ProjectChatView from '../projects/ProjectChatView';
import { ProjectContextProvider } from '../projects/ProjectContextProvider';

// === NUEVO: Subheader estilizado para la sección "Categories" (14px y no sticky)
const CategoriesSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'static',
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: 600,
  padding: '8px 4px',
  lineHeight: '20px',
  ...getThemeStyles('AllChatsText', theme.palette.mode),
}));

// === NUEVO: Contenedor animado para mostrar/ocultar los filtros + categorías
const AnimatedFiltersContainer = styled(Box)(({ theme }) => ({
  transition: 'height 0.3s ease, opacity 0.3s ease',
  overflow: 'hidden',
}));

const debouncedLoadMore = debounce((callback) => {
  callback();
}, 150);

const SCROLL_THRESHOLD = 100;
const BATCH_SIZE = 10;
const SCROLL_SMOOTH_DURATION = 300;
const INTERSECTION_THRESHOLD = 0.5;

// Styled Components
const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  '*::-webkit-scrollbar': {
    width: '8px !important',
    height: '8px !important',
  },
  '*::-webkit-scrollbar-track': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px !important',
  },
  '*::-webkit-scrollbar-thumb': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px !important',
    border: 'none !important',
    ':hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.15)',
    },
  },
  '*::-webkit-scrollbar-corner': {
    background: 'transparent !important',
  },
  ...getThemeStyles('ChatContainer', theme.palette.mode),
}));

const Sidebar = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isOpen' && prop !== '$isMobile',
})(({ theme, $isOpen, $isMobile }) => {
  const themeStyles = getThemeStyles('Sidebar', theme.palette.mode);

  return {
    width: '260px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid var(--system-grey3) !important',
    borderRadius: '0 !important',
    backdropFilter: $isMobile ? 'none' : 'blur(10px)',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 1200,
    transition: 'all 0.3s ease',
    overflow: 'hidden',
    transform: $isOpen ? 'translateX(0)' : 'translateX(-260px)',
    visibility: $isOpen ? 'visible' : 'hidden',
    opacity: $isOpen ? 1 : 0,
    fontSize: '14px',
    ...themeStyles,
    '&&': {
      borderRight: '1px solid var(--system-grey3)',
      borderRadius: 0,
    },
  };
});

const SidebarOverlay = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$visible'
})(({ theme, $visible }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1000,
  display: $visible ? 'block' : 'none',
  transition: 'opacity 0.3s ease',
  opacity: $visible ? 1 : 0,
  pointerEvents: $visible ? 'auto' : 'none',
  ...getThemeStyles('SidebarOverlay', theme.palette.mode),
}));

const SidebarContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  fontSize: '14px',
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    ...getThemeStyles('AllChatsText', theme.palette.mode),
  },
  '& .MuiButton-root': {
    fontSize: '14px',
  },
  '& .MuiChip-root': {
    fontSize: '14px',
  },
}));

const SidebarHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0px',
  padding: '12px',
  fontSize: '14px',
  ...getThemeStyles('SidebarHeader', theme.palette.mode),
}));

const ButtonsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginBottom: '4px',
  padding: '0 4px',
  '& > button:first-of-type': {
    marginLeft: '0px',
  },
  '& > button': {
    flex: '0 0 36px',
  },
});

const MenuButton = styled(IconButton)(({ theme }) => ({
  color: '#0385FF',
  padding: '8px',
  minWidth: '36px',
  width: '36px',
  height: '36px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));

const BlueIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#0385FF',
  color: 'white',
  padding: '8px',
  minWidth: '36px',
  width: '36px',
  height: '36px',
  '&:hover': {
    backgroundColor: '#0371db',
  },
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));

const GreyIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== '$selected',
})(({ theme, $selected }) => ({
  backgroundColor: $selected
    ? '#0385FF'
    : theme.palette.mode === 'dark'
    ? 'rgba(255,255,255,0.04)'
    : 'rgba(0,0,0,0.04)',
  color: $selected ? '#FFFFFF' : '#0385FF',
  padding: '8px',
  minWidth: '36px',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  transition: 'background-color 0.2s ease',
  '&:hover': {
    backgroundColor: $selected
      ? '#0371db'
      : theme.palette.mode === 'dark'
      ? 'rgba(255,255,255,0.08)'
      : 'rgba(0,0,0,0.08)',
  },
  '& svg': {
    width: '20px',
    height: '20px',
  },
}));

const MoreFiltersButton = styled(IconButton)(({ theme }) => ({
  width: '28px',
  height: '28px',
  minWidth: '28px',
  padding: 0,
  margin: '2px 8px 8px 0',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.04)'
      : 'rgba(0, 0, 0, 0.04)',
  border: `1px solid ${
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(0, 0, 0, 0.1)'
  }`,
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '14px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.08)'
        : 'rgba(0, 0, 0, 0.08)',
  },
  '&.selected': {
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    border: `1px solid ${
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.2)'
        : 'rgba(0, 0, 0, 0.2)'
    }`,
  },
  '& svg': {
    width: '16px',
    height: '16px',
  },
}));

const ChatList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  paddingTop: '16px',
  scrollbarWidth: 'thin',
  scrollbarColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1) transparent'
      : 'rgba(0, 0, 0, 0.1) transparent',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  '&::-webkit-scrollbar': {
    width: '8px !important',
    height: '8px !important',
  },
  '&::-webkit-scrollbar-track': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px !important',
  },
  '&::-webkit-scrollbar-thumb': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px !important',
    border: 'none !important',
    ':hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.15)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent !important',
  },
  ...getThemeStyles('ChatList', theme.palette.mode),
}));

const ChatArea = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== '$isSidebarOpen' &&
    prop !== '$isMobile' &&
    prop !== '$isAGTMakerOpen' &&
    prop !== '$withHeader'
})(({ theme, $isSidebarOpen, $isMobile, $isAGTMakerOpen, $withHeader }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: $isAGTMakerOpen && !$isMobile ? '50%' : '100%',
  transition: 'all 0.3s ease',
  paddingTop: $withHeader ? '56px' : '0',
  opacity: 1,
  position: $isAGTMakerOpen && !$isMobile ? 'absolute' : 'relative',
  left: $isAGTMakerOpen && !$isMobile ? 0 : 'auto',
  '*::-webkit-scrollbar': {
    width: '8px !important',
    height: '8px !important',
  },
  '*::-webkit-scrollbar-track': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px !important',
  },
  '*::-webkit-scrollbar-thumb': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px !important',
    border: 'none !important',
    ':hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.15)',
    },
  },
  '*::-webkit-scrollbar-corner': {
    background: 'transparent !important',
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: $isSidebarOpen ? '260px' : '0',
    transition: 'padding-left 0.3s ease, width 0.3s ease',
  },
  ...getThemeStyles('ChatArea', theme.palette.mode),
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  width: '100%',
  maxWidth: '800px',
  scrollBehavior: 'smooth',
  transition: 'all 0.3s ease-out',
  scrollbarWidth: 'thin',
  scrollbarColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.1) transparent'
      : 'rgba(0, 0, 0, 0.1) transparent',
  msOverflowStyle: '-ms-autohiding-scrollbar',
  '& pre': {
    maxWidth: '100%',
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  '& img': {
    maxWidth: '100%',
    height: 'auto',
  },
  '&::-webkit-scrollbar': {
    width: '8px !important',
    height: '8px !important',
  },
  '&::-webkit-scrollbar-track': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.05)'
        : 'rgba(0, 0, 0, 0.05)',
    borderRadius: '4px !important',
  },
  '&::-webkit-scrollbar-thumb': {
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px !important',
    border: 'none !important',
    ':hover': {
      background:
        theme.palette.mode === 'dark'
          ? 'rgba(255, 255, 255, 0.15)'
          : 'rgba(0, 0, 0, 0.15)',
    },
  },
  '&::-webkit-scrollbar-corner': {
    background: 'transparent !important',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 0',
  },
  ...getThemeStyles('MessagesContainer', theme.palette.mode),
}));

const MessageBarContainer = styled(Box)(({ theme }) => ({
  padding: '0px 18px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  backgroundColor: theme.palette.background.paper,
  ...getThemeStyles('MessageBarContainer', theme.palette.mode),
}));

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  borderRadius: '30px',
  padding: 0,
  margin: 0,
  overflow: 'hidden',
  '& textarea': {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
  },
  ...getThemeStyles('InputContainer', theme.palette.mode),
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '32px',
  padding: '8px 0',
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'sticky',
  bottom: 0,
  zIndex: 1000,
  ...getThemeStyles('FooterContainer', theme.palette.mode),
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(0, 0, 0, 0.7)',
  '& a': {
    color: 'inherit',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  textAlign: 'center',
  margin: 0,
  lineHeight: 1,
  ...getThemeStyles('InfoText', theme.palette.mode),
}));

const MobileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  backgroundColor: 'transparent',
  overflow: 'hidden',
  '& .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ...getThemeStyles('MobileHeader', theme.palette.mode),
}));

const DesktopHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1100,
  ...getThemeStyles('DesktopHeader', theme.palette.mode),
}));

const ErrorMessage = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),
  ...getThemeStyles('ErrorMessage', theme.palette.mode),
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  borderRadius: '30px',
  ...getThemeStyles('RetryButton', theme.palette.mode),
}));

const FilterChip = styled('div')(({ theme }) => ({
  margin: '2px 8px 8px 0',
  height: '28px',
  borderRadius: '14px',
  display: 'flex',
  alignItems: 'center',
  padding: '0 16px',
  cursor: 'pointer',
  userSelect: 'none',
  transition: 'background-color 0.2s ease',
  fontSize: '14px',
  ...getThemeStyles('FilterChip', theme.palette.mode),
}));

const SearchInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  padding: '4px 4px',
  borderRadius: '30px',
  marginBottom: '16px',
  marginLeft: '4px',
  width: '100%',
  maxWidth: '98%',
  overflow: 'hidden',
  ...getThemeStyles('SearchInputContainer', theme.palette.mode),
}));

const ClearAllButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  textTransform: 'none',
  padding: '4px 8px',
  minWidth: 'auto',
  marginLeft: 'auto',
  ...getThemeStyles('ClearAllButton', theme.palette.mode),
}));

const AGTMakerContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '50%',
  height: '100%',
  transition: 'transform 0.3s ease',
  transform: 'translateX(100%)',
  zIndex: 1300,
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

// === NUEVO: CategoryChip para mostrar "Work project", "Team project", etc.
const CategoryChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  height: '20px',
  padding: '0 8px',
  borderRadius: '10px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(3, 133, 255, 0.1)'
      : 'rgba(3, 133, 255, 0.08)',
  color: '#0385FF',
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  marginLeft: theme.spacing(1),
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#ffffff',
  borderRadius: '24px',
  padding: '40px',
  width: '100%',
  maxWidth: '560px',
  position: 'relative',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  padding: '4px',
  color: theme.palette.text.secondary,
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    margin: '24px',
    maxWidth: '560px',
    width: '100%',
    borderRadius: '24px',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  flex: 1,
  border: 'none',
  background: 'none',
  outline: 'none',
  fontSize: '14px',
  ...getThemeStyles('StyledInput', theme.palette.mode),
}));

const StyledInputModal = styled('input')(({ theme, error }) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '12px',
  border: `1px solid ${
    error ? '#ff1744' 
    : theme.palette.mode === 'dark' 
      ? '#404040' 
      : '#e0e0e0'
  }`,
  backgroundColor: theme.palette.mode === 'dark' ? '#1a1a1a' : '#f5f5f7',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#000000',
  fontSize: '16px',
  outline: 'none',
  transition: 'all 0.2s',
  marginTop: '16px',
  '&:focus': {
    borderColor: '#0385FF',
    boxShadow: '0 0 0 4px rgba(3, 133, 255, 0.15)',
    backgroundColor: theme.palette.mode === 'dark' ? '#2d2d2d' : '#ffffff',
  },
  '&::placeholder': {
    color: theme.palette.mode === 'dark' 
      ? 'rgba(255, 255, 255, 0.5)' 
      : 'rgba(0, 0, 0, 0.4)',
  },
}));

const ActionButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: '24px',
  padding: '12px 24px',
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '15px',
  transition: 'all 0.2s',
  ...(variant === 'cancel' && {
    color: theme.palette.mode === 'dark' ? '#ff453a' : '#ff3b30',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 69, 58, 0.1)' 
        : 'rgba(255, 59, 48, 0.1)',
    },
  }),
  ...(variant === 'contained' && {
    backgroundColor: '#0385FF',
    color: '#ffffff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#0374e0',
    },
  }),
  ...(variant === 'delete' && {
    backgroundColor: '#ff3b30',
    color: '#ffffff',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#ff1e0f',
    },
  }),
}));

// Función para verificar etiqueta AGT
const hasAGTTag = (content) => {
  if (!content) return false;
  try {
    const parsed = JSON.parse(content);
    if (parsed.text) {
      return typeof parsed.text === 'string' && parsed.text.includes('<agt>');
    }
    return typeof parsed === 'string' && parsed.includes('<agt>');
  } catch {
    return typeof content === 'string' && content.includes('<agt>');
  }
};

const ChatViewNest = React.memo(
  ({
    // NUEVA PROP: hideSidebar, por defecto false
    hideSidebar = false,

    // NUEVA PROP: hideFooter, por defecto false
    hideFooter = false,

    user,
    isAuthorized,
    themeMode,
    onSendMessage,
    isLimited,
    initialView,
    messages: initialMessages,
    isLoading: initialIsLoading,
    onNavigate,
    mostRecentChatId,
    onOpenAGTPromo,
    userName,
    projectContext,
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();

    // ===== NUEVO: estado para controlar si el input de búsqueda está enfocado o si hay texto
    const [isSearchFocused, setIsSearchFocused] = useState(false);

    // [ENFOQUE MIXTO: projectIdForBackend para API, no para disparar vista de proyecto]
    const [projectIdForBackend, setProjectIdForBackend] = useState(null);

    // Estados
    const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
      const savedState = localStorage.getItem('sidebarOpen');
      return savedState !== null ? JSON.parse(savedState) : !isMobile;
    });
    const [chats, setChats] = useState([]);
    const [currentChatId, setCurrentChatId] = useState(null);
    const [messages, setMessages] = useState(initialMessages);
    const [visibleMessages, setVisibleMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(initialIsLoading);
    const [error, setError] = useState(null);
    const [chatContext, setChatContext] = useState({});
    const [chatTitle, setChatTitle] = useState('');
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [menuChatId, setMenuChatId] = useState(null);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [waitingStartTime, setWaitingStartTime] = useState(null);
    const [isNewChatView, setIsNewChatView] = useState(initialView);
    const [retryAction, setRetryAction] = useState(null);
    const [lastMessageId, setLastMessageId] = useState(null);
    const [lastImageRequestId, setLastImageRequestId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateFilter, setDateFilter] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isAGTMakerOpen, setIsAGTMakerOpen] = useState(false);
    const [currentAGTContent, setCurrentAGTContent] = useState('');
    const [agtMakerReadyCallback, setAGTMakerReadyCallback] = useState(null);
    const [loadingStates, setLoadingStates] = useState({
      messages: false,
      chatInfo: false,
    });
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [rateLimitInfo, setRateLimitInfo] = useState(null);
    const [selectedModel, setSelectedModel] = useState(() => {
      return user?.isPro ? 'claude-3-5-sonnet-20241022' : 'claude-3-haiku-20240307';
    });
    const [selectedPersonality, setSelectedPersonality] = useState('normal');
    const [isProcessingAudio, setIsProcessingAudio] = useState(false);
    const [isProcessingImage, setIsProcessingImage] = useState(false);
    const [lastAssistantMessage, setLastAssistantMessage] = useState('');
    const [messageTypeInfo, setMessageTypeInfo] = useState({
      isAudio: false,
      isImage: false,
      isPdf: false,
      isExcel: false,
      isProjectChat: false,
      modelType: '',
      userIsPro: false
    });

    // === NUEVO: Estado para la categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState(() => {
      const storedCategory = localStorage.getItem('selectedCategory');
      return storedCategory || 'personal';
    });

    // === NUEVO: Estado para proyectos
    const [selectedProject, setSelectedProject] = useState(null);

    // === NUEVO: Estados para categorías personalizadas
    const [customCategories, setCustomCategories] = useState([]);
    // Estado para "pensamientos" ULTRA
    const [ultraThoughts, setUltraThoughts] = useState([]);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [showCategoryManager, setShowCategoryManager] = useState(false);

    // === NUEVO: Confirmación de borrado de categoría
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [totalSearchResults, setTotalSearchResults] = useState(0);

    // Guardamos la categoría en localStorage cada vez que cambie
    useEffect(() => {
      localStorage.setItem('selectedCategory', selectedCategory);
    }, [selectedCategory]);

    // Referencias
    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null);
    const inputRef = useRef(null);
    const chatCache = useRef({});
    const imageSearchCache = useRef({});
    const prevAGTMakerState = useRef(isAGTMakerOpen);
    const userToggledSidebar = useRef(false);
    const isFirstRender = useRef(true);
    const messageUpdateQueue = useRef([]);
    const isProcessingQueue = useRef(false);
    const revealTimeoutRef = useRef(null);
    const loadingRef = useRef(false);
    const optimizedInputRef = useRef(null); // Nuevo ref específico para OptimizedInput

    const projectsSectionRef = useRef(null);

    // === NUEVO: ref para detectar si el usuario ha scrolleado manualmente
    const userScrollingRef = useRef(false);

    // === NUEVO: estado para manejar el “scroll automático”
    const [autoScrollActive, setAutoScrollActive] = useState(false);

    const [isRevealing, setIsRevealing] = useState(false);

    // Cargar categorías personalizadas al montar o cuando user cambie
    useEffect(() => {
      if (user && user.uid) {
        getUserCategories(user.uid)
          .then((cats) => setCustomCategories(cats))
          .catch((err) =>
            console.error('[ChatViewNest] Error fetching custom categories:', err)
          );
      }
    }, [user]);

    const handleDeleteCategoryRequest = useCallback((catName) => {
      setCategoryToDelete(catName);
      setShowDeleteCategoryModal(true);
    }, []);

    const handleProjectClick = useCallback(() => {
      if (projectsSectionRef.current) {
        projectsSectionRef.current.handleNewProject();
      }
    }, []);

    const handleConfirmDeleteCategory = useCallback(async () => {
      if (!categoryToDelete || !user || !user.uid) return;
      setShowDeleteCategoryModal(false);

      try {
        const updatedCategories = await deleteUserCategory(
          user.uid,
          categoryToDelete,
          'personal'
        );
        setCustomCategories(updatedCategories);

        if (selectedCategory === categoryToDelete) {
          setSelectedCategory('personal');
        }

        setSnackbarMessage(`Category "${categoryToDelete}" deleted`);
        setSnackbarOpen(true);
      } catch (err) {
        console.error('Error deleting category', err);
        setSnackbarMessage('Error deleting category');
        setSnackbarOpen(true);
      } finally {
        setCategoryToDelete(null);
      }
    }, [user, categoryToDelete, selectedCategory]);

    const handlePromptCardAction = (action) => {
      if (inputRef.current) {
        inputRef.current.handlePromptAction(action);
      }
    };

    const handleCancelDeleteCategory = useCallback(() => {
      setShowDeleteCategoryModal(false);
      setCategoryToDelete(null);
    }, []);

    // Animación de respuesta por chunks
    const revealWordsGradually = useCallback(
      (fullResponse, setterFunction, onComplete) => {
        if (!fullResponse) {
          onComplete();
          return () => {};
        }

        setIsRevealing(true);

        const words = fullResponse.split(' ');
        const chunks = [];
        let currentChunk = [];
        let currentLength = 0;

        words.forEach((word) => {
          currentChunk.push(word);
          currentLength += word.length;
          if (currentLength >= 50 || word.includes('\n')) {
            chunks.push(currentChunk.join(' '));
            currentChunk = [];
            currentLength = 0;
          }
        });
        if (currentChunk.length > 0) {
          chunks.push(currentChunk.join(' '));
        }

        let isAnimating = true;
        let currentText = '';
        let currentChunkIndex = 0;

        const animate = () => {
          if (!isAnimating) return;

          if (currentChunkIndex < chunks.length) {
            currentText += (currentText ? ' ' : '') + chunks[currentChunkIndex];

            setterFunction((prevMessages) => {
              const newMessages = [...prevMessages];
              const lastIndex = newMessages.length - 1;
              if (lastIndex >= 0) {
                newMessages[lastIndex] = {
                  ...newMessages[lastIndex],
                  text: currentText,
                  animation: {
                    fadeIn: true,
                    slideUp: true,
                    chunk: chunks[currentChunkIndex],
                    chunkIndex: currentChunkIndex,
                  },
                };
              }
              return newMessages;
            });

            // Scroll si el usuario no scrollea
            if (messagesEndRef.current && !userScrollingRef.current) {
              messagesEndRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }

            currentChunkIndex++;
            const chunkLength = chunks[currentChunkIndex - 1]?.length || 1;
            const baseDelay = 30;
            const maxDelay = 200;
            const delay = Math.min(chunkLength * baseDelay, maxDelay);

            setTimeout(animate, delay);
          } else {
            setterFunction((prevMessages) => {
              const newMessages = [...prevMessages];
              const lastIndex = newMessages.length - 1;
              if (lastIndex >= 0) {
                newMessages[lastIndex] = {
                  ...newMessages[lastIndex],
                  text: fullResponse,
                  animation: {
                    fadeIn: false,
                    slideUp: false,
                    complete: true,
                  },
                };
              }
              return newMessages;
            });

            if (messagesEndRef.current && !userScrollingRef.current) {
              messagesEndRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
              });
            }

            setIsRevealing(false);
            onComplete();
          }
        };

        requestAnimationFrame(animate);

        return () => {
          isAnimating = false;
          setIsRevealing(false);
        };
      },
      []
    );

    const getChatCategory = useCallback((chat) => {
      if (!chat.updatedAt && !chat.createdAt) {
        console.warn('Chat sin fecha:', chat);
        return 'Unknown';
      }
      const date = parseISO(chat.updatedAt || chat.createdAt);
      if (!isValid(date)) {
        console.warn('Fecha inválida para el chat:', chat);
        return 'Unknown';
      }
      const now = new Date();
      if (isToday(date)) return 'Today';
      if (isYesterday(date)) return 'Yesterday';
      if (isAfter(date, subDays(now, 7))) return 'Previous 7 Days';
      if (isAfter(date, subDays(now, 30))) return 'Previous 30 Days';
      if (isThisYear(date)) {
        return format(date, 'MMMM');
      }
      return format(date, 'yyyy');
    }, []);

    const saveCurrentChatId = useCallback((chatId) => {
      if (chatId) {
        localStorage.setItem('currentChatId', chatId);
      } else {
        localStorage.removeItem('currentChatId');
      }
    }, []);

    const updateChatsOrder = useCallback(() => {
      setChats((prevChats) => {
        const sortedChats = [...prevChats].sort((a, b) => {
          const dateA = new Date(a.updatedAt || a.createdAt);
          const dateB = new Date(b.updatedAt || b.createdAt);
          return dateB - dateA;
        });
        return sortedChats;
      });
    }, []);

    const handleOpenAGTMaker = useCallback((content, readyCallback) => {
      setCurrentAGTContent(content);
      setIsAGTMakerOpen(true);
      setAGTMakerReadyCallback(() => readyCallback);
    }, []);

    const handleCloseAGTMaker = useCallback(() => {
      setIsAGTMakerOpen(false);
      setCurrentAGTContent('');
      setAGTMakerReadyCallback(null);
    }, []);

    const handleAGTMakerContentReady = useCallback(() => {
      if (agtMakerReadyCallback) {
        agtMakerReadyCallback();
        setAGTMakerReadyCallback(null);
      }
    }, [agtMakerReadyCallback]);

    // Detectar scrolling manual
    useEffect(() => {
      const container = messagesContainerRef.current;
      if (!container) return;

      const handleScroll = () => {
        const distanceFromBottom =
          container.scrollHeight - container.scrollTop - container.clientHeight;
        if (!isRevealing || distanceFromBottom > 150) {
          userScrollingRef.current = true;
          setAutoScrollActive(false);
        }
      };

      const handleUserInteraction = (e) => {
        if (e.type === 'wheel') {
          if (e.deltaY < 0) {
            userScrollingRef.current = true;
            setAutoScrollActive(false);
          }
          return;
        }
        if (!isRevealing) {
          userScrollingRef.current = true;
          setAutoScrollActive(false);
        }
      };

      container.addEventListener('scroll', handleScroll, { passive: true });
      container.addEventListener('wheel', handleUserInteraction, { passive: true });
      container.addEventListener('mousedown', handleUserInteraction);
      container.addEventListener('touchstart', handleUserInteraction);

      return () => {
        container.removeEventListener('scroll', handleScroll);
        container.removeEventListener('wheel', handleUserInteraction);
        container.removeEventListener('mousedown', handleUserInteraction);
        container.removeEventListener('touchstart', handleUserInteraction);
      };
    }, [isRevealing]);

    // Activar auto-scroll cuando llega nuevo mensaje
    useEffect(() => {
      if (!messages.length) return;
      const lastMessage = messages[messages.length - 1];
      if (!lastMessage.isUser) {
        const container = messagesContainerRef.current;
        if (!container) return;

        const distanceFromBottom =
          container.scrollHeight - container.scrollTop - container.clientHeight;
        if (distanceFromBottom < 100 && !userScrollingRef.current) {
          setAutoScrollActive(true);
        }
      }
    }, [messages]);

    // Efecto de auto-scroll
    useEffect(() => {
      if (!autoScrollActive || userScrollingRef.current) return;
      const scrollToEnd = () => {
        if (messagesEndRef.current && !userScrollingRef.current) {
          messagesEndRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      };
      const timeoutId = setTimeout(scrollToEnd, 100);
      return () => clearTimeout(timeoutId);
    }, [messages, autoScrollActive]);

    const scrollToBottom = useCallback(
      (options = { force: false }) => {
        if (!messagesContainerRef.current) return;
        if (userScrollingRef.current && !options.force) {
          return;
        }
        const executeScroll = () => {
          if (messagesEndRef.current && (!userScrollingRef.current || options.force)) {
            messagesEndRef.current.scrollIntoView({
              behavior: options.force ? 'auto' : 'smooth',
              block: 'end',
            });
          }
        };
        requestAnimationFrame(executeScroll);
      },
      []
    );

    // === NUEVO: Función para seleccionar proyecto (y deseleccionar chat)
    const handleSelectProject = useCallback((project) => {
      // Deseleccionamos chat
      setCurrentChatId(null);
      localStorage.removeItem('currentChatId');
      // Seleccionamos el proyecto
      setSelectedProject(project);
    }, []);

    // [ENFOQUE MIXTO] - Mantenemos setSelectedProject(null) pero guardamos el projectId en projectIdForBackend
    const selectChat = useCallback(
      async (chatId) => {
        if (!user || !user.uid || chatId === currentChatId) return;

        // Forzamos la vista normal (no ProjectChatView):
        setSelectedProject(null);

        setIsFirstLoad(true);
        setMessages([]);
        setVisibleMessages([]);

        setIsAGTMakerOpen(false);
        setCurrentAGTContent('');
        setCurrentChatId(chatId);
        saveCurrentChatId(chatId);
        setLoadingStates({ messages: true, chatInfo: true });
        setError(null);
        setIsNewChatView(false);

        try {
          let chatMessages, context;
          if (chatCache.current[chatId]) {
            chatMessages = chatCache.current[chatId].messages;
            context = chatCache.current[chatId].context;
          } else {
            [chatMessages, context] = await Promise.all([
              getChatMessages(user.uid, chatId),
              getChatContext(user.uid, chatId),
            ]);
            chatCache.current[chatId] = { messages: chatMessages, context };
          }

          setMessages(chatMessages || []);
          setVisibleMessages((chatMessages || []).slice(-50));
          setChatContext(context || {});

          const selectedChat = chats.find((c) => c.id === chatId);
          if (selectedChat) {
            setChatTitle(selectedChat.title || 'New chat');

            // [ENFOQUE MIXTO] Guardamos projectId en projectIdForBackend
            if (selectedChat.projectId) {
              setProjectIdForBackend(selectedChat.projectId);
            } else {
              setProjectIdForBackend(null);
            }
          } else {
            const response = await getChats(user.uid, { limit: 20 });
            const updatedSelectedChat = response.chats.find((c) => c.id === chatId);
            setChatTitle(
              updatedSelectedChat
                ? updatedSelectedChat.title || 'New chat'
                : 'New chat'
            );
            setChats(response.chats);
            updateChatsOrder();
          }
        } catch (error) {
          console.error('Error selecting chat:', error);
          setError('Failed to load chat data. Please try again.');
          setRetryAction(() => () => selectChat(chatId));
          setMessages([]);
          setVisibleMessages([]);
          setChatContext({});
          setIsFirstLoad(false);
        } finally {
          setLoadingStates({ messages: false, chatInfo: false });
          setIsFirstLoad(false);
          requestAnimationFrame(() => {
            scrollToBottom({ force: true });
          });
        }
      },
      [
        user,
        chats,
        currentChatId,
        saveCurrentChatId,
        updateChatsOrder,
        scrollToBottom,
      ]
    );

    // Estado persistente
    const [persistentChatState, setPersistentChatState] = useState(() => {
      try {
        const saved = localStorage.getItem('chatView_state');
        return saved
          ? JSON.parse(saved)
          : {
              lastScrollPosition: 0,
              lastVisibleCategory: null,
              loadedChatIds: [],
              totalChatsLoaded: 0,
            };
      } catch (error) {
        console.error('Error loading persistent chat state:', error);
        return {
          lastScrollPosition: 0,
          lastVisibleCategory: null,
          loadedChatIds: [],
          totalChatsLoaded: 0,
        };
      }
    });

    // Cargar lista de chats
    const loadChats = useCallback(
      async (loadMore = false) => {
        if (!user || !user.uid || (loadMore && isNextPageLoading)) return;

        setIsNextPageLoading(true);
        setError(null);

        try {
          // Opciones base
          const options = {
            limit: isSearchMode ? 50 : 20,
            lastEvaluatedKey: loadMore ? lastEvaluatedKey : null,
            category: selectedCategory !== 'add' ? selectedCategory : null
          };

          // Añadir searchTerm si estamos en modo búsqueda
          if (isSearchMode && searchTerm) {
            options.searchTerm = searchTerm;
          }

          const response = await getChats(user.uid, options);

          if (!loadMore) {
            setChats(response.chats);
          } else {
            setChats(prevChats => {
              const newChatsMap = new Map(response.chats.map(chat => [chat.id, chat]));
              const uniqueChats = [...prevChats];

              response.chats.forEach(chat => {
                if (!uniqueChats.some(existing => existing.id === chat.id)) {
                  uniqueChats.push(chat);
                }
              });

              return uniqueChats;
            });
          }

          setHasNextPage(response.hasMore);
          setLastEvaluatedKey(response.lastEvaluatedKey);

          if (isSearchMode) {
            setTotalSearchResults(response.total || 0);
          }

        } catch (error) {
          console.error('Error loading chats:', error);
          setError('Error loading chats. Please try again.');
          setRetryAction(() => () => loadChats(loadMore));
        } finally {
          setIsNextPageLoading(false);
        }
      },
      [user, lastEvaluatedKey, isNextPageLoading, isSearchMode, searchTerm, selectedCategory]
    );

    const loadChatContext = useCallback(
      async (chatId) => {
        if (!user || !user.uid) return;
        try {
          const context = await getChatContext(user.uid, chatId);
          setChatContext(context || {});
        } catch (error) {
          console.error('Error loading chat context:', error);
          setError('Error loading chat context. Some conversation history may be missing.');
          setRetryAction(() => () => loadChatContext(chatId));
        }
      },
      [user]
    );

    const handleUpdateMessage = useCallback((messageId, newContent) => {
      messageUpdateQueue.current.push({ messageId, newContent });
      if (!isProcessingQueue.current) {
        isProcessingQueue.current = true;
        requestAnimationFrame(() => {
          const updates = [...messageUpdateQueue.current];
          messageUpdateQueue.current = [];

          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            updates.forEach((update) => {
              const index = newMessages.findIndex((msg) => msg.id === update.messageId);
              if (index !== -1) {
                newMessages[index] = {
                  ...newMessages[index],
                  text: update.newContent,
                };
              }
            });
            return newMessages;
          });

          setVisibleMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            updates.forEach((update) => {
              const index = newMessages.findIndex((msg) => msg.id === update.messageId);
              if (index !== -1) {
                newMessages[index] = {
                  ...newMessages[index],
                  text: update.newContent,
                };
              }
            });
            return newMessages;
          });

          isProcessingQueue.current = false;
        });
      }
    }, []);

    // **** IMPORTANTE: Ajustes en handleNewChatInput para usar la ID local (chatIdToSend) ****
    const handleNewChatInput = useCallback(
      async (messageData) => {
        if (!user || !user.uid || isWaitingForResponse) return;
    
        const isValidMessage =
          typeof messageData.text === 'object' &&
          messageData.text.type === 'audio_message'
            ? true
            : typeof messageData.text === 'string' &&
              messageData.text.trim().length > 0;
    
        if (!isValidMessage && !messageData.files?.length) return;
    
        setIsWaitingForResponse(true);
        setWaitingStartTime(Date.now());
    
        // Detectar tipos de archivos
        const isAudioMessage =
          typeof messageData.text === 'object' &&
          messageData.text.type === 'audio_message';
        const hasImageFile = messageData.files?.some((file) =>
          file.type.startsWith('image/')
        );
        const hasPdf = messageData.files?.some((file) => 
          file.type === 'application/pdf'
        );
        const hasExcel = messageData.files?.some((file) =>
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
          file.type === 'application/vnd.ms-excel'
        );
    
        setIsProcessingAudio(isAudioMessage);
        setIsProcessingImage(hasImageFile);
        setMessageTypeInfo({
          isAudio: isAudioMessage,
          isImage: hasImageFile,
          isPdf: hasPdf,
          isExcel: hasExcel,
          isProjectChat: !!projectContext,
          modelType: selectedModel,
          userIsPro: user?.isPro,
          modelTier: selectedModel === 'agt-ultra-20241227'
            ? 'ultra'
            : selectedModel === 'claude-3-5-sonnet-20241022'
              ? 'pro'
              : 'agt'
        });
    
        try {
          // 1) Creamos el nuevo chat en backend
          const chatCategory = projectContext
            ? projectContext.category
            : selectedCategory !== 'add'
            ? selectedCategory
            : 'personal';
    
          const newChat = await createNewChat(
            user.uid,
            chatCategory,
            projectIdForBackend
          );
          if (!newChat || !newChat.id) {
            throw new Error('Failed to create new chat');
          }
    
          // Guardamos la ID localmente
          const chatIdToSend = newChat.id;
          setCurrentChatId(chatIdToSend);
          saveCurrentChatId(chatIdToSend);
          setChatTitle('New chat');
    
          // Actualizar lista de chats local
          const chatsResponse = await getChats(user.uid, { limit: 20 });
          setChats((prevChats) => {
            const newChatList = [
              {
                ...newChat,
                name: 'New chat',
                updatedAt: new Date().toISOString(),
              },
              ...prevChats,
            ];
            return newChatList;
          });
          setHasNextPage(chatsResponse.hasMore);
          setLastEvaluatedKey(chatsResponse.lastEvaluatedKey);
          updateChatsOrder();
    
          // 2) Agregar el primer mensaje a la UI
          const newMessage = {
            id: uuidv4(),
            text:
              typeof messageData.text === 'object'
                ? JSON.stringify({
                    ...messageData.text,
                    source: messageData.source,
                    captureType: messageData.captureType,
                  })
                : messageData.text,
            isUser: true,
            timestamp: Date.now(),
            files: messageData.files?.map((file) => ({
              ...file,
              source: messageData.source || file.source,
            })),
            voicePreference: messageData.voicePreference,
            imageBase64Data: messageData.files,
            source: messageData.source,
            captureType: messageData.captureType,
          };
    
          setMessages((prev) => [...prev, newMessage]);
          setVisibleMessages((prev) => [...prev, newMessage]);
          setIsNewChatView(false);
    
          requestAnimationFrame(() => {
            scrollToBottom({ force: true });
          });
    
          // 3) Enviar el mensaje al backend usando chatIdToSend
          const response = await sendMessageToAGT(
            {
              text: messageData.text,
              files: messageData.files,
              voicePreference: messageData.voicePreference,
              source: messageData.source,
              captureType: messageData.captureType,
              projectId: projectIdForBackend,
              metadata: {
                source: messageData.source,
                captureType: messageData.captureType,
                deviceInfo: 'web',
                timestamp: new Date().toISOString(),
              },
            },
            user.uid,
            chatIdToSend,
            {},  // No había contexto anterior
            selectedModel,
            selectedPersonality,
            userName,
            messageData.voicePreference
          );
    
          // == (A) NUEVA VERIFICACIÓN DE RATE LIMIT, ANTES DEL IF !response.reply
          if (response && response.isRateLimit) {
            setRateLimitInfo({
              message: response.message || 'Has alcanzado el límite de mensajes por hora',
              resetIn: response.resetIn || 3600,
              isPro: response.isPro || false
            });
            return; // <-- Salimos sin lanzar "Invalid response from AGT"
          }
          // == (B) Si no es rate-limit, verificar .reply
          if (!response || !response.reply) {
            throw new Error('Invalid response from AGT');
          }
    
          // 4) Respuesta normal: actualizamos la UI
          setLastAssistantMessage({
            text: response.reply,
            audioBlob: response.audioBlob,
            isUser: false,
          });

          // **Capturar ultraThoughts**
          if (response.ultraThoughts && response.ultraThoughts.length > 0) {
            setUltraThoughts(response.ultraThoughts);
          } else {
            setUltraThoughts([]);
          }
    
          const responseMessage = {
            id: uuidv4(),
            text: '',
            isUser: false,
            timestamp: Date.now(),
            imageBase64Data: null,
            source: messageData.source,
            captureType: messageData.captureType,
          };
          setLastMessageId(responseMessage.id);
    
          setMessages((prev) => [...prev, responseMessage]);
          setVisibleMessages((prev) => [...prev, responseMessage]);
    
          if (response.reply.includes('<AGTSearch:image:')) {
            setLastImageRequestId(responseMessage.id);
          } else {
            setLastImageRequestId(null);
          }
    
          revealWordsGradually(
            response.reply,
            (updater) => {
              setMessages(updater);
              setVisibleMessages(updater);
            },
            () => {
              setIsWaitingForResponse(false);
              setLastMessageId(null);
              setAutoScrollActive(false);
            }
          );
    
          setChatContext(response.updatedContext || {});
          chatCache.current[chatIdToSend] = {
            messages: [newMessage, { ...responseMessage, text: response.reply }],
            context: response.updatedContext || {},
          };
    
          if (response.title) {
            setChatTitle(response.title);
            setChats((prevChats) =>
              prevChats.map((c) =>
                c.id === chatIdToSend
                  ? { ...c, title: response.title, name: response.title }
                  : c
              )
            );
            updateChatsOrder();
          }
    
          await updateChatTimestamp(user.uid, chatIdToSend);
    
          const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
          setChats(updatedChatsResponse.chats);
          setHasNextPage(updatedChatsResponse.hasMore);
          setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);
          updateChatsOrder();
    
          // Revisar si hay etiqueta <agt>
          if (hasAGTTag(response.reply)) {
            handleOpenAGTMaker(response.reply, () => {});
          }
    
          return {
            reply: response.reply,
            audioBlob: response.audioBlob,
            updatedContext: response.updatedContext,
          };
    
        } catch (error) {
          console.error('[ChatViewNest] Error in handleNewChatInput:', error);
    
          if (error.response?.status === 429) {
            const data = error.response?.data || {};
            setRateLimitInfo({
              message: data.message || 'Has alcanzado el límite de mensajes por hora',
              resetIn: data.resetIn || 3600,
              isPro: data.isPro || false
            });
            return;
          } else {
            setError('Error al crear nuevo chat o enviar mensaje. Por favor, intenta de nuevo.');
            setRetryAction(() => () => handleNewChatInput(messageData));
          }
        } finally {
          setIsWaitingForResponse(false);
          setIsProcessingAudio(false);
          setIsProcessingImage(false);
          inputRef.current?.focus();
        }
      },
      [
        user,
        isWaitingForResponse,
        projectContext,
        selectedCategory,
        projectIdForBackend,
        scrollToBottom,
        selectedModel,
        selectedPersonality,
        userName,
        revealWordsGradually,
        updateChatTimestamp,
        handleOpenAGTMaker,
        hasAGTTag,
        saveCurrentChatId,
        updateChatsOrder
      ]
    );    

    // **** IMPORTANTE: Ajustes en handleSendMessage para usar la ID local (chatIdToSend) ****
    // ==== Dentro de ChatViewNest.js, en la función handleSendMessage ====
const handleSendMessage = useCallback(
  async (messageData) => {
    if (!user || !user.uid || isWaitingForResponse) return;

    // Guardamos el chatId en una variable local para no perderlo
    const chatIdToSend = currentChatId;

    const isValidMessage =
      typeof messageData.text === 'object' &&
      messageData.text.type === 'audio_message'
        ? true
        : typeof messageData.text === 'string' &&
          messageData.text.trim().length > 0;

    if (!isValidMessage && !messageData.files?.length) return;

    setIsWaitingForResponse(true);
    setWaitingStartTime(Date.now());

    // Detectar todos los tipos de archivos (imagen/pdf/etc.)
    const isAudioMessage =
      typeof messageData.text === 'object' &&
      messageData.text.type === 'audio_message';
    const hasImageFile = messageData.files?.some((file) =>
      file.type.startsWith('image/')
    );
    const hasPdf = messageData.files?.some((file) => 
      file.type === 'application/pdf'
    );
    const hasExcel = messageData.files?.some((file) =>
      file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    );

    // Actualizar estados de procesamiento
    setIsProcessingAudio(isAudioMessage);
    setIsProcessingImage(hasImageFile);
    setMessageTypeInfo({
      isAudio: isAudioMessage,
      isImage: hasImageFile,
      isPdf: hasPdf,
      isExcel: hasExcel,
      isProjectChat: !!projectContext,
      modelType: selectedModel,
      userIsPro: user?.isPro,
      modelTier: selectedModel === 'agt-ultra-20241227'
        ? 'ultra'
        : selectedModel === 'claude-3-5-sonnet-20241022'
          ? 'pro'
          : 'agt'
    });

    try {
      // Si no existe chatId local, creamos uno nuevo con handleNewChatInput
      if (!chatIdToSend) {
        return await handleNewChatInput(messageData);
      }

      // 1) Agregamos el mensaje del user a la UI local
      const newMessage = {
        id: uuidv4(),
        text:
          typeof messageData.text === 'object'
            ? JSON.stringify({
                ...messageData.text,
                source: messageData.source,
                captureType: messageData.captureType,
              })
            : messageData.text,
        isUser: true,
        timestamp: Date.now(),
        files: messageData.files?.map((file) => ({
          ...file,
          source: messageData.source || file.source,
        })),
        voicePreference: messageData.voicePreference,
        imageBase64Data: messageData.files,
        source: messageData.source,
        captureType: messageData.captureType,
      };

      setMessages((prev) => [...prev, newMessage]);
      setVisibleMessages((prev) => [...prev, newMessage]);

      // Hacemos un scroll al final
      requestAnimationFrame(() => {
        scrollToBottom({ force: true });
      });

      // 2) Llamamos al backend
      const response = await sendMessageToAGT(
        {
          text: messageData.text,
          files: messageData.files,
          voicePreference: messageData.voicePreference,
          source: messageData.source,
          captureType: messageData.captureType,
          projectId: projectIdForBackend,
          metadata: {
            source: messageData.source,
            captureType: messageData.captureType,
            deviceInfo: 'web',
            timestamp: new Date().toISOString(),
          },
        },
        user.uid,
        chatIdToSend,
        chatContext,
        selectedModel,
        selectedPersonality,
        userName,
        messageData.voicePreference
      );

      // == (A) NUEVA VERIFICACIÓN DE RATE LIMIT, ANTES DE !response.reply ==
      if (response && response.isRateLimit) {
        // Es un caso de límite de mensajes. Colocamos la notificación
        setRateLimitInfo({
          message: response.message || 'Has alcanzado el límite de mensajes por hora',
          resetIn: response.resetIn || 3600,
          isPro: response.isPro || false
        });
        // Salimos de la función para NO lanzar "Invalid response from AGT"
        return;
      }
      // == (B) SI NO es rate-limit, recién aquí checamos "Invalid response"
      if (!response || !response.reply) {
        throw new Error('Invalid response from AGT');
      }

      // 3) Es un response normal => Actualizamos la UI
      setLastAssistantMessage({
        text: response.reply,
        audioBlob: response.audioBlob,
        isUser: false,
      });

      // **Capturar ultraThoughts**
      if (response.ultraThoughts && response.ultraThoughts.length > 0) {
        setUltraThoughts(response.ultraThoughts);
      } else {
        setUltraThoughts([]);
      }      

      const responseMessage = {
        id: uuidv4(),
        text: '',
        isUser: false,
        timestamp: Date.now(),
        imageBase64Data: null,
        source: messageData.source,
        captureType: messageData.captureType,
      };

      setLastMessageId(responseMessage.id);
      setMessages((prev) => [...prev, responseMessage]);
      setVisibleMessages((prev) => [...prev, responseMessage]);

      // Verificamos si la respuesta pide AGTSearch:image
      if (response.reply.includes('<AGTSearch:image:')) {
        setLastImageRequestId(responseMessage.id);
      } else {
        setLastImageRequestId(null);
      }

      // Animación incremental
      revealWordsGradually(
        response.reply,
        (updater) => {
          setMessages(updater);
          setVisibleMessages(updater);
        },
        () => {
          setIsWaitingForResponse(false);
          setLastMessageId(null);
          setAutoScrollActive(false);
        }
      );

      setChatContext(response.updatedContext || {});
      chatCache.current[chatIdToSend] = {
        messages: [
          ...(chatCache.current[chatIdToSend]?.messages || []),
          newMessage,
          { ...responseMessage, text: response.reply },
        ],
        context: response.updatedContext || {},
      };

      // Actualizar timestamp en la DB
      await updateChatTimestamp(user.uid, chatIdToSend);

      // Volvemos a cargar la lista de chats
      const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
      setChats(updatedChatsResponse.chats);
      setHasNextPage(updatedChatsResponse.hasMore);
      setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);
      updateChatsOrder();

      if (response.title) {
        setChatTitle(response.title);
        setChats((prevChats) =>
          prevChats.map((c) =>
            c.id === chatIdToSend
              ? {
                  ...c,
                  title: response.title,
                  name: response.title,
                  updatedAt: new Date().toISOString(),
                }
              : c
          )
        );
        updateChatsOrder();
      }

      // Si detectamos <agt> en la respuesta
      if (hasAGTTag(response.reply)) {
        handleOpenAGTMaker(response.reply, () => {});
      }

      return {
        reply: response.reply,
        audioBlob: response.audioBlob,
        updatedContext: response.updatedContext,
      };

    } catch (error) {
      console.log('=== ERROR DEBUG ===');
      console.log(error); // stack
      console.log('error.response =>', error.response); 
      console.log('error.response?.status =>', error.response?.status);

      if (error.response?.status === 429) {
        // De todos modos, si el request se hizo directo => fallback
        const data = error.response?.data || {};
        setRateLimitInfo({
          message: data.message || 'Has alcanzado el límite de mensajes por hora',
          resetIn: data.resetIn || 3600,
          isPro: data.isPro || false
        });
        return;
      } else {
        setError('Error al enviar mensaje. Por favor, intenta de nuevo.');
        setRetryAction(() => () => handleSendMessage(messageData));
      }
    } finally {
      setIsWaitingForResponse(false);
      setIsProcessingAudio(false);
      setIsProcessingImage(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  },
  [
    user,
    currentChatId,
    isWaitingForResponse,
    chatContext,
    selectedModel,
    selectedPersonality,
    userName,
    projectContext,
    revealWordsGradually,
    updateChatTimestamp,
    getChats,
    updateChatsOrder,
    handleOpenAGTMaker,
    hasAGTTag,
    scrollToBottom,
    handleNewChatInput,
    projectIdForBackend
  ]
);

    // Editar título de un chat
    const handleTitleEdit = useCallback(() => {
      setIsEditingTitle(true);
      setEditedTitle(chatTitle);
    }, [chatTitle]);

    const handleTitleSave = useCallback(async () => {
      if (!user || !user.uid || !currentChatId) return;
      try {
        const response = await updateChatTitle(user.uid, currentChatId, editedTitle);
        if (response && response.success) {
          setChatTitle(editedTitle);
          setChats((prevChats) =>
            prevChats.map((c) =>
              c.id === currentChatId
                ? {
                    ...c,
                    title: editedTitle,
                    name: editedTitle,
                    updatedAt: response.updatedChat.updatedAt,
                  }
                : c
            )
          );
          updateChatsOrder();
          setIsEditingTitle(false);
          setSnackbarMessage('Chat title updated successfully');
          setSnackbarOpen(true);
        } else {
          throw new Error(response?.error || 'Failed to update chat title');
        }
      } catch (error) {
        console.error('Error in handleTitleSave:', error);
        setError('Failed to update chat title. Please try again.');
        setSnackbarMessage('Failed to update chat title. Please try again.');
        setSnackbarOpen(true);
        setRetryAction(() => handleTitleSave);
      }
    }, [user, currentChatId, editedTitle, updateChatsOrder]);

    // Toggle sidebar
    const toggleSidebar = useCallback(() => {
      // Si hideSidebar = true, no hacemos nada
      if (hideSidebar) return;

      userToggledSidebar.current = true;
      const newState = !isSidebarOpen;
      setIsSidebarOpen(newState);
      localStorage.setItem('sidebarOpen', JSON.stringify(newState));
      setTimeout(() => {
        userToggledSidebar.current = false;
      }, 100);
    }, [isSidebarOpen, hideSidebar]);

    // Context menu chats
    const handleMenuOpen = useCallback((event, chatId) => {
      event.stopPropagation();
      const rect = event.currentTarget.getBoundingClientRect();
      setMenuPosition({ top: rect.bottom, left: rect.right });
      setMenuAnchorEl(event.currentTarget);
      setMenuChatId(chatId);
    }, []);

    const handleMenuClose = useCallback(() => {
      setMenuAnchorEl(null);
      setMenuChatId(null);
    }, []);

    const handleDeleteChat = useCallback(async () => {
      if (!user || !user.uid || !menuChatId) return;
      try {
        await deleteChat(user.uid, menuChatId);

        const updatedChatsResponse = await getChats(user.uid, { limit: 20 });
        setChats(updatedChatsResponse.chats);
        setHasNextPage(updatedChatsResponse.hasMore);
        setLastEvaluatedKey(updatedChatsResponse.lastEvaluatedKey);

        if (currentChatId === menuChatId) {
          setCurrentChatId(null);
          saveCurrentChatId(null);
          setChatTitle('');
          setMessages([]);
          setVisibleMessages([]);
          setChatContext({});
          setIsNewChatView(true);
          setIsAGTMakerOpen(false);
          setCurrentAGTContent('');
        }

        delete chatCache.current[menuChatId];
        setSnackbarMessage('Chat deleted successfully');
        setSnackbarOpen(true);
        updateChatsOrder();
      } catch (error) {
        console.error('Error in handleDeleteChat:', error);
        setError('Failed to delete chat. Please try again.');
        setSnackbarMessage('Failed to delete chat. Please try again.');
        setSnackbarOpen(true);
        setRetryAction(() => handleDeleteChat);
      } finally {
        handleMenuClose();
      }
    }, [
      user,
      menuChatId,
      currentChatId,
      handleMenuClose,
      saveCurrentChatId,
      updateChatsOrder,
    ]);

    // Manejadores de búsqueda / filtros
    const handleSearchFocus = () => {
      setIsSearchFocused(true);
    };
    const handleSearchBlur = () => {
      if (!searchTerm.trim()) {
        setIsSearchFocused(false);
      }
    };
    const debouncedSearch = useCallback(
      debounce(async (term) => {
        if (!user?.uid) return;

        try {
          setIsNextPageLoading(true);

          if (!term.trim()) {
            // Si no hay término de búsqueda, volvemos al modo normal
            setIsSearchMode(false);
            await loadChats(false);
            return;
          }

          setIsSearchMode(true);

          const response = await getChats(user.uid, {
            limit: 50,
            searchTerm: term,
            category: selectedCategory !== 'add' ? selectedCategory : null
          });

          setChats(response.chats);
          setHasNextPage(response.hasMore);
          setLastEvaluatedKey(response.lastEvaluatedKey);
          setTotalSearchResults(response.total || 0);

        } catch (error) {
          console.error('Error searching chats:', error);
          setError('Error searching chats. Please try again.');
        } finally {
          setIsNextPageLoading(false);
        }
      }, 300),
      [user?.uid, selectedCategory]
    );
    const handleSearchChange = useCallback((e) => {
      const term = e.target.value;
      setSearchTerm(term);

      if (term.trim()) {
        setIsSearchFocused(true);
      } else {
        setIsSearchFocused(false);
      }

      // Invocamos la búsqueda debounced
      debouncedSearch(term);
    }, [debouncedSearch]);
    const toggleFilter = useCallback(() => {
      setIsFilterOpen((prev) => !prev);
    }, []);
    const handleFilterToggle = useCallback((filter) => {
      setSelectedFilters((prev) =>
        prev.includes(filter) ? prev.filter((f) => f !== filter) : [...prev, filter]
      );
    }, []);
    const clearAllFilters = useCallback(() => {
      setSelectedFilters([]);
      setSearchTerm('');
      setDateFilter(null);
      setIsSearchFocused(false);
    }, []);

    // Retry general
    const handleRetry = useCallback(() => {
      if (retryAction) {
        retryAction();
        setRetryAction(null);
        setError(null);
      }
    }, [retryAction]);

    const renderErrorMessage = useCallback(() => {
      if (!error) return null;
      return (
        <ErrorMessage>
          <Typography color="text.primary" gutterBottom>
            {error}
          </Typography>
          {retryAction && (
            <RetryButton
              variant="contained"
              startIcon={<RefreshCw />}
              onClick={handleRetry}
            >
              RETRY
            </RetryButton>
          )}
        </ErrorMessage>
      );
    }, [error, retryAction, handleRetry]);

    // Crear nuevo chat (botón + en la interfaz)
    const createNewChatHandler = useCallback(() => {
      // Si había un proyecto seleccionado, lo deseleccionamos
      setSelectedProject(null);

      setCurrentChatId(null);
      saveCurrentChatId(null);
      setMessages([]);
      setVisibleMessages([]);
      setChatContext({});
      setChatTitle('');
      setIsNewChatView(true);
      setIsAGTMakerOpen(false);
      setCurrentAGTContent('');
    }, [saveCurrentChatId, setSelectedProject]);

    const loadMoreMessages = useCallback(() => {
      const currentLength = visibleMessages.length;
      const newMessages = messages.slice(-currentLength - 20, -currentLength);
      setVisibleMessages((prev) => [...newMessages, ...prev]);
    }, [messages, visibleMessages]);

    // Filtros por fechas
    const filterCategories = useMemo(
      () => ({
        main: [
          { name: 'Today', tooltip: 'Show chats from today' },
          { name: 'Yesterday', tooltip: 'Show chats from yesterday' },
        ],
        extra: [
          { name: 'Previous 7 Days', tooltip: 'Show chats from the last week' },
          { name: 'Previous 30 Days', tooltip: 'Show chats from the last month' },
          { name: 'Older', tooltip: 'Show older chats' },
        ],
      }),
      []
    );

    // Filtrar chats según búsquedas y fechas
    const filteredChats = useMemo(() => {
      let filtered = chats;
      if (selectedCategory && selectedCategory !== 'add') {
        filtered = filtered.filter((chat) => chat.category === selectedCategory);
      }
      if (searchTerm.trim()) {
        filtered = filtered.filter((chat) =>
          chat.title?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }
      if (selectedFilters.length > 0) {
        filtered = filtered.filter((chat) => {
          const category = getChatCategory(chat);
          return (
            selectedFilters.includes(category) ||
            (selectedFilters.includes('Older') &&
              !['Today', 'Yesterday', 'Previous 7 Days', 'Previous 30 Days'].includes(
                category
              ))
          );
        });
      }
      if (dateFilter) {
        filtered = filtered.filter((chat) => {
          const chatDate = parseISO(chat.updatedAt || chat.createdAt);
          return (
            isValid(chatDate) &&
            isAfter(chatDate, startOfMonth(dateFilter)) &&
            isBefore(chatDate, endOfMonth(dateFilter))
          );
        });
      }
      return filtered.sort((a, b) => {
        const dateA = new Date(a.updatedAt || a.createdAt);
        const dateB = new Date(b.updatedAt || b.createdAt);
        return dateB - dateA;
      });
    }, [
      chats,
      searchTerm,
      selectedFilters,
      dateFilter,
      getChatCategory,
      selectedCategory,
    ]);

    // Guardar estado persistente
    useEffect(() => {
      try {
        localStorage.setItem('chatView_state', JSON.stringify(persistentChatState));
      } catch (error) {
        console.error('Error saving persistent chat state:', error);
      }
    }, [persistentChatState]);

    // Seleccionar chat si hay un mostRecentChatId
    useEffect(() => {
      if (mostRecentChatId) {
        selectChat(mostRecentChatId);
      }
    }, [mostRecentChatId, selectChat]);

    // Cerrar sidebar si abrimos AGTMaker (solo desktop)
    useEffect(() => {
      if (!isMobile && !userToggledSidebar.current) {
        if (isAGTMakerOpen !== prevAGTMakerState.current) {
          const savedSidebarState = localStorage.getItem('sidebarOpen');
          if (isAGTMakerOpen) {
            localStorage.setItem(
              'sidebarStateBeforeAGTMaker',
              JSON.stringify(isSidebarOpen)
            );
            setIsSidebarOpen(false);
          } else {
            const stateBeforeAGTMaker = localStorage.getItem(
              'sidebarStateBeforeAGTMaker'
            );
            if (stateBeforeAGTMaker !== null) {
              setIsSidebarOpen(JSON.parse(stateBeforeAGTMaker));
            } else if (savedSidebarState !== null) {
              setIsSidebarOpen(JSON.parse(savedSidebarState));
            }
          }
          prevAGTMakerState.current = isAGTMakerOpen;
        }
      }
    }, [isAGTMakerOpen, isMobile, isSidebarOpen]);

    useEffect(() => {
      if (!isAGTMakerOpen) {
        localStorage.setItem('sidebarOpen', JSON.stringify(isSidebarOpen));
      }
    }, [isSidebarOpen, isAGTMakerOpen]);

    // Cargar chats al inicio
    useEffect(() => {
      if (user && user.uid) {
        if (isInitialLoad) {
          loadChats(false);
          setIsInitialLoad(false);
        }
      }
    }, [user, isInitialLoad, loadChats]);

    // Actualizar la lista de chats al volver de background
    useEffect(() => {
      if (!user || !user.uid) return;
      const updateChatsEffect = async () => {
        try {
          const response = await getChats(user.uid, {
            limit: persistentChatState.totalChatsLoaded || 20,
          });
          setChats(response.chats);
          updateChatsOrder();

          if (currentChatId) {
            const currentChat = response.chats.find((c) => c.id === currentChatId);
            if (currentChat) {
              setChatTitle(currentChat.title || 'New chat');
            }
          }
        } catch (err) {
          console.error('Error updating chats:', err);
          setError('Error updating chats. Please try again.');
        }
      };
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          updateChatsEffect();
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, [user, currentChatId, updateChatsOrder, persistentChatState.totalChatsLoaded]);

    // Manejo de modelo (Claude) y personalidad
    const handleModelChange = useCallback(
      (newModel) => {
        if (newModel === 'claude-3-5-sonnet-20241022' && !user?.isPro) {
          onOpenAGTPromo();
          return;
        }
        setSelectedModel(newModel);
      },
      [user?.isPro, onOpenAGTPromo]
    );

    const handlePersonalityChange = useCallback(
      async (newPersonality) => {
        if (newPersonality !== 'normal' && !user?.isPro) {
          onOpenAGTPromo();
          return;
        }
        try {
          await setUserPersonalityPreference(user.uid, newPersonality);
          setSelectedPersonality(newPersonality);
          localStorage.setItem('selectedPersonality', newPersonality);
        } catch (error) {
          console.error('Error saving personality preference:', error);
          setSnackbarMessage('Error saving personality preference');
          setSnackbarOpen(true);
        }
      },
      [user?.uid, user?.isPro, onOpenAGTPromo]
    );

    // Intentar restaurar último chat
    useEffect(() => {
      const savedChatId = localStorage.getItem('currentChatId');
      if (savedChatId && user && user.uid) {
        selectChat(savedChatId);
      }
    }, [user, selectChat]);

    // Manejo de focus en el input
    useEffect(() => {
      if (!isWaitingForResponse) {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    }, [isWaitingForResponse]);

    // Manejo de rate-limit
    useEffect(() => {
      if (rateLimitInfo) {
        const timer = setTimeout(() => {
          setRateLimitInfo(null);
        }, rateLimitInfo.resetIn * 1000);
        return () => clearTimeout(timer);
      }
    }, [rateLimitInfo]);

    // Model por default si es Pro o no
    useEffect(() => {
      if (user?.isPro) {
        setSelectedModel('claude-3-5-sonnet-20241022');
      } else {
        setSelectedModel('claude-3-haiku-20240307');
      }
    }, [user?.isPro]);

    // Personalidad por default
    useEffect(() => {
      if (!user?.isPro) {
        setSelectedPersonality('normal');
        localStorage.setItem('selectedPersonality', 'normal');
      }
    }, [user?.isPro]);

    // Si se cambia de categoría, borramos cualquier proyecto seleccionado
    useEffect(() => {
      setSelectedProject(null);
    }, [selectedCategory]);

    // Callback para manejar selección de un chat de proyecto
    const handleProjectChatSelect = useCallback(
      async (chatId, isNew = false, newView = null) => {
        // Limpiamos estado inicial
        setCurrentChatId(null);
        saveCurrentChatId(null);
        setMessages([]);
        setVisibleMessages([]);
        setChatContext({});

        if (isNew) {
          // Chat recién creado
          setCurrentChatId(chatId);
          saveCurrentChatId(chatId);
          setIsNewChatView(false);

          // Cargamos el chat de inmediato
          await selectChat(chatId);

          // Ajustamos la vista del proyecto si hace falta
          if (selectedProject && newView) {
            setSelectedProject((prev) => ({
              ...prev,
              currentView: newView
            }));
          }

          // Guardamos info de proyecto en chatContext, si aplica
          if (selectedProject) {
            setChatContext((prev) => ({
              ...prev,
              project: {
                projectId: selectedProject.id,
                category: selectedProject.category,
                name: selectedProject.name
              }
            }));
          }
        } else {
          // Chat existente
          await selectChat(chatId);
        }
      },
      [selectChat, saveCurrentChatId, selectedProject]
    );

    // Footer (solo se renderiza si hideFooter es false)
    const renderFooter = useCallback(() => {
      if (hideFooter) return null;
      return (
        <FooterContainer>
          <InfoText>
            By using AGT, you agree to our{' '}
            <a
              href="https://directiveai.org/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policies
            </a>
            .
          </InfoText>
        </FooterContainer>
      );
    }, [hideFooter]);

    // Manejar clic en categorías
    const handleCategoryClick = useCallback(
      (category) => {
        if (category === 'add') {
          setShowCategoryModal(true);
          return;
        }
        setSelectedCategory(category);
      },
      []
    );

    // SIDEBAR
    const renderSidebar = useCallback(() => {
      return (
        <>
          <SidebarHeader>
            <ButtonsContainer>
              <MenuButton onClick={toggleSidebar}>
                <MenuIcon />
              </MenuButton>
              <BlueIconButton onClick={createNewChatHandler}>
                <Plus />
              </BlueIconButton>
            </ButtonsContainer>

            <div className="header-content">
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: '1.75rem',
                  color: 'text.primary',
                  marginBottom: '8px',
                  paddingLeft: '4px',
                }}
              >
                Chats
              </Typography>

              <SearchInputContainer>
                <Search size={20} style={{ marginRight: '8px' }} />
                <StyledInput
                  placeholder="Search chats"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onFocus={handleSearchFocus}
                  onBlur={handleSearchBlur}
                />
              </SearchInputContainer>

              <AnimatedFiltersContainer
                sx={{
                  height: isSearchFocused || searchTerm.trim() ? 'auto' : 0,
                  opacity: isSearchFocused || searchTerm.trim() ? 1 : 0,
                }}
              >
                <Box display="flex" flexWrap="wrap" mb={1} mt={1} alignItems="center">
                  {filterCategories.main.map((category) => (
                    <Tooltip key={category.name} title={category.tooltip}>
                      <FilterChip
                        onClick={() => handleFilterToggle(category.name)}
                        className={
                          selectedFilters.includes(category.name) ? 'selected' : ''
                        }
                      >
                        {category.name}
                      </FilterChip>
                    </Tooltip>
                  ))}

                  <Tooltip
                    title={showMoreFilters ? 'Show less filters' : 'Show more filters'}
                  >
                    <MoreFiltersButton
                      onClick={() => setShowMoreFilters(!showMoreFilters)}
                      className={showMoreFilters ? 'selected' : ''}
                      size="small"
                    >
                      <Plus />
                    </MoreFiltersButton>
                  </Tooltip>

                  {showMoreFilters &&
                    filterCategories.extra.map((category) => (
                      <Tooltip key={category.name} title={category.tooltip}>
                        <FilterChip
                          onClick={() => handleFilterToggle(category.name)}
                          className={
                            selectedFilters.includes(category.name) ? 'selected' : ''
                          }
                        >
                          {category.name}
                        </FilterChip>
                      </Tooltip>
                    ))}

                  {(selectedFilters.length > 0 || searchTerm) && (
                    <ClearAllButton
                      onClick={clearAllFilters}
                      startIcon={<XCircle size={16} />}
                    >
                      Clear all
                    </ClearAllButton>
                  )}
                </Box>
              </AnimatedFiltersContainer>

              <CategoriesSubheader disableSticky disableGutters>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '14px', lineHeight: '20px' }}
                  >
                    Categories
                  </Typography>
                  {customCategories.length > 0 && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#0385FF',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 500,
                      }}
                      onClick={() => setShowCategoryManager(!showCategoryManager)}
                    >
                      More
                    </Typography>
                  )}
                </Box>
              </CategoriesSubheader>

              {/* Fila 1: Personal, Work, Team, Add */}
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                {/* Personal */}
                <Box display="flex" flexDirection="column" alignItems="center">
                  <GreyIconButton
                    onClick={() => handleCategoryClick('personal')}
                    $selected={selectedCategory === 'personal'}
                  >
                    <User />
                  </GreyIconButton>
                  <Typography variant="caption" sx={{ marginTop: '4px', fontSize: '12px' }}>
                    Personal
                  </Typography>
                </Box>

                {/* Work */}
                <Box display="flex" flexDirection="column" alignItems="center">
                  <GreyIconButton
                    onClick={() => handleCategoryClick('work')}
                    $selected={selectedCategory === 'work'}
                  >
                    <Briefcase />
                  </GreyIconButton>
                  <Typography variant="caption" sx={{ marginTop: '4px', fontSize: '12px' }}>
                    Work
                  </Typography>
                </Box>

                {/* Team */}
                <Box display="flex" flexDirection="column" alignItems="center">
                  <GreyIconButton
                    onClick={() => handleCategoryClick('team')}
                    $selected={selectedCategory === 'team'}
                  >
                    <Users />
                  </GreyIconButton>
                  <Typography variant="caption" sx={{ marginTop: '4px', fontSize: '12px' }}>
                    Team
                  </Typography>
                </Box>

                {/* Add */}
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Tooltip
                    title={
                      customCategories.length >= 2
                        ? 'Max. 2 custom categories. Delete one if you want to create a new one.'
                        : 'Add a new category'
                    }
                    arrow
                  >
                    <span>
                      <GreyIconButton
                        onClick={() => handleCategoryClick('add')}
                        $selected={selectedCategory === 'add'}
                        disabled={customCategories.length >= 2}
                      >
                        <Plus />
                      </GreyIconButton>
                    </span>
                  </Tooltip>
                  <Typography variant="caption" sx={{ marginTop: '4px', fontSize: '12px' }}>
                    Add
                  </Typography>
                </Box>
              </Box>

              {/* Fila 2: categorías personalizadas */}
              {customCategories.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  mt={2}
                >
                  {customCategories.map((cat) => (
                    <Box
                      key={cat.name}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      sx={{ cursor: 'pointer', position: 'relative' }}
                    >
                      <GreyIconButton
                        $selected={selectedCategory === cat.name}
                        sx={
                          showCategoryManager
                            ? {
                                backgroundColor: 'red',
                                color: 'white',
                                '&:hover': { backgroundColor: 'darkred' },
                              }
                            : {}
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          if (showCategoryManager) {
                            handleDeleteCategoryRequest(cat.name);
                          } else {
                            handleCategoryClick(cat.name);
                          }
                        }}
                      >
                        {showCategoryManager ? <MinusCircle /> : <Puzzle />}
                      </GreyIconButton>
                      <Typography
                        variant="caption"
                        sx={{ marginTop: '4px', fontSize: '12px' }}
                      >
                        {cat.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </div>
          </SidebarHeader>

          <SidebarContent>
            <ProjectsSection
              ref={projectsSectionRef}
              selectedCategory={selectedCategory}
              setSelectedProject={handleSelectProject}
              selectedProject={selectedProject}
              user={user}
              onProjectChatSelect={handleProjectChatSelect}
            />

            <CategorizedChatList
              chats={filteredChats}
              currentChatId={currentChatId}
              onSelectChat={selectChat}
              onMenuOpen={handleMenuOpen}
              hasNextPage={hasNextPage}
              isNextPageLoading={isNextPageLoading}
              onLoadMore={() => loadChats(true)}
              onSidebarClose={toggleSidebar}
              isMobile={isMobile}
              selectedCategory={selectedCategory}
              persistentState={persistentChatState}
              onPersistentStateChange={(newState) => {
                setPersistentChatState((prev) => ({
                  ...prev,
                  ...newState,
                }));
              }}
            />
          </SidebarContent>
        </>
      );
    }, [
      toggleSidebar,
      createNewChatHandler,
      searchTerm,
      handleSearchChange,
      filterCategories,
      selectedFilters,
      showMoreFilters,
      handleFilterToggle,
      clearAllFilters,
      filteredChats,
      currentChatId,
      selectChat,
      handleMenuOpen,
      hasNextPage,
      isNextPageLoading,
      loadMoreMessages,
      handleCategoryClick,
      isSearchFocused,
      selectedCategory,
      customCategories,
      showCategoryManager,
      handleDeleteCategoryRequest,
      isMobile,
      persistentChatState,
      handleSelectProject,
      user,
      selectedProject,
      handleProjectChatSelect
    ]);

    const currentChatObj = useMemo(
      () => chats.find((c) => c.id === currentChatId),
      [chats, currentChatId]
    );

    // Header principal de ChatViewNest (mostrar solo si NO hay un proyecto seleccionado)
    const renderHeader = useCallback(() => {
      return (
        <>
          {isMobile && (
            <MobileHeader>
              <MenuButton onClick={toggleSidebar}>
                <MenuIcon />
              </MenuButton>

              <Box
                sx={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxWidth: '70%',
                  overflow: 'hidden',
                }}
              >
                {currentChatId && !isNewChatView ? (
                  <>
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {chatTitle || 'AGT Chat'}
                    </Typography>

                    {currentChatObj?.type === 'project' && currentChatObj?.category && (
                      <CategoryChip>
                        {currentChatObj.category} project
                      </CategoryChip>
                    )}
                  </>
                ) : (
                  <Typography variant="h6" noWrap>
                    New Chat
                  </Typography>
                )}
              </Box>

              <BlueIconButton onClick={createNewChatHandler}>
                <Plus />
              </BlueIconButton>
            </MobileHeader>
          )}

          {!isMobile && (
            <DesktopHeader>
              <Box width="33.33%" display="flex" justifyContent="flex-start">
                {!hideSidebar && !isSidebarOpen && (
                  <>
                    <MenuButton onClick={toggleSidebar}>
                      <MenuIcon />
                    </MenuButton>
                    <BlueIconButton onClick={createNewChatHandler} sx={{ ml: 1 }}>
                      <Plus />
                    </BlueIconButton>
                  </>
                )}
              </Box>

              <Box width="33.33%" display="flex" justifyContent="center">
                {currentChatId && !isNewChatView ? (
                  isEditingTitle ? (
                    <TextField
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                      onBlur={handleTitleSave}
                      onKeyPress={(e) => e.key === 'Enter' && handleTitleSave()}
                      autoFocus
                      variant="standard"
                      sx={{
                        input: { textAlign: 'center' },
                        '& .MuiInput-underline:before': {
                          borderBottomColor: 'text.primary',
                        },
                        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                          borderBottomColor: 'text.primary',
                        },
                      }}
                    />
                  ) : (
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="h6"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleTitleEdit}
                      >
                        {chatTitle}
                      </Typography>

                      {currentChatObj?.type === 'project' && currentChatObj?.category && (
                        <CategoryChip>
                          {currentChatObj.category} project
                        </CategoryChip>
                      )}
                    </Box>
                  )
                ) : (
                  <Typography variant="h6">New Chat</Typography>
                )}
              </Box>

              <Box width="33.33%" display="flex" justifyContent="flex-end" />
            </DesktopHeader>
          )}
        </>
      );
    }, [
      isMobile,
      toggleSidebar,
      hideSidebar,
      isSidebarOpen,
      currentChatId,
      isNewChatView,
      chatTitle,
      createNewChatHandler,
      isEditingTitle,
      editedTitle,
      handleTitleSave,
      handleTitleEdit,
      currentChatObj
    ]);

    return (
      <ChatContainer>
        {!hideSidebar && isMobile && (
          <SidebarOverlay $visible={isSidebarOpen} onClick={toggleSidebar} />
        )}

        {!hideSidebar && (
          <Sidebar $isOpen={isSidebarOpen} $isMobile={isMobile}>
            <ProjectContextProvider>
              {renderSidebar()}
            </ProjectContextProvider>
          </Sidebar>
        )}

        <ChatArea
          $isSidebarOpen={!hideSidebar && isSidebarOpen && !isMobile}
          $isMobile={isMobile}
          $isAGTMakerOpen={isAGTMakerOpen && !isMobile}
          $withHeader={!selectedProject}
          className={loadingStates.messages || loadingStates.chatInfo ? 'loading' : ''}
        >
          {/* MOSTRAMOS EL HEADER DEL CHAT SOLO SI NO hay un proyecto seleccionado */}
          {renderHeader()}

          <Box display="flex" flexDirection="column" height="100%">
            <Box flexGrow={1} overflow="auto">
              {selectedProject && selectedProject.currentView !== 'chat' ? (
                <ProjectChatView
                  project={selectedProject}
                  onProjectChatSelect={handleProjectChatSelect}
                  isWaitingForResponse={isWaitingForResponse}
                  handleSendMessage={handleSendMessage}
                  inputRef={inputRef}
                  rateLimitInfo={rateLimitInfo}
                  onOpenAGTPromo={onOpenAGTPromo}
                  selectedModel={selectedModel}
                  onModelChange={handleModelChange}
                  selectedPersonality={selectedPersonality}
                  onPersonalityChange={handlePersonalityChange}
                  isPro={user?.isPro}
                  userId={user?.uid}
                  error={error}
                  onErrorClose={() => setError(null)}
                  lastAssistantMessage={lastAssistantMessage}
                  toggleSidebar={toggleSidebar}
                />
              ) : isNewChatView || (!currentChatId && messages.length === 0) ? (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  padding="20px"
                >
                  <PromptCards
                    isPro={user?.isPro}
                    onSelectPrompt={(action) => {
                      if (optimizedInputRef.current) {
                        optimizedInputRef.current.handlePromptAction(action);
                      }
                    }}
                  />
                </Box>
              ) : (
                <MessagesContainer ref={messagesContainerRef}>
                  {visibleMessages.map((message, index) => {
                    const isLastAssistantMessage =
                      !message.isUser && message.id === lastMessageId;
                    return (
                      <MagicalMessage
                        key={message.id || index}
                        content={message.text}
                        isUser={message.isUser}
                        isLastMessage={isLastAssistantMessage}
                        onFeedback={() => {}}
                        userId={user?.uid}
                        messageId={message.id}
                        hasAGTMaker={hasAGTTag(message.text)}
                        onOpenAGTMaker={() =>
                          hasAGTTag(message.text) &&
                          handleOpenAGTMaker(message.text, () => {})
                        }
                        isLoading={message.isLoading}
                        onUpdateMessage={handleUpdateMessage}
                        lastImageRequestId={lastImageRequestId}
                        files={message.files}
                        imageBase64Data={message.imageBase64Data}
                      />
                    );
                  })}

                  {isWaitingForResponse && (
                    <ThinkingIcon
                      key="thinking-icon"
                      startTime={waitingStartTime}
                      messageTypeInfo={messageTypeInfo}
                      thinkingPhrases={ultraThoughts}
                    />
                  )}
                  {renderErrorMessage()}

                  <div ref={messagesEndRef} />
                </MessagesContainer>
              )}
            </Box>

            {/* 
              Mostramos el OptimizedInput cuando:
              1. No hay proyecto seleccionado, O
              2. El proyecto está en vista 'chat'
            */}
            {(!selectedProject || selectedProject.currentView === 'chat') && (
              <MessageBarContainer>
                <InputContainer>
                  <OptimizedInput
                    ref={optimizedInputRef}
                    onSendMessage={handleSendMessage}
                    isWaitingForResponse={isWaitingForResponse}
                    inputRef={inputRef}
                    rateLimitInfo={rateLimitInfo}
                    onOpenAGTPromo={onOpenAGTPromo}
                    selectedModel={selectedModel}
                    onModelChange={handleModelChange}
                    selectedPersonality={selectedPersonality}
                    onPersonalityChange={handlePersonalityChange}
                    isPro={user?.isPro}
                    userId={user.uid}
                    chatId={currentChatId}
                    error={error}
                    onErrorClose={() => setError(null)}
                    lastAssistantMessage={lastAssistantMessage}
                    onPromptAction={handlePromptCardAction}
                    onProjectClick={handleProjectClick}
                  />
                </InputContainer>
              </MessageBarContainer>
            )}

            {renderFooter()}
          </Box>
        </ChatArea>

        <AGTMakerContainer
          sx={{
            transform: isAGTMakerOpen ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          <AGTMaker
            content={currentAGTContent}
            isOpen={isAGTMakerOpen}
            onClose={handleCloseAGTMaker}
            onContentReady={handleAGTMakerContentReady}
          />
        </AGTMakerContainer>

        <Menu
          anchorEl={menuAnchorEl}
          anchorReference="anchorPosition"
          anchorPosition={menuPosition}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            onClick={handleDeleteChat}
            sx={{
              color: 'error.main',
              '&:hover': { backgroundColor: 'error.light' },
            }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            Delete
          </MenuItem>
        </Menu>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={error ? 'error' : 'success'}
            sx={{ width: '100%' }}
            action={
              error && retryAction ? (
                <Button
                  color="inherit"
                  size="small"
                  onClick={handleRetry}
                  sx={{
                    borderRadius: '30px',
                    backgroundColor: 'action.selected',
                    color: 'text.primary',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    },
                  }}
                >
                  RETRY
                </Button>
              ) : null
            }
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* MODAL para CREAR nueva categoría */}
        <StyledDialog 
          open={showCategoryModal} 
          onClose={() => setShowCategoryModal(false)}
          maxWidth="sm"
          fullWidth
        >
          <ModalContent>
            <CloseButton onClick={() => setShowCategoryModal(false)}>
              <X size={20} />
            </CloseButton>

            <Typography 
              variant="h6" 
              sx={{ mb: 2, fontWeight: 600, fontSize: '24px', letterSpacing: '-0.5px' }}
            >
              Create New Category
            </Typography>

            <StyledInputModal
              placeholder="Enter category name"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              autoFocus
            />

            <Typography sx={{ 
              mt: 3,
              color: theme => theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.7)' 
                : 'rgba(0, 0, 0, 0.6)',
              fontSize: '14px'
            }}>
              Create a new category to organize your chats. An icon will be assigned automatically.
            </Typography>

            <Box
              sx={{
                mt: 'auto',
                pt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <ActionButton 
                variant="cancel"
                onClick={() => setShowCategoryModal(false)}
              >
                Cancel
              </ActionButton>
              
              <ActionButton
                variant="contained"
                onClick={async () => {
                  if (!newCategoryName.trim()) return;
                  try {
                    const updatedCategories = await createUserCategory(
                      user.uid,
                      newCategoryName.trim(),
                      'puzzle'
                    );
                    setCustomCategories(updatedCategories);
                    setNewCategoryName('');
                    setShowCategoryModal(false);
                  } catch (err) {
                    console.error(err);
                    setSnackbarMessage('Error creating category');
                    setSnackbarOpen(true);
                  }
                }}
              >
                Create category
              </ActionButton>
            </Box>
          </ModalContent>
        </StyledDialog>

        {/* MODAL de confirmación para borrar categoría */}
        <StyledDialog 
          open={showDeleteCategoryModal} 
          onClose={handleCancelDeleteCategory}
          maxWidth="sm"
          fullWidth
        >
          <ModalContent>
            <CloseButton onClick={handleCancelDeleteCategory}>
              <X size={20} />
            </CloseButton>

            <Typography 
              variant="h6" 
              sx={{ mb: 2, fontWeight: 600, fontSize: '24px', letterSpacing: '-0.5px' }}
            >
              Delete Category
            </Typography>

            <Typography sx={{ fontSize: '15px' }}>
              Are you sure you want to delete the category <strong>{categoryToDelete}</strong>?
            </Typography>

            <Typography 
              sx={{ 
                mt: 2,
                color: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255, 255, 255, 0.7)' 
                  : 'rgba(0, 0, 0, 0.6)',
                fontSize: '14px'
              }}
            >
              Your chats will not be deleted; they will be reassigned to the <strong>personal</strong> category.
            </Typography>

            <Box
              sx={{
                mt: 'auto',
                pt: 4,
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}
            >
              <ActionButton 
                variant="cancel"
                onClick={handleCancelDeleteCategory}
              >
                Cancel
              </ActionButton>
              
              <ActionButton
                variant="delete"
                onClick={handleConfirmDeleteCategory}
              >
                Delete category
              </ActionButton>
            </Box>
          </ModalContent>
        </StyledDialog>
      </ChatContainer>
    );
  }
);

export default ChatViewNest;
