import React, { useState, useContext, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Grid,
    Box,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    IconButton,
    InputAdornment,
    Tooltip,
    useTheme,
    Chip,
    Alert,
    AlertTitle,
    Paper,
    LinearProgress
  } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Refresh as RefreshIcon,
  Search as SearchIcon,
  Help as HelpIcon,
  TrendingUp as TrendingUpIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Timeline as TimelineIcon
} from '@mui/icons-material';
import { ProductContext } from '../../../context/ProductContext';
import { toast } from 'react-toastify';

// Definimos colores consistentes
const COLORS = {
  primary: '#0385FF',
  text: {
    light: '#0385FF',
    dark: '#0385FF'
  }
};

// Creamos un theme personalizado
const createCustomTheme = (mode) => createTheme({
  palette: { 
    mode 
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        contained: {
          backgroundColor: COLORS.primary,
          color: '#ffffff',
          '&:hover': {
            backgroundColor: COLORS.primary,
          },
        },
        text: {
          color: COLORS.text[mode],
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '16px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0,0,0,0.12)' : 'rgba(255,255,255,0.12)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          border: '1px solid',
          borderColor: mode === 'light' ? 'rgba(0,0,0,0.12)' : 'rgba(255,255,255,0.12)',
          marginBottom: '8px',
          '&:hover': {
            backgroundColor: mode === 'light' ? 'rgba(3, 133, 255, 0.04)' : 'rgba(3, 133, 255, 0.08)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
  },
  typography: {
    h5: { fontWeight: 600 },
    button: { textTransform: 'none' },
  },
});

const CheckInventoryShortcut = ({ open, onClose }) => {
  const theme = useTheme();
  const customTheme = createCustomTheme(theme.palette.mode);
  const { products, refreshProducts } = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // Clasificar productos
  const stockProducts = products.filter(p => p.category === 'physical' || p.category === 'food');
  const nonStockProducts = products.filter(p => !(p.category === 'physical' || p.category === 'food'));

  // Determinar el tipo de usuario
  const userType = stockProducts.length === 0 ? 'digital' : 
                  nonStockProducts.length === 0 ? 'physical' : 'mixed';

  // Función para refrescar datos
  const handleRefresh = async () => {
    setLoading(true);
    try {
      await refreshProducts();
      toast.success('Data refreshed successfully');
    } catch (error) {
      toast.error('Failed to refresh data');
    } finally {
      setLoading(false);
    }
  };

  // Renderizado específico para usuarios de productos digitales/servicios
  const renderDigitalView = () => (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: 2
        }}
      >
        <Typography variant="h5">
          Product Availability
        </Typography>
        <Tooltip title="Check your product settings and availability">
          <IconButton size="small">
            <HelpIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Alertas y Sugerencias */}
          <Grid item xs={12}>
            <Alert 
              severity="info" 
              sx={{ 
                borderRadius: '12px',
                mb: 2 
              }}
            >
              <AlertTitle>Digital Products Configuration</AlertTitle>
              We noticed you manage digital products. Make sure you have configured 
              automatic delivery and availability settings for optimal performance.
            </Alert>
          </Grid>

          {/* Estado de Disponibilidad */}
          <Grid item xs={12}>
            <Box sx={{ p: 3, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Sales Availability
              </Typography>
              
              <List>
                {nonStockProducts.map(product => (
                  <ListItem
                    key={product.id}
                    sx={{
                      mb: 1,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '12px',
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle2">
                            {product.name}
                          </Typography>
                          <Chip
                            size="small"
                            label={product.active ? 'Available for Sale' : 'Paused'}
                            color={product.active ? 'success' : 'warning'}
                            sx={{ borderRadius: '6px' }}
                          />
                        </Box>
                      }
                      secondary={
                        <Box sx={{ mt: 1 }}>
                          <Typography variant="body2" color="text.secondary">
                            ${product.price?.toFixed(2) || '0.00'}
                          </Typography>
                          {product.maxSales && (
                            <Typography variant="body2" color="warning.main" sx={{ mt: 1 }}>
                              Sales limit configured: {product.maxSales}
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => {/* Navegar a configuración */}}
                    >
                      Settings
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          {/* Recomendaciones */}
          <Grid item xs={12}>
            <Paper 
              sx={{ 
                p: 3, 
                borderRadius: '12px',
                backgroundColor: theme.palette.mode === 'dark' ? 'rgba(3, 133, 255, 0.05)' : 'rgba(3, 133, 255, 0.02)',
                border: '1px solid',
                borderColor: COLORS.primary
              }}
            >
              <Typography variant="subtitle1" gutterBottom>
                Recommendations
              </Typography>
              <List>
                <ListItem>
                  <ListItemText 
                    primary="Configure Automatic Delivery"
                    secondary="Set up automatic delivery for your digital products to ensure instant customer access."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Set Sales Limits"
                    secondary="Consider configuring sales limits if your service has capacity constraints."
                  />
                </ListItem>
                <ListItem>
                  <ListItemText 
                    primary="Review Pricing Strategy"
                    secondary="Regular price reviews help maintain competitiveness in digital markets."
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );

  // Renderizado específico para usuarios de productos físicos
  const renderPhysicalView = () => (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: 2
        }}
      >
        <Typography variant="h5">
          Inventory Management
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="Refresh Stock Levels">
            <IconButton onClick={handleRefresh} disabled={loading}>
              {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* KPIs de Inventario */}
          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle2" color="text.secondary">
                Low Stock Items
              </Typography>
              <Typography variant="h4" sx={{ mt: 1, color: 'warning.main' }}>
                {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) < 10).length}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Need attention
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle2" color="text.secondary">
                Out of Stock
              </Typography>
              <Typography variant="h4" sx={{ mt: 1, color: 'error.main' }}>
                {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) === 0).length}
              </Typography>
              <Typography variant="caption" color="error">
                Urgent restock needed
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle2" color="text.secondary">
                Healthy Stock
              </Typography>
              <Typography variant="h4" sx={{ mt: 1, color: 'success.main' }}>
                {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) >= 10).length}
              </Typography>
              <Typography variant="caption" color="success.main">
                Good inventory levels
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle2" color="text.secondary">
                Total Stock Value
              </Typography>
              <Typography variant="h4" sx={{ mt: 1 }}>
                ${stockProducts.reduce((acc, p) => acc + ((parseInt(p.stock, 10) || 0) * (p.price || 0)), 0).toFixed(2)}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Current inventory value
              </Typography>
            </Box>
          </Grid>

          {/* Lista de Productos con Alertas */}
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Inventory Alerts
              </Typography>
              <List>
                {stockProducts
                  .filter(p => (parseInt(p.stock, 10) || 0) < 10)
                  .map(product => (
                    <ListItem
                      key={product.id}
                      sx={{
                        mb: 1,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '12px',
                        border: '1px solid',
                        borderColor: 'divider'
                      }}
                    >
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {product.name}
                            {parseInt(product.stock, 10) === 0 ? (
                              <Chip
                                size="small"
                                label="Out of Stock"
                                color="error"
                                icon={<WarningIcon />}
                                sx={{ borderRadius: '6px' }}
                              />
                            ) : (
                              <Chip
                                size="small"
                                label="Low Stock"
                                color="warning"
                                sx={{ borderRadius: '6px' }}
                              />
                            )}
                          </Box>
                        }
                        secondary={
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={4}>
                              <Typography variant="caption" color="text.secondary">
                                Current Stock: {product.stock || 0}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="caption" color="text.secondary">
                                Reorder Point: 10
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="caption" color="text.secondary">
                                Last Updated: Today
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );

  // Renderizado para usuarios con productos mixtos
  const renderMixedView = () => (
    <>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pb: 2
        }}
      >
        <Typography variant="h5">
          Product Overview
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Tooltip title="Refresh Data">
            <IconButton onClick={handleRefresh} disabled={loading}>
              {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
            </IconButton>
          </Tooltip>
        </Box>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3}>
          {/* Alertas de Inventario */}
          {stockProducts.some(p => (parseInt(p.stock, 10) || 0) < 10) && (
            <Grid item xs={12}>
              <Alert 
                severity="warning"
                sx={{ borderRadius: '12px' }}
                action={
                  <Button color="warning" size="small">
                    View Details
                  </Button>
                }
              >
                {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) < 10).length} products need attention
              </Alert>
            </Grid>
          )}

          {/* Estado de Servicios */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Digital Services Status
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                <CheckCircleIcon color="success" />
                <Typography>
                  All {nonStockProducts.length} services operational
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Resumen de Inventario */}
          <Grid item xs={12} md={6}>
            <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: '12px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Inventory Status
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Out of Stock
                  </Typography>
                  <Typography variant="body2" color="error.main">
                    {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) === 0).length} items
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary">
                    Low Stock
                  </Typography>
                  <Typography variant="body2" color="warning.main">
                    {stockProducts.filter(p => (parseInt(p.stock, 10) || 0) < 10 && (parseInt(p.stock, 10) || 0) > 0).length} items
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Lista Combinada de Productos */}
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              Product Overview
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search products..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 2 }}
            />
            <List>
              {products
                .filter(product => 
                  product.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map(product => (
                  <ListItem
                    key={product.id}
                    sx={{
                      mb: 1,
                      backgroundColor: theme.palette.background.paper,
                      borderRadius: '12px',
                      border: '1px solid',
                      borderColor: 'divider'
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          {product.name}
                          {(product.category === 'physical' || product.category === 'food') ? (
                            <Chip
                              size="small"
                              label={parseInt(product.stock, 10) === 0 ? 'Out of Stock' : 
                                     parseInt(product.stock, 10) < 10 ? 'Low Stock' : 'In Stock'}
                              color={parseInt(product.stock, 10) === 0 ? 'error' : 
                                    parseInt(product.stock, 10) < 10 ? 'warning' : 'success'}
                              sx={{ borderRadius: '6px' }}
                            />
                          ) : (
                            <Chip
                              size="small"
                              label="Digital"
                              color="info"
                              sx={{ borderRadius: '6px' }}
                            />
                          )}
                        </Box>
                      }
                      secondary={
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">
                              Type: {product.category}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="caption" color="text.secondary">
                              {product.category === 'physical' || product.category === 'food' 
                                ? `Stock: ${product.stock || 0}` 
                                : 'Always Available'}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>
                ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="inventory-dialog-title"
      >
        {userType === 'digital' && renderDigitalView()}
        {userType === 'physical' && renderPhysicalView()}
        {userType === 'mixed' && renderMixedView()}

        <DialogActions sx={{ p: 2, gap: 1 }}>
          <Button 
            onClick={onClose}
            sx={{ color: COLORS.text[customTheme.palette.mode] }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CheckInventoryShortcut;