// D:\directiveai-react\frontend\src\components\AGTUps\services\AGTUps-product-services\pricingStrategyService.js

import { api } from './config';

class PricingStrategyService {
  /**
   * Obtiene las estrategias de pricing para un producto específico
   */
  async getPricingStrategiesByProduct(productId) {
    try {
      const response = await api.get(`/pricing/${productId}`);
      
      if (!response.data?.success) {
        throw new Error('Invalid response from server');
      }

      // Sanitización de executive summary
      const executiveSummary = response.data?.data?.executiveSummary || {};
      const sanitizedExecutiveSummary = {
        marketMetrics: {
          totalMarketSize: Number(executiveSummary.marketMetrics?.totalMarketSize || 0),
          marketShare: Number(executiveSummary.marketMetrics?.marketShare || 0),
          estimatedVolume: Number(executiveSummary.marketMetrics?.estimatedVolume || 0),
          growthRate: Number(executiveSummary.marketMetrics?.growthRate || 0)
        },
        costs: {
          perUnit: Number(executiveSummary.costs?.perUnit || 0),
          fixed: Number(executiveSummary.costs?.fixed || 0),
          total: Number(executiveSummary.costs?.total || 0)
        },
        pricing: {
          current: Number(executiveSummary.pricing?.current || 0),
          previous: Number(executiveSummary.pricing?.previous || 0),
          competitorAvg: Number(executiveSummary.pricing?.competitorAvg || 0)
        },
        projections: {
          revenue: {
            current: Number(executiveSummary.projections?.revenue?.current || 0),
            optimal: Number(executiveSummary.projections?.revenue?.optimal || 0),
            change: Number(executiveSummary.projections?.revenue?.change || 0)
          },
          margin: {
            current: Number(executiveSummary.projections?.margin?.current || 0),
            optimal: Number(executiveSummary.projections?.margin?.optimal || 0),
            change: Number(executiveSummary.projections?.margin?.change || 0)
          },
          profit: {
            current: Number(executiveSummary.projections?.profit?.current || 0),
            optimal: Number(executiveSummary.projections?.profit?.optimal || 0),
            change: Number(executiveSummary.projections?.profit?.change || 0)
          }
        },
        breakEven: {
          units: Number(executiveSummary.breakEven?.units || 0),
          revenue: Number(executiveSummary.breakEven?.revenue || 0)
        },
        competitors: Array.isArray(executiveSummary.competitors)
          ? executiveSummary.competitors.map(comp => ({
              name: String(comp.name || 'Unknown'),
              marketShare: Number(comp.marketShare || 0),
              price: Number(comp.price || 0)
            }))
          : [],
        // Nueva propiedad salesMetrics (reutilizando las métricas calculadas en el módulo de ventas)
        salesMetrics: {
          totalRevenueToday: Number(executiveSummary.salesMetrics?.totalRevenueToday || 0),
          totalRevenueMonthly: Number(executiveSummary.salesMetrics?.totalRevenueMonthly || 0),
          totalRevenueAlltime: Number(executiveSummary.salesMetrics?.totalRevenueAlltime || 0),
          totalSalesToday: Number(executiveSummary.salesMetrics?.totalSalesToday || 0),
          totalSalesMonthly: Number(executiveSummary.salesMetrics?.totalSalesMonthly || 0),
          totalSalesAlltime: Number(executiveSummary.salesMetrics?.totalSalesAlltime || 0)
        }
      };

      // Sanitización de AI Analysis
      const aiAnalysis = response.data?.data?.aiAnalysis || {};
      const sanitizedAiAnalysis = {
        currentAnalysis: {
          optimal: {
            price: Number(aiAnalysis.currentAnalysis?.optimal?.price || 0),
            confidence: Number(aiAnalysis.currentAnalysis?.optimal?.confidence || 0),
            reasoning: Array.isArray(aiAnalysis.currentAnalysis?.optimal?.reasoning)
              ? aiAnalysis.currentAnalysis.optimal.reasoning.map(String)
              : []
          },
          elasticity: {
            coefficient: Number(aiAnalysis.currentAnalysis?.elasticity?.coefficient || 0),
            impact: String(aiAnalysis.currentAnalysis?.elasticity?.impact || 'UNKNOWN')
          },
          competitors: Array.isArray(aiAnalysis.currentAnalysis?.competitors)
            ? aiAnalysis.currentAnalysis.competitors.map(comp => ({
                name: String(comp.name || ''),
                price: Number(comp.price || 0),
                relativeDiff: Number(comp.relativeDiff || 0)
              }))
            : []
        },
        projections: {
          revenue: {
            current: Number(aiAnalysis.projections?.revenue?.current || 0),
            optimal: Number(aiAnalysis.projections?.revenue?.optimal || 0),
            change: Number(aiAnalysis.projections?.revenue?.change || 0)
          },
          margin: {
            current: Number(aiAnalysis.projections?.margin?.current || 0),
            optimal: Number(aiAnalysis.projections?.margin?.optimal || 0),
            change: Number(aiAnalysis.projections?.margin?.change || 0)
          },
          volume: {
            current: Number(aiAnalysis.projections?.volume?.current || 0),
            projected: Number(aiAnalysis.projections?.volume?.projected || 0),
            change: Number(aiAnalysis.projections?.volume?.change || 0)
          }
        },
        recommendations: Array.isArray(aiAnalysis.recommendations)
          ? aiAnalysis.recommendations.map(rec => ({
              action: String(rec.action || ''),
              impact: String(rec.impact || 'LOW'),
              timeframe: String(rec.timeframe || 'SHORT'),
              details: String(rec.details || '')
            }))
          : []
      };

      const sanitizedData = {
        executiveSummary: sanitizedExecutiveSummary,
        owned: Array.isArray(response.data?.data?.owned) 
          ? response.data.data.owned.map(strategy => ({
              productId: String(strategy.productId || ''),
              userId: String(strategy.userId || ''),
              pricingModel: String(strategy.pricingModel || ''),
              marginTarget: Number(strategy.marginTarget || 0),
              currentPrice: Number(strategy.currentPrice || 0),
              competitorPrices: Array.isArray(strategy.competitorPrices) ? strategy.competitorPrices : [],
              status: String(strategy.status || 'draft'),
              createdAt: String(strategy.createdAt || ''),
              updatedAt: String(strategy.updatedAt || '')
            }))
          : [],
        aiAnalysis: sanitizedAiAnalysis,
        shared: Array.isArray(response.data?.data?.shared) ? response.data.data.shared : [],
        costs: response.data?.data?.costs,
        marketAnalysis: response.data?.data?.marketAnalysis
      };

      return { success: true, data: sanitizedData };
    } catch (error) {
      console.error('[FRONTEND][SERVICE] Error en getPricingStrategiesByProduct:', error);
      throw new Error(`Error fetching pricing strategies: ${error.message}`);
    }
  }

  /**
   * Analiza el historial de precios de un producto
   */
  async analyzePriceHistory(productId) {
    try {
      const response = await api.get(`/pricing/${productId}/history`);

      if (!response.data?.success) {
        throw new Error('Invalid response from server');
      }

      const sanitizedData = {
        priceHistory: {
          data: Array.isArray(response.data?.data?.priceHistory?.data) 
            ? response.data.data.priceHistory.data.map(item => ({
                date: String(item.date || ''),
                price: Number(item.price || 0),
                optimal: Number(item.optimal || 0)
              }))
            : [],
          summary: {
            lowestPrice: Number(response.data?.data?.priceHistory?.summary?.lowestPrice || 0),
            highestPrice: Number(response.data?.data?.priceHistory?.summary?.highestPrice || 0),
            averagePrice: Number(response.data?.data?.priceHistory?.summary?.averagePrice || 0),
            averageVolume: Number(response.data?.data?.priceHistory?.summary?.averageVolume || 0),
            peakVolume: Number(response.data?.data?.priceHistory?.summary?.peakVolume || 0)
          }
        },
        competitorPrices: Array.isArray(response.data?.data?.competitorPrices)
          ? response.data.data.competitorPrices.map(comp => ({
              name: String(comp.name || ''),
              price: Number(comp.price || 0),
              relativeDiff: Number(comp.relativeDiff || 0),
              marketShare: Number(comp.marketShare || 0)
            }))
          : [],
        rangeConsiderations: {
          below25: String(response.data?.data?.rangeConsiderations?.below25 || ''),
          slightlyBelow: String(response.data?.data?.rangeConsiderations?.slightlyBelow || ''),
          optimalRange: String(response.data?.data?.rangeConsiderations?.optimalRange || ''),
          slightlyAbove: String(response.data?.data?.rangeConsiderations?.slightlyAbove || ''),
          above25: String(response.data?.data?.rangeConsiderations?.above25 || '')
        },
        marketMetrics: response.data?.data?.marketMetrics
      };

      return { success: true, data: sanitizedData };
    } catch (error) {
      console.error('[FRONTEND][SERVICE] Error en analyzePriceHistory:', error);
      throw new Error(`Error analyzing price history: ${error.message}`);
    }
  }

  /**
   * Calcula el precio óptimo para un producto
   */
  async calculateOptimalPrice(productId, params) {
    try {
      const response = await api.post(`/pricing/${productId}/optimal`, params);

      if (!response.data?.success) {
        throw new Error('Invalid response from server');
      }

      const sanitizedData = {
        suggested: Number(response.data?.data?.suggested || 0),
        metrics: {
          estimatedVolume: Number(response.data?.data?.metrics?.estimatedVolume || 0),
          volumeChange: Number(response.data?.data?.metrics?.volumeChange || 0),
          marketPotential: {
            currentShare: Number(response.data?.data?.metrics?.marketPotential?.currentShare || 0),
            potentialShare: Number(response.data?.data?.metrics?.marketPotential?.potentialShare || 0),
            untappedVolume: Number(response.data?.data?.metrics?.marketPotential?.untappedVolume || 0)
          },
          financials: {
            projectedRevenue: Number(response.data?.data?.metrics?.financials?.projectedRevenue || 0),
            projectedProfit: Number(response.data?.data?.metrics?.financials?.projectedProfit || 0),
            marginAtOptimal: Number(response.data?.data?.metrics?.financials?.marginAtOptimal || 0)
          }
        },
        rangeConsiderations: {
          below25: String(response.data?.data?.rangeConsiderations?.below25 || ''),
          slightlyBelow: String(response.data?.data?.rangeConsiderations?.slightlyBelow || ''),
          optimalRange: String(response.data?.data?.rangeConsiderations?.optimalRange || ''),
          slightlyAbove: String(response.data?.data?.rangeConsiderations?.slightlyAbove || ''),
          above25: String(response.data?.data?.rangeConsiderations?.above25 || '')
        },
        costAnalysis: response.data?.data?.costAnalysis
      };

      return { success: true, data: sanitizedData };
    } catch (error) {
      console.error('[FRONTEND][SERVICE] Error en calculateOptimalPrice:', error);
      throw new Error(`Error calculating optimal price: ${error.message}`);
    }
  }

  /**
   * Actualiza los precios de competidores para un producto
   */
  async updateCompetitorPrices(productId, competitorPrices) {
    try {
      const response = await api.post(`/pricing/${productId}/competitors`, { competitorPrices });
      
      if (!response.data?.success) {
        throw new Error('Invalid response from server');
      }
      
      return response.data;
    } catch (error) {
      console.error('[FRONTEND][SERVICE] Error in updateCompetitorPrices:', error);
      throw new Error(`Error updating competitor prices: ${error.message}`);
    }
  }

  formatChartData(priceHistory) {
    if (!priceHistory) return null;

    return {
      priceHistory: {
        data: Array.isArray(priceHistory.priceHistory?.data) 
          ? priceHistory.priceHistory.data.map(item => ({
              date: String(item.date || ''),
              price: Number(item.price || 0),
              optimal: Number(item.optimal || 0),
              volume: Number(item.volume || 0),
              marketShare: Number(item.marketShare || 0)
            }))
          : [],
        summary: {
          lowestPrice: Number(priceHistory.priceHistory?.summary?.lowestPrice || 0),
          highestPrice: Number(priceHistory.priceHistory?.summary?.highestPrice || 0),
          averagePrice: Number(priceHistory.priceHistory?.summary?.averagePrice || 0),
          averageVolume: Number(priceHistory.priceHistory?.summary?.averageVolume || 0),
          peakVolume: Number(priceHistory.priceHistory?.summary?.peakVolume || 0)
        }
      },
      competitorPrices: Array.isArray(priceHistory.competitorPrices)
        ? priceHistory.competitorPrices.map(comp => ({
            name: String(comp.name || 'Unknown'),
            price: Number(comp.price || 0),
            relativeDiff: Number(comp.relativeDiff || 0),
            marketShare: Number(comp.marketShare || 0)
          }))
        : [],
      trends: {
        priceMovement: String(priceHistory.trends?.priceMovement || ''),
        volumeMovement: String(priceHistory.trends?.volumeMovement || ''),
        marketShareTrend: String(priceHistory.trends?.marketShareTrend || '')
      }
    };
  }
}

export default new PricingStrategyService();
