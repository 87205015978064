// MarketAnalysis.js
import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  LinearProgress,
  Chip,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Alert,
  CircularProgress,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import {
  TrendingUp,
  TrendingDown,
  Users,
  Target,
  Download,
  RefreshCcw,
  AlertCircle,
  Share2,
  Eye,
  UserCheck,
  ShoppingCart,
  Info,
  ArrowUpRight,
  ArrowDownRight,
  Package,
  PieChart as PieChartIcon,
  History
} from 'lucide-react';

import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  PieChart as RechartsPieChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Line,
  Pie,
  Cell,
  Legend
} from 'recharts';

import TouchAppIcon from '@mui/icons-material/TouchApp';

import { api } from '../../services/AGTUps-product-services/config';
import MarketAnalysisService from '../../services/AGTUps-product-services/marketAnalysisService';
import ProductSelector from './ProductSelector';
import MarketAnalysisHistory from './MarketAnalysisHistory';
// <<-- We import the ProductContext -->
import { ProductContext } from '../../../../context/ProductContext';

/* -------------------------------------------------------------------------
   Color System (light/dark).
------------------------------------------------------------------------- */
const colors = {
  light: {
    label: {
      primary: '#000000'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#C7C7CC',
      white: '#FFFFFF',
      black: '#000000',
      green: '#31C859' // Success
    },
    bg: {
      primaryElevated: '#FFFFFF'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#007BFE',
      backgroundBlur: '#FFFFFF',
      base: '#F2F2F7'
    },
    grouped: {
      bgPrimaryBase: '#FFFFFF'
    }
  },
  dark: {
    label: {
      primary: '#FFFFFF'
    },
    system: {
      grey: '#8E8E93',
      grey3: '#464649',
      white: '#FFFFFF',
      black: '#000000',
      green: '#2DD257' // Success in dark mode
    },
    bg: {
      primaryElevated: '#000000'
    },
    fill: {
      tertiary: '#767680'
    },
    ui: {
      accent: '#0385FF',
      backgroundBlur: '#282828',
      base: '#272729'
    },
    grouped: {
      bgPrimaryBase: '#000000'
    }
  }
};

/* -------------------------------------------------------------------------
   Function to get navigation colors based on the mode (light/dark).
------------------------------------------------------------------------- */
const navigationColors = (theme) => {
  const mode = theme.palette.mode;
  const currentColors = mode === 'light' ? colors.light : colors.dark;

  return {
    background: currentColors.bg.primaryElevated,
    textDefault: currentColors.label.primary,
    textSecondary: currentColors.system.grey,
    cardBackground: currentColors.ui.base,
    borderColor: currentColors.system.grey3,
    accent: currentColors.ui.accent,
    success: currentColors.system.green,
    error: '#FF3B30',   // iOS style red
    warning: '#FF9500', // iOS style orange/yellow
    secondary: '#AF52DE' // optional iOS style purple
  };
};

/* -------------------------------------------------------------------------
   Apple-like Styled Components.
------------------------------------------------------------------------- */

/**
 * Translucent paper with rounded borders.
 */
const StyledPaper = styled(Paper)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.8),
    color: navColors.textDefault,
    padding: theme.spacing(3),
    borderRadius: '16px',
    transition: 'all 0.3s ease',
    boxShadow: 'none',
    backdropFilter: 'blur(20px)'
  };
});

/**
 * Small metric card with translucent background.
 */
const MetricCard = styled(Box)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    padding: theme.spacing(2.5),
    borderRadius: '12px',
    backdropFilter: 'blur(10px)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)'
    }
  };
});

/**
 * Select with translucent background and no border.
 */
const StyledSelect = styled(Select)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    backgroundColor: alpha(navColors.cardBackground, 0.5),
    borderRadius: '12px',
    '& .MuiSelect-select': {
      padding: '12px 16px',
      fontSize: '0.95rem',
      fontWeight: 500
    }
  };
});

/**
 * Thin progress bar in Apple style.
 */
const StyledLinearProgress = styled(LinearProgress)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    height: 6,
    borderRadius: 3,
    backgroundColor: alpha(navColors.borderColor, 0.2),
    '& .MuiLinearProgress-bar': {
      borderRadius: 3,
      backgroundColor: navColors.accent
    }
  };
});

/**
 * Translucent chip with no border.
 */
const StyledChip = styled(Chip)(({ theme }) => {
  const navColors = navigationColors(theme);
  return {
    borderRadius: '8px',
    backgroundColor: alpha(navColors.accent, 0.1),
    color: navColors.accent,
    '&:hover': {
      backgroundColor: alpha(navColors.accent, 0.15)
    }
  };
});

/**
 * Overlay that appears over the card when updating.
 */
const LoadingOverlay = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  borderRadius: '16px'
}));

/**
 * Apple-style button with transparency.
 */
const AppleStyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0385FF',
  color: '#FFFFFF',
  borderRadius: '28px',
  fontWeight: 600,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#0385FF',
    transform: 'translateY(-2px)'
  },
  '&:disabled': {
    opacity: 0.6,
    cursor: 'not-allowed'
  }
}));

/**
 * Large centered button to generate AI analysis if none exists.
 */
const CenteredAnalysisButton = ({ onClick, disabled, isGenerating }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: '#0385FF',
        color: '#FFFFFF',
        backdropFilter: 'blur(10px)',
        borderRadius: '28px',
        fontWeight: 600,
        textTransform: 'none',
        padding: '12px 32px',
        fontSize: '1.1rem',
        transition: 'all 0.3s ease',
        '&:hover': {
          backgroundColor: '#0385FF',
          transform: 'translateY(-2px)',
          boxShadow: '0 4px 12px rgba(3, 133, 255, 0.2)'
        },
        '&:disabled': {
          opacity: 0.6,
          cursor: 'not-allowed'
        }
      }}
      startIcon={isGenerating ? (<CircularProgress size={20} color="inherit" />) : null}
    >
      {isGenerating ? 'Generating Analysis...' : 'Generate Analysis with AGT'}
    </Button>
  );
};

/**
 * IMPORTANT: Container with internal scroll and fixed height.
 */
const StyledContainer = styled(Box)(() => ({
  backgroundColor: 'transparent',
  padding: '24px',
  width: '100%',
  height: 'calc(100vh - 180px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px'
  }
}));

/* -------------------------------------------------------------------------
   Main Component: MarketAnalysis
------------------------------------------------------------------------- */
// NOTE: The "products" prop has been removed; we now consume the global context.
const MarketAnalysis = () => {
  const theme = useTheme();
  const navColors = navigationColors(theme);
  const svgColor = theme.palette.mode === 'light' ? '#0056B3' : navColors.accent;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // <<-- Consuming the global product context -->
  const { products, loading: productsLoading } = useContext(ProductContext);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [timeRange, setTimeRange] = useState('6m');
  const [analysisType, setAnalysisType] = useState('overview');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [marketData, setMarketData] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [generatingAI, setGeneratingAI] = useState(false);

  // To show/hide the history dialog
  const [showHistory, setShowHistory] = useState(false);

  /* -----------------------------------------------------------------------
     Load existing analysis the first time a product is selected.
  ------------------------------------------------------------------------ */
  useEffect(() => {
    if (selectedProduct?.id && !initialLoadDone) {
      loadMarketData();
      setInitialLoadDone(true);
    }
  }, [selectedProduct, initialLoadDone]);

  /* -----------------------------------------------------------------------
     Load data every time the timeRange changes (if analysis exists).
  ------------------------------------------------------------------------ */
  useEffect(() => {
    if (selectedProduct?.id && initialLoadDone) {
      loadMarketData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, timeRange, initialLoadDone]);

  /* -----------------------------------------------------------------------
     Function to load the analysis.
  ------------------------------------------------------------------------ */
  const loadMarketData = async () => {
    try {
      setLoading(true);
      setError(null);

      const existingAnalysis = await MarketAnalysisService.getMarketAnalysisByProduct(
        selectedProduct.id,
        { timeRange }
      );

      if (!existingAnalysis.data) {
        setMarketData(null);
      } else {
        setMarketData(existingAnalysis.data);
      }

      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error loading market data: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  /* -----------------------------------------------------------------------
     Generate (or refresh) a new AI analysis (when clicking "refresh").
  ------------------------------------------------------------------------ */
  const handleRefreshData = async () => {
    if (!selectedProduct) return;
    try {
      setUpdating(true);
      const response = await MarketAnalysisService.createAnalysis(selectedProduct.id);
      setMarketData(response.data);
      setLastUpdated(new Date());
    } catch (err) {
      setError(`Error updating analysis: ${err.message}`);
    } finally {
      setUpdating(false);
    }
  };

  /* -----------------------------------------------------------------------
     Export to JSON.
  ------------------------------------------------------------------------ */
  const handleExportData = () => {
    if (!selectedProduct || !marketData) return;
    try {
      const data = {
        marketAnalysis: marketData,
        product: selectedProduct,
        exportDate: new Date().toISOString(),
        timeRange,
        metadata: {
          version: '1.0',
          generatedBy: 'AGT Market Analysis',
          lastUpdated: lastUpdated?.toISOString()
        }
      };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: 'application/json'
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `market-analysis-${selectedProduct.id}-${timeRange}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError(`Error exporting data: ${err.message}`);
    }
  };

  /* -----------------------------------------------------------------------
     Generate AI analysis (initial "Generate Analysis with AGT" button).
  ------------------------------------------------------------------------ */
  const handleGenerateAIAnalysis = async () => {
    if (!selectedProduct?.id) return;
    try {
      setGeneratingAI(true);
      setError(null);

      const response = await api.post(`/market/generate/${selectedProduct.id}`);
      if (response.data?.success) {
        setMarketData(response.data.data);
        setLastUpdated(new Date());
        setInitialLoadDone(true);
      }
    } catch (err) {
      setError(`Error generating AI analysis: ${err.message}`);
    } finally {
      setGeneratingAI(false);
    }
  };

  /* -----------------------------------------------------------------------
     Format data for charts (line and pie charts).
  ------------------------------------------------------------------------ */
  const formattedMarketData = useMemo(() => {
    if (!marketData || !selectedProduct) return null;
    return MarketAnalysisService.formatChartData(marketData, selectedProduct);
  }, [marketData, selectedProduct]);

  /* -----------------------------------------------------------------------
     Calculate metrics (marketFit, growthPotential, etc.).
  ------------------------------------------------------------------------ */
  const metrics = useMemo(() => {
    if (!marketData) return null;
    return MarketAnalysisService.calculateMetrics(marketData);
  }, [marketData]);

  /* -----------------------------------------------------------------------
     Colors for the pie slices (competitors).
  ------------------------------------------------------------------------ */
  const competitorColors = useMemo(() => {
    return [
      navColors.accent,
      navColors.success,
      navColors.warning,
      navColors.secondary,
      navColors.error
    ];
  }, [
    navColors.accent,
    navColors.success,
    navColors.warning,
    navColors.secondary,
    navColors.error
  ]);

  /* -----------------------------------------------------------------------
     Main Render.
  ------------------------------------------------------------------------ */
  return (
    <StyledContainer>
      <Box sx={{ '& > *:not(:last-child)': { mb: 4 } }}>
        {/* Row: Product Selector + "Generate Analysis with AGT" Button */}
        <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
              <Box sx={{ flex: 1, minWidth: 200 }}>
                <ProductSelector
                  products={products}
                  selectedProduct={selectedProduct}
                  onProductSelect={setSelectedProduct}
                  loading={productsLoading}
                  compact
                />
              </Box>
              <AppleStyledButton
                onClick={handleGenerateAIAnalysis}
                disabled={!selectedProduct || generatingAI || productsLoading}
              >
                {generatingAI ? 'Generating...' : 'Generate Analysis with AGT'}
              </AppleStyledButton>
            </Box>
          </Grid>
        </Grid>

        {/* Global loading state from context */}
        {productsLoading && (
          <Box sx={{ textAlign: 'center', py: 5 }}>
            <CircularProgress />
          </Box>
        )}

        {/* Error Alert */}
        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{
              borderRadius: '12px',
              backgroundColor: alpha(theme.palette.error.main, 0.1),
              color: theme.palette.error.main,
              backdropFilter: 'blur(10px)'
            }}
          >
            {error}
          </Alert>
        )}

        {/* Case 1: No product selected */}
        {!selectedProduct && (
          <StyledPaper sx={{ textAlign: 'center', py: 8 }}>
            <Package
              size={48}
              style={{
                marginBottom: '16px',
                color: alpha(navColors.textSecondary, 0.6)
              }}
            />
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
              Select a Product
            </Typography>
            <Typography variant="body2" sx={{ color: navColors.textSecondary }}>
              Choose a product from above to view market analysis
            </Typography>
          </StyledPaper>
        )}

        {/* Case 2: Product selected but NO analysis available */}
        {selectedProduct && !marketData && (
          <>
            {loading ? (
              <Box sx={{ textAlign: 'center', py: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '60vh',
                  textAlign: 'center',
                  px: 3
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    letterSpacing: '-0.02em',
                    marginBottom: 2,
                    color: navColors.textDefault
                  }}
                >
                  No Analysis Available
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: navColors.textSecondary,
                    maxWidth: 600,
                    marginBottom: 4
                  }}
                >
                  Generate a comprehensive market analysis for {selectedProduct.name} using our advanced AI technology.
                </Typography>
                <CenteredAnalysisButton
                  onClick={handleGenerateAIAnalysis}
                  disabled={generatingAI}
                  isGenerating={generatingAI}
                />
              </Box>
            )}
          </>
        )}

        {/* Case 3: Analysis available => Display the analysis */}
        {selectedProduct && marketData && (
          <>
            {/* Controls Bar: Time Range, Analysis Type, Refresh, Export, History */}
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: navColors.textSecondary,
                      '&.Mui-focused': {
                        color: navColors.accent
                      }
                    }}
                  >
                    Time Range
                  </InputLabel>
                  <StyledSelect
                    value={timeRange}
                    label="Time Range"
                    onChange={(e) => setTimeRange(e.target.value)}
                    disabled={loading || updating}
                  >
                    <MenuItem value="1m">Last Month</MenuItem>
                    <MenuItem value="3m">Last 3 Months</MenuItem>
                    <MenuItem value="6m">Last 6 Months</MenuItem>
                    <MenuItem value="1y">Last Year</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: navColors.textSecondary,
                      '&.Mui-focused': {
                        color: navColors.accent
                      }
                    }}
                  >
                    Analysis Type
                  </InputLabel>
                  <StyledSelect
                    value={analysisType}
                    label="Analysis Type"
                    onChange={(e) => setAnalysisType(e.target.value)}
                    disabled={loading || updating}
                  >
                    <MenuItem value="overview">Overview</MenuItem>
                    <MenuItem value="marketShareTrends">Market Share Trends</MenuItem>
                    <MenuItem value="marketPosition">Market Position</MenuItem>
                    <MenuItem value="keyMetrics">Key Metrics</MenuItem>
                    <MenuItem value="marketShareDistribution">Market Share Distribution</MenuItem>
                    <MenuItem value="marketInsights">Market Insights</MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} display="flex" justifyContent="flex-end" gap={1}>
                <Tooltip title="View Analysis History">
                  <AppleStyledButton
                    onClick={() => setShowHistory(true)}
                    startIcon={<History size={16} />}
                    disabled={!selectedProduct}
                  >
                    History
                  </AppleStyledButton>
                </Tooltip>

                <Tooltip
                  title={
                    lastUpdated
                      ? `Last updated: ${lastUpdated.toLocaleString()}`
                      : 'Never updated'
                  }
                >
                  <IconButton onClick={handleRefreshData} disabled={updating || loading}>
                    <RefreshCcw
                      size={20}
                      style={{ color: navColors.accent }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Export Analysis">
                  <IconButton onClick={handleExportData} disabled={loading || !marketData}>
                    <Download size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Share Analysis">
                  <IconButton disabled={loading}>
                    <Share2 size={20} style={{ color: navColors.accent }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            {/* History Dialog */}
            <Dialog
              open={showHistory}
              onClose={() => setShowHistory(false)}
              maxWidth="lg"
              fullWidth
            >
              <DialogContent sx={{ p: 0 }}>
                {selectedProduct && (
                  <MarketAnalysisHistory productId={selectedProduct.id} />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowHistory(false)}>Close</Button>
              </DialogActions>
            </Dialog>

            {loading && !marketData ? (
              <Box sx={{ textAlign: 'center', py: 5 }}>
                <CircularProgress />
              </Box>
            ) : (
              marketData && (
                <>
                  {/* Render based on analysisType */}

                  {/* 1. OVERVIEW => All in one */}
                  {analysisType === 'overview' && (
                    <>
                      {/* Main metrics block */}
                      <StyledPaper>
                        <Box sx={{ mb: 4 }}>
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 700,
                              letterSpacing: '-0.02em',
                              mb: 1
                            }}
                          >
                            {selectedProduct.name}
                          </Typography>
                          <Typography variant="body1" sx={{ color: navColors.textSecondary }}>
                            Market Performance Overview
                          </Typography>
                        </Box>

                        {metrics && (
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                              <MetricCard>
                                <Tooltip
                                  title="The percentage of the total market that your product captures."
                                  arrow
                                  placement="top"
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: navColors.textSecondary,
                                      mb: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                      cursor: 'help'
                                    }}
                                  >
                                    Market Share
                                    <Info size={16} />
                                  </Typography>
                                </Tooltip>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontWeight: 700,
                                    letterSpacing: '-0.02em'
                                  }}
                                >
                                  {marketData?.marketShare}%
                                </Typography>
                                <StyledLinearProgress
                                  variant="determinate"
                                  value={marketData?.marketShare || 0}
                                  sx={{ mt: 2 }}
                                />
                              </MetricCard>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <MetricCard>
                                <Tooltip
                                  title="Indicates how well your product meets market needs."
                                  arrow
                                  placement="top"
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: navColors.textSecondary,
                                      mb: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                      cursor: 'help'
                                    }}
                                  >
                                    Market Fit
                                    <Info size={16} />
                                  </Typography>
                                </Tooltip>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontWeight: 700,
                                    letterSpacing: '-0.02em'
                                  }}
                                >
                                  {metrics?.marketFit || 0}%
                                </Typography>
                                <StyledLinearProgress
                                  variant="determinate"
                                  value={metrics?.marketFit || 0}
                                  sx={{ mt: 2 }}
                                />
                              </MetricCard>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <MetricCard>
                                <Tooltip
                                  title="Percentage increase in sales/users/revenue over a period."
                                  arrow
                                  placement="top"
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: navColors.textSecondary,
                                      mb: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                      cursor: 'help'
                                    }}
                                  >
                                    Growth Rate
                                    <Info size={16} />
                                  </Typography>
                                </Tooltip>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontWeight: 700,
                                    letterSpacing: '-0.02em'
                                  }}
                                >
                                  {metrics?.growthPotential?.potential || 0}%
                                </Typography>
                                <StyledLinearProgress
                                  variant="determinate"
                                  value={metrics?.growthPotential?.potential || 0}
                                  sx={{ mt: 2 }}
                                />
                              </MetricCard>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                              <MetricCard>
                                <Tooltip
                                  title="Number of regions/markets where your product is present."
                                  arrow
                                  placement="top"
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: navColors.textSecondary,
                                      mb: 1,
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 0.5,
                                      cursor: 'help'
                                    }}
                                  >
                                    Market Reach
                                    <Info size={16} />
                                  </Typography>
                                </Tooltip>
                                <Typography
                                  variant="h4"
                                  sx={{
                                    fontWeight: 700,
                                    letterSpacing: '-0.02em'
                                  }}
                                >
                                  {Object.keys(marketData?.geographicDistribution || {}).length} Regions
                                </Typography>
                                <StyledLinearProgress
                                  variant="determinate"
                                  value={Object.keys(marketData?.geographicDistribution || {}).length * 10}
                                  sx={{ mt: 2 }}
                                />
                              </MetricCard>
                            </Grid>
                          </Grid>
                        )}
                      </StyledPaper>

                      {/* Market Share Trends + Market Position + Key Metrics */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                          <StyledPaper style={{ position: 'relative' }}>
                            {updating && (
                              <LoadingOverlay>
                                <CircularProgress />
                              </LoadingOverlay>
                            )}
                            <Box sx={{ mb: 3 }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                  Market Share Trends
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <Tooltip title="Market Share" arrow>
                                    <StyledChip
                                      size="small"
                                      icon={<Eye size={14} />}
                                      label="Market Share"
                                    />
                                  </Tooltip>
                                  <Tooltip title="Competitors" arrow>
                                    <StyledChip
                                      size="small"
                                      icon={<Users size={14} />}
                                      label="Competitors"
                                    />
                                  </Tooltip>
                                  <Tooltip title="Market Potential" arrow>
                                    <StyledChip
                                      size="small"
                                      icon={<Target size={14} />}
                                      label="Market Potential"
                                    />
                                  </Tooltip>
                                </Box>
                              </Box>
                              <Alert
                                severity="info"
                                sx={{
                                  backgroundColor: alpha(navColors.accent, 0.1),
                                  color: navColors.textDefault
                                }}
                              >
                                <Typography variant="body2">
                                  Market share represents the portion of the total market allocated to your product. It indicates what percentage of all sales or consumption in that specific market your product could capture. This metric is essential for assessing your product's competitive position and growth potential relative to the entire market.
                                </Typography>
                              </Alert>
                            </Box>

                            <Box sx={{ height: 320 }}>
                              {formattedMarketData?.marketShare?.data ? (
                                <ResponsiveContainer width="100%" height="100%">
                                  <RechartsLineChart data={formattedMarketData.marketShare.data}>
                                    <CartesianGrid strokeDasharray="3 3" stroke={alpha(navColors.textDefault, 0.2)} />
                                    <XAxis dataKey="name" tick={{ fill: navColors.textDefault }} />
                                    <YAxis tick={{ fill: navColors.textDefault }} />
                                    <RechartsTooltip
                                      contentStyle={{
                                        backgroundColor: alpha(navColors.cardBackground, 0.8),
                                        borderRadius: '8px',
                                        color: navColors.textDefault
                                      }}
                                    />
                                    <Line
                                      type="monotone"
                                      dataKey="marketShare"
                                      name="Market Share"
                                      stroke={navColors.accent}
                                      strokeWidth={2}
                                      dot={{ r: 4 }}
                                      activeDot={{ r: 8 }}
                                    />
                                    <Line
                                      type="monotone"
                                      dataKey="competitors"
                                      name="Competitors"
                                      stroke={navColors.secondary}
                                      strokeWidth={2}
                                      dot={{ r: 4 }}
                                    />
                                    <Line
                                      type="monotone"
                                      dataKey="potential"
                                      name="Market Potential"
                                      stroke={navColors.success}
                                      strokeWidth={2}
                                      dot={{ r: 4 }}
                                    />
                                    <Legend wrapperStyle={{ color: navColors.textDefault }} />
                                  </RechartsLineChart>
                                </ResponsiveContainer>
                              ) : (
                                <Box
                                  sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Typography sx={{ color: navColors.textSecondary }}>
                                    No market share data available
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </StyledPaper>
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <StyledPaper>
                                <Box sx={{ mb: 3 }}>
                                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                                    Market Position
                                  </Typography>
                                  <Alert
                                    severity="info"
                                    sx={{
                                      backgroundColor: alpha(navColors.accent, 0.1),
                                      color: navColors.textDefault
                                    }}
                                  >
                                    <Typography variant="body2">
                                      Your current market positioning.
                                    </Typography>
                                  </Alert>
                                </Box>

                                {marketData ? (
                                  <Box sx={{ display: 'grid', rowGap: 2 }}>
                                    <Tooltip title="Percentage of the market your product dominates." arrow placement="top">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: 1,
                                          cursor: 'help'
                                        }}
                                      >
                                        <Typography variant="body2">Market Share</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          {metrics?.yearOverYear?.growth > 0 ? (
                                            <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                          ) : (
                                            <ArrowDownRight size={16} style={{ color: navColors.error }} />
                                          )}
                                          <Typography variant="body2" sx={{ color: metrics?.yearOverYear?.growth > 0 ? navColors.success : navColors.error }}>
                                            {marketData.marketShare}%
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Tooltip>
                                    <StyledLinearProgress variant="determinate" value={marketData.marketShare} />

                                    <Tooltip title="Measures your strength against direct competitors." arrow placement="top">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: 1,
                                          cursor: 'help'
                                        }}
                                      >
                                        <Typography variant="body2">Competitive Position</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                          <Typography variant="body2" sx={{ color: navColors.success }}>
                                            {metrics?.competitivePosition || 0}%
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Tooltip>
                                    <StyledLinearProgress variant="determinate" value={metrics?.competitivePosition || 0} />

                                    <Tooltip title="Percentage of the total potential market that you cover." arrow placement="top">
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          marginBottom: 1,
                                          cursor: 'help'
                                        }}
                                      >
                                        <Typography variant="body2">Market Penetration</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                          {metrics?.marketPenetration > 0 ? (
                                            <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                          ) : (
                                            <ArrowDownRight size={16} style={{ color: navColors.error }} />
                                          )}
                                          <Typography variant="body2" sx={{ color: metrics?.marketPenetration > 0 ? navColors.success : navColors.error }}>
                                            {metrics?.marketPenetration || 0}%
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Tooltip>
                                    <StyledLinearProgress variant="determinate" value={metrics?.marketPenetration || 0} />
                                  </Box>
                                ) : (
                                  <Box sx={{ textAlign: 'center', py: 3 }}>
                                    <CircularProgress />
                                  </Box>
                                )}
                              </StyledPaper>
                            </Grid>

                            <Grid item xs={12}>
                              <StyledPaper>
                                <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
                                  Key Metrics
                                </Typography>
                                <Alert
                                  severity="info"
                                  sx={{
                                    mb: 3,
                                    backgroundColor: alpha(navColors.accent, 0.1),
                                    color: navColors.textDefault,
                                    '& .MuiAlert-icon': {
                                      color: navColors.accent
                                    }
                                  }}
                                >
                                  <Typography variant="body2">
                                    Metrics that indicate how well your product performs in the market and its growth potential.
                                  </Typography>
                                </Alert>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <Tooltip title="Measures whether your product meets the real needs of your customers." arrow placement="top">
                                      <MetricCard sx={{ cursor: 'help' }}>
                                        <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                          <UserCheck size={20} style={{ color: navColors.accent }} />
                                          <Typography variant="body2">Market Fit</Typography>
                                        </Box>
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                          {metrics?.marketFit || 'N/A'}%
                                        </Typography>
                                      </MetricCard>
                                    </Tooltip>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <Tooltip title="Indicates the future growth potential of your product." arrow placement="top">
                                      <MetricCard sx={{ cursor: 'help' }}>
                                        <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                          <ShoppingCart size={20} style={{ color: navColors.secondary }} />
                                          <Typography variant="body2">Growth Potential</Typography>
                                        </Box>
                                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                          {metrics?.growthPotential?.potential || 'N/A'}%
                                        </Typography>
                                        {metrics?.growthPotential?.confidence && (
                                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                            <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                              {metrics.growthPotential.confidence} confidence
                                            </Typography>
                                          </Box>
                                        )}
                                      </MetricCard>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </StyledPaper>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Market Share Distribution + Market Insights */}
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <StyledPaper>
                            <Box sx={{ mb: 3 }}>
                              <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                                Market Share Distribution
                              </Typography>
                              <Alert
                                severity="info"
                                sx={{
                                  backgroundColor: alpha(navColors.accent, 0.1),
                                  color: navColors.textDefault,
                                  mb: 2,
                                  '& .MuiAlert-icon': {
                                    color: navColors.accent
                                  }
                                }}
                              >
                                <Typography variant="body2">
                                  This chart shows the market share distribution between your product and its main competitors.
                                </Typography>
                              </Alert>
                            </Box>

                            <Box sx={{ height: 320 }}>
                              {formattedMarketData?.competitors ? (
                                <ResponsiveContainer width="100%" height="100%">
                                  <RechartsPieChart>
                                    <Pie
                                      data={formattedMarketData.competitors}
                                      cx="50%"
                                      cy="50%"
                                      innerRadius={60}
                                      outerRadius={100}
                                      dataKey="value"
                                      labelLine={false}
                                      label={({ name, percent }) =>
                                        `${name} (${(percent * 100).toFixed(1)}%)`
                                      }
                                    >
                                      {formattedMarketData.competitors.map((entry, index) => (
                                        <Cell
                                          key={`cell-${index}`}
                                          fill={competitorColors[index % competitorColors.length]}
                                          className="transition-all duration-300 hover:opacity-80"
                                        />
                                      ))}
                                    </Pie>
                                    <RechartsTooltip
                                      content={({ active, payload }) => {
                                        if (!active || !payload?.[0]) return null;
                                        const data = payload[0].payload;
                                        return (
                                          <Box
                                            sx={{
                                              backgroundColor: alpha(navColors.cardBackground, 0.9),
                                              p: 2,
                                              borderRadius: '8px',
                                              color: navColors.textDefault
                                            }}
                                          >
                                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                              {data.name}
                                            </Typography>
                                            <Typography variant="body2">
                                              Market Share: {data.value.toFixed(1)}%
                                            </Typography>
                                            {data.strengths?.length > 0 && (
                                              <Box sx={{ mt: 1 }}>
                                                <Typography variant="caption" sx={{ color: navColors.success }}>
                                                  Strengths: {data.strengths.join(', ')}
                                                </Typography>
                                              </Box>
                                            )}
                                            {data.weaknesses?.length > 0 && (
                                              <Box sx={{ mt: 1 }}>
                                                <Typography variant="caption" sx={{ color: navColors.error }}>
                                                  Weaknesses: {data.weaknesses.join(', ')}
                                                </Typography>
                                              </Box>
                                            )}
                                          </Box>
                                        );
                                      }}
                                    />
                                    <Legend verticalAlign="bottom" height={36} wrapperStyle={{ color: navColors.textDefault }} />
                                  </RechartsPieChart>
                                </ResponsiveContainer>
                              ) : (
                                <Box
                                  sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                  }}
                                >
                                  <Typography sx={{ color: navColors.textSecondary }}>
                                    No competitor data available
                                  </Typography>
                                </Box>
                              )}
                            </Box>

                            {/* Interactive legend */}
                            <Box sx={{ mt: 2, textAlign: 'center' }}>
                              {isMobile ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                  <TouchAppIcon style={{ color: navColors.textSecondary }} />
                                  <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                    Tap on a competitor to view their strengths and weaknesses
                                  </Typography>
                                </Box>
                              ) : (
                                <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                  Click on a competitor to view their strengths and weaknesses
                                </Typography>
                              )}
                            </Box>
                          </StyledPaper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <StyledPaper>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                Market Insights
                              </Typography>
                              <StyledChip label="AI Generated" size="small" />
                            </Box>

                            {marketData?.insights ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Alert
                                  severity="info"
                                  sx={{
                                    backgroundColor: alpha(navColors.accent, 0.1),
                                    color: navColors.textDefault,
                                    mb: 3,
                                    '& .MuiAlert-icon': {
                                      color: navColors.accent
                                    }
                                  }}
                                >
                                  <Typography variant="body2" sx={{ mb: 2 }}>
                                    Intelligent summary of your market position:
                                  </Typography>
                                </Alert>

                                {/* Growth Opportunities */}
                                {marketData.insights.opportunities?.map((opportunity, index) => (
                                  <Alert
                                    key={index}
                                    severity="info"
                                    icon={<TrendingUp />}
                                    sx={{
                                      backgroundColor: alpha(navColors.accent, 0.1),
                                      color: navColors.textDefault,
                                      backdropFilter: 'blur(8px)'
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                      Growth Opportunity
                                    </Typography>
                                    <Typography variant="body2">
                                      {opportunity.description}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                      <StyledChip label={`Impact: ${opportunity.impact}`} size="small" />
                                      {opportunity.timeframe && (
                                        <Chip
                                          label={opportunity.timeframe}
                                          size="small"
                                          sx={{ borderRadius: '8px' }}
                                        />
                                      )}
                                    </Box>
                                  </Alert>
                                ))}

                                {/* Potential Threats */}
                                {marketData.insights.threats?.map((threat, index) => (
                                  <Alert
                                    key={index}
                                    severity="warning"
                                    icon={<AlertCircle />}
                                    sx={{
                                      backgroundColor: alpha(navColors.warning, 0.1),
                                      color: navColors.textDefault,
                                      backdropFilter: 'blur(8px)'
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                      Market Threat
                                    </Typography>
                                    <Typography variant="body2">
                                      {threat.description}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                      <StyledChip
                                        label={`Severity: ${threat.severity}`}
                                        size="small"
                                        sx={{
                                          backgroundColor: alpha(navColors.warning, 0.15),
                                          color: navColors.warning
                                        }}
                                      />
                                      <StyledChip
                                        label={`Probability: ${threat.probability}`}
                                        size="small"
                                        sx={{
                                          backgroundColor: alpha(navColors.warning, 0.15),
                                          color: navColors.warning
                                        }}
                                      />
                                    </Box>
                                  </Alert>
                                ))}

                                {/* Strategic Recommendations */}
                                {marketData.insights.recommendations?.map((recommendation, index) => (
                                  <Alert
                                    key={index}
                                    severity="success"
                                    icon={<Info />}
                                    sx={{
                                      backgroundColor: alpha(navColors.success, 0.1),
                                      color: navColors.textDefault,
                                      backdropFilter: 'blur(8px)'
                                    }}
                                  >
                                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                      Strategic Recommendation
                                    </Typography>
                                    <Typography variant="body2">
                                      {recommendation.strategy}
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                      <StyledChip
                                        label={`Priority: ${recommendation.priority}`}
                                        size="small"
                                        sx={{
                                          backgroundColor: alpha(navColors.success, 0.15),
                                          color: navColors.success
                                        }}
                                      />
                                      <Chip
                                        label={recommendation.expectedImpact}
                                        size="small"
                                        sx={{ borderRadius: '8px' }}
                                      />
                                    </Box>
                                  </Alert>
                                ))}
                              </Box>
                            ) : (
                              <Box>
                                <Alert
                                  severity="info"
                                  sx={{
                                    backgroundColor: alpha(navColors.accent, 0.1),
                                    color: navColors.textDefault,
                                    mb: 3,
                                    '& .MuiAlert-icon': {
                                      color: navColors.accent
                                    }
                                  }}
                                >
                                  <Typography variant="body2" sx={{ mb: 2 }}>
                                    Intelligent summary of your market position:
                                  </Typography>
                                  <Typography variant="body2" component="div">
                                    • Growth opportunities
                                    <br />
                                    • Potential threats
                                    <br />
                                    • Strategic recommendations
                                    <br />
                                    • Relevant trends
                                  </Typography>
                                </Alert>

                                <Box
                                  sx={{
                                    textAlign: 'center',
                                    py: 4,
                                    backgroundColor: alpha(navColors.background, 0.5),
                                    borderRadius: 2
                                  }}
                                >
                                  <Typography sx={{ color: navColors.textSecondary, mb: 1 }}>
                                    No insights available at the moment
                                  </Typography>
                                  <Typography variant="body2" sx={{ color: navColors.textSecondary, opacity: 0.8 }}>
                                    Insights will be generated automatically when sufficient market data is available
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                          </StyledPaper>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* 2. MARKET SHARE TRENDS => Only the chart */}
                  {analysisType === 'marketShareTrends' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper style={{ position: 'relative' }}>
                          {updating && (
                            <LoadingOverlay>
                              <CircularProgress />
                            </LoadingOverlay>
                          )}
                          <Box sx={{ mb: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                Market Share Trends
                              </Typography>
                              <Box sx={{ display: 'flex', gap: 1 }}>
                                <Tooltip title="Market Share" arrow>
                                  <StyledChip
                                    size="small"
                                    icon={<Eye size={14} />}
                                    label="Market Share"
                                  />
                                </Tooltip>
                                <Tooltip title="Competitors" arrow>
                                  <StyledChip
                                    size="small"
                                    icon={<Users size={14} />}
                                    label="Competitors"
                                  />
                                </Tooltip>
                                <Tooltip title="Market Potential" arrow>
                                  <StyledChip
                                    size="small"
                                    icon={<Target size={14} />}
                                    label="Market Potential"
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                            <Alert
                              severity="info"
                              sx={{
                                backgroundColor: alpha(navColors.accent, 0.1),
                                color: navColors.textDefault
                              }}
                            >
                              <Typography variant="body2">
                                Evolution of your market position over time.
                              </Typography>
                            </Alert>
                          </Box>

                          <Box sx={{ height: 320 }}>
                            {formattedMarketData?.marketShare?.data ? (
                              <ResponsiveContainer width="100%" height="100%">
                                <RechartsLineChart data={formattedMarketData.marketShare.data}>
                                  <CartesianGrid strokeDasharray="3 3" stroke={alpha(navColors.textDefault, 0.2)} />
                                  <XAxis dataKey="name" tick={{ fill: navColors.textDefault }} />
                                  <YAxis tick={{ fill: navColors.textDefault }} />
                                  <RechartsTooltip
                                    contentStyle={{
                                      backgroundColor: alpha(navColors.cardBackground, 0.8),
                                      borderRadius: '8px',
                                      color: navColors.textDefault
                                    }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="marketShare"
                                    name="Market Share"
                                    stroke={navColors.accent}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                    activeDot={{ r: 8 }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="competitors"
                                    name="Competitors"
                                    stroke={navColors.secondary}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                  />
                                  <Line
                                    type="monotone"
                                    dataKey="potential"
                                    name="Market Potential"
                                    stroke={navColors.success}
                                    strokeWidth={2}
                                    dot={{ r: 4 }}
                                  />
                                  <Legend wrapperStyle={{ color: navColors.textDefault }} />
                                </RechartsLineChart>
                              </ResponsiveContainer>
                            ) : (
                              <Box
                                sx={{
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Typography sx={{ color: navColors.textSecondary }}>
                                  No market share data available
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  )}

                  {/* 3. MARKET POSITION => "Market Position" section */}
                  {analysisType === 'marketPosition' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper>
                          <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 1 }}>
                              Market Position
                            </Typography>
                            <Alert
                              severity="info"
                              sx={{
                                backgroundColor: alpha(navColors.accent, 0.1),
                                color: navColors.textDefault
                              }}
                            >
                              <Typography variant="body2">
                                Your current market positioning from various perspectives.
                              </Typography>
                            </Alert>
                          </Box>

                          {marketData ? (
                            <Box sx={{ display: 'grid', rowGap: 2 }}>
                              <Tooltip title="Percentage of the market your product dominates." arrow placement="top">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Market Share</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {metrics?.yearOverYear?.growth > 0 ? (
                                      <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                    ) : (
                                      <ArrowDownRight size={16} style={{ color: navColors.error }} />
                                    )}
                                    <Typography variant="body2" sx={{ color: metrics?.yearOverYear?.growth > 0 ? navColors.success : navColors.error }}>
                                      {marketData.marketShare}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>
                              <StyledLinearProgress variant="determinate" value={marketData.marketShare} />

                              <Tooltip title="Measures your strength against direct competitors." arrow placement="top">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Competitive Position</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                    <Typography variant="body2" sx={{ color: navColors.success }}>
                                      {metrics?.competitivePosition || 0}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>
                              <StyledLinearProgress variant="determinate" value={metrics?.competitivePosition || 0} />

                              <Tooltip title="Percentage of the total potential market that you cover." arrow placement="top">
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginBottom: 1,
                                    cursor: 'help'
                                  }}
                                >
                                  <Typography variant="body2">Market Penetration</Typography>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {metrics?.marketPenetration > 0 ? (
                                      <ArrowUpRight size={16} style={{ color: navColors.success }} />
                                    ) : (
                                      <ArrowDownRight size={16} style={{ color: navColors.error }} />
                                    )}
                                    <Typography variant="body2" sx={{ color: metrics?.marketPenetration > 0 ? navColors.success : navColors.error }}>
                                      {metrics?.marketPenetration || 0}%
                                    </Typography>
                                  </Box>
                                </Box>
                              </Tooltip>
                              <StyledLinearProgress variant="determinate" value={metrics?.marketPenetration || 0} />
                            </Box>
                          ) : (
                            <Box sx={{ textAlign: 'center', py: 3 }}>
                              <CircularProgress />
                            </Box>
                          )}
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  )}

                  {/* 4. KEY METRICS => "Key Metrics" section */}
                  {analysisType === 'keyMetrics' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper>
                          <Typography variant="h6" sx={{ fontWeight: 600, marginBottom: 2 }}>
                            Key Metrics
                          </Typography>
                          <Alert
                            severity="info"
                            sx={{
                              mb: 3,
                              backgroundColor: alpha(navColors.accent, 0.1),
                              color: navColors.textDefault,
                              '& .MuiAlert-icon': {
                                color: navColors.accent
                              }
                            }}
                          >
                            <Typography variant="body2">
                              Metrics that indicate how well your product performs in the market and its growth potential.
                            </Typography>
                          </Alert>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Tooltip title="Measures whether your product meets the real needs of your customers." arrow placement="top">
                                <MetricCard sx={{ cursor: 'help' }}>
                                  <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                    <UserCheck size={20} style={{ color: navColors.accent }} />
                                    <Typography variant="body2">Market Fit</Typography>
                                  </Box>
                                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    {metrics?.marketFit || 'N/A'}%
                                  </Typography>
                                </MetricCard>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={6}>
                              <Tooltip title="Indicates the future growth potential of your product." arrow placement="top">
                                <MetricCard sx={{ cursor: 'help' }}>
                                  <Box sx={{ display: 'flex', gap: 1, mb: 1, alignItems: 'center' }}>
                                    <ShoppingCart size={20} style={{ color: navColors.secondary }} />
                                    <Typography variant="body2">Growth Potential</Typography>
                                  </Box>
                                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                    {metrics?.growthPotential?.potential || 'N/A'}%
                                  </Typography>
                                  {metrics?.growthPotential?.confidence && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                      <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                        {metrics.growthPotential.confidence} confidence
                                      </Typography>
                                    </Box>
                                  )}
                                </MetricCard>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  )}

                  {/* 5. MARKET SHARE DISTRIBUTION => PieChart */}
                  {analysisType === 'marketShareDistribution' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper>
                          <Box sx={{ mb: 3 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
                              Market Share Distribution
                            </Typography>
                            <Alert
                              severity="info"
                              sx={{
                                backgroundColor: alpha(navColors.accent, 0.1),
                                color: navColors.textDefault,
                                mb: 2,
                                '& .MuiAlert-icon': {
                                  color: navColors.accent
                                }
                              }}
                            >
                              <Typography variant="body2">
                                This chart shows the market share distribution between your product and its main competitors.
                              </Typography>
                            </Alert>
                          </Box>

                          <Box sx={{ height: 320 }}>
                            {formattedMarketData?.competitors ? (
                              <ResponsiveContainer width="100%" height="100%">
                                <RechartsPieChart>
                                  <Pie
                                    data={formattedMarketData.competitors}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={60}
                                    outerRadius={100}
                                    dataKey="value"
                                    labelLine={false}
                                    label={({ name, percent }) =>
                                      `${name} (${(percent * 100).toFixed(1)}%)`
                                    }
                                  >
                                    {formattedMarketData.competitors.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={competitorColors[index % competitorColors.length]}
                                        className="transition-all duration-300 hover:opacity-80"
                                      />
                                    ))}
                                  </Pie>
                                  <RechartsTooltip
                                    content={({ active, payload }) => {
                                      if (!active || !payload?.[0]) return null;
                                      const data = payload[0].payload;
                                      return (
                                        <Box
                                          sx={{
                                            backgroundColor: alpha(navColors.cardBackground, 0.9),
                                            p: 2,
                                            borderRadius: '8px',
                                            color: navColors.textDefault
                                          }}
                                        >
                                          <Typography variant="subtitle2" sx={{ mb: 1 }}>
                                            {data.name}
                                          </Typography>
                                          <Typography variant="body2">
                                            Market Share: {data.value.toFixed(1)}%
                                          </Typography>
                                          {data.strengths?.length > 0 && (
                                            <Box sx={{ mt: 1 }}>
                                              <Typography variant="caption" sx={{ color: navColors.success }}>
                                                Strengths: {data.strengths.join(', ')}
                                              </Typography>
                                            </Box>
                                          )}
                                          {data.weaknesses?.length > 0 && (
                                            <Box sx={{ mt: 1 }}>
                                              <Typography variant="caption" sx={{ color: navColors.error }}>
                                                Weaknesses: {data.weaknesses.join(', ')}
                                              </Typography>
                                            </Box>
                                          )}
                                        </Box>
                                      );
                                    }}
                                  />
                                  <Legend verticalAlign="bottom" height={36} wrapperStyle={{ color: navColors.textDefault }} />
                                </RechartsPieChart>
                              </ResponsiveContainer>
                            ) : (
                              <Box
                                sx={{
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <Typography sx={{ color: navColors.textSecondary }}>
                                  No competitor data available
                                </Typography>
                              </Box>
                            )}
                          </Box>

                          {/* Interactive legend */}
                          <Box sx={{ mt: 2, textAlign: 'center' }}>
                            {isMobile ? (
                              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                <TouchAppIcon style={{ color: navColors.textSecondary }} />
                                <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                  Tap on a competitor to view their strengths and weaknesses
                                </Typography>
                              </Box>
                            ) : (
                              <Typography variant="caption" sx={{ color: navColors.textSecondary }}>
                                Click on a competitor to view their strengths and weaknesses
                              </Typography>
                            )}
                          </Box>
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  )}

                  {/* 6. MARKET INSIGHTS => "Market Insights" section */}
                  {analysisType === 'marketInsights' && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <StyledPaper>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                              Market Insights
                            </Typography>
                            <StyledChip label="AI Generated" size="small" />
                          </Box>

                          {marketData?.insights ? (
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                              <Alert
                                severity="info"
                                sx={{
                                  backgroundColor: alpha(navColors.accent, 0.1),
                                  color: navColors.textDefault,
                                  mb: 3,
                                  '& .MuiAlert-icon': {
                                    color: navColors.accent
                                  }
                                }}
                              >
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                  Intelligent summary of your market position:
                                </Typography>
                              </Alert>

                              {/* Growth Opportunities */}
                              {marketData.insights.opportunities?.map((opportunity, index) => (
                                <Alert
                                  key={index}
                                  severity="info"
                                  icon={<TrendingUp />}
                                  sx={{
                                    backgroundColor: alpha(navColors.accent, 0.1),
                                    color: navColors.textDefault,
                                    backdropFilter: 'blur(8px)'
                                  }}
                                >
                                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                    Growth Opportunity
                                  </Typography>
                                  <Typography variant="body2">
                                    {opportunity.description}
                                  </Typography>
                                  <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                    <StyledChip label={`Impact: ${opportunity.impact}`} size="small" />
                                    {opportunity.timeframe && (
                                      <Chip
                                        label={opportunity.timeframe}
                                        size="small"
                                        sx={{ borderRadius: '8px' }}
                                      />
                                    )}
                                  </Box>
                                </Alert>
                              ))}

                              {/* Potential Threats */}
                              {marketData.insights.threats?.map((threat, index) => (
                                <Alert
                                  key={index}
                                  severity="warning"
                                  icon={<AlertCircle />}
                                  sx={{
                                    backgroundColor: alpha(navColors.warning, 0.1),
                                    color: navColors.textDefault,
                                    backdropFilter: 'blur(8px)'
                                  }}
                                >
                                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                    Market Threat
                                  </Typography>
                                  <Typography variant="body2">
                                    {threat.description}
                                  </Typography>
                                  <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                    <StyledChip
                                      label={`Severity: ${threat.severity}`}
                                      size="small"
                                      sx={{
                                        backgroundColor: alpha(navColors.warning, 0.15),
                                        color: navColors.warning
                                      }}
                                    />
                                    <StyledChip
                                      label={`Probability: ${threat.probability}`}
                                      size="small"
                                      sx={{
                                        backgroundColor: alpha(navColors.warning, 0.15),
                                        color: navColors.warning
                                      }}
                                    />
                                  </Box>
                                </Alert>
                              ))}

                              {/* Strategic Recommendations */}
                              {marketData.insights.recommendations?.map((recommendation, index) => (
                                <Alert
                                  key={index}
                                  severity="success"
                                  icon={<Info />}
                                  sx={{
                                    backgroundColor: alpha(navColors.success, 0.1),
                                    color: navColors.textDefault,
                                    backdropFilter: 'blur(8px)'
                                  }}
                                >
                                  <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                                    Strategic Recommendation
                                  </Typography>
                                  <Typography variant="body2">
                                    {recommendation.strategy}
                                  </Typography>
                                  <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                                    <StyledChip
                                      label={`Priority: ${recommendation.priority}`}
                                      size="small"
                                      sx={{
                                        backgroundColor: alpha(navColors.success, 0.15),
                                        color: navColors.success
                                      }}
                                    />
                                    <Chip
                                      label={recommendation.expectedImpact}
                                      size="small"
                                      sx={{ borderRadius: '8px' }}
                                    />
                                  </Box>
                                </Alert>
                              ))}
                            </Box>
                          ) : (
                            <Box>
                              <Alert
                                severity="info"
                                sx={{
                                  backgroundColor: alpha(navColors.accent, 0.1),
                                  color: navColors.textDefault,
                                  mb: 3,
                                  '& .MuiAlert-icon': {
                                    color: navColors.accent
                                  }
                                }}
                              >
                                <Typography variant="body2" sx={{ mb: 2 }}>
                                  Intelligent summary of your market position:
                                </Typography>
                                <Typography variant="body2" component="div">
                                  • Growth opportunities
                                  <br />
                                  • Potential threats
                                  <br />
                                  • Strategic recommendations
                                  <br />
                                  • Relevant trends
                                </Typography>
                              </Alert>

                              <Box
                                sx={{
                                  textAlign: 'center',
                                  py: 4,
                                  backgroundColor: alpha(navColors.background, 0.5),
                                  borderRadius: 2
                                }}
                              >
                                <Typography sx={{ color: navColors.textSecondary, mb: 1 }}>
                                  No insights available at the moment
                                </Typography>
                                <Typography variant="body2" sx={{ color: navColors.textSecondary, opacity: 0.8 }}>
                                  Insights will be generated automatically when sufficient market data is available
                                </Typography>
                              </Box>
                            </Box>
                          )}
                        </StyledPaper>
                      </Grid>
                    </Grid>
                  )}
                </>
              )
            )}
          </>
        )}
      </Box>
    </StyledContainer>
  );
};

export default MarketAnalysis;
